import { useState ,useCallback,useEffect, Fragment} from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {CircularProgress,Backdrop,Checkbox,Button,FormControlLabel,Switch, Tooltip} from '@material-ui/core';
import { useStyles } from '../../css/muiStyle';
import ContentEditable from 'react-contenteditable';
import '../../css/catalogue.css'
import {CalendarToday,CheckCircle,Category,RemoveRedEye, DragIndicator} from '@material-ui/icons';
import {AlertCompo,NotAllowedComponent,AutocompleteS, InputNbrEditable} from "../../js/components"
import DialogPub from '../content/modals/cat_add_profil'
import DialogCatAdd from '../content/modals/cat_cat_add'
import TablePagination from '@material-ui/core/TablePagination';
import { useMediaQuery } from 'react-responsive'
import { useContext } from 'react';
import ParamContext from '../../Contexte';
import {ReactComponent as Limit} from '../../img/icons/seuil.svg'

export default function Cat_cat() {
const classes = useStyles();
const Gfunc=require('../../Gfunc')
const param=useContext(ParamContext);
const librery=require('../../js/data_table_librery')
const service='_cat.php'
const [data,setData]=useState([]);
const [at,setAt]=useState([]);
const [icon,setIcon]=useState('')
const [activ,setActiv]=useState('');
const [,setNewI]=useState('');
const [,setNewV]=useState('');
const [,setDat]=useState('');
const [loading, setLoading] = useState(false);
const [totalRows, setTotalRows] = useState(0);
const [rowsPerPage, setRowsPerPage] =useState(10);
const [page, setPage] =useState(1);
const [openA, setOpenA] =useState(false);
const [errMessage,setErrMessage]=useState('');
const [notAllowed,setNotAllowed]=useState(false);
const [messageA, setMessageA] =useState('');
const [severity, setSeverity] =useState('info');
const [switchState, setSwitchState] = useState(false);

const isDesktopOrLaptop = useMediaQuery({query: '(max-width: 850px)'})
 
const handleAlert=(open,severity,message)=>{
  setOpenA(true)
  setSeverity(severity)
  setMessageA(message)
  setTimeout(function(){
    setOpenA(false)
  }, 5000);
  }

const fetch= useCallback(async(page,servic = service, size = rowsPerPage,val) => {
  setLoading(true);
  const response=await librery.axiosGet(page,servic,size)
  setLoading(false);
  if(response.data.err&&response.data.err!=='-1'){
    setErrMessage(response.data.err)
    setNotAllowed(true)
  }else{
  Gfunc.axiosResponse(response,()=>{
  setData(response.data.data)
  setTotalRows(response.data.recordsFiltered)
  setNotAllowed(false)
})
}
},[Gfunc,param.urlService,rowsPerPage]);


useEffect(() => {
  if(data!==undefined&&data.length===0) fetch(1);
},[fetch,data]);

const fetchAt= useCallback(async() => {
  const response = await Gfunc.axiosGetAction('_cpt.php' ,'getactivities')
    if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,"error",response.data.err)
    }else{
    Gfunc.axiosResponse(response,()=>{
        setAt(response.data.jData)  
    })
  }
},[Gfunc,param.urlService]);
const handleOpenAt= useCallback(async () => {
    if(at.length===0)
    fetchAt()
  },[at,fetchAt])


const handleDragEnd =async (e) => {
    if (!e.destination) return null;
    let tempData = Array.from(data);
    let [source_data] = tempData.splice(e.source.index, 1);
    tempData.splice(e.destination.index, 0, source_data); 
    setData(tempData);
    const response = await Gfunc.axiosPostAction1(service ,'order',{this_cat:parseInt(tempData[e.destination.index].DT_RowId.substr(1)),
                                                  before_that:(e.destination.index!==0)?parseInt(tempData[e.destination.index-1].DT_RowId.substr(1)):''})
    if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,"error","vous n'avez pas le droit d'effectuer cette action")
        setData(data)
      }else{
      Gfunc.axiosResponse(response,()=>{
        if(response.data.jData===true){
        //console.log(true)
        }
        })
      }
  };

const handleOnEdit=useCallback(async(e,row,att)=>{
    e.preventDefault();
    const edt=e.target.innerHTML
    const edt1=Gfunc.DeleteSpace(edt)
    if(row[att]!==edt1){
      const attrib=row[att];
      const response=await librery.axiosEdit(service,row,edt1,att) 
      if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,"error",response.data.err)
      }else{
      Gfunc.axiosResponse(response,()=>{row[att]=edt1;e.target.innerHTML=edt1})
      }
      if(response.data.err) {e.target.innerHTML=attrib}
    }else {e.target.innerHTML=edt1}
  },[librery,Gfunc]);

const handleChangePage = (event, newPage) => {
  fetch(newPage+1,service,rowsPerPage);
  setPage(newPage+1);
};

const handleChangeRowsPerPage = (event) => {
    fetch(1,service,(event.target.value==="Tous")?-1:parseInt(event.target.value,10));
    setRowsPerPage((event.target.value==="Tous")?totalRows:event.target.value);
    setPage(1);
};
const flagMan = useCallback(async(e,row,val,srv)=>{
  const response= await Gfunc.axiosPostAction('flagman.php','1',({ srv:srv, id: row.DT_RowId.substr(1),att:val, val: (row[val]===true)?1:0 }))
  if(response.data.err&&response.data.err!=='-1'){
    handleAlert(true,"error",response.data.err)
    if(val==='new'){
      setNewI(row.DT_RowId)
    }else{
      setNewV(row.DT_RowId)
    }
  }else{
  Gfunc.axiosResponse(response,()=>{
    setSwitchState(true)
    const status=(row[val]===true)?"activé":"désactivé"
    if(val==='activ'){
      setIcon(row.DT_RowId) 
      setActiv(row[val])
      const status=(row[val]===true)?"activé":"désactivé"
      handleAlert(true,"success",'Le catalogue est '+status+" avec succès")
    }else{
      const ind=data.findIndex((obj => obj.DT_RowId ===val.DT_RowId))
      if(ind!==-1){
        setDat(row.DT_RowId)
        if(val==='new'){data[ind].new=e.target.checked
                        setData(data)}
        if(val==='vip'){data[ind].vip=e.target.checked
                        setData(data)}
      }
    }
     })
    
  }
},[Gfunc,data]);



  return (
    <div className="content">
       <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA}/>
       <Backdrop className={classes.backdrop} open={loading} >
        <CircularProgress color="inherit" />
       </Backdrop>
       {(notAllowed===false)?
      <div>
      <div  className="table-header">
       <div className='select-flex-wrapper'>
          <DialogCatAdd fetch={fetch}/>
        </div>
     </div>
     </div>:null
     }
     { 
      (notAllowed===false)?
    <div>
      <DragDropContext onDragEnd={handleDragEnd}>
        <table className="table-borderd">
          <Droppable droppableId="droppable-1">
            {(provider) => (
              <tbody
                className="text-capitalize"
                ref={provider.innerRef}
                {...provider.droppableProps}
              >
                {data.map((el,index) => (
                  <Draggable
                    key={el.DT_RowId}
                    draggableId={el.DT_RowId}
                    index={index}
                  >
                    {(provider) => (
                      <Fragment >
                        <tr  {...provider.draggableProps} ref={provider.innerRef}  id="tr-cat">
                        <td  style={{backgroundColor:'white'}} {...provider.dragHandleProps} ref={provider.innerRef} colSpan={2} title='Glisser'> <DragIndicator/></td>
                        <td colSpan={5}>
                          <div id={(isDesktopOrLaptop===false)?'cat-prod':'cat-prod1'}>
                            <div id='cat-img'><DialogPub id={el.DT_RowId} row={el} handleAlert={handleAlert}/></div>
                            <div id="cat-inf">
                            <div id='div-cat-head'>
                             <Tooltip title={(el.activ===true)?'Activé':'Désactivé'}>
                                <div id='div-cat-title'>
                                <ContentEditable
                                    html={(el.titre_fr)?el.titre_fr:''}
                                    name="compte"
                                    data-column="item"
                                    className="content-editable-cat"
                                    disabled={false}
                                    onBlur={e => handleOnEdit(e,el,"titre_fr")}
                                /> 
                                {(icon===el.DT_RowId)?el.activ=!activ:null}
                                  <CheckCircle   style={{color:(el.activ===true)?'green':'gray'}} />
                                </div>
                             </Tooltip>
                             <Tooltip title="Nom du catalogue en arabe">
                              <div id='div-cat-title-1' >
                                <ContentEditable
                                    html={(el.titre_ar)?el.titre_ar:''}
                                    name="nameAr"
                                    data-column="item"
                                    className="content-editable-cat-ar"
                                    disabled={false}
                                    onBlur={e => handleOnEdit(e,el,"titre_ar")}
                                /> 
                              </div>
                             </Tooltip>
                             <Tooltip title="Nom du catalogue en englais">
                              <div id='div-cat-title-1' >
                                  <ContentEditable
                                      html={(el.titre_en)?el.titre_en:'---'}
                                      name="nameEn"
                                      data-column="item"
                                      className="content-editable-cat-ar"
                                      disabled={false}
                                      onBlur={e => handleOnEdit(e,el,"titre_en")}
                                  /> 
                              </div>
                              </Tooltip>
                              </div>
                              <div id='catalogue-info'>
                              <Tooltip title="Date d'ajout" >
                                <div className="div-row" id='date-cat'>
                                  <CalendarToday id='icon' />
                                  <p>{el.date_add}</p>
                                </div>
                              </Tooltip>
                              <Tooltip title="La limite" >
                                <div className="div-row"  id='date-cat'>
                                  <Limit id='icon' />
                                  <InputNbrEditable
                                        service={service} action='upd' min={1} className='content-editable-compte'
                                        row={el} att="limit" value={el.limit} id='DT_RowId' idi={el.DT_RowId}
                                        handleAlert={handleAlert} from='catalogue'
                                  />
                                </div>
                              </Tooltip>
                              <Tooltip title="Activité">
                                <div className="div-row" >
                                  <Category id='icon' />
                                  <AutocompleteS
                                      service={service} action={'upd'} className='content-editable-compte' className1='SelectDT'
                                      defaultValue={el.activite} row={el} list={at} id={'activite_id'} name='activite' attr='nom' from='inf'
                                      onOpen={handleOpenAt} handleAlert={handleAlert}/>
                                </div>
                              </Tooltip  >
                             </div>
                            </div>
                            <div id="div-cat-info">
                               <div id="div-cat-qte">
                                 <b>Nombre de produit(s) : </b><p id='qte'>{el.products}</p>
                               </div>
                               <div id="cat-check">
                                  <FormControlLabel color="primary" id='formcontrol' control={<Checkbox defaultChecked={el.new}  className={classes.checkBox}
                                                    onChange={e=>{flagMan(e,el,"new",'catalog')}} inputProps={{ 'aria-label': 'controlled' }}  />}
                                                    label="New" />  
                                  <FormControlLabel  id='formcontrol' control={<Checkbox defaultChecked={el.vip} className={classes.checkBox}
                                                     onChange={e=>{flagMan(e,el,'vip','catalog')}} inputProps={{ 'aria-label': 'controlled' }}  />}
                                                     label="VIP" />  
                               </div>
                               <div id="cat-btn">
                                <Button  className={(isDesktopOrLaptop===false)?classes.fieldBtn:classes.fieldBtn1} variant="contained"  type='submit' id="bouton" name="addBtn" color="primary" startIcon={<RemoveRedEye fontSize="small"/>}>{(isDesktopOrLaptop===false)?'Voir':''}</Button>
                                <Tooltip title={(el.activ)?el.titre_fr+" est activé":el.titre_fr+" est désactivé"}>
                                  <Button variant="contained" className={(el.activ)?classes.SwitchBtn:classes.fieldBtn} 
                                          startIcon={ <Switch checked={el.activ} style={{color:(el.activ)?'red':'green'}} 
                                          onClick={()=>flagMan('',el,'activ','catalog')}/>}
                                  >
                                    {el.activ ? <p style={{color:"white"}}>DÉSACTIVER</p> : <p style={{color:"white"}}>ACTIVER</p>}
                                  </Button>
                                </Tooltip>
                               </div>
                            </div>
                          </div>                        
                       </td>
                      </tr>
                      <tr style={{height:'12px',border:'none'}}></tr>
                      </Fragment>
                    )}
                  </Draggable>
                ))}
                {provider.placeholder}
              </tbody>

            )}
          </Droppable>
        </table>
      </DragDropContext>
        <TablePagination
          component="div"
          labelRowsPerPage='Élément par page:'
          count={totalRows}
          rowsPerPageOptions={[10,30,50,100,'Tous']}
          page={page-1}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
      />
     </div> :(errMessage!=='')?<div className='compAllow'><NotAllowedComponent message={errMessage}/></div>:<></>
      }   
    </div>
  );
}

