import React ,{useRef,useState,useMemo} from "react";
import DataTable from "react-data-table-component";
import {Button,CircularProgress,Backdrop, Box } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { ValidatorForm} from 'react-material-ui-form-validator';
import {DoneAll,Search} from '@material-ui/icons';
import { customStyles} from '../../css/datatable-custom'
import { useStyles } from '../../css/muiStyle';
import '../../css/reporting.css'
import {FicheClient,NoDataComponent} from '../../js/components'
import { useMediaQuery } from 'react-responsive'
import { useContext } from 'react';
import ParamContext from '../../Contexte';


export default function Rep_obs (){
    const classes = useStyles();
    const [loading,setLoading]=useState(false)
    const [err,setErr]=useState(false)
    const [selectNumber,SetSelectNumber]=useState(15)
    const [search,setSearch]=useState('')
    const [data,SetData]=useState([])
    const [tous,SetTous]=useState([])
    const [doSearch,SetDoSreach]=useState(false)
    const Gfunc=require('../../Gfunc')
    const param=useContext(ParamContext);
    const message='Aucune donnée trouvée'
    const isDesktopOrLaptop = useMediaQuery({query: '(max-width: 850px)'})

    //valider le formulaire 
    const handleSubmit=async()=>{
      setSearch('')
      setLoading(true)
      const response=await Gfunc.axiosPostAction("_rep.php","getobs",{jours:selectNumber})
      setLoading(false)
      Gfunc.axiosResponse(response,()=>{
        SetTous(response.data.jData)
        SetData(response.data.jData)
        if(response.data.jData.length!==0){SetDoSreach(true)}
        setErr(true)
      })
    } 
    //valider formulaire avec touche d'entrer
   const detectTouche= (e)=>{
    if (e.keyCode === 13 && (e.target.name==="addBtn")) {
        if(selectNumber===''){
          alert("Vous devez remplir tous les champs!")
        }
      }
    }
   
    const columns =useMemo(
      () => [ 
        {
          name: "Compte",
          selector:row=> row.compte,
          sortable: true,
       }, 
        {
            name: "Date",
            selector:row=> row.date,
            sortable: true,
        },
        {
          name: "Dernière Connexion ",
          selector:row=> row.last_con,
          sortable: true,
        },
       {
          name: "Webtelco",
          selector:row=> {if(row.airtime!=null){return row.airtime.nom}},
          sortable: true,
       },
       {
          name: "Phone",
          selector:row=> row.phone,
          sortable: true,
       },
       {
        name: "Accessoire",
        selector:row=> row.accessoire,
        sortable: true,
       },
       {
        right:true,
        cell: (row) => {
          return (
            <FicheClient row={row} airtime={row.airtime} nbr='60' val='rep'/>
          )
        }
       },
      ],[]);

 const filtrer=(val)=>{
        var test=tous.filter((el) =>  JSON.stringify(el).toLowerCase().indexOf(val.toLowerCase()) !== -1)
         SetData(test)
     }
 const  HandleOnChange=(val)=>{
         //setSearch(val)
         filtrer(val)
     }
return (
  <div className='content'>
  <Backdrop className={classes.backdrop} open={loading} >
        <CircularProgress color="inherit" />
  </Backdrop>
  <fieldset className="fieldsetTop" id="Nco">
        <legend>Analyser les comptes sans activité depuis</legend>
        <ValidatorForm noValidate id="formAdd" ref={useRef(null)} onSubmit={handleSubmit} onKeyPress={e=>detectTouche(e)}  >
            <div className="rowGrid" id="row-1">
              <TextField required type='number' color="primary" variant="outlined"  label="Nombre de jours" size="small" value={selectNumber}   
                         className='smallText'  inputProps={{ tabIndex: "4" ,className:classes.input1}}        
                         onChange={e =>{SetSelectNumber(e.target.value);}} placeholder="Dernier jours."/>
              <Button className={classes.fieldButton} variant="contained" tabIndex="3" type='submit' id="bouton" name="addBtn" color="primary" 
                      startIcon={<DoneAll/>}>VALIDER</Button>
            </div>
        </ValidatorForm>
  </fieldset>
  { 
   (doSearch===true)?
    <div  className="table-header">
      <div className='search' >
            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <TextField className={classes.search} variant="outlined"  label="Recherche" value={search} 
                           InputProps={{startAdornment: (<Search style={{ marginRight: '5px',zIndex:'1000',width:'20px',height:'20px',
                              cursor:"pointer"}} />),}}
                           size="small" margin='none'  onChange={e =>{HandleOnChange(e.target.value);setSearch(e.target.value)}} /> 
            </Box>
      </div>
    </div>:null
  }
{
  (data.length!==0 || doSearch===true)?
        <DataTable 
              columns={columns}
              data={data}
              defaultSortAsc={true}
              persistTableHead={true}
              noDataComponent={param.ErreurData}
              paginationRowsPerPageOptions={[10,30,50,100]}
              pagination
              highlightOnHover
              paginationComponentOptions={{ rowsPerPageText: 'Élément par page:', rangeSeparatorText: 'sur',selectAllRowsItemText: 'Tous',selectAllRowsItem: true}}
              customStyles={customStyles}   
        />
  :<></>
}
{
  (data.length===0 && err===true && doSearch===false)?<NoDataComponent message={message}/>:<></>
}
</div>   
  );
}