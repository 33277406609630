import { useState ,useCallback,useEffect, Fragment} from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {CircularProgress,Backdrop, Tooltip, Tab, Box, Button, Switch} from '@material-ui/core';
import { useStyles } from '../../css/muiStyle';
import ContentEditable from 'react-contenteditable';
import '../../css/catalogue.css'
import {CheckCircle,DragIndicator, Person, Title} from '@material-ui/icons';
import {AlertCompo,NotAllowedComponent,AutocompleteS, InputNbrEditable} from "../../js/components"
import DialogImg from '../content/modals/cat_bdl_profil'
import DialogCatAdd from '../content/modals/cat_bdl_add'
import TablePagination from '@material-ui/core/TablePagination';
import { useMediaQuery } from 'react-responsive'
import { useContext } from 'react';
import ParamContext from '../../Contexte';
import {ReactComponent as FrFlag} from '../../img/icons/fr.svg'
import {ReactComponent as DzFlag} from '../../img/icons/dz.svg'
import {ReactComponent as UkFlag} from '../../img/icons/uk.svg' 
import {ReactComponent as Date} from '../../img/icons/date.svg' 
import {ReactComponent as Money} from '../../img/icons/money.svg' 
import {TabContext,TabList,TabPanel} from '@material-ui/lab';
import axios from "axios";

export default function Cat_bdl() {
const classes = useStyles();
const Gfunc=require('../../Gfunc')
const param=useContext(ParamContext);
const librery=require('../../js/data_table_librery')
const [data,setData]=useState([]);
const [libId,setLibId]=useState([]);
const [called, setCalled] = useState(false);
const [loading, setLoading] = useState(false);
const [totalRows, setTotalRows] = useState(0);
const [rowsPerPage, setRowsPerPage] =useState(-1);
const [page, setPage] =useState(1);
const [openA, setOpenA] =useState(false);
const [errMessage,setErrMessage]=useState('');
const [notAllowed,setNotAllowed]=useState(false);
const [messageA, setMessageA] =useState('');
const [severity, setSeverity] =useState('info');
const service='_bdl.php'
const srv='bundle'

const isDesktopOrLaptop = useMediaQuery({query: '(max-width: 850px)'})
 
const handleAlert=(open,severity,message)=>{
  setOpenA(true)
  setSeverity(severity)
  setMessageA(message)
  setTimeout(function(){
    setOpenA(false)
  }, 5000);
  }

const fetchLib= useCallback(async() => {
    const response = await axios.get( param.urlService+service+'?do=getlibbdl',{ headers: Gfunc.getHeader()});
    if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,"error",response.data.err)
    }else{
     Gfunc.axiosResponse(response,()=>{
        setLibId(response.data.jData)  
     })
     return response.data.jData
  }
},[Gfunc,param.urlService]);

const fetch= useCallback(async(page,servic = service, size = rowsPerPage,val) => {
  setCalled(true)
  setLoading(true);
  const response=await librery.axiosGet(page,servic,size)
  setLoading(false);
  if(response.data.err&&response.data.err!=='-1'){
    setErrMessage(response.data.err)
    setNotAllowed(true)
  }else{
    Gfunc.axiosResponse(response,async()=>{
      if(response.data.data){
        const resp=await fetchLib();
        response.data.data.map((el,index)=>{ 
          var lib=(Gfunc.getIndex(resp,el.liberoId,"id")!==-1)?resp[Gfunc.getIndex(resp,el.liberoId,"id")].nom:null
          el['libroName']=lib
        })
        setData(response.data.data)
        setTotalRows(response.data.recordsFiltered)
        setNotAllowed(false)
      } 
})
}
},[Gfunc,param.urlService,rowsPerPage,fetchLib]);

useEffect(() => {
  if(!called) fetch(1);
},[fetch,called]);

const handleDragEnd =async (e) => {
    if (!e.destination) return null;
    let tempData = Array.from(data);
    let [source_data] = tempData.splice(e.source.index, 1);
    tempData.splice(e.destination.index, 0, source_data); 
    setData(tempData);
    const response = await Gfunc.axiosPostAction1(service ,'order',{this_bundle:parseInt(tempData[e.destination.index].DT_RowId.substr(1)),
                                                  before_that:(e.destination.index!==0)?parseInt(tempData[e.destination.index-1].DT_RowId.substr(1)):''})
    if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,"error","vous n'avez pas le droit d'effectuer cette action")
        setData(data)
      }else{
      Gfunc.axiosResponse(response,()=>{
        if(response.data.jData===true){
        //console.log(true)
        }
        })
      }
  };

const handleOnEdit=useCallback(async(e,row,att)=>{
    e.preventDefault();
    const edt=e.target.innerHTML
    const edt1=Gfunc.DeleteSpace(edt)
    if(row[att]!==edt1){
      const attrib=row[att];
      const response=await librery.axiosEdit(service,row,edt1,att) 
      if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,"error",response.data.err)
      }else{
      Gfunc.axiosResponse(response,()=>{
        row[att]=edt1;e.target.innerHTML=edt1;
        if(att==="titreFr"){
          Gfunc.updateElemS('title-fix'+row.DT_RowId,edt1)
        }
      })
      }
      if(response.data.err) {e.target.innerHTML=attrib}
    }else {e.target.innerHTML=edt1}
  },[librery,Gfunc]); 

const handleChangePage = (event, newPage) => {
  fetch(newPage+1,service,rowsPerPage);
  setPage(newPage+1);
};

const handleChangeRowsPerPage = (event) => {
    fetch(1,service,(event.target.value==="Tous")?-1:parseInt(event.target.value,10));
    setRowsPerPage((event.target.value==="Tous")?totalRows:event.target.value);
    setPage(1);
};

const flagMan = useCallback(async(e,row,val)=>{
  const response= await Gfunc.axiosPostAction('flagman.php','1',({ srv:srv, id: parseInt(row.DT_RowId.substr(1)),att:val, val:row[val]}))
  if(response.data.err&&response.data.err!=='-1'){
    handleAlert(true,"error",response.data.err)
  }else{
  Gfunc.axiosResponse(response,()=>{
    if(response.data.jData){
      var objIndex = data.findIndex((obj => obj.DT_RowId === row.DT_RowId));
      const nouveauTableau = [...data]; 
      nouveauTableau[objIndex][val] = (row[val]===1)?0:1; 
      setData(nouveauTableau);
    }
  })
    
  }
},[Gfunc,data]);

const Tableau=(props)=>{
  const [value, setValue] =useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return(
   <div id='tab-dig'>
    <Box sx={{ width: '90%' }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} >
            <Tab label={<FrFlag fill='black' style={{height:'20px',width:'20px',padding:"1px"}}/>} value="1" />
            <Tab label={<UkFlag fill='black' style={{height:'20px',width:'20px',padding:"1px"}}/>} value="2" />
            <Tab label={<DzFlag fill='black' style={{height:'20px',width:'20px',padding:"1px"}}/>} value="3" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <Tooltip title="Titre Français">
            <div id='row-grid' >
              <Title id='icon' />
              <div style={{width:'80%'}}>
                  <ContentEditable
                      html={(props.el.titreFr)?props.el.titreFr:''}
                      name="name Fr"
                      data-column="item"
                      disabled={false}
                      className="content-editable-compte"
                      onBlur={e => handleOnEdit(e,props.el,"titreFr")}
                  /> 
              </div>
            </div>
          </Tooltip>
        </TabPanel>
        <TabPanel value="2">
        <Tooltip title="Titre Englais" >
          <div id='row-grid' >
              <Title id='icon' />
              <div style={{width:'80%'}}>
                <ContentEditable
                    html={(props.el.titreEn)?props.el.titreEn:''}
                    name="name en"
                    data-column="item"
                    disabled={false}
                    className="content-editable-compte"
                    onBlur={e => handleOnEdit(e,props.el,"titreEn")}
                /> 
              </div>
          </div>
        </Tooltip>
        </TabPanel>
        <TabPanel value="3">
          <Tooltip title="Titre Arabe" >
            <div id='row-grid' >
                <Title id='icon' />
                <div style={{width:'80%'}}>
                 <ContentEditable
                      html={(props.el.titreAr)?props.el.titreAr:''}
                      name="name ar"
                      data-column="item"
                      disabled={false}
                      className="content-editable-compte"
                      onBlur={e => handleOnEdit(e,props.el,"titreAr")}
                  /> 
                </div >
             </div>
          </Tooltip>
        </TabPanel>
      </TabContext>
    </Box>
  </div>
 )
}

  return (
    <div className="content">
       <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA}/>
       <Backdrop className={classes.backdrop} open={loading} >
        <CircularProgress color="inherit" />
       </Backdrop>
       {(notAllowed===false)?
      <div>
      <div  className="table-header">
       <div className='select-flex-wrapper'>
          <DialogCatAdd fetch={fetch}/>
        </div>
     </div>
     </div>:null
     }
     { 
      (notAllowed===false)?
    <div>
     <DragDropContext onDragEnd={handleDragEnd}>
        <table className="table-borderd">
          <Droppable droppableId="droppable-1">
            {(provider) => (
              <tbody
                className="text-capitalize"
                ref={provider.innerRef}
                {...provider.droppableProps}
              >
                {data.map((el,index) => (
                  <Draggable
                    key={el.DT_RowId}
                    draggableId={el.DT_RowId}
                    index={index}
                  >
                    {(provider) => (
                      <Fragment >
                        <tr  {...provider.draggableProps} ref={provider.innerRef}  id="tr-cat">
                        <td  style={{backgroundColor:'white'}} {...provider.dragHandleProps} ref={provider.innerRef} colSpan={2} title='Glisser'> <DragIndicator/></td>
                        <td colSpan={5}>
                         <div className='bdl-div' >
                           <DialogImg id={el.DT_RowId} handleAlert={handleAlert}/>
                           <div className="bundle-info">
                              <div >
                                <div id='bdl-head'>
                                  <Tooltip title='Titre français'>
                                    <div id='div-bdl-title' >
                                      <div id={'title-fix'+el.DT_RowId} >{el.titreFr}</div>
                                    </div>
                                  </Tooltip>
                                  <CheckCircle  style={{color:(el.activ===1)?'green':'gray'}} />
                                </div>
                                <div>
                                <Tooltip title="Nom libero" >
                                  <div id='div-bdl-libero' >
                                    <AutocompleteS service={service} action={'upd'} className='content-editable-cat-ar' className1='SelectDT'
                                      defaultValue={el.libroName} row={el} list={libId} id={'liberoId'} name='libroName' attr='nom' from='inf'
                                      onOpen={fetchLib} handleAlert={handleAlert}/>
                                  </div>
                                </Tooltip >
                                </div>
                                <div id='div-bdl-det'>
                                <Tooltip title="Date d'ajout" >
                                <div className="div-row" >
                                    <Date fill='black' style={{height:'18px',width:'18px'}}/>
                                    <p style={{marginLeft:'9px'}}>{el.dateAdd}</p>
                                </div>
                                </Tooltip>
                                <Tooltip title="Agent">
                                  <div className="div-row">                                
                                    <Person fill='black' style={{height:'18px',width:'18px'}}/> 
                                    <p style={{marginLeft:'9px'}}>{el.agentAdd}</p>
                                  </div>
                                </Tooltip>
                                <Tooltip title="Prix">
                                  <div id="div-cat-prx" className="div-row">
                                      <Money fill='black' style={{height:'18px',width:'18px'}}/> 
                                      <InputNbrEditable service={service} action='upd' min={1} className='content-editable-compte' 
                                                        from='catalogue' row={el} att="prix" value={el.prix} id='DT_RowId' idi={el.DT_RowId}
                                                        handleAlert={handleAlert} />
                                      <b>DA</b>
                                  </div>
                                </Tooltip>
                                </div>
                              </div>
                              <div>
                                <Tableau el={el}/>
                              </div>
                           </div>
                          </div>
                          <div id='div-bdl-btn'>
                            <Tooltip title={(el.activ)?el.titre_fr+" est activé":el.titre_fr+" est désactivé"}>
                              <Button variant="contained" className={(el.activ)?classes.SwitchBtn:classes.fieldBtn} 
                                      startIcon={ <Switch checked={el.activ} style={{color:(el.activ)?'red':'green'}} 
                                      onClick={()=>flagMan('',el,'activ')}/>}>
                                        {el.activ===1 ? <p style={{color:"white"}}>DÉSACTIVER</p> : <p style={{color:"white"}}>ACTIVER</p>}
                                </Button>
                              </Tooltip>
                          </div>
                        </td>
                      </tr>
                      <tr style={{height:'12px',border:'none'}}></tr>
                      </Fragment>
                    )}
                  </Draggable>
                ))}
                {provider.placeholder}
              </tbody>

            )}
          </Droppable>
        </table>
    </DragDropContext>
    <TablePagination
      component="div"
      labelRowsPerPage='Élément par page:'
      rowsPerPageOptions={[totalRows]}
      count={totalRows}
      //rowsPerPageOptions={[10,30,50,100,'Tous']}
      page={page-1}
      onPageChange={handleChangePage}
      rowsPerPage={totalRows}
      onRowsPerPageChange={handleChangeRowsPerPage}
      />
   </div> :(errMessage!=='')?<div className='compAllow'><NotAllowedComponent message={errMessage}/></div>:<></>
   }   
  </div>
  );
}

