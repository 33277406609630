import React ,{useCallback,useState,useMemo,useEffect} from "react";
import DataTable from "react-data-table-component";
import {customStyles,iconCell} from '../../css/datatable-custom'
import {Search,FiberManualRecord} from '@material-ui/icons';
import {Grid,ClickAwayListener,Backdrop,CircularProgress, Box} from '@material-ui/core';
import ContentEditable from 'react-contenteditable';
import TextField from '@material-ui/core/TextField';
import {AlertCompo,InputNbrEditable,NotAllowedComponent} from "../../js/components"
import DialogAddCol from '../../components/content/modals/cat_add_col'
import { useContext } from 'react';
import { useStyles } from '../../css/muiStyle';
import ParamContext from '../../Contexte';


export default function Cat_col(){
  const classes=useStyles()
   const Gfunc=require('../../Gfunc')
   const param=useContext(ParamContext);
   const librery=require('../../js/data_table_librery')
   const [cpt,setCpt]=useState([])
   const [search,setSearch]=useState('')
   const [loading, setLoading] = useState(false);
   const [totalRows, setTotalRows] = useState(0);
   const [perPage, setPerPage] = useState(10);
   const [currentPage, setCurrentPage] = useState(1);
   const [openA, setOpenA] =useState(false);
   const [errMessage,setErrMessage]=useState('');
   const [notAllowed,setNotAllowed]=useState(false);
   const [messageA, setMessageA] =useState('');
   const [severity, setSeverity] =useState('info');
   const [selecto,setSelecto]=useState('')
   const [Item,setItem]=useState('')
   const [ItemN,setItemN]=useState('')
   const [val,setVal]=useState('')
   const [id,setid]=useState('')
   const service='_col.php'
  

  const handleAlert=(open,severity,message)=>{
    setOpenA(true)
    setSeverity(severity)
    setMessageA(message)
    setTimeout(function(){
      setOpenA(false)
    }, 5000);
  }
  const fetch= useCallback(async(page,servic = service, size = perPage,val) => {
      setLoading(true);
      const response=await librery.axiosGet(page+'&search='+((val!==undefined)?val:''),servic,size)
      setLoading(false);
      if(response.data.err&&response.data.err!=='-1'){
        setErrMessage(response.data.err)
        setNotAllowed(true)
      }else{
      Gfunc.axiosResponse(response,()=>{
        setCpt(response.data.data)  
        setTotalRows(response.data.recordsFiltered)
    })
  }
  },[Gfunc,param.urlService,perPage]);

    
  useEffect(() => {
    if(cpt!==undefined&&cpt.length===0) fetch(1);
  },[fetch,cpt]);
  

  //edit champs
 const handleOnEdit=useCallback(async(e,row,att)=>{
    e.preventDefault();
    const edt=e.target.innerHTML
    const edt1=Gfunc.DeleteSpace(edt)
        if(row[att]!==edt1){
          const attrib=row[att];
          const response=await librery.axiosEdit(service,row,edt1,att) 
          if(response.data.err&&response.data.err!=='-1'){
            handleAlert(true,"error",response.data.err)
          }else{
          Gfunc.axiosResponse(response,()=>{row[att]=edt1;e.target.innerHTML=edt1})
          }
          if(response.data.err) {e.target.innerHTML=attrib}
        }else {e.target.innerHTML=edt1}
  },[librery,Gfunc]);

  const handleOnEditColor=useCallback(async(e,row,att)=>{ 
    e.preventDefault();
        const edt=e.target.value.substr(1)
        if(row[att]!==edt){
          const attrib=row[att];
          const response=await librery.axiosEdit(service,row,edt,att) 
          if(response.data.err&&response.data.err!=='-1'){
            handleAlert(true,"error",response.data.err)
          }else{
          Gfunc.axiosResponse(response,()=>{
            setSelecto('');setItem('');setItemN('');
            setid(row.DT_RowId)
            setVal(edt)
            row.color=edt
            //e.target.innerHTML=e.target.value
          })
          }
          if(response.data.err) {e.target.innerHTML=attrib}
        }else {e.target.innerHTML=edt}
  
  },[Gfunc,librery])
 
 const test=useCallback((row,att)=>{
   setSelecto(row[att])
   setItem(row.DT_RowId)
   setItemN(att)
 },[])
  const columns =useMemo(
    () => [ 
      {
        width:"5px",
        style:iconCell,
        cell: (row) => {
          return (
            <FiberManualRecord id='icon' style={{color:row.color}}/>
           );
         },
      },
      {
        name: "Nom",
        selector:row=> row.nom,
        sortable: true,
        sortField:"nom",
        cell: (row) => {
          if(row.DT_RowId===id){
            row.color=val
          }
          return (
            <div className="addedCell">
              <ContentEditable
                html={(row.nom)?row.nom.toString():''}
                name="compte"
                data-column="item"
                className="content-editable-edit"
                disabled={false}
                onBlur={e => handleOnEdit(e,row,"nom")}
              />
           </div>
           );
         },
      },
     {
        name: "Code",
        selector:row=> row.color,
        sortable: true,
        sortField:"color",
        cell: (row) => {
          return (
            <div className="addedCell">
             {
            (row.DT_RowId===Item && row.color===selecto && ItemN==="color")?
            <ClickAwayListener onClickAway={()=>setItem('')}>
            <div>
              <input type="color" id="head" name="head" disablePortal defaultValue={'#'+row.color} style={{height:'18px',border:'none',padding: '0px'}} 
               onBlur={e=>handleOnEditColor(e,row,'color')} />
            </div>
            </ClickAwayListener>:(row.color)?<div className="content-editable-edit" onClick={()=>test(row,'color')}>{row.color}</div> 
                  :<div className="div-vide" onClick={()=>{test(row,'color')}}></div>         
            }
           </div>
           );
         },
     },
     {
        name: "Alpha",
        selector:row=> row.alpha,
        sortable: true,
        sortField:"alpha",
        cell: (row) => {
          return (
            <div className="addedCell">
             <ContentEditable
                html={(row.alpha)?row.alpha.toString():''}
                name="compte"
                data-column="item"
                className="content-editable-edit"
                disabled={false}
                onBlur={e => handleOnEdit(e,row,"alpha")}
             />
             
         </div>
           );
         },
     },
     {
      name: "Opacité",
      selector:row=> row.opacity,
      sortable: true,
      sortField:"opacity",
      cell: (row) => {
        return (
              <InputNbrEditable
                  service={service} action='upd' min={0} max={1} className="content-editable-edit"
                  row={row} att="opacity" value={row.opacity} id='DT_RowId' idi={row.DT_RowId} from="color"
                  handleAlert={handleAlert}
               />
        );
       }
    },
    {
      name: "Date",
      selector:row=> row.date,
      sortable: true,
      sortField:"date",
    }
    ],[Item,ItemN,selecto,handleOnEditColor,test,val,id,handleOnEdit]);
  
function handlePageChange (page) {
  fetch(page,service,perPage,search);
  setCurrentPage(page);
}
const handlePerRowsChange = async (newPerPage, page) => {
   fetch(page,service, newPerPage,search);
   setPerPage(newPerPage);
}

const handleSearch=async(size = perPage)=>{
     setLoading(true);
     const recherche=search;
     const response=await librery.axiosSearch(service,recherche,1,size,'get')
     setLoading(false);
     if(response.data.err&&response.data.err!=='-1'){
       handleAlert(true,'error',response.data.err)
     }else{
     Gfunc.axiosResponse(response,()=>{
       setCurrentPage(1)
       setCpt(response.data.data);
       setTotalRows(response.data.recordsFiltered);
     })  
    } 
     return recherche
     }
   
 const handleOnSort=async(column, sortdirection,page=currentPage, size = perPage)=>{
   setLoading(true);
   const response=await librery.axiosSort(column,sortdirection,1,size,service,'get')
   setLoading(false);
   if(response.data.err&&response.data.err!=='-1'){
     handleAlert(true,'error',response.data.err)
   }else{
   Gfunc.axiosResponse(response,()=>{
    setCurrentPage(1)
     setCpt(response.data.data);
     setTotalRows(response.data.recordsFiltered);
   })  
  }   
}

function liveSearch(e,search){
  if(param.liveSearch&&search.length>3){
     e.preventDefault();
     handleSearch();
  }else{ if (e.keyCode === 13) {
            e.preventDefault();
            handleSearch();
          }
  }
}

 return (
  <div className="content">
      <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA}/>
      <Backdrop className={classes.backdrop} open={loading} >
        <CircularProgress color="inherit" />
      </Backdrop>
      {(notAllowed===false)?
      <div>
      <div  className="table-header">
        <DialogAddCol fetch={fetch}/>
        <div className='search' >
            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <TextField className={classes.search} variant="outlined"  label="Recherche" value={search} 
                               InputProps={{startAdornment: (<Search onClick={() => handleSearch()}
                               style={{ marginRight: '5px',zIndex:'1000',width:'20px',height:'20px',cursor:"pointer"}} />),}}
                               size="small" margin='none'  onKeyUp={e => liveSearch(e)} onChange={e => setSearch(e.target.value)} /> 
            </Box>
        </div>
     </div>
     </div>:null
     }
{ 
    (notAllowed===false)?
     <DataTable 
              columns={columns}
              data={cpt}
              defaultSortAsc={true}
              persistTableHead={true}
              //progressPending={loading}
              noDataComponent={param.ErreurData}
              pagination
              paginationServer
              paginationRowsPerPageOptions={[10,30,50,100]}
              paginationTotalRows={totalRows}
              paginationDefaultPage={currentPage}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              paginationComponentOptions={{ rowsPerPageText: 'Élément par page:', rangeSeparatorText: 'sur',selectAllRowsItemText: 'Tous',selectAllRowsItem: true}}
              sortServer={true}
              onSort={handleOnSort}
              highlightOnHover
              customStyles={customStyles}   
            />:<div className='compAllow'><NotAllowedComponent message={errMessage}/></div>
        }
        
  </div>
  );
 }