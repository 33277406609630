import React,{useState} from 'react';
import {DialogContent,Typography,Paper,Dialog,IconButton} from '@material-ui/core';
import Draggable from 'react-draggable';
import {paperPropsPh } from '../../../css/muiStyle';
import {Close} from '@material-ui/icons';
import { withStyles} from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Person from '../../../img/person.png'
import { useContext } from 'react';
import ParamContext from '../../../Contexte';
import '../../../css/compte.css'


const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });
const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle  className={classes.root} {...other}>
        <Typography >{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <Close />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

//Add a new role
function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function Cmd_acc_profil(props) {
  const param=useContext(ParamContext);
    const [open,setOpen]=useState(false)

    const handleClickOpen = () => { 
        setOpen(true); 
      };
    const handleClose = () => { setOpen(false); };

  return (
    <div>
     <img src={param.userProfileImg+"/"+props.row.accountId+'.jpg'} style={{cursor:'pointer'}}
          alt='photo de client' className='client-profile-img' onClick={handleClickOpen}
          onError={(e) => {
          e.target.onerror = null
          e.target.src = Person }}/> 
        <Dialog 
            open={open}
            onClose={handleClose}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={paperPropsPh}
        >
        <DialogTitle id="simple-dialog-title" onClose={handleClose}/>
            <DialogContent>
            <div style={{width:'100%',height:'100%'}}>
                <img src={param.userProfileImg+"/"+props.row.accountId+'.jpg'} alt='photo de client' onClick={handleClickOpen}
                     style={{width:'100%',height:'100%'}}
                     onError={(e) => {
                     e.target.onerror = null
                     e.target.src = Person }}/>
            </div>
        </DialogContent>
      </Dialog>
      </div>
  );
}
