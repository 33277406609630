import React, { useState } from 'react';
import { Popover, IconButton, TextField, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import {FiberManualRecord } from '@material-ui/icons';


export default function ColorDropdown (props){
  const [searchInput, setSearchInput] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);

  const handleColorSelection = (color,index) => {
    setAnchorEl(null);
    props.handleOnEdit(color,props.data,'colorId');
  };

  const filteredColors = props.col.filter((color) =>
    color.nom.toLowerCase().includes(searchInput.toLowerCase())
  )

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const HandleChange=(e)=>{
    setSearchInput(e.target.value)    
  }

  return (
    <>
      <IconButton onClick={handleOpen}>
         <FiberManualRecord style={{color:'#'+props.data.colorCode}} />      
      </IconButton>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{vertical: "bottom", horizontal: "left" }}
        transformOrigin={{vertical: "top", horizontal: "left" }}
        style={props.style}
      >
        <div>
          <TextField
            label="Rechercher une couleur"
            value={searchInput}
            onChange={(e) => HandleChange(e)}
          />
          <List>
            {filteredColors.map((option,index) => (
              <ListItem button key={index} onClick={() => handleColorSelection(option,index)}>
                <ListItemIcon>
                  <FiberManualRecord style={{color:(option.color.includes('#'))?option.color:'#'+option.color}} />
                </ListItemIcon>
                <ListItemText primary={option.nom} />
              </ListItem>
            ))}
          </List>
        </div>
      </Popover>
    </>
  );
};

