import React,{useState,useContext} from 'react';
import {DialogContent,Typography,Paper,Dialog,IconButton, Divider,CircularProgress,CardActionArea, CardContent,TablePagination, Grid, TextField} from '@material-ui/core';
import{ ToggleButton,ToggleButtonGroup} from '@mui/material';
import Draggablee from 'react-draggable';
import {paperPropsComs } from '../../../css/muiStyle';
import {Close, FiberManualRecord, Search} from '@material-ui/icons';
import '../../../css/compte.css'
import { withStyles} from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Err from "../../../img/photo.png"
import PlacePhoto from "../../../img/photoCAdd.png"
import ParamContext from '../../../Contexte';
import { AlertCompo, NoDataComponent } from '../../../js/components';
import axios from 'axios'

const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });
const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle  className={classes.root} {...other}>
        <Typography >{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <Close />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

//Add a new role
function PaperComponent(props) {
  return (
    <Draggablee handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggablee>
  )
}

export default function Cat_prd_profil_add(props) {
    const param=useContext(ParamContext);
    const Gfunc=require('../../../Gfunc')
    const librery=require('../../../js/data_table_librery')
    const [totalRows, setTotalRows] = useState(0);
    const [rowsPerPage, setRowsPerPage] =useState(5);
    const [page, setPage] =useState(1);
    const [selectedImage, setSelectedImage] = useState('');
    const [loadingExp,setLoadingExp]=useState(false)
    const [open,setOpen]=useState(false)
    const [imgs,setImgs]=useState([])
    const [openA, setOpenA] =useState(false)
    const [test, settest] =useState(false)
    const [messageA, setMessageA] =useState('');
    const [severity, setSeverity] =useState('info');
    const [search,setSearch]=useState('');
    const service='_prd.php'
    
    const handleAlert=(open,severity,message)=>{
        setOpenA(true)
        setSeverity(severity)
        setMessageA(message)
        setTimeout(function(){
          setOpenA(false)
        }, 5000);
    }

    const handleClickOpen = () => { 
        setOpen(true);setImgs([]);settest(false);setSelectedImage('');setSearch('');setLoadingExp(false)
        getImg(1,service,search,rowsPerPage);
    };
    const handleClose = () => { setOpen(false); setImgs([]);settest(false);setSelectedImage('');setSearch('');setLoadingExp(false)};

    const getImg=async(page,service,search,rowsPerPage)=>{ 
      var arr=[];
      setLoadingExp(true);
      const response = await axios.get( param.urlService+service+'?do=getcphoto&page='+(page)+"&per_page="+rowsPerPage+
                      '&catalogId='+props.data.catalogId+'&search='+search+'&produitId='+props.data.DT_RowId.substr(1),{ headers: Gfunc.getHeader()});
      setLoadingExp(false);
      if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,'error',response.data.err)
      }else{
        Gfunc.axiosResponse(response,()=>{
          setTotalRows(response.data.recordsFiltered)
          if(response.data.data){
            response.data.data.map((el)=>{
              var objIndex = props.imgs.findIndex((obj => obj.photoId ===el.photoId));
              if(objIndex===-1){
                arr.push(el)
              }
            })
            setImgs(arr)
          }
      })
      }
    }

    const handleImageSelect = async(event, newSelectedImage) => {
        setSelectedImage(newSelectedImage);
        const response = await Gfunc.axiosPostAction(service ,'addphcat',{produitId:parseInt(props.data.DT_RowId.substr(1)),photoId:parseInt(newSelectedImage.substr(1))})
        if(response.data.err&&response.data.err!=='-1'){
          handleAlert(true,"error",response.data.err)  
        }else{
          Gfunc.axiosResponse(response,()=>{
            setOpen(false)
            props.imgs.push({photoId:parseInt(newSelectedImage.substr(1)),main:false,ordre:null})
            props.handleAlert(true,'success'," L'image a été ajouté avec succès")
        })
        }
      };

    const handleChangePage = (event, newPage) => {
      getImg(newPage+1,service,search,rowsPerPage);
      setPage(newPage+1);
    };
    
    const handleChangeRowsPerPage = (event) => {
        getImg(1,service,search,(event.target.value==="Tous")?-1:parseInt(event.target.value,10));
        setRowsPerPage((event.target.value==="Tous")?totalRows:event.target.value);
        setPage(1);
    };

    const handleSearch=async(size = rowsPerPage)=>{   
      setLoadingExp(true);
      const recherche=search;
      const response=await librery.axiosSearch(service,recherche,1,size,'getcphoto&catalogId='+props.data.catalogId+'&produitId='+props.data.DT_RowId.substr(1))
      setLoadingExp(false);
      if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,'error',response.data.err)
      }else{
      Gfunc.axiosResponse(response,()=>{
         setImgs(response.data.data||[])
         setTotalRows(response.data.recordsFiltered)
         if(response.data.data===undefined){
          settest(true)
         }
      
      })   
    }
      return recherche
      }
 
   function liveSearch(e,search){
     if(param.liveSearch&&search.length>3){
        e.preventDefault();
        handleSearch();
     }else{ if (e.keyCode === 13) {
               e.preventDefault();
               handleSearch();
             }
     }
   }
   
   function liveSearch2() {
     handleSearch();
   }
  
  return (
    <div >
     <Paper style={{border:'1px solid #f0eaea',borderRadius: '8px',backgroundColor:'white',maxHeight:'280px',justifyContent:'center'}} elevation={5}>
      <CardActionArea>
        <CardContent>
          <img src={PlacePhoto} onClick={handleClickOpen} style={{height:'220px',width:'100%',cursor:'pointer'}}
               onError={(e) => {
               e.target.onerror = null
               e.target.src = Err }}/>
        </CardContent>
      </CardActionArea>
     </Paper>
    <Dialog 
            open={open}
            onClose={handleClose}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={paperPropsComs}
    >
        <DialogTitle id="simple-dialog-title" onClose={handleClose}></DialogTitle>
        <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA}/>
        <DialogContent> 
            <div id='div-name' style={{marginBottom:'5px',marginLeft:'10px',fontSize:'14px',fontWeight:'bold',marginLeft:'8px'}}>
                <p >{props.data.nameFr}</p>
                <FiberManualRecord style={{color:props.data.colorCode}}/>
            </div>
            <Divider /><br/>  
            <div > 
           { (loadingExp)?<div id="empty-expender"><CircularProgress /></div>
            :(imgs.length!==0&&!test)?
            <div className='div-search'>
            <div className='search' >
              <Grid container spacing={1} alignItems="flex-end" onKeyUp={e=>liveSearch(e)} placeholder='rechercher' >
                <Grid item>
                  <Search onClick={(e)=>liveSearch2(e)}/>
                </Grid>
                <Grid item>
                  <TextField id="input-with-icon-grid" label="Recherche" value={search} onChange={e =>setSearch(e.target.value)} />
                </Grid>
              </Grid>
            </div>
            <div id='div-ButtonG'>
            <ToggleButtonGroup exclusive value={selectedImage} onChange={handleImageSelect} 
                               sx={{
                                    "& .MuiToggleButton-root": {
                                      padding:0.5,
                                    },
                                    "& .MuiToggleButton-root:last-of-type": {
                                      padding: 0.5,
                                      marginLeft:1,
                                      marginBottom:1
                                    },
                                    "& .MuiToggleButton-root:not(:last-child)": {
                                      marginLeft:1,
                                      marginBottom:1,
                                    },
                                    "& .MuiToggleButton-root:not(:first-child)": {
                                      borderLeft:'1px solid rgba(0, 0, 0, 0.12)',
                                    },
                                    "& .Mui-selected": {
                                      bgcolor: 'green',
                                    },
                                }}
                >
                {
                  imgs.map((el,index)=>{
                    return (
                        <ToggleButton value={el.DT_RowId} key={index}>
                           <img src={param.ProdImg+el.DT_RowId.substr(1)+'.jpg'} alt={"image"+el.DT_RowId} id='product-image' 
                                onError={(e) => {
                                    e.target.onerror = null
                                    e.target.src = Err }}/>
                        </ToggleButton>
                    )
                  })
                }
               
            </ToggleButtonGroup>
            </div>
            <TablePagination
              component="div"
              labelRowsPerPage='Élément par page:'
              count={totalRows}
              rowsPerPageOptions={[5,10,'Tous']}
              page={page-1}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
            </div>:<NoDataComponent message="Aucun résultat trouvé" produit='produit' className='notfound1'/>
            }
            </div>
        </DialogContent>
    </Dialog>
      </div>
  );
}
