import React, { useEffect, useState } from 'react'
import '../css/global.css'
import err from "../img/default.jpg"

export default function Empty(props) {
    const [message,setMessage]=useState(false)
    const getMessage=()=>{
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        if(urlParams.has('message')) {setMessage( urlParams.get('message'))}
        else setMessage(false)
    }

    useEffect(()=>{
        getMessage()
    },[getMessage])

    return (

<div className="container">
            <div className="error-template">
               <img src={err} alt="err" id='err'/>  
                <h1>{(message)?message:(props.message)?props.message:"Problème de navigation, Vérifier votre Connexion"}</h1>           
                <p>{/*Consulter le service client*/}</p>
                <div className="error-actions">
                {(!props.hideButton)?<button onClick={()=>window.location=('/')} className='btn' style={{ textDecoration: 'none',color:'black' }}>Retour à l'accueil </button>:null}          
               </div>
            </div>
</div>

    )
}

