import React,{useState,useMemo,createRef,useCallback ,useEffect} from 'react';
import {DialogContent,Paper,Dialog,Button,DialogActions,Collapse,Typography,TextField,IconButton,CircularProgress, Card} from '@material-ui/core';
import Tooltipp from '@material-ui/core/Tooltip';
import { ValidatorForm} from 'react-material-ui-form-validator';
import { Person,Search,AttachMoney,Room,StoreMallDirectory,LocalPhone,AccessAlarms,Usb, Note, Mail} from '@material-ui/icons';
import Personn from '../img/icons/person.svg'
import Draggable from 'react-draggable';
import { withStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import  {useStyles} from '../css/muiStyle';
import  {customStyles} from '../css/datatable-custom';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import NoData from '../img/nodata-found.jpg'
import NoAjust from '../img/noajust.png'
import NotAllowed from '../img/sorryicon.png'
import '../css/global.css'
import Profil from '../../src/components/content/modals/cpt_new_profil'
import LineCharts  from '../compo/LineChart';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useContext } from 'react';
import ParamContext from '../Contexte';
import DynamicBarchart from '../compo/DynamicBarChar';
import MapLocation from '../compo/MapLocation';
import {ReactComponent as FavCompte} from '../img/icons/Fav.svg'
import DataTable from "react-data-table-component";


const Gfunc=require('../Gfunc')
const librery=require('./data_table_librery')

const paperProps={
    style:{
      width:"700px",
      maxWidth:"2000px",
      maxHeight:"95%",
     // height:"1000px",
      overflow:"hidden"
    }
 }
const paperPropsD={
  style:{
    width:"1000px",
    maxWidth:"2500px",
    maxHeight:"95%",
    height:"900px",
    overflow:"hidden"
  }
}

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[className*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}
export const NotAllowedComponent =((props) => {
  return (
  <div className='nodata'>
    <div style={{width:"100%",padding:'0px auto'}}>
    <img src={NotAllowed} alt="NoData" className='notAllowed' /> 
    </div>  
    <p className='message'>{props.message}</p>  
  </div>        
  );
});
const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle  className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });
  export const NoDataComponent =((props) => {
    return (
    <div className='nodata'>
     <div id='cont'>
      <img src={(props.ajust)?NoAjust:NoData} alt="NoData" className={(props.produit)?'notFound1':'notFound'} />   
      <p className='message'>{props.message}</p>  
     </div>  
    </div>      
    );
  }); 

export function Detail_tiket(props) {
    const param=useContext(ParamContext);
    //const [detail,setDetail]=useState([]);
    const [open, setOpen] = useState(false);
    //const classes= useStyles();

    
    const handleClickOpen = () => {  
      setOpen(true); 
    };
    const handleClose = () => { setOpen(false); };


    const columns = useMemo(()=>[   
          {
              name: "Date",
              selector: row=>row.date,
              sortable: true,
          },
          {
              name: "Action",
              selector: row=>row.action,
              sortable: true,
          },
          {
              name: "Agent",
              selector: row=>row.agent,
              sortable: true,
          },
          {
              name: "Fournisseur",
              selector: row=>row.frns,
              sortable: true,
          },
          {
              name: "Tiket",
              selector: row=>row.tiket_num,
              sortable: true,
          },
        ],[])

  return (
    <div>
      <div  onClick={handleClickOpen}>{props.row.tiket_num}</div> 
      <Dialog
            open={open}
            onClose={handleClose}
            variant="inline"
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={paperProps}
      >
        <DialogTitle id="simple-dialog-title" onClose={handleClose}>Dètail du tiket</DialogTitle>
            <DialogContent >
                <DataTable 
                  columns={columns}
                  data={props.data}
                  noHeader={true}
                  customStyles={customStyles}
                  defaultSortAsc={true}
                  noDataComponent={param.ErreurData}    
                  highlightOnHover
                  paginationComponentOptions={{ rowsPerPageText: 'Element par page:', rangeSeparatorText: 'sur'}}               
               />
        </DialogContent>
      </Dialog>
      </div>
  );
}


export class OutsideClickHandler extends React.Component {
  wrapperRef = createRef();

  static defaultProps = {
    onOutsideClick: () => {}
  };

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (
      this.wrapperRef.current &&
      !this.wrapperRef.current.contains(event.target)
    ) {
      this.props.onOutsideClick();
    }
  };

  render() {
    const { children } = this.props;

    return <div ref={this.wrapperRef}>{children}</div>;
  }
}


export function FicheClient(props) {
  const classes=useStyles();
  const Gfunc=require('../Gfunc')
  const param=useContext(ParamContext);
  const [open, setOpen] = useState(false);
  const [dataCon,SetDataCon]=useState([]);
  const [dataCom,SetDataCom]=useState([]);
  const [,SetdataInfo]=useState([]);
  const [dataInfo2,SetdataInfo2]=useState([]);
  const [errMessage,setErrMessage]=useState('');
  const [notAllowed,setNotAllowed]=useState(false);
  const [Allowed,setAllowed]=useState(false);
  const [selectNumber,SetSelectNumber]=useState(props.nbr)
  var id=(props.val==='cpt')?props.row.DT_RowId.substr(1):(props.val==='com')?props.row.accountId:props.row.id;

  const fetch =async()=>{
    SetDataCom([]); SetdataInfo([]);SetDataCon([])
    const response=await Gfunc.axiosPostAction("_cpt.php","getcptdet",{timeline:selectNumber,
         compte:(props.val==='cpt')?props.row.DT_RowId.substr(1):(props.val==='com')?props.row.accountId:props.row.id})
    if(response.data.err&&response.data.err!=='-1'){
      setErrMessage(response.data.err)
      setNotAllowed(true)
    }
    else{
      setAllowed(true)
      Gfunc.axiosResponse(response,()=>{
      var elem=[];var dat=[];var cnx=[];var com=[]
      var ordercn=response.data.jData.connexions.sort(Gfunc.custom_sort) 
      var ordercm=response.data.jData.commandes.sort(Gfunc.custom_sort) 
      if(response.data.jData.connexions.length!==0){
       elem.push(ordercn[0])
       elem.push(ordercn[response.data.jData.connexions.length-1])
      }
      if(response.data.jData.commandes.length!==0){
        elem.push(ordercm[0])
        elem.push(ordercm[response.data.jData.commandes.length-1])
      }
      if(elem.length!==0)
      {
          var test=elem.sort(Gfunc.custom_sort)
          var min=test[0].day
          var max=test[test.length-1].day
          var dateParts = min.split("-");
          var dateObject = new Date(dateParts[2], dateParts[1]-1, dateParts[0]);
          var dateParts1 = max.split("-");
          var dateObject1 = new Date(dateParts1[2], dateParts1[1]-1, dateParts1[0]);
          var testt=Gfunc.getDates(dateObject,dateObject1) 
          testt.map((el)=>{
            let date= Gfunc.formatDate(el)
            dat.push(date)
            return true
                    }) 
          dat.map((el)=>{
            var ind=ordercn.findIndex((obj => obj.day ===el));
            if(ind!==-1){
              cnx.push({day:el,connexions:ordercn[ind].connexions})
            }else{
              cnx.push({day:el,connexions:0})
            }
            return true
          }) 
          dat.map((el)=>{
            var ind=ordercm.findIndex((obj => obj.day ===el));
            if(ind!==-1){
              com.push(ordercm[ind])
            }else{
              com.push({day:el,phone:0,airtime:0,accessoire:0,digital:0})
            }
            return true
          }) 
          com.map(un=>{
            if(!un.hasOwnProperty("airtime")){un["airtime"]=0}
            if(!un.hasOwnProperty("digital")){un["digital"]=0}
            if(!un.hasOwnProperty("accessoire")){un["accessoire"]=0}
            if(!un.hasOwnProperty("phone")){un["phone"]=0}
            return true
          }) 
     }
       
       SetdataInfo(response.data.jData)
        SetDataCon(cnx)
        SetDataCom(com)
    })}
    return response.data.jData
  }

  const handleClickOpen =async () => {    
    setOpen(true); 
    SetdataInfo2(await fetch())
    
    };

  const handleClose = () => { setOpen(false); SetSelectNumber(props.nbr) };

 return (
  <div> 
    {   
      (props.val==='rep')?
      <Person className={classes.blackIcon} onClick={handleClickOpen}/>:
      (props.val==='com' )?
      <img src={param.userProfileImg+"/"+props.row.accountId+'.jpg'} style={{cursor:'pointer'}}
           alt='photo de client' className='client-profile-img' onClick={handleClickOpen}
           onError={(e) => {
           e.target.onerror = null
           e.target.src = Personn }}/>
      :
      <img src={param.userProfileImg+"/"+id+'.jpg'} style={{cursor:'pointer'}}
           alt='photo de client' className='client-profile-img' onClick={handleClickOpen}
           onError={(e) => {
           e.target.onerror = null
           e.target.src = Personn }}/>
    }    
    <Dialog open={open} onClose={handleClose} PaperComponent={PaperComponent} aria-labelledby="draggable-dialog-title" PaperProps={paperPropsD}>
    <DialogTitle className={classes.topDivTitle} id="draggable-dialog-title" >Fiche du compte de {props.row.compte}</DialogTitle>
      <DialogContent >
      {
      (notAllowed===false && Allowed===true)?
        <div id='fiche_comp'>
          <div style={{textAlign:'end'}} title='Client fidèle'>{(dataInfo2.fidelity)?<FavCompte style={{height:'50px',width:'50px',cursor:"pointer"}} />:<></>}</div>
         <div id='head-fiche'>
        <div id='fiche_info'>
         <div className='img-clt'><Profil id={/*props.row.id*/id}/></div> 
         <div id='infos-cpt'>
            <div>
                <div id='item' >
                  <div id='Circle-icon' data-text='Nom du compte' className='tooltip'><Person style={{width:'15px',height:'20px'}}/></div>
                  <div><b>{(dataInfo2&&(dataInfo2.nom||dataInfo2.prenom))?dataInfo2.nom+' '+dataInfo2.prenom:'---'}</b> </div>
                </div>
                <div id='item' >
                  <div id='Circle-icon' data-text='Adresse du magasin' className='tooltip'><Room style={{width:'15px',height:'20px'}}/></div>
                  <div><b>{(dataInfo2&&dataInfo2.adresse)?dataInfo2.adresse:'---'}</b> </div>
                </div>
                <div id='item' >
                  <div id='Circle-icon' data-text='Nom du magasin' className='tooltip'><StoreMallDirectory style={{width:'15px',height:'20px'}}/></div>
                  <div><b>{(dataInfo2&&dataInfo2.mag)?dataInfo2.mag:'---'}</b> </div>
                </div>
                <div id='item' >
                  <div id='Circle-icon' data-text='Numéro du téléphone' className='tooltip'><LocalPhone style={{width:'15px',height:'20px'}}/></div>
                  <div><b>{(dataInfo2&&dataInfo2.tel)?dataInfo2.tel:'---'}</b> </div>
                </div>
                <div id='item' >
                  <div id='Circle-icon' data-text='Email du magasin' className='tooltip'><Mail style={{width:'15px',height:'20px'}}/></div>
                  <div><b>{(dataInfo2&&dataInfo2.mail)?dataInfo2.mail:'---'}</b> </div>
                </div>
                <div id='item' >
                  <div id='Circle-icon' data-text='Note' className='tooltip' style={{backgroundColor:'#ccc'}}><Note style={{width:'15px',height:'20px'}}/></div>
                  <div><b>{(dataInfo2&&dataInfo2.note)?dataInfo2.note:'---'}</b> </div>
                </div>
            </div>
            <div>
                {
                  (props.row.subCom===null||!props.row.hasOwnProperty('subCom'))?
                <>
                <div id='item' >
                  <div id='Circle-icon' data-text='Commercial accessoire' className='tooltip' style={{backgroundColor:'#ccc'}}><Person style={{height:'16px'}}/><Usb style={{height:'15px'}}/></div>
                  <div><b>{(dataInfo2&&dataInfo2.com_accessoire)?dataInfo2.com_accessoire:'--'}</b> </div>
                </div>
                <div id='item'>
                  <div id='Circle-icon' data-text='Commercial airtime' className='tooltip' style={{backgroundColor:'#ccc'}}><Person style={{height:'16px'}}/><AccessAlarms style={{height:'15px'}}/></div>
                  <div><b>{(dataInfo2&&dataInfo2.com_airtime)?dataInfo2.com_airtime:'--'}</b> </div>
                </div>
                <div id='item' >
                  <div id='Circle-icon' data-text='Commercial phone' className='tooltip' style={{backgroundColor:'#ccc'}}><Person style={{height:'16px'}}/><LocalPhone style={{height:'15px'}}/></div>
                  <div><b>{(dataInfo2&&dataInfo2.com_phone)?dataInfo2.com_phone:'--'}</b> </div>
                </div>
                </>:<>
                  <div id='item' >
                    <div id='Circle-icon' data-text='Commercial' className='tooltip' style={{backgroundColor:'#ccc'}}><Person style={{height:'16px'}}/></div>
                    <div><b>{(props.row.subCom)?props.row.subCom:'---'}</b> </div>
                  </div>
                </>
                }
                <div id='item' >
                  <div id='Circle-icon' data-text='Solde accessoire' className='tooltip' style={{backgroundColor:'#ccc'}}><AttachMoney style={{height:'16px'}}/><Usb style={{height:'15px'}}/></div>
                  <div><b>{(dataInfo2)?new Intl.NumberFormat().format(dataInfo2.solde_accessoire):'---'}</b> </div>
                </div>
                <div id='item' >
                  <div id='Circle-icon' data-text='Solde airtime' className='tooltip' style={{backgroundColor:'#ccc'}}><AttachMoney style={{height:'16px'}}/><AccessAlarms style={{height:'15px'}}/></div>
                  <div><b>{(dataInfo2)?new Intl.NumberFormat().format(dataInfo2.solde_airtime):'---'}</b> </div>
                </div>
                <div id='item' >
                  <div id='Circle-icon' data-text='Solde phone' className='tooltip' style={{backgroundColor:'#ccc'}}><AttachMoney style={{height:'16px'}}/><LocalPhone style={{height:'15px'}}/></div>
                  <div><b>{(dataInfo2)?new Intl.NumberFormat().format(dataInfo2.solde_phone):'---'}</b> </div>
                </div>
            </div>
             <MapLocation dataInfo2={dataInfo2} row={props.row}/> 
         </div>
         </div> 
         <ValidatorForm noValidate id="formadd"/* ref={useRef(null)} */ onSubmit={fetch}  >
                <TextField required  color="primary" variant="outlined" className={classes.InputComponent} label="Nombre de jours" size="small" inputprops={{ tabIndex: "4" ,className:classes.input1}}
                        value={selectNumber}  onChange={e =>SetSelectNumber(e.target.value)} placeholder="Dernier jours.">
                </TextField>
               <Button  className={classes.fieldBtn} variant="contained"  tabIndex="3" type='submit' id="bouton" name="addBtn" color="primary" startIcon={<Search/>}>OK</Button>
        </ValidatorForm>
        </div>
         <div id='fiche_chart'>
          <Paper className='chart' elevation={8}>
            <DynamicBarchart width={'100%'} height={165} data={dataCom} margin={{ top: 15, right: 30, left: 0, bottom:0 }} legend='true'
             dataKeyX="day" padding={{ left: 10, right: 10 }} dataKeyY={['airtime','digital']} fill={["#0000FF","#FFA500"]} stackId={['stack','stack']}/>
            <Typography className='titleCharts'>Nombre de commandes </Typography>
          </Paper>
          <Paper className='chart' elevation={8}>
             <LineCharts  height={165} data={dataCon} dataKeyX='day' dataKeyY='connexions' color='#659DB4'/>
             <Typography className='titleCharts'>Nombre de connexions</Typography>
           </Paper>
           <Paper className='chart' elevation={8}>
            <DynamicBarchart width={'100%'} height={165} data={dataCom} margin={{ top: 15, right: 30, left: 0, bottom:0 }} legend='true'
             dataKeyX="day" padding={{ left: 10, right: 10 }} dataKeyY={['phone','accessoire']} fill={["#ff0000","#82ca9d"]} stackId={['a','a']}/>
            <Typography className='titleCharts'>Nombre de commandes </Typography>
          </Paper>
         </div>
        </div>:(Allowed===false && notAllowed===true)?<div className='compAllow'><NotAllowedComponent message={errMessage}/></div>:null
        }
      </DialogContent>
      <DialogActions>
            <Button variant="contained"  onClick={handleClose} color="secondary">FERMER</Button>
      </DialogActions>
    </Dialog>
    </div>
);
}

export const AlertCompo=(props)=>{
  const classes=useStyles();
  return (
    <div className={classes.root1}>
      <Collapse in={props.open}>
        <Alert variant="filled" severity={props.severity}
          action={ <IconButton aria-label="close" color="inherit" size="small" onClick={() => {   props.setOpen(); }}>
                        <CloseIcon fontSize="inherit" />
                   </IconButton>
          }
        >
          {props.message}
        </Alert>
      </Collapse>
    </div>
  )
}

export function AutocompleteS({service,action,row,list,defaultValue,id,idi,name,onOpen,attr,handleAlert,from,rowId,dateupd,agentupd,
                               datein,agentin,handleResponse,attr2,separ,className1,className,men}) {
  const classes = useStyles();
  const [selecto,setSelecto]=useState('')
  const [Item,setItem]=useState('')
  const [ItemN,setItemN]=useState('')
  const [value,setValue]=useState('')


  const handleSelect=(row,att)=>{
    setSelecto(row[att])
    setItem(row[rowId])
    setItemN(att)
  }

  const handleOnEditSelect=async(val,attList,f=()=>{})=>{
    const rowid=(rowId!==undefined)?rowId:"DT_RowId"
    if(row[id]!==val){
      const edt=val
      const response=await librery.axiosEdit2(service,action,row,edt,id,rowid) 
      if(response.data.err&&response.data.err!=='1'){
        handleAlert(true,'error',response.data.err)
      }else
      Gfunc.axiosResponse(response,()=>{
        const objIndex =(idi!==undefined)?list.findIndex((obj => obj[idi] === edt)):list.findIndex((obj => obj.id === edt));
        row[name]=(list[objIndex][attList]!=="Aucun")?list[objIndex][attList]:null
        setValue(row[name])
        row[id]=edt
        if(handleResponse){
          handleResponse((list[objIndex][attList]!=="Aucun")?list[objIndex][attList]:null)
        }
        setSelecto('')
    })
  }
  }

 return(
    <Tooltipp title={(datein!==undefined||dateupd!==undefined)?((!dateupd)?"Entré le: "+datein +' par: ' +agentin:"Édité le: "+dateupd +' par: ' +agentupd):''}>
    <div className={(className1==='SelectDT')?className1:(from==='inf')?"smallSelectDT2":"smallSelectDT"}>
  {
    ((row[rowId]===Item || from==='inf') && row[name]===selecto && ItemN===name)?
    <ClickAwayListener onClickAway={()=>setSelecto('')}>
    <div>
      <Autocomplete 
        disableClearable
        loading={list.length===0}
        loadingText="Chargement..."
        defaultValue={row[name]}
        //getOptionSelected={(option, value) => option[attr] === value}
        onChange={
          (e,newValue)=>{
            var v=newValue;
            if(separ!==undefined)
            v=newValue.substr(0,newValue.indexOf(separ)).trim()
            if(v==='') v=newValue.trim()
            handleOnEditSelect((idi!==undefined)?list[Gfunc.getIndex(list,v,attr)][idi]:list[Gfunc.getIndex(list,v,attr)].id,attr,setSelecto(v))
          }
        }
        onOpen={onOpen}
        options={
          list.map((option) => {
            var separ2=''
            var separ1=' '+separ
            if (separ==='[')
              separ2=']'
            else separ1=separ1+' '
            if(attr2===undefined||option[attr2]===null) return option[attr]; else return option[attr]+separ1+option[attr2]+separ2
          
          })
        }
        inputprops={{className:classes.diagFormInput}}
        renderInput={(params) => (
        <TextField {...params} color="primary" variant="outlined" size="small"/>)} /></div></ClickAwayListener>
      :(row[name]!==null)? <div className={(className!=="")?className:"content-editable-compte"}  onClick={()=>{handleSelect(row,name)}}>{(value==='')?row[name]:value}</div>
      :<div className={(men==='prd')?className:"div-vide"} onClick={()=>{handleSelect(row,name)}}></div>
  }
  </div></Tooltipp>
  )
}

export function AutocompleteSS({service,action,row,list,chaine,concat,defaultValue,id,name,onOpen,attr,handleAlert,from,rowId,dateupd,agentupd,datein,agentin,handleResponse,attr2,separ,noOpt}) {
  const classes = useStyles();
  const [selecto,setSelecto]=useState('')
  const [Item,setItem]=useState('')
  const [ItemN,setItemN]=useState('')
  const [value,setValue]=useState('')
  const [upd,setUpd]=useState(false)
  
  const handleSelect=(row,att)=>{
    setSelecto(row[att])
    setItem(row[rowId])
    setItemN(att)
  }

const handleT=(val)=>{
setUpd(false)
}
  const handleOnEditSelect=async(val,attList,f=()=>{})=>{
    const rowid=(rowId!==undefined)?rowId:"DT_RowId"
     if(row[id]!==val){
      const edt=(chaine==='true')?val:parseInt(val) 
      const response=await librery.axiosEdit2(service,action,row,edt,id,rowid) 
      if(response.data.err&&response.data.err!=='1'){
        handleAlert(true,'error',response.data.err)
      }else
      Gfunc.axiosResponse(response,()=>{
        if(val!==''){ 
            const objIndex =list.findIndex((obj => obj.id === val));
            row[name]=list[objIndex][attList]
            if(concat!==''&&concat!==undefined){
              const ch=concat+row[name]
              setValue(ch)
            }else{
              setValue(row[name])
            }
            row[id]=edt
            Gfunc.updateElemS(name+row.DT_RowId,row[name])
        }else{
          row[name]=null
          row[id]=null
          setUpd(true)
          handleT(false)
        }
        if(handleResponse){
          handleResponse()
        }
        setSelecto('')
    })
  }
  }
  return(
    <Tooltipp title={(datein!==undefined||dateupd!==undefined)?((!dateupd)?"Entré le: "+datein +' par: ' +agentin:"Édité le: "+dateupd +' par: ' +agentupd):''}>
    <div className={(from==='inf')?"smallSelectDT2":"smallSelectDT"}>
  {
    ((row[rowId]===Item || from==='inf') && row[name]===selecto && ItemN===name)?
    <ClickAwayListener onClickAway={()=>setSelecto('')}>
    <div>
      <Autocomplete 
        disableClearable
        loading={list.length===0}
        loadingText="Chargement..."
        defaultValue={row[name]}
        //getOptionSelected={(option, value) => option[attr] === value}
        onChange={
          (e,newValue)=>{
            var v=newValue;
            if(v!==noOpt){
            if(separ!==undefined)
            v=newValue.substr(0,newValue.indexOf(separ)).trim()
            if(v==='') v=newValue.trim()
            handleOnEditSelect(list[Gfunc.getIndex(list,v,attr)].id,attr,setSelecto(v))
          }
            else{
              v=''
              handleOnEditSelect('',attr,setSelecto(v))
            }   
          }
        }
        onOpen={onOpen}
        options={
          list.map((option) => {
            var separ2=''
            var separ1=' '+separ
            if (separ==='[')
              separ2=']'
            else separ1=separ1+' '
            if(attr2===undefined||option[attr2]===null) return option[attr]; else return option[attr]+separ1+option[attr2]+separ2
          
          })
        }
        inputprops={{className:classes.diagFormInput}}
        renderInput={(params) => (
        <TextField {...params} color="primary" variant="outlined" size="small"/>)} /></div></ClickAwayListener>
      :(row[name]!==null)? <div className="content-editable"  onClick={()=>{handleSelect(row,name)}}>{(value==='')?row[name]:value}</div>
      :<div className="div-vide" onClick={()=>{handleSelect(row,name)}}></div>
  }
  </div></Tooltipp>
  )
}


export function AutocompleteSsS({service,action,row,list,defaultValue,id,name,onOpen,setLoading,setItem,setSelecto,attr,attr2,separ,noOpt}) {
  const classes = useStyles();
  const handleOnEditSelect=async(val,attList,f=()=>{})=>{
    if(row[id]!==val){
      const edt=val
      const attrib=row[id];
      const response=await librery.axiosEdit2(service,action,row,edt,id) 
      Gfunc.axiosResponse(response,()=>{
        if(val!==''){ const objIndex =list.findIndex((obj => obj.id === edt));
        row[name]=list[objIndex][attList]
        row[id]=edt
        }else row[name]=''
        Gfunc.updateElemS(name+row.DT_RowId,row[name])
        setItem("");
        f();
    })
    if(response.data.err) {setItem("");row[id]=attrib; }
  }
  }
  return(
    <div className="smallSelectDT">
      <Autocomplete onClick={e=>e.stopPropagation()}
      disableClearable
      loading={list.length===0} loadingText="Chargement..."
      defaultValue={defaultValue}
      clearOnBlur={true}
      onChange={(e,newValue)=>{
        var v=newValue;
        if(v!==noOpt){
        if(separ!==undefined)
        v=newValue.substr(0,newValue.indexOf(separ)).trim()
        if(v==='') v=newValue.trim()
        handleOnEditSelect(list[Gfunc.getIndex(list,v,attr)].id,attr,setSelecto(v))
      }
        else{
          v=''
          handleOnEditSelect('',attr,setSelecto(v))
        }   
      }}
      onOpen={onOpen}
      options={list.map((option) => {
        var separ2=''
        var separ1=' '+separ
        if (separ==='[')
          separ2=']'
        else separ1=separ1+' '
        if(attr2===undefined||option[attr2]===null) return option[attr]; else return option[attr]+separ1+option[attr2]+separ2
      
      })}
      inputprops={{className:classes.diagFormInput}}
      openText="Ouvrir"
      closeText='Fermer'
      renderInput={(params) => (
      <TextField {...params} color="primary" variant="outlined" size="small"/>)} />
  </div>
  )
 }


// editable input of type number 
export function InputNbrEditable({service,handleAlert,row,att,action,id,value,idi,className,min,max,traetResp,dateupd,agentupd,
  datein,agentin,positif,from}) {
  const [open, setOpen] = useState('');
  const [val, setVal] = useState(value);

  
 //update field for formatted numbers
 const handleOnEdit2=useCallback(async(e)=>{
  if(e.target.value!==''){
  const edt1=e.target.value
  var edt=edt1.replace(/\s/g, '');
      if(row[att]!==edt&&!isNaN(edt)){
        const attrib=row[att]; 
        if(positif && edt<0) edt=-edt
        const response=await librery.axiosEdit2(service,action,row,parseFloat(edt),att,id) 
        if(response.data.err&&response.data.err!=='-1'){
          handleAlert(true,'error',response.data.err)
        }else
        Gfunc.axiosResponse(response,()=>{
          setVal(edt);
          row[att]=edt
          e.target.value=new Intl.NumberFormat().format(edt)
          if(traetResp!==undefined)
            traetResp(response.data)
        })
        if(response.data.err) {setVal(attrib)}
      }}
},[librery,Gfunc,row,att,action,id,traetResp]);
 
return (
  <Tooltipp title={(datein!==undefined||dateupd!==undefined)?((!dateupd)?"Entré le: "+datein +' par: ' +agentin:"Édité le: "+dateupd +' par: ' +agentupd):''}>
  <div className='InputNbrEditable'>
  {(open!==row[id])?<div id={idi} className={(className!=='content-editable')?className:"content-editable"} 
    style={{textAlign:(from!=='catalogue')?'end':'start'}} onClick={()=>setOpen(row[id])}>
      {(val!==null)?new Intl.NumberFormat().format(val):''}</div>
  :<ClickAwayListener onClickAway={()=>{setOpen('');}}>
  <input
      min={min}
      max={max}
      step={(from==='color')?'0.01':'1'}
      id={idi}
      type='number'
      value={(val===null)?'':val}
      data-column="item"
      className={(className!=='')?className:"content-editable"}
      onChange={(e)=>{
        if(min!==undefined&&min!=null&&min!==''){
         if(min>=0&&e.target.value.includes('-'))
          {handleAlert(true,'error',"Veuillez saisir un montant positif!");setVal(row[att]);setOpen('')}
          else{
            if(from==='color'){
              if(e.target.value>=min&&e.target.value<=max){
                setVal(e.target.value)  
              }
            }else{
              setVal(e.target.value)
            }
          }
        }else setVal(e.target.value)
      }}
      autoFocus

      onBlur={e => {handleOnEdit2(e);setOpen('')}}
  />
  </ClickAwayListener>
  }
  </div>
  </Tooltipp>

);
}

// editable input of type number 
export function TelUpd({service,handleAlert,row,att,action,id,value,idi,min,max,traetResp,dateupd,agentupd,datein,agentin,
  positif,loading,setLoading,charge,supp,suppAtt,className,cpt}) {
  const [open, setOpen] = useState('');
  const [val, setVal] = useState(value);
  const [changed, setChanged] = useState(false);
  const [load, setLoad] =useState(false);
  
 //update field for  numbers phone
 const handleOnEdit2=useCallback(async(val)=>{
  if(val!==''){
    const check=Gfunc.phonenumber(val)
  if(check.response &&val!==null){
  setLoad(true)
  const edt1=val
  var edt=edt1.replace(/\s/g, '');
      if(row[att]!==edt&&edt!==""){
        const attrib=row[att]; 
        const response=await librery.axiosEdit2(service,action,row,edt,att,id,"number",supp,suppAtt) 
        if(response.data.err&&response.data.err!=='-1'){
          handleAlert(true,'error',response.data.err)
        }else
        Gfunc.axiosResponse(response,()=>{
          setVal(edt);
          row[att]=edt
         // e.target.value=new Intl.NumberFormat().format(edt)
          if(traetResp!==undefined)
            traetResp(response.data)
        })
        if(response.data.err) {setVal(attrib)}
      }
      setLoad(false)
    }
    else {
        handleAlert(true,'error',check.message);setVal(value)}
        setOpen('')
    }else{setOpen('');setVal(row[att])}
},[librery,Gfunc,row,att,action,id,traetResp]);

useEffect(async() => {
  if(open===row[id]){
    const elem=document.getElementById(idi)
    if (elem) {
      elem.focus(); elem.select()
      document.addEventListener('click', function(event) {
      
      })
    }
  }
}, [open]);
 
return (
  <Tooltipp title={((datein!==undefined||dateupd!==undefined)&& cpt!=='cpt')?((!dateupd)?"Entré le: "+datein +' par: ' +agentin:"Édité le: "+dateupd +' par: ' +agentupd):''}>
  <div className={'InputNbrEditable'}>
  {(open!==row[id])?
  <div id={idi} className={(cpt)?className:"content-editable"} style={{textAlign:'center',justifyContent:"center"}} onClick={()=>setOpen(row[id])}>
    {(val!==null)?val:'---'}
  </div>
  :<div >
    {(!load)?
    <input 
        id={idi}
        key={row[id]}
        onBlur={()=>{if(changed){handleOnEdit2(val);}else setOpen('')}}
        //defaultValue={value}
        value={(val===null)?'':val}
        type="number"  
        style={{color:"black !important"}}
        name="iTel"
        className={(cpt)?className:'content-editable removeArrow'} margin='dense' variant='outlined'
        autoFocus
        onChange={(e)=>{
          if(value!==e.target.value) setChanged(true); else setChanged(false)
          if(e.target.value && e.target.value.length >=10){setVal(e.target.value.substr(0,10))}else{setVal(e.target.value)}
        }}
        onKeyPress={(e)=>{
          if(e.key==="Enter"){
          handleOnEdit2(val);}
          }}
        required
    /> 
:<CircularProgress style={{width:"12px",height:"12px"}} color="inherit" />}
  </div>
  }
  </div>
  </Tooltipp>

);
}



