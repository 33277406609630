import React ,{useRef,useCallback,useState} from "react";
import {Paper} from '@material-ui/core';
import {Typography} from '@material-ui/core';
import {Button,CircularProgress,Backdrop } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { ValidatorForm} from 'react-material-ui-form-validator';
import { MuiPickersUtilsProvider,KeyboardDatePicker} from "@material-ui/pickers";
import {DoneAll} from '@material-ui/icons';
import { fr } from "date-fns/locale";
import DateFnsUtils from "@date-io/date-fns";
import { useStyles } from '../../css/muiStyle';
import {NoDataComponent,AlertCompo} from '../../js/components'
import Autocomplete from '@material-ui/lab/Autocomplete';
import '../../css/reporting.css'
import { useMediaQuery } from 'react-responsive'
import  PieCharts  from "../../compo/Piechart";
import { useContext } from 'react';
import ParamContext from '../../Contexte';


export default function Rep_acc(){
    const Gfunc=require('../../Gfunc')
    const param=useContext(ParamContext);
    const classes = useStyles();
    const [loading,setLoading]=useState(false)
    const [User,SetUser]=useState([])
    const [selectDateD,SetSelectDateD]=useState(Gfunc.DateDeb(new Date()))
    const [selectDateF,SetSelectDateF]=useState(new Date())
    const [data,SetData]=useState([])
    const [dataA,SetDataA]=useState([])
    const [dataP,SetDataP]=useState([])
    const [openA, setOpenA] =useState(false);
    const [messageA, setMessageA] =useState('');
    const [severity, setSeverity] =useState('info');
    const [err,SetErr]=useState(false)
    const [selectUser,SetSelectUser]=useState('')
    const [percent,SetPercent]=useState('')
    const [percentc,SetPercentc]=useState('')
    const message='Aucune donnée trouvée'
    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
    const COLORS1 = ['#FFBB28', '#FF8042','#0088FE', '#00C49F'];

    const isDesktopOrLaptop = useMediaQuery({query: '(max-width: 850px)'})     
     

    const handleAlert=(open,severity,message)=>{
      setOpenA(true)
      setSeverity(severity)
      setMessageA(message)
      setTimeout(function(){
        setOpenA(false)
      }, 5000);
    }
    const fetchUser= useCallback(async() => {
        const response = await Gfunc.axiosPostAction('_usr.php' ,'gets')
        if(response.data.err&&response.data.err!=='-1'){
          handleAlert(true,"error",response.data.err)
        }else{
        Gfunc.axiosResponse(response,()=>{
            response.data.jData.unshift({id:'empty',name:'Tous'});
            SetUser(response.data.jData);   
        })
        }
    },[Gfunc,param.urlService]);

    //valider le formulaire 
    const handleSubmit=async()=>{
      setLoading(true)
      var somm=0;var som=0;
      var phone=[];var acc=[]
      const response=await Gfunc.axiosPostAction("_rep.php","getacc",{date1:Gfunc.formatDate(new Date(selectDateD)),date2:Gfunc.formatDate(selectDateF),agent:(selectUser!=='empty')?selectUser:''})
      setLoading(false)
      Gfunc.axiosResponse(response,()=>{
        Object.keys(response.data.jData.phone).map((key)=> { 
          phone.push({name:(key==='accused')? 'Accusé':'Autres',value:response.data.jData.phone[key]})
          somm=somm+response.data.jData.phone[key]
          return true
        })
        Object.keys(response.data.jData.accessoire).map((key)=> {
          acc.push({name:(key==='accused')? 'Accusé':'Autres',value:response.data.jData.accessoire[key]})
          som=som+response.data.jData.accessoire[key]
          return true
        })
        SetData(response.data.jData)
        SetDataA(acc)
        SetDataP(phone)
        SetPercent(somm)
        SetPercentc(som)
        SetErr(true)
      })
    } 
    
    //remplir Select Commune
    const handleOpenUser= useCallback(async () => {
      if(User.length===0)
      fetchUser()
    },[User,fetchUser])

    //valider formulaire avec touche d'entrer
   const detectTouche= (e)=>{
    if (e.keyCode === 13 && (e.target.name==="addBtn")) {
        handleSubmit()
      }
    }


return (
  <div className='content'>
  <Backdrop className={classes.backdrop} open={loading} >
        <CircularProgress color="inherit" />
 </Backdrop>
  <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA}/>
  <fieldset className="fieldsetTop" id="Con">
          <legend>Taux des accusés de réception des commandes</legend>
        <ValidatorForm noValidate id="formAdd" ref={useRef(null)} onSubmit={handleSubmit} onKeyPress={e=>detectTouche(e)}  >
            <div className="rowGrid" id="row-1">
                <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
                    <KeyboardDatePicker disableToolbar value={selectDateD} variant="inline" placeholder="01/01/2020" onChange={date => SetSelectDateD(date)}
                                minDate="01/01/2020" format="dd/MM/yyyy"  className='smallText' margin="dense" inputVariant="outlined"
                                invalidDateMessage="Le format de la date est incorrect" 
                  />
                </MuiPickersUtilsProvider>
                 <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
                    <KeyboardDatePicker disableToolbar value={selectDateF} variant="inline" placeholder="01/01/2020" onChange={date => SetSelectDateF(date)}
                                minDate="01/01/2020" format="dd/MM/yyyy"  className='smallText' margin="dense" inputVariant="outlined"
                                invalidDateMessage="Le format de la date est incorrect" 
                  />
                </MuiPickersUtilsProvider>
                <Autocomplete
                    disableClearable
                    value={selectUser}
                    onChange={(e,newValue) =>{SetSelectUser(User[Gfunc.getIndex(User,newValue,"name")].id)}} 
                    onFocus={handleOpenUser}
                    options={User.map((option) => option.name)}
                    inputprops={{className:classes.input}} 
                    className='smallText'     
                    renderInput={(params) => (
                        <TextField {...params}  label="Agent..." color="primary" variant="outlined" size="small"  />
               )} />
              <Button className={classes.fieldButton} variant="contained" tabIndex="3" type='submit' id="bouton" name="addBtn" color="primary" 
                      startIcon={<DoneAll/>}>VALIDER</Button>
           </div>
    </ValidatorForm>
  </fieldset>
 { 
(data.length!==0)?
 <div id='charts-pie'>
    <Paper id='chart-pie' elevation={8}>
       <PieCharts data={dataP} color={COLORS} percent={percent} /> 
       <Typography className='titleCharts'>Phone</Typography>
    </Paper>
    <Paper id='chart-pie' elevation={8}  >
       <PieCharts data={dataA} color={COLORS1} percent={percentc}/> 
        <Typography className='titleCharts'>Accessoire</Typography>
    </Paper>
  </div>:<></>
  }
  { (data.length===0 && err===true)?<NoDataComponent message={message}/>:<></>}
</div>   
  );
}