import React,{useState,useEffect,useRef} from 'react';
import ReactMapGL,{Marker} from 'react-map-gl';
import MapboxLanguage from '@mapbox/mapbox-gl-language';
import 'mapbox-gl/dist/mapbox-gl.css';

// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from "!mapbox-gl";
import { Room } from '@material-ui/icons';
// The following is required to stop "npm build" from transpiling mapbox code.
// notice the exclamation point in the import.
// @ts-ignore
//eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

export default function Map (props) {
    const [viewport, setViewport] =useState({longitude: 0,latitude:0,zoom:8});
    const mapRef = useRef(null);

    useEffect(() => {
    if (mapRef && mapRef.current) {
        const map = mapRef.current.getMap();
        map.addControl(new MapboxLanguage({ defaultLanguage: "fr" }));
    }
    },[mapRef])

    const geolocalisation=(lan,log)=>{
        window.open('https://www.google.com/maps/search/?api=1&query='+lan+','+log)
      }
  return (
    <div id='map'>
        <ReactMapGL
            ref={mapRef}    
            latitude={(props.dataInfo2&&props.dataInfo2.latitude)?parseInt(props.dataInfo2.latitude):viewport.latitude}
            longitude={(props.dataInfo2&&props.dataInfo2.longitude)?parseInt(props.dataInfo2.longitude):viewport.longitude}
            zoom={viewport.zoom}     
            mapboxApiAccessToken='pk.eyJ1IjoibWVyaW91bWEiLCJhIjoiY2t1cDZnbXd3MTU2aTJvcDExeDdsZmh5MSJ9.JEBnDl-hQQZMlmSzaX0oTg'
            width="100%"
            height="100%"
            mapStyle='mapbox://styles/mapbox/streets-v11'
            onViewportChange={viewport=>(true)?setViewport(viewport):null}
        >
            <Marker latitude={(props.dataInfo2&&props.dataInfo2.latitude)?parseInt(props.dataInfo2.latitude):0} 
                    longitude={(props.dataInfo2&&props.dataInfo2.longitude)?parseInt(props.dataInfo2.longitude):0} offsetLeft={0} offsetTop={0}>
            <Room style={{color:'red',cursor:'pointer'}} id='icon' title='Localisation du magasin'
                  onClick={()=>geolocalisation((props.dataInfo2&&props.dataInfo2.latitude)?parseInt(props.dataInfo2.latitude):0,(props.dataInfo2.longitude)?props.dataInfo2.longitude:0)} 
                />
            </Marker>
        </ReactMapGL > 
    </div>
  );
};

