import React ,{useRef,useCallback,useState,useMemo} from "react";
import DataTable from "react-data-table-component";
import {Button,CircularProgress,Backdrop, Box } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { ValidatorForm} from 'react-material-ui-form-validator';
import {DoneAll,Search} from '@material-ui/icons';
import {iconCell}from '../../css/datatable-custom'
import { customStyles} from '../../css/datatable-custom'
import { useStyles } from '../../css/muiStyle';
import {FicheClient,NoDataComponent} from '../../js/components'
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useMediaQuery } from 'react-responsive'
import { useContext } from 'react';
import ParamContext from '../../Contexte';
import '../../css/reporting.css'



export default function Rep_noc(){
    const classes = useStyles();
    const [loading,setLoading]=useState(false)
    const [err,setErr]=useState(false)
    const [selectNumber,SetSelectNumber]=useState(15)
    const [selectBu,SetSelectBu]=useState('')
    const [Bu,SetBu]=useState([])
    const [data,SetData]=useState([])
    const [search,setSearch]=useState('')
    const [tous,SetTous]=useState([])
    const [doSearch,SetDoSreach]=useState(false)
    const Gfunc=require('../../Gfunc')
    const param=useContext(ParamContext);
    const message='Aucune donnée trouvée'
    const service="_rep.php";

    var isDesktopOrLaptop = useMediaQuery({query: '(max-width: 850px)'})

    //recuperer les communes
    const fetchBU= useCallback(async() => {
      const response = await Gfunc.axiosPostAction(service,'getbu')
      Gfunc.axiosResponse(response,()=>{
          response.data.jData.unshift({id:'empty',bu:'Toutes'});
          SetBu(response.data.jData)  
      })
    },[Gfunc,param.urlService]);

    //valider le formulaire 
    const handleSubmit=async()=>{
      setSearch('')
      setErr(false)
      setLoading(true)
      const response=await Gfunc.axiosPostAction(service,"getnocom",{jours:selectNumber,bu:(selectBu!=='empty')?selectBu:''})
      setLoading(false)
      Gfunc.axiosResponse(response,()=>{
        SetData(response.data.jData)
        SetTous(response.data.jData)
        setErr(true)
       if(response.data.jData.length!==0) {SetDoSreach(true)}
      })
    } 

    //remplir Select Commune
    const handleOpenBU= useCallback(async () => {
      if(Bu.length===0)
      fetchBU()
    },[Bu,fetchBU])

    //valider formulaire avec touche d'entrer
   const detectTouche= (e)=>{
    if (e.keyCode === 13 && (e.target.name==="addBtn")) {
        if(selectNumber===''){
          alert("Vous devez remplir tous les champs!")
        }
      }
    }
    
    const columns =useMemo(
      () => [ 
        {
          name: "Compte",
          selector:row=> row.compte,
          sortable: true,
       }, 
        {
            name: "Date",
            selector:row=> row.date,
            sortable: true,
        },
        {
          name: "Dernière Commande ",
          selector:row=> row.last_com,
          sortable: true,
        },
       {
          name: "Webtelco",
          selector:row=> {if(row.airtime!=null){return row.airtime.nom}},
          sortable: true,
       },
       {
          name: "Phone",
          selector:row=> row.phone,
          sortable: true,
       },
       {
        name: "Accessoire",
        selector:row=> row.accessoire,
        sortable: true,
      },
      {
        right:true,
        cell: (row) => {
          return (
            <FicheClient row={row} nbr='60' val='rep'/>
          )
       }
      }
      ],[]);
 
const filtrer=(val)=>{
  var test=tous.filter((el) =>  JSON.stringify(el).toLowerCase().indexOf(val.toLowerCase()) !== -1)
  SetData(test)
}

const  HamdleOnChange=(e)=>{
  setSearch(e.target.value)
  filtrer(e.target.value)
}

return (
  <div className='content'>
  <Backdrop className={classes.backdrop} open={loading} >
        <CircularProgress color="inherit" />
 </Backdrop>

  <fieldset className={"fieldsetTop"} id="Ncom">
          <legend>Analyser les comptes sans commandes depuis</legend>
        <ValidatorForm noValidate id="formAdd" ref={useRef(null)} onSubmit={handleSubmit} onKeyPress={e=>detectTouche(e)}  >
            <div className="rowGrid" id="row-1">
                <Autocomplete
                      disableClearable
                      onChange={(e,newValue) =>{SetSelectBu(Bu[Gfunc.getIndex(Bu,newValue,"bu")].id);}} 
                      onFocus={handleOpenBU}
                      options={Bu.map((option) => option.bu)}
                      className='smallText'  
                      inputprops={{className:classes.input}}     
                      renderInput={(params) => (
                        <TextField {...params}  label="BU..." color="primary" variant="outlined" size="small"  />
               )} />
             
                <TextField required  type='number' color="primary" variant="outlined" className='smallText' label="Nombre de jours" size="small" inputProps={{ tabIndex: "2" ,className:classes.input1}}
                        value={selectNumber}  onChange={e =>SetSelectNumber(e.target.value)} placeholder="Dernier jours."/>

                <Button className={classes.fieldButton} variant="contained" tabIndex="3" type='submit' id="bouton" name="addBtn" 
                        color="primary" startIcon={<DoneAll/>}>VALIDER</Button>
            </div>
    </ValidatorForm>
  </fieldset>
  { (doSearch===true)?
      <div  className="table-header">
        <div className='search' >
            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <TextField className={classes.search} variant="outlined"  label="Recherche" value={search} 
                               InputProps={{startAdornment: (<Search  style={{ marginRight: '5px',zIndex:'1000',width:'20px',height:'20px',
                                    cursor:"pointer"}} />),}} size="small" margin='none'  onChange={e =>HamdleOnChange(e)}/> 
            </Box>
        </div>
      </div>:null
}  {
    (data.length!==0 || doSearch===true)?
      <div >
        <DataTable 
              columns={columns}
              data={data}
              defaultSortAsc={true}
              persistTableHead={true}
              noDataComponent={param.ErreurData}
              paginationRowsPerPageOptions={[10,30,50,100]}
              pagination
              highlightOnHover
              paginationComponentOptions={{ rowsPerPageText: 'Élément par page:', rangeSeparatorText: 'sur',selectAllRowsItemText: 'Tous',selectAllRowsItem: true}}
              customStyles={customStyles}   
        />
  </div>:<></>
  }
{
  (data.length===0 && err===true && doSearch===false)?<NoDataComponent message={message}/>:<></>
}
</div>   
  );
}