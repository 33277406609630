import React ,{useRef,useCallback,useState,useMemo} from "react";
import {Paper} from '@material-ui/core';
import DataTable from "react-data-table-component";
import { customStyles} from '../../css/datatable-custom'
import {Typography} from '@material-ui/core';
import {Button,MenuItem,CircularProgress,Backdrop } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { ValidatorForm} from 'react-material-ui-form-validator';
import { MuiPickersUtilsProvider,KeyboardDatePicker} from "@material-ui/pickers";
import {DoneAll} from '@material-ui/icons';
import { fr } from "date-fns/locale";
import DateFnsUtils from "@date-io/date-fns";
import { useStyles } from '../../css/muiStyle';
import {NoDataComponent,AlertCompo} from '../../js/components'
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useMediaQuery } from 'react-responsive'
import  PieCharts  from "../../compo/Piechart";
import { useContext } from 'react';
import ParamContext from '../../Contexte';
import '../../css/reporting.css'


export default function Rep_act (){
    const Gfunc=require('../../Gfunc')
    const param=useContext(ParamContext);
    const classes = useStyles();
    const [loading,setLoading]=useState(false)
    const [cpt,SetCpt]=useState([])
    const [selectDateD,SetSelectDateD]=useState(Gfunc.DateDeb(new Date()))
    const [selectDateF,SetSelectDateF]=useState(new Date())
    const [tous,SetTous]=useState([])
    const [dataR,SetDataR]=useState([])
    const [dataC,SetDataC]=useState([])
    const [dataP,SetDataP]=useState([])
    const [color,setcolor]=useState([])
    const [colorc,setcolorc]=useState([])
    const [catalogue,setcatalogue]=useState([])
    const [openA, setOpenA] =useState(false);
    const [messageA, setMessageA] =useState('');
    const [severity, setSeverity] =useState('info');
    const [err,SetErr]=useState(false)
    const [selectCpt,SetSelectCpt]=useState('')
    const [selectfiltre,SetSelectfiltre]=useState('')
    const [percent,SetPercent]=useState('')
    const [percentc,SetPercentc]=useState('')
    const message='Aucune donnée trouvée'

    const isDesktopOrLaptop = useMediaQuery({query: '(max-width: 850px)'})
    //const isLengend = useMediaQuery({query: '(max-width: 1150px)'})     


    const handleAlert=(open,severity,message)=>{
      setOpenA(true)
      setSeverity(severity)
      setMessageA(message)
      setTimeout(function(){
        setOpenA(false)
      }, 5000);
    }
    const fetchCpt= useCallback(async() => {
      var cpt=[]
      const response = await Gfunc.axiosPostAction('_cpt.php' ,'gets')
      if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,"error",response.data.err)
      }else{
      Gfunc.axiosResponse(response,()=>{
        response.data.jData.map((el)=>{
          cpt.push({id:el.id,nom:el.nom+' '+el.prenom+'  ['+el.mag+']'})
          return true
        })
        cpt.unshift({id:'empty',nom:'Tous'});
        SetCpt(cpt)  
      })
     }
    },[Gfunc,param.urlService]);

    //valider le formulaire 
    const handleSubmit=async()=>{
      SetSelectfiltre(''); setLoading(true); SetErr(false);SetDataR([]);SetDataC([]);SetDataP([]);SetTous([])
      var somm=0;var som=0;var reb=[];var ca=[];var cata=[]
      const response=await Gfunc.axiosPostAction("_rep.php","gatact",{date1:Gfunc.formatDate(new Date(selectDateD)),date2:Gfunc.formatDate(selectDateF),compte:selectCpt})
      setLoading(false)
      Gfunc.axiosResponse(response,()=>{
        response.data.jData.rubriques.map((el)=>{
           reb.push({name:el.rubrique,value:el.count})
           somm=somm+el.count
           return true
        })
        response.data.jData.catalogues.map((el)=>{
           ca.push({name:el.catalogue,value:el.count})
           som=som+el.count
           return true
         })
        response.data.jData.produits.map((el,index)=>{
            el['ind']=index+1
            const indx=cata.indexOf(el.catalogues)
            if(indx===-1){
            cata.push(el.catalogues)
           }
           return true
          })
        var colorap=(response.data.jData.rubriques.length!==1)?Gfunc.generateColors(response.data.jData.rubriques.length,(["#343838", '#f44336','#f44336'])):["#f44336"]
        var color=(response.data.jData.catalogues.length!==0)?Gfunc.generateColors(response.data.jData.catalogues.length,(['#0088FE', '#00C49F', '#FFBB28', '#FF8042'])):[]
        setcolor(colorap)
        setcolorc(color)
        SetTous(response.data.jData.produits)
        SetDataP(response.data.jData.produits)
        SetDataR(reb)
        SetDataC(ca)
        SetPercent(somm)
        SetPercentc(som)
        setcatalogue(cata)
        SetErr(true)
      })
    } 
    
    //remplir Select Commune
    const handleOpenCpt= useCallback(async () => {
      if(cpt.length===0)
      fetchCpt()
    },[cpt,fetchCpt])

    //valider formulaire avec touche d'entrer
   const detectTouche= (e)=>{
    if (e.keyCode === 13 && (e.target.name==="addBtn")) {
        handleSubmit()
      }
    }
       const columns =useMemo(
            () => [
              {
                 width:'40px',
                 name: "",
                 selector:row=> row.ind,
                 sortable: true,
                },  
              {
                 name: "Produit",
                 selector:row=> row.produit,
                 sortable: true,
              }, 
              {
                  name: "Catalogue",
                  selector:row=> row.catalogues,
                  sortable: true,
              },
              {
                name: "Nombre ",
                selector:row=> row.count,
                sortable: true,
                cell: (row) => {
                  return (
                    <div >
                      {new Intl.NumberFormat().format(row.count)} 
                     </div>
                  )
                }
              },
           ],[]);
const filtrer=(val)=>{
   var test=tous.filter((el) =>  JSON.stringify(el).toLowerCase().indexOf(val.toLowerCase()) !== -1)
    SetDataP(test)
}
const  HandleOnChange=(e)=>{
    SetSelectfiltre(e.target.value)
    filtrer(e.target.value)
}
return (
  <div className='content'>
  <Backdrop className={classes.backdrop} open={loading} >
        <CircularProgress color="inherit" />
 </Backdrop>
 <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA}/>
  <fieldset className={"fieldsetTop"} id={"Con"}>
          <legend>Répartition des activités des comptes par métrique</legend>
        <ValidatorForm noValidate id="formAdd" ref={useRef(null)} onSubmit={handleSubmit} onKeyPress={e=>detectTouche(e)}  >
            <div className="rowGrid" id="row-1">
                <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
                    <KeyboardDatePicker disableToolbar value={selectDateD} variant="inline" placeholder="01/01/2020" onChange={date => SetSelectDateD(date)}
                        minDate="01/01/2020" format="dd/MM/yyyy"  className='smallText' margin="dense" inputVariant="outlined"
                        invalidDateMessage="Le format de la date est incorrect" 
                  />
                </MuiPickersUtilsProvider>
                 <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
                    <KeyboardDatePicker disableToolbar value={selectDateF} variant="inline" placeholder="01/01/2020" onChange={date => SetSelectDateF(date)}
                        minDate="01/01/2020" format="dd/MM/yyyy"  className='smallText' margin="dense" inputVariant="outlined"
                        invalidDateMessage="Le format de la date est incorrect" 
                  />
                </MuiPickersUtilsProvider>
                <Autocomplete
                        disableClearable
                        onChange={(e,newValue) =>{SetSelectCpt(cpt[Gfunc.getIndex(cpt,newValue,"nom")].id)}} 
                        onFocus={handleOpenCpt}
                        options={cpt.map((option) => option.nom)}
                        inputprops={{className:classes.input}}
                        className='smallText'     
                        renderInput={(params) => (
                                <TextField {...params} label="Compte..." color="primary" variant="outlined" size="small"  />
               )} />
              <Button className={classes.fieldButton} variant="contained" tabIndex="3" type='submit' id="bouton" name="addBtn" color="primary" 
                      startIcon={<DoneAll/>}>VALIDER</Button>
           </div>
    </ValidatorForm>
  </fieldset>
 { 

 <div id='charts-pie'>
   { (dataR.length!==0)?
    <Paper id='chart-pie' elevation={8}>
          <PieCharts data={dataR} color={color} percent={percent}/> 
          <Typography className='titleCharts'>Rubriques</Typography>
    </Paper>
:<></>
   }
    { (dataC.length!==0)?
      <Paper id='chart-pie' elevation={8}>
          <PieCharts data={dataC} color={colorc} percent={percentc}/> 
          <Typography className='titleCharts'>Catalogues</Typography>
      </Paper>
    :<></>
   }
  </div>
  }
  {  
  (dataP.length!==0)?
      <div id='table-prod'>
        <div >
                <TextField select color="primary" variant="outlined" className={(isDesktopOrLaptop===false)?classes.headInput1:classes.headFiltreResp} label="Catalogue..." size="small" 
                        inputprops={{ tabIndex: "1" ,className:classes.input1}}
                         value={selectfiltre}  onChange={e =>{HandleOnChange(e)}}>
                                {
                                        catalogue.map((ct,index)=>{
                                            return <MenuItem key={index} value={ct}>{ct}</MenuItem>
                                        })       
                                }
                </TextField>
        </div>
        <DataTable 
              columns={columns}
              data={dataP}
              defaultSortAsc={true}
              noDataComponent={param.ErreurData}
              paginationRowsPerPageOptions={[10,30,50,100]}
              pagination
              highlightOnHover
              paginationComponentOptions={{ rowsPerPageText: 'Élément par page:', rangeSeparatorText: 'sur',selectAllRowsItemText: 'Tous',selectAllRowsItem: true}}
              customStyles={customStyles}   
        />
     </div>:<></>
 }
  
  { (dataP.length===0 &&dataR.length===0 &&dataC.length===0&&err===true)?<NoDataComponent message={message}/>:<></>}
</div>   
  );
}