import React ,{useRef,useCallback,useState} from "react";
import {Paper} from '@material-ui/core';
import {Typography} from '@material-ui/core';
import {Button,CircularProgress,Backdrop } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { ValidatorForm} from 'react-material-ui-form-validator';
import { MuiPickersUtilsProvider,KeyboardDatePicker} from "@material-ui/pickers";
import { XAxis,YAxis,Tooltip, Legend,BarChart,Bar,ReferenceLine,ResponsiveContainer} from "recharts";
import {DoneAll} from '@material-ui/icons';
import { fr } from "date-fns/locale";
import DateFnsUtils from "@date-io/date-fns";
import { useStyles } from '../../css/muiStyle';
import {NoDataComponent,AlertCompo} from '../../js/components'
import Autocomplete from '@material-ui/lab/Autocomplete';
import '../../css/reporting.css'
import axios from "axios"
import { useMediaQuery } from 'react-responsive'
import { useContext } from 'react';
import ParamContext from '../../Contexte';


export default function Rep_tmp (){
   const Gfunc=require('../../Gfunc')
   const param=useContext(ParamContext);
    const classes = useStyles();
    const [loading,setLoading]=useState(false)
    const [User,SetUser]=useState([])
    const [Bu,SetBu]=useState([])
    const [selectDateD,SetSelectDateD]=useState(Gfunc.DateDeb(new Date()))
    const [selectDateF,SetSelectDateF]=useState(new Date())
    const [data,SetData]=useState([])
    const [openA, setOpenA] =useState(false);
    const [messageA, setMessageA] =useState('');
    const [severity, setSeverity] =useState('info');
    const [err,SetErr]=useState(false)
    const [selectUser,SetSelectUser]=useState('')
    const [selectBu,SetSelectBu]=useState('')
    const message='Aucune donnée trouvée'
    const service='_rep.php'

    const isDesktopOrLaptop = useMediaQuery({query: '(max-width: 850px)'})
    const isWidth = useMediaQuery({query: '(max-width: 1150px)'})     

 
    const handleAlert=(open,severity,message)=>{
      setOpenA(true)
      setSeverity(severity)
      setMessageA(message)
      setTimeout(function(){
        setOpenA(false)
      }, 5000);
    }
    const fetchUser= useCallback(async() => {
        const response = await Gfunc.axiosPostAction('_usr.php' ,'gets')
        if(response.data.err&&response.data.err!=='-1'){
          handleAlert(true,"error",response.data.err)
        }else{
        Gfunc.axiosResponse(response,()=>{
            response.data.jData.unshift({id:'empty',name:'Tous'});
            SetUser(response.data.jData);   
        })
      }
    },[Gfunc,param.urlService]);

    const fetchBU= useCallback(async() => {
      const response = await axios.get( param.urlService+service+'?do=getbu&mod=CATALOG-ONLY',{ headers: Gfunc.getHeader()});
      if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,"error",response.data.err)
      }else{
      Gfunc.axiosResponse(response,()=>{
          response.data.jData.unshift({id:'empty',bu:'Toutes'});
          SetBu(response.data.jData)  
      })
     }
  },[Gfunc,param.urlService]);

    //valider le formulaire 
    const handleSubmit=async()=>{
      var dat=[]
      setLoading(true)
      const response=await Gfunc.axiosPostAction('_rep.php',"gettime",{date1:Gfunc.formatDate(new Date(selectDateD)),date2:Gfunc.formatDate(selectDateF),agent:(selectUser!=='empty')?selectUser:'',
                                                 bu:(selectBu!=='empty')?selectBu:''})
      setLoading(false)
      Gfunc.axiosResponse(response,()=>{
        response.data.jData['name']='Processus'
        dat.push(response.data.jData)
        SetData(dat)
        SetErr(true)
      })
    } 
    
    //remplir Select Commune
    const handleOpenUser= useCallback(async () => {
      if(User.length===0)
      fetchUser()
    },[User,fetchUser])

     //remplir Select Commune
     const handleOpenBU= useCallback(async () => {
      if(Bu.length===0)
      fetchBU()
    },[Bu,fetchBU])

    //valider formulaire avec touche d'entrer
   const detectTouche= (e)=>{
    if (e.keyCode === 13 && (e.target.name==="addBtn")) {
        handleSubmit()
      }
    }
    function CustomizedLegend({ payload, label, active,...props }) {
      return (
      <div className='legend'>  
       <ul className='legend-content'>
      {
        payload.map((entry, index) => (
          (entry.value==='affect_time')?
           <li id='content' style={{color:entry.payload.fill}} key={`item-${index}`}>Temps d'affectation</li>
           : (entry.value==='delivery_time')?
           <li  id='content' style={{color:entry.payload.fill}} key={`item-${index}`}>Temps de livraison </li>
           : (entry.value==='take_time')?
           <li  id='content' style={{color:entry.payload.fill}} key={`item-${index}`}>Prise en charge </li>
           : (entry.value==='prepare_time')?
           <li  id='content' style={{color:entry.payload.fill}} key={`item-${index}`}>Temps de préparation </li>
           :<></>
        ))
      }
      </ul>
     </div>
  );
            }
function CustomTooltip({ payload, label, active,...props }) {
     if (active) {
      return (
          <div className="custom-tooltip-bar" style={{border:'1px solid'+payload[0].color}}>
             {
              payload.map((entry, index) => (
                (entry.dataKey==='affect_time')?
                  <p id='label' style={{color:entry.fill}} key={`item-${index}`}>Affectation: {Gfunc.convertirMin(entry.value)}</p>
               : (entry.dataKey==='delivery_time')?
                  <p id='label' style={{color:entry.fill}} key={`item-${index}`}>Livraison: {Gfunc.convertirMin(entry.value)}</p>
               : (entry.dataKey==='take_time')?
                  <p id='label' style={{color:entry.fill}} key={`item-${index}`}>Prise en charge: {Gfunc.convertirMin(entry.value)}</p>
               : (entry.dataKey==='prepare_time')?
               <p id='label' style={{color:entry.fill}} key={`item-${index}`}>Préparation: {Gfunc.convertirMin(entry.value)}</p>
               :<></>
                ))
             }    
         </div>
             );
     }
     return null
}
return (
  <div className='content'>
  <Backdrop className={classes.backdrop} open={loading} >
        <CircularProgress color="inherit" />
 </Backdrop>
 <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA}/>
  <fieldset className="fieldsetTop" id="tmp">
        <legend>Temps de traitement des commandes</legend>
        <ValidatorForm noValidate id="formAdd" ref={useRef(null)} onSubmit={handleSubmit} onKeyPress={e=>detectTouche(e)}  >
            <div className="rowGrid" id="row-1">
             <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
                    <KeyboardDatePicker disableToolbar value={selectDateD} variant="inline" placeholder="01/01/2020" onChange={date => SetSelectDateD(date)}
                                minDate="01/01/2020" format="dd/MM/yyyy"  className='smallText' margin="dense" inputVariant="outlined"
                                invalidDateMessage="Le format de la date est incorrect" 
                  />
              </MuiPickersUtilsProvider>
              <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
                    <KeyboardDatePicker disableToolbar value={selectDateF} variant="inline" placeholder="01/01/2020" onChange={date => SetSelectDateF(date)}
                                minDate="01/01/2020" format="dd/MM/yyyy"  className='smallText' margin="dense" inputVariant="outlined"
                                invalidDateMessage="Le format de la date est incorrect" 
                  />
              </MuiPickersUtilsProvider>
              <Autocomplete
                    disableClearable
                    onChange={(e,newValue) =>{SetSelectUser(User[Gfunc.getIndex(User,newValue,"name")].id)}} 
                    onFocus={handleOpenUser}
                    options={User.map((option) => option.name)}
                    className='smallText'       
                    inputprops={{className:classes.input}}     
                        renderInput={(params) => (
                            <TextField {...params}  label="Agent..." color="primary" variant="outlined" size="small"  />
               )} />
             <div >
                <Autocomplete
                    disableClearable
                    onChange={(e,newValue) =>{SetSelectBu(Bu[Gfunc.getIndex(Bu,newValue,"bu")].id);}} 
                    onFocus={handleOpenBU}
                    options={Bu.map((option) => option.bu)}
                    inputprops={{className:classes.input}}   
                    className='smallText'       
                    renderInput={(params) => (
                            <TextField {...params}  label="BU..." color="primary" variant="outlined" size="small"  />
               )} />
             </div>
              <Button className={classes.fieldButton} variant="contained" tabIndex="3" type='submit' id="bouton" name="addBtn" color="primary" 
                      startIcon={<DoneAll/>}>VALIDER</Button>
           </div>
    </ValidatorForm>
  </fieldset>
  
 {
 (data.length!==0)?
  <Paper id='chart-bar-t' elevation={8}>
    <Typography className='information'>Durée moyenne de satisfaction des commandes: {Gfunc.convertirMin(data[0].process_time)}<br/>
                                        Nombre de commandes: {new Intl.NumberFormat().format(data[0].orders)}</Typography>
    <ResponsiveContainer width='96%' height={320}>
    <BarChart layout="vertical" reverseStackOrder='true' data={data} stackOffset="none" margin={{ top:0, right: 0, bottom:25, left:(isDesktopOrLaptop===false)?90:-52}}>
                <XAxis type="number" />
                <YAxis dataKey='name' type="category" />
                <Tooltip content={<CustomTooltip />} />
                {(isWidth===false)?
                  <Legend width={(isDesktopOrLaptop===false)?700:250} content={<CustomizedLegend  />} 
                        wrapperStyle={{ bottom:(isDesktopOrLaptop===false)?-80:-160, right:0,left:(isDesktopOrLaptop===false)?220:10, backgroundColor: '#f5f5f5', border: '1px solid #d5d5d5', borderRadius: 3, lineHeight: '40px' }}/>
                   :<></>     }
                <ReferenceLine y={0} stroke="#000" />
                <Bar barSize={75} dataKey="delivery_time" fill="#febe44"  stackId='a'/>
                <Bar barSize={75} dataKey="affect_time" fill="#ed7d31" stackId='a' />
                <Bar barSize={75} dataKey="prepare_time" fill="#ff687d" stackId='a' />
                <Bar barSize={75} dataKey="take_time" fill="#30e8a8" stackId='a' />
    </BarChart>
    </ResponsiveContainer>
    <Typography className='titleChartss'>Temps de traitement </Typography>
  </Paper>:<></>
}
{ (data.length===0 && err===true)?<NoDataComponent message={message}/>:<></>}
</div>   
  );
}