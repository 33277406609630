import React,{useState,useRef,useCallback} from 'react';
import {DialogActions,DialogContent,Checkbox,FormGroup,FormControlLabel,Paper,Button,Dialog,DialogTitle} from '@material-ui/core';
import Draggable from 'react-draggable';
import { ValidatorForm} from 'react-material-ui-form-validator';
import {useStyles,paperPropsSmall } from '../../../css/muiStyle';
import {NotAllowedComponent,AlertCompo} from '../../../js/components'
import axios from "axios"
import { useContext } from 'react';
import ParamContext from '../../../Contexte';
import { DoneAll } from '@mui/icons-material';
import {ReactComponent as PlusMinus} from '../../../img/icons/plusMinus.svg'
import '../../../css/compte.css'


//Add a new role
function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function Cpt_new_pass(props) {
    const Gfunc=require('../../../Gfunc')
    const param=useContext(ParamContext);
    const librery=require('../../../js/data_table_librery')
    const classes=useStyles();
    const [open,setOpen]=useState(false)
    const [check,setCheck]=useState([])
    const [elem,setElem]=useState('')
    const [act,setAct]=useState([])
    const [errMessage,setErrMessage]=useState('');
    const [notAllowed,setNotAllowed]=useState(false);
    const [openA, setOpenA] =useState(false);
    const [messageA, setMessageA] =useState('');
    const [severity, setSeverity] =useState('info');


    const handleAlert=(open,severity,message)=>{
      setOpenA(true)
      setSeverity(severity)
      setMessageA(message)
      setTimeout(function(){
        setOpenA(false)
      }, 5000);
    }

    const handleClickOpen = () => { 
        fetchAct();
        setOpen(true); 
        if(props.str==='certifié'){
          setCheck(props.check)
        }
        else{
          setCheck(props.data)
        }
      };

    const handleClose = () => {
       setOpen(false); 
      }
      
    const fetchAct= useCallback(async() => {
      var dat=[]
      const response = await axios.get( param.urlService+'_cpt.php?do=getactivities',{ headers: Gfunc.getHeader()});
      if(response.data.err&&response.data.err!=='-1'){
        setErrMessage(response.data.err)
        setNotAllowed(true)   
      }else{
        Gfunc.axiosResponse(response,()=>{
        response.data.jData.map((el)=>{
           dat.push({id:el.id,activite:el.nom})
            return true
        })
        setAct(dat)  
      })
      }
    },[Gfunc,param.urlService]);

    const handleSubmit=async()=>{
      var data=[];
      check.map(el=>{
        const ind=data.indexOf(el.id)
        if(ind===-1) {data.push(el.id)}
        return true
      })
      const rend=act
      const response=await librery.axiosEdit('_cpt.php',props.Gdata,data,'activities') 
      if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,"error",response.data.err)
        setCheck([])
        setAct(rend)
        setTimeout(function(){
          setOpen(false)
        }, 10000);     
       }
      else{
       Gfunc.axiosResponse(response,()=>{
       setOpen(false)
       if(response.data.jData===true)
        {
          props.handleData(check,props.Gdata.DT_RowId,'certifié')
        }
        setAct([]) 
      })
     }
   }

   const handleChange=useCallback((e,val)=>{
    if(e.target.checked===true){
       const ind=check.findIndex((obj => obj.id ===val.id));
       if(ind===-1){check.push(val)}
    }
    else{
        const ind=check.findIndex((obj => obj.id ===val.id))
        check.splice(ind,1)
    }
   },[check])
  return (
    <div>
      <Button id='expender-button' className={classes.fieldButton} variant="contained" onClick={handleClickOpen} type='submit' 
              color="primary" title='Ajouter/Supprimer une activité'><PlusMinus id='icon' />Activité</Button>
      <Dialog 
            open={open}
            onClose={handleClose}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={paperPropsSmall}
       >
            <DialogTitle className={classes.topDivTitle} id="draggable-dialog-title" >Ajouter / Supprimer une activité</DialogTitle>
            <ValidatorForm ref={useRef(null)} onSubmit={handleSubmit}  >
            <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA}/>
            {
              (notAllowed===false)?
            <DialogContent>
              <FormGroup >
              <div id='acts'>
              <div id='act-1'>
              {
                act.map((el,index)=>{
                const ind=check.findIndex((obj => obj.activite ===el.activite));
                return( 
                    (index<act.length/2)?
                    <div key={el.id.toString()+Math.floor(Math.random() * (1000 - 1 + 1)) + 1}> 
                       <FormControlLabel control={<Checkbox defaultChecked={(ind!==-1)?true:false} value={elem} className={classes.checkBox}
                                         onChange={e=>{handleChange(e,el);setElem(e.target.checked)}} inputProps={{ 'aria-label': 'controlled' }}  />}
                                         label={el.activite} />           
                    </div>:null
                      )
                })
              }
              </div>
              <div>
              {
                act.map((el,index)=>{
                const ind=check.findIndex((obj => obj.activite ===el.activite));
                return( 
                    (index>=act.length/2)?
                    <div key={el.id.toString()+Math.floor(Math.random() * (1000 - 1 + 1)) + 1}> 
                       <FormControlLabel control={<Checkbox  defaultChecked={(ind!==-1)?true:false} value={elem} className={classes.checkBox}
                                         onChange={e=>{handleChange(e,el);setElem(e.target.checked)}} inputProps={{ 'aria-label': 'controlled' }}  />}
                                         label={el.activite} />           
                    </div>:null
                      )
                })
              }
              </div>
              </div>
            </FormGroup>
            </DialogContent>:<div className='compAllow'><NotAllowedComponent message={errMessage}/></div>
             }
            <DialogActions>
              {(notAllowed===false)? <Button variant="contained" type='submit' color="primary" startIcon={<DoneAll/>}>VALIDER</Button>:null}
              <Button variant="contained"  onClick={handleClose} color="secondary">FERMER</Button>
            </DialogActions>
            </ValidatorForm>
      </Dialog>
      </div>
  );
}
