import { useState ,useCallback,useEffect, Fragment} from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { CircularProgress,Backdrop} from '@material-ui/core';
import ContentEditable from 'react-contenteditable';
import {CallSplit,Language,Settings,DragIndicator,Visibility,Person} from '@material-ui/icons';
import DialogAdd from '../content/modals/ani_new_add'
import {AlertCompo,AutocompleteS,NotAllowedComponent} from "../../js/components"
import DialogPub from '../content/modals/ani_new_pub'
import TablePagination from '@material-ui/core/TablePagination';
import Favories from './modals/ani_new_favories'
import Comments from './modals/ani_new_comments'
import AllComments from './modals/ani_new_AllComments'
import AllFavories from './modals/ani_new_AllFavories'
import { useContext } from 'react';
import ParamContext from '../../Contexte';
import {ReactComponent as Date} from '../../img/icons/date.svg' 
import {ReactComponent as TFr} from '../../img/icons/TRr.svg' 
import AR from '../../img/icons/TAR.png'
import { useStyles } from '../../css/muiStyle';
import '../../css/animation.css'


export default function Ani_new() {
const classes = useStyles();
const Gfunc=require('../../Gfunc')
const param=useContext(ParamContext);
const librery=require('../../js/data_table_librery')
const [data,setData]=useState([]);
const [pub,setPub]=useState([]);
const [loading, setLoading] = useState(false);
const [totalRows, setTotalRows] = useState(0);
const [openA, setOpenA] =useState(false);
const [errMessage,setErrMessage]=useState('');
const [notAllowed,setNotAllowed]=useState(false);
const [,setTest]=useState(false);
const [messageA, setMessageA] =useState('');
const [severity, setSeverity] =useState('info');
const [page, setPage] =useState(1);
const [rowsPerPage, setRowsPerPage] =useState(10);
const service='_new.php'


const fetchPub= useCallback(async() => {
  const response = await Gfunc.axiosGetAction(service ,'getpubs')
  if(response.data.err&&response.data.err!=='-1'){
    handleAlert(true,"error",response.data.err)
  }else{
  Gfunc.axiosResponse(response,()=>{
      setPub(response.data.jData)  
  })
  return response.data.jData
  }
},[Gfunc,param.urlService]);


const fetch= useCallback(async(page,servic = service, size = rowsPerPage,val) => {
  setLoading(true);
  const response=await librery.axiosGet(page,servic,size)
  setLoading(false);
  if(response.data.err&&response.data.err!=='-1'){
    setErrMessage(response.data.err)
    setNotAllowed(true)
  }else{
  Gfunc.axiosResponse(response,async()=>{
  if(response.data.data){
      var pub=await fetchPub();
      response.data.data.map((el)=>{
        const objIndex =pub.findIndex((obj => obj.id === el.pub_state));
        el['pub-name']=pub[objIndex].published
      })
      setData(response.data.data)
      setTotalRows(response.data.recordsFiltered)
      setNotAllowed(false)
      setTest(true)
      }
    else{
      setNotAllowed(true)
      setErrMessage('Nombre de lignes restantes est insuffisant')
    }
})
}
},[Gfunc,param.urlService,rowsPerPage,fetchPub]);


useEffect(() => {
  if(data!==undefined&&data.length===0) fetch(1);
},[fetch,data]);

const handleAlert=(open,severity,message)=>{
  setOpenA(true)
  setSeverity(severity)
  setMessageA(message)
  setTimeout(function(){
    setOpenA(false)
  }, 5000);
  }

  const handleDragEnd =async (e) => {
    if (!e.destination) return null;
    let tempData = Array.from(data);
    let [source_data] = tempData.splice(e.source.index, 1);
    tempData.splice(e.destination.index, 0, source_data); 
    setData(tempData);
    const response = await Gfunc.axiosPostAction1(service ,'order',{this_new:tempData[e.destination.index].DT_RowId.substr(1),
                                                  before_that:(e.destination.index!==0)?tempData[e.destination.index-1].DT_RowId.substr(1):''})
    if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,"error","vous n'avez pas le droit d'effectuer cette action")
        setData(data)
    }else{
      Gfunc.axiosResponse(response,()=>{
        //console.log(tempData)
      })
    }
  };
  const handleOnEdit=useCallback(async(e,row,att)=>{
    e.preventDefault();
    const edt=e.target.innerHTML
    const edt1=Gfunc.DeleteSpace(edt)
    if(row[att]!==edt1){
      const attrib=row[att];
      const response=await librery.axiosEdit(service,row,edt1,att) 
      if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,"error",response.data.err)
      }else{
      Gfunc.axiosResponse(response,()=>{row[att]=edt1;e.target.innerHTML=edt1})
      }
      if(response.data.err) {e.target.innerHTML=attrib}
    }else {e.target.innerHTML=edt1}
  },[librery,Gfunc]);

const handleChangePage = (event, newPage) => {
  fetch(newPage+1,service,rowsPerPage);
  setPage(newPage+1);
};

const handleChangeRowsPerPage = (event) => {
    console.log(parseInt(event.target.value,10))
    fetch(1,service,(event.target.value==="Tous")?-1:parseInt(event.target.value,10));
    setRowsPerPage((event.target.value==="Tous")?totalRows:event.target.value);
};

  return (
    <div className="content">
       <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA}/>
       <Backdrop className={classes.backdrop} open={loading} >
        <CircularProgress color="inherit" />
       </Backdrop>
       {(notAllowed===false)?
      <div>
      <div  className="table-header">
       <div className='select-flex-wrapper1'>
          <DialogAdd fetch={fetch}/>
      </div>
      <div id='Allreacts'>
        <AllFavories/>
        <AllComments/>
      </div>
     </div>
     </div>:null
     }
     { 
      (notAllowed===false)?
    <div>
      <DragDropContext onDragEnd={handleDragEnd}>
        <table className="table-borderd">
          <Droppable droppableId="droppable-1">
            {(provider) => (
              <tbody
                className="text-capitalize"
                ref={provider.innerRef}
                {...provider.droppableProps}
              >
                {data.map((el, index) => (
                  <Draggable
                    key={el.DT_RowId}
                    draggableId={el.DT_RowId}
                    index={index}
                  >
                    {(provider) => (
                      <Fragment >
                      <tr {...provider.draggableProps} ref={provider.innerRef} >
                        <td {...provider.dragHandleProps} ref={provider.innerRef} colSpan={2} title='Glisser'> <DragIndicator/></td>
                        <td colSpan={5}>
                        <div className='expender' >
                          <DialogPub id={el.DT_RowId} handleAlert={handleAlert}/>
                          <div className="expender-content">
                           <div id='row-0'>
                              <div id='div-content'>
                                <Date fill='black' style={{height:'18px',width:'18px'}}/>
                                <p>{(el.date_add)?el.date_add:"--"} </p>
                              </div>
                              <div id='div-content'> 
                                <Person style={{height:'18px',width:'18px'}}/> 
                                <p>{(el.agent_add)?el.agent_add:"--"}</p>
                              </div>  
                           </div>
                           <div id='row-1'>
                             <div id='div-content'>
                                <TFr fill='black' style={{height:'18px',width:'18px'}}/>
                                <ContentEditable
                                  html={el.titre_fr}
                                  name="compte"
                                  data-column="item"
                                  className="content-editable-compte"
                                  disabled={false}
                                  onBlur={e => handleOnEdit(e,el,"titre_fr")}
                                />    
                             </div>
                             <div id='div-content'>
                                <img src={AR} style={{height:'18px',width:'18px'}}/>
                                <ContentEditable
                                  html={el.titre_ar}
                                  name="compte"
                                  data-column="item"
                                  className="content-editable-compte"
                                  disabled={false}
                                  onBlur={e => handleOnEdit(e,el,"titre_ar")}
                                />  
                             </div>
                             <div id='div-content'>
                                <Visibility style={{height:'18px',width:'18px'}}/>
                                <AutocompleteS
                                   service={service} action={'upd'}  row={el} list={pub} className='content-editable-compte' className1='SelectDT'
                                   id='pub_state' name='pub-name' attr='published' onOpen={fetchPub} handleAlert={handleAlert}/>
                             
                             </div>
                           </div>
                           <div id='row-2'>
                            <div id='div-content'>
                              <CallSplit />
                               <ContentEditable
                                html={(el.route)?el.route:"--"} name="anim" data-column="item" className="content-editable-compte" disabled={false}
                                onBlur={e => handleOnEdit(e,el,"route")}
                               />
                            </div>
                            <div id='div-content'>
                              <Language style={{height:'18px',width:'18px'}}/>
                              <ContentEditable
                                 html={(el.navigator)?el.navigator:"--"} name="anim" data-column="item" className="content-editable-compte" disabled={false}
                                 onBlur={e => handleOnEdit(e,el,"navigator")}
                              />
                            </div>
                            <div id='div-content' >
                              <Settings style={{height:'18px',width:'18px'}}/>
                              <ContentEditable
                                html={(el.param1)?el.param1:"--"} name="anim" data-column="item" className="content-editable-compte" disabled={false}
                                onBlur={e => handleOnEdit(e,el,"param1")}
                              />
                            </div>
                            <div id='div-content'>
                              <Settings style={{height:'18px',width:'18px'}}/>
                              <ContentEditable
                                html={(el.param2)?el.param2:"--"} name="anim" data-column="item" className="content-editable-compte" disabled={false}
                                onBlur={e => handleOnEdit(e,el,"param2")}
                              />
                            </div>
                            </div>
                          </div>
                        </div>
                        </td>
                        <td id='reacts'>
                          <div style={{marginBottom:"10px"}}>
                            <Favories nbr={el.loves} row={el}/><p style={{color:'red'}}>{(el.loves!==undefined)?el.loves:0}</p>
                          </div>
                          <div>
                            <Comments nbr={el.comments} row={el}/><p style={{color:'green'}}>{(el.comments!==undefined)?el.comments:0}</p>
                          </div>
                        </td>
                      </tr>
                       <tr style={{border:'none'}}></tr>
                      </Fragment>
                     
                    )}
                  </Draggable>
                ))}
                {provider.placeholder}
              </tbody>
            )}
          </Droppable>
        </table>
      </DragDropContext>
        <TablePagination
          component="div"
          labelRowsPerPage='Élément par page:'
          count={totalRows}
          rowsPerPageOptions={[10,30,50,100,'Tous']}
          page={page-1}
          rowsPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
     </div> :<div className='compAllow'><NotAllowedComponent message={errMessage}/></div>
      }   
    </div>
  );
}

