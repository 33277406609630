import React,{useCallback, useRef, useState} from 'react';
import {DialogContent,Typography,Paper,Dialog,IconButton,Button,Divider,DialogActions,CircularProgress,TextareaAutosize} from '@material-ui/core';
import Draggable from 'react-draggable';
import {paperProps } from '../../../css/muiStyle';
import {Clear, Close, Comment, FiberManualRecord} from '@material-ui/icons';
import '../../../css/compte.css'
import { withStyles} from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import {useStyles } from '../../../css/muiStyle';
import { useContext } from 'react';
import ParamContext from '../../../Contexte';
import { AddShoppingCart,Save } from '@mui/icons-material';
import Err1 from "../../../img/photo.png"
import NumberPicker from '../../../compo/NumberPicker';
import { textAreaEditGray } from '../../../css/muiStyle';
import { ReactComponent as Iraha } from "../../../img/logos/iraha.svg"
import Boutiqa from './../../../img/logos/boutiqa.png'
import { AlertCompo } from '../../../js/components';

const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });
const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle  className={classes.root} {...other}>
        <Typography >{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <Close />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

//Add a new role
function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function Cmd_acc_prepare(props) {
    const classes = useStyles();
    const Gfunc=require('../../../Gfunc')
    const param=useContext(ParamContext);
    const [open,setOpen]=useState(false)
    const [produits,setProduits]=useState([])
    const [loading, setLoading] =useState(false)
    const [noteAr, setNoteAr] = useState('');
    const [noteFr, setNoteFr] = useState('');
    const [noteCli, setNoteCli] = useState('');
    const [openA, setOpenA] =useState(false);
    const [messageA, setMessageA] =useState('');
    const [severity, setSeverity] =useState('info');
    const service='_cmd.php'
    const count=useRef({count1:null,count2:null,count3:null})

    const handleAlert=(open,severity,message)=>{
      setOpenA(true)
      setSeverity(severity)
      setMessageA(message)
      setTimeout(function(){
        setOpenA(false)
      }, 5000);
    }
    
    const handleClickOpen = () => { 
        setOpen(true); 
        fetch();
        setNoteAr('');setNoteCli('');setNoteFr('');setProduits([]);
      };

    const handleClose = () => {
       setOpen(false);
       if(props.row.state==='Attente'){
        props.handleChangeState(props.row)
       }
     };
    
    const fetch=useCallback(async() => {
      var tot=0
      setLoading(true)
      const response=await Gfunc.axiosPostAction(service,"getdet",{orderId:parseInt(props.row.DT_RowId.substr(1)),prepare:true})
      setLoading(false)
      if(response.data.err&&response.data.err!=='-1'){
        //handleAlert(true,"error",response.data.err)
      }else{
        Gfunc.axiosResponse(response,()=>{
          if(response.data.jData){
            response.data.jData.map((el)=>{
              if(el.minstock===el.mdlstock) {
                  el['minstock']=0
                  el['minpin']=0
              }
              if(el.maxstock===el.mdlstock){
                  el['mdlstock']=0
                  el['mdlpin']=0

              }
              if(el.qte<=el.maxstock){
                el['minqte']=0
                el['mdlqte']=0
                el['maxqte']=el.qte
              }else{
                if(el.qte-el.maxstock<=el.mdlstock){
                  el['minqte']=0
                  el['mdlqte']=el.qte-el.maxstock
                  el['maxqte']=el.maxstock
                }else{
                  if(el.qte-el.maxqte-el.mdlqte<=el.minstock){
                    el['minqte']=el.qte-el.maxqte-el.mdlqte
                    el['mdlqte']=el.mdlstock
                    el['maxqte']=el.maxstock
                  }else{
                    el['minqte']=(el.hasOwnProperty('minstock'))?el.minstock:0
                    el['mdlqte']=(el.hasOwnProperty('mdlstock'))?el.mdlstock:0
                    el['maxqte']=(el.hasOwnProperty('maxstock'))?el.maxstock:0
                  }
                }
              }
              el['totale']=el.qte*el.prx
              tot=tot+(el.qte*el.prx)
            })
            response.data.jData['somme']=tot
            setProduits(response.data.jData)
          }
        })
      }
    },[props,Gfunc])


    const Calculate=(val,att,elem)=>{
      var somme=0;
      if(att==='maxqte'){ somme=elem.prx*(val+elem.mdlqte+elem.minqte)}
      if(att==='mdlqte'){ somme=elem.prx*(val+elem.maxqte+elem.minqte)}
      if(att==='minqte'){ somme=elem.prx*(val+elem.mdlqte+elem.maxqte)}
      return somme;
    }
    
    const handleTestAtt=(att)=>{
      switch(att){
        case "maxqte": return ['mdlqte','minqte']
        case "mdlqte": return ['maxqte','minqte']
        case "minqte": return ['maxqte','mdlqte']
      }
    }

    const handleEditQte=useCallback((el,val,att)=>{
      if(att!=="prx"){
        const arr=handleTestAtt(att)
        //if(val+el[arr[0]]+el[arr[1]]<=el.qte){
          var updatedProduits = produits.map((produit) => {
            if (produit.bqId === el.bqId){
                    return {...produit,[att]:val,qte:val+el[arr[0]]+el[arr[1]],totale:Calculate(val,att,produit)}
                  }
                  return produit; 
          })   
          const sommeCom = updatedProduits.reduce((acc, produit) => acc + produit.totale, 0);
          updatedProduits['somme']=parseFloat(sommeCom)
          setProduits(updatedProduits)  

        //}else{
         //     handleAlert(true,'warning','Vous devez respecter la quantité commandée!')
         //     var updatedProduits = produits.map((produit) => {
         //       if (produit.bqId === el.bqId){
         //         return {...produit,[att]:el[att],totale:Calculate(val,att,produit)}
         //       }
         //       return produit; 
         //     }) 
         //     setProduits(updatedProduits)   
        //    }
      }else{
        var updatedProduits = produits.map((produit) => {
          if (produit.bqId === el.bqId){
            return {...produit,[att]:val,totale:val*(el.maxqte+el.mdlqte+el.minqte)}
          }
          return produit; 
        })  

        const sommeCom = updatedProduits.reduce((acc, produit) => acc + produit.totale, 0);
        updatedProduits['somme']=parseFloat(sommeCom)
        setProduits(updatedProduits)  
      }
      
    },[produits])

    
    const handleValidate=async()=>{
      var data={};var prdDet=[]
      produits.map((produit) => {
        var element={
          orderIdDet:produit.orderIdDet,
          prx:produit.prx,
          bqId1:(produit.minidp!==produit.mdlidp)?produit.minidp:null,qte1:produit.minqte,achat1:produit.minpin,
          bqId2:(produit.mdlidp!==produit.maxidp)?produit.mdlidp:null,qte2:produit.mdlqte,achat2:produit.mdlpin,
          bqId3:produit.maxidp,qte3:produit.maxqte,achat3:produit.maxpin
        }
        prdDet.push(element)
      });
      data={'orderId':produits[0].orderId,'noteAr':noteAr,'noteFr':noteFr,noteBon:noteCli,'orderDets':prdDet, 
       // prx:parseFloat(produits['somme'])
       }

      const response=await Gfunc.axiosPostAction(service,"valcom",data)
      if(response.data.err&&response.data.err!=='-1'){
        props.handleAlert(true,"error",response.data.err)
      }else{
        Gfunc.axiosResponse(response,()=>{
          if(response.data.jData===true){ 
            props.handleAlert(true,"success",'La commande a été validée avec succès')
            props.HandleValidate(props.row,produits['somme']);
            setOpen(false)
          }else{
            setOpen(false)
            if(response.data.jData===''){
              props.handleAlert(true,"error","Stock insuffisant")
            }else{
              props.handleAlert(true,"error",response.data.jData)
            }
          }
        })
      }
      
    }

    const handleReject=async()=>{
      const response=await Gfunc.axiosPostAction(service,"reject",{orderId:parseInt(props.row.DT_RowId.substr(1))})
      if(response.data.err&&response.data.err!=='-1'){
        //handleAlert(true,"error",response.data.err)
      }else{
        Gfunc.axiosResponse(response,()=>{
          if(response.data.jData){
            props.handleAlert(true,"success",'La commande a été rejetée avec succès')
            props.row.stateId=0
            props.row.state="Rejetée"
            props.HandleReject();
            setOpen(false)
          }
        })
      }
    }

    const FindStep = (val) => {
      switch (true) {
        case val <= 100:
          return 1;
        case val > 100 && val <= 1000:
          return 10;
        case val > 1000:
          return 100;
        default:
          return 0; 
      }
    };
    


  return (
    <div>
        <Button className={classes.fieldButton} variant="contained"  color="primary" startIcon={<AddShoppingCart/>} onClick={handleClickOpen} 
                style={{width:'98%'}} title={props.Validate||props.reject||props.row.state==='Rejetée'||props.row.state==='Prête'?
                                            "Consultation du récapitulatif de la commande":"Récapitulatif de la commande"}>
                        {props.Validate||props.reject||props.row.state==='Rejetée'||props.row.state==='Prête'?
                      "Consultation":"Préparation"}
        </Button>
        <Dialog 
            open={open}
            onClose={handleClose}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={paperProps}
        >
            <DialogTitle id="simple-dialog-title" onClose={handleClose}>
                <b>Récapitulatif de la commande de : {props.row.accountMag}</b>
            </DialogTitle>
            <DialogContent> 
            <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA}/>
            <div id='commades-det'>
            {
              (loading)?<div id="empty-expender"><CircularProgress /></div>
              :<>
              <Divider/>
              <div id='head-cmd'>
                <p>Produit</p>
                 <p>STOCK BAS<br/>ACHAT STOCK</p>
                 <p>AUTRE STOCK<br/>ACHAT STOCK</p>
                 <p>STOCK HAUT<br/>ACHAT STOCK</p>
                 <p>VENTE</p>
              </div>
             <Divider/>
              <div style={{maxHeight:'350px',overflow:'auto'}}  >
               {
                (produits.length!==0)?
                  produits.map((el,index)=>{
                    return(
                        <div key={index}>
                            <div id='prod-cmd'>
                                <div style={{display:'flex',gridGap:'10px',alignItems:'center'}}>
                                    <img src={param.ProdImg+el.photoId+'.jpg'} style={{height:"80px"}}
                                          onError={(e) => {
                                            e.target.onerror = null
                                            e.target.src = Err1 }}/>
                                    <div >
                                       <div  style={{display:'flex',gridGap:'10px',marginBottom:'10px'}}>
                                          <img src={Boutiqa} style={{height:'16px',width:'16px'}} fill='#a31a17'/>
                                          <p>{el.nomBq}</p>
                                        </div>
                                        <div style={{display:'flex',gridGap:'10px'}}>
                                          <Iraha style={{height:'16px',width:'16px'}}/>
                                          <p style={{fontSize:'13px'}}>{el.nom}</p>
                                          <FiberManualRecord  style={{color:'#'+el.colorCod,height:'18px'}}/>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                  <div style={{display:"flex",alignItems:'center',justifyContent:'center',gridGap:"5px"}}>
                                    <p>{(el.minpin!==0 && el.minpin !==undefined )?new Intl.NumberFormat().format(el.minpin):'-'}</p>
                                    <hr id='hr'/>
                                    <p>{(el.minstock!==0 && el.minstock!==undefined)?new Intl.NumberFormat().format(el.minstock):'-'}</p>
                                  </div> <br/>
                                  <div key={Math.floor(Math.random() * (1000 - 1 + 1)) + 1}>
                                  <NumberPicker row={el} count={el.minqte} max2={el.minstock} bool={el.minstock===0} 
                                                att='minqte' min={0}
                                                width='20px' maxWidth='35px' step={1} handleEditQte={handleEditQte}/>
                                  </div>
                                </div>
                                <div>
                                  <div style={{display:"flex",alignItems:'center',justifyContent:'center',gridGap:"5px"}}>
                                    <p>{(el.mdlpin!==0 && el.mdlpin!==undefined)?new Intl.NumberFormat().format(el.mdlpin):'-'}</p>
                                    <hr id='hr'/>
                                    <p>{(el.mdlstock!==0 && el.mdlstock!==undefined)?new Intl.NumberFormat().format(el.mdlstock):'-'}</p>
                                  </div> <br/>
                                  <div key={Math.floor(Math.random() * (1000 - 1 + 1)) +2}>
                                  <NumberPicker row={el} count={el.mdlqte} max2={el.mdlstock} min={0}  bool={el.mdlstock===0} 
                                               att='mdlqte'
                                                width='20px' maxWidth='35px' step={1} handleEditQte={handleEditQte}/>
                                  </div>
                                </div>
                                <div>
                                  <div style={{display:"flex",alignItems:'center',justifyContent:'center',gridGap:"5px"}}>
                                    <p>{(el.maxpin!==0 && el.maxpin!==undefined)?new Intl.NumberFormat().format(el.maxpin):'-'}</p>
                                    <hr id='hr'/>
                                    <p>{(el.maxstock!==0 && el.maxstock!==undefined)?new Intl.NumberFormat().format(el.maxstock):'-'}</p> 
                                  </div> <br/> 
                                  <div  key={Math.floor(Math.random() * (1000 - 1 + 1)) + 3}>
                                    <NumberPicker row={el} count={el.maxqte} max2={el.maxstock} bool={el.maxstock===0} min={0} att='maxqte' 
                                                  width='20px' maxWidth='35px' step={1} handleEditQte={handleEditQte} />
                                  </div>
                                </div>
                                <div key={Math.floor(Math.random() * (1000 - 1 + 1)) + 3}>
                                    <NumberPicker row={el} count={el.prx} max='10000000' max2='10000000' min={0} att='prx' 
                                                  width='50px' maxWidth='70px' step={FindStep(el.prx)}  handleEditQte={handleEditQte} />
                                 <br/>
                                 <p style={{fontSize:"15px"}}><b>Total Qte. :</b>
                                  {el.qte}
                                  </p>
                                </div>
                            </div>
                            {(index!==(produits.length+1))?<Divider/>:null}
                        </div>
                    )
                })
                :<p style={{textAlign:"center",fontWeight:'bold',marginTop:'5px'}}>{param.ErreurData}</p>
               }
               </div>
               <div id='tot-cmd'>
                  <p>Total: </p>
                  {(loading||produits.somme===undefined)?
                  new Intl.NumberFormat().format(props.row.mtn):
                  new Intl.NumberFormat().format(produits.somme)} 
               </div>
               </>
               }
               </div><br/>
                {!(props.Validate||props.reject||props.row.state==='Rejetée'||props.row.state==='Prête')?<div id='cmd-notes'>
                  <div style={{textAlign:'center',fontSize:'13px',fontWeight:'bold'}}>
                    <p>Note client en arabe</p>
                    <div className="noteDiv" title='Note au client Arabe'>
                      <Comment style={{height:"18px"}}/>
                      <TextareaAutosize style={textAreaEditGray} onChange={e=>{setNoteAr(e.target.value)}} value={noteAr}/>
                    </div>
                  </div>
                  <div style={{textAlign:'center',fontSize:'13px',fontWeight:'bold'}}>
                    <p>Note client en français</p>
                    <div className="noteDiv" title='Note au client français'>
                      <Comment style={{height:"18px"}}/>
                      <TextareaAutosize style={textAreaEditGray} onChange={e=>{setNoteFr(e.target.value)}} value={noteFr}/>
                    </div>
                  </div>
                  <div style={{textAlign:'center',fontSize:'13px',fontWeight:'bold'}}>
                    <p>Note sur le bon</p>
                    <div className="noteDiv" title='Note sur le bon'>
                      <Comment style={{height:"18px"}}/>
                      <TextareaAutosize style={textAreaEditGray} onChange={e=>{setNoteCli(e.target.value)}} value={noteCli}/>
                    </div>
                  </div>
                </div>:null}
                
            </DialogContent>
            {
            props.Validate||props.reject||props.row.state==='Rejetée'||props.row.state==='Prête'?
             <br/>
             :
              <DialogActions>
                <Button variant="contained" onClick={handleValidate} form='form' color="primary" startIcon={<Save/>}>VALIDER</Button>
                <Button variant="contained" onClick={handleReject} className={classes.fieldButtonReject} color="primary" startIcon={<Clear/>}>Rejeter</Button>
                <Button variant="contained"  onClick={handleClose} color="secondary">FERMER</Button>
              </DialogActions>
           }
        </Dialog>
      </div>
  );
}
