import React from 'react';
import Nothing from './error'
import Empt from './empty'
import MainMenu from './main_menu';
import { Route,Switch,Redirect} from 'react-router-dom';
import { useMediaQuery } from 'react-responsive'
import ParamContext from '../Contexte';


//is a component that returns the content of the page according to the links of the sub menu
function Pages (props) {
    const param=props.param;
    const version=require('../version.json');
    const Gfunc=require("../Gfunc")
    const isDesktopOrLaptop = useMediaQuery({query: '(max-width: 850px)'})

    if(!Gfunc.isEmpty(props.menu)) {
        return (
            <ParamContext.Provider value={param}>
                <MainMenu men={props.menu} param={param} />
                <Switch>
                {
                <Route exact path="/" render={() => (
                        
                            <Redirect to={"/"+sessionStorage.getItem('cscope')+"/"+sessionStorage.getItem('scope')}/>
                        
               )}/>}
            {
                Object.keys(props.menu).map((key, index)=> {  
                 var value=props.menu[key];
                 var Component=null;
                 if(value[2]===2){
                    try{
                        Component =require('./content/'+value[1]).default;
                         }
                        catch(ex){
                         Component=null
                        }
                        if(Component) return <Route key={index} exact path={'/'+value[0]+'/'+key} component={props => <Component {...props}/>}/>
                        else return <Route key={index} exact path={'/'+value[0]+'/'+key} component={props => <Empt {...props} message="Page introuvable"  hideButton={true} />}/>  
                  }
                  if(value[2]===1){
                  // console.log(key)
                  // console.log(props.menu[Object.keys(props.menu)[Gfunc.navObj(props.menu,key,1)]][2]!==1)
                   if(props.menu[Object.keys(props.menu)[Gfunc.navObj(props.menu,key,1)]][2]!==1){
                        try{
                            Component =require('./content/'+props.menu[Object.keys(props.menu)[Gfunc.navObj(props.menu,key,1)]][1]).default;
                        }catch(ex){
                                Component=null
                            }
                        if(Component) return <Route key={index} exact path={'/'+key} component={props => <Component {...props} />}/>
                        else return <Route key={index} exact path={'/'+value[0]+'/'+key} component={props => <Empt {...props} message="Page introuvable"  hideButton={true} />}/> 
                    }
                   else{
                        return <Route key={index} exact path={'/'+key} component={props => <Empt {...props} message="Page introuvable"/>}/> 
                    }   
                  }
                  return true
               }
               )        
                 }
                 <Route component={props => <Nothing {...props} />}/> 
             
                </Switch>
                <div id="footer">
                    <span id={(isDesktopOrLaptop === false) ? "copyright" : 'copyright1'}> {((param.app === "1") ? param.webApp : param.webApp2)} {version[0].build} &copy; {param.copyright}</span>
                    {(isDesktopOrLaptop===false)? <span id="powered">powered by {param.company} for {param.licence}</span>:<></> }
                    {(isDesktopOrLaptop===false)?   <span id="lastMAJ"> Last update : {version[0].date}</span>:<></>}
                </div>
            </ParamContext.Provider>
          
        );
    } else {
        return <Route exact path={'/Empty'} component={props => <Nothing {...props} />}/> ;
        ;
    }
}

export default Pages;