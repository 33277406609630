import React,{useState,useRef,useCallback} from 'react';
import {DialogActions,DialogContent,DialogTitle,Paper,Button,Dialog,TextField,FormControlLabel,Checkbox} from '@material-ui/core';
import Draggable from 'react-draggable';
import {DoneAll} from '@material-ui/icons';
import { ValidatorForm} from 'react-material-ui-form-validator';
import AddIcon from '@material-ui/icons/Add';
import { useStyles, paperPropsUpd } from '../../../css/muiStyle';
import {AlertCompo} from '../../../js/components'
import { MuiPickersUtilsProvider,KeyboardDatePicker} from "@material-ui/pickers";
import { fr } from "date-fns/locale";
import DateFnsUtils from "@date-io/date-fns";
import axios from "axios"
import { useMediaQuery } from 'react-responsive'
import { useContext } from 'react';
import ParamContext from '../../../Contexte';
import Autocomplete from '@mui/material/Autocomplete';
import '../../../css/animation.css'

//Add a new role
function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function Cat_cat_add(props) {
    const classes=useStyles();
    const Gfunc=require('../../../Gfunc')
    const param=useContext(ParamContext);
    const [titreFr,setTitreFr]=useState('');
    const [titreAr,setTitreAr]=useState('');
    const [titreEn,setTitreEn]=useState('');
    const [selectact,setSelectAct]=useState('');
    const [limit,setLimit]=useState('');
    const [New,setNew]=useState(true);
    const [vip,setVip]=useState(true);
    const [nom,setNom]=useState('');
    const [ordre,setOrdre]=useState('');
    const [img,setImg]=useState('');
    const [selectDate,setSelectDate]=useState(new Date());
    const [open, setOpen] = useState(false);
    const [openA, setOpenA] =useState(false);
    const [act, setAct] =useState([]);
    const [after, setAfter] =useState([]);
    const [messageA, setMessageA] =useState('');
    const [severity, setSeverity] =useState('info');
    const isDesktopOrLaptop = useMediaQuery({query: '(max-width: 600px)'})
    const service="_cat.php"


    const handleAlert=(open,severity,message)=>{
      setOpenA(true)
      setSeverity(severity)
      setMessageA(message)
      setTimeout(function(){
        setOpenA(false)
      }, 5000);
      }
    const handleClickOpen = () => { 
        setNew(true)
        setOpen(true); 
        setNom('')
        setImg('');setOrdre('');setAct([]);setTitreAr('');setTitreFr('');setVip(true);setNew(true)
    }
    const handleClose = () => { setOpen(false); };

    const fetchAct= useCallback(async() => {
      const response = await axios.get( param.urlService+'_cpt.php?do=getactivities',{ headers: Gfunc.getHeader()});
      if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,"error",response.data.err)
      }else{
      Gfunc.axiosResponse(response,()=>{
          setAct(response.data.jData)  
      })
      }
    },[Gfunc,param.urlService]);

    const fetchAfter= useCallback(async() => {
      const response = await axios.get( param.urlService+service+'?do=gets',{ headers: Gfunc.getHeader()});
      if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,"error",response.data.err)
      }else{
      Gfunc.axiosResponse(response,()=>{
        response.data.jData.sort((a, b) => {
          const elementA = a.nom.toLowerCase();
          const elementB = b.nom.toLowerCase();
          if (elementA < elementB) {return -1;}
          if (elementA > elementB) {return 1;}
          return 0; 
        });            
        setAfter(response.data.jData)  
      })
      }
    },[Gfunc,param.urlService]);

    const handleOpenAct= useCallback(async () => {
      if(act.length===0)
      fetchAct();
    },[act,fetchAct])

    const handleOpenAfter= useCallback(async () => {
      if(after.length===0)fetchAfter();
    },[after,fetchAfter])

    const handleSubmit= async() =>{
      var activite=(selectact)?act[Gfunc.getIndex(act,selectact,"nom")].id:''
      var before=(selectact)?after[Gfunc.getIndex(after,ordre,"nom")].id:''
      var formData = new FormData();
      formData.append("titre_ar", titreAr);
      formData.append("titre_fr", titreFr);
      formData.append("titre_en", titreEn);
      formData.append("date", Gfunc.formatDate(selectDate));
      formData.append("activite_id", activite);
      formData.append("before_that", before);
      formData.append("limit", limit);
      formData.append("img", img);
      formData.append("vip",(vip===true)?1:0);
      formData.append("new", (New===true)?1:0);
      const response = await Gfunc.axiosPostAction1(service ,'add',formData)
      if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,"error",response.data.err)
      }else{
        Gfunc.axiosResponse(response,()=>{
          if(response.data.jData){
            setOpen(false)
            props.fetch(1)
          }
        })
      }
    }
  //valider formulaire avec touche d'entrer
  const detectTouche= (e)=>{
      if (e.keyCode === 13 && (e.target.name==="addBtn")) {
          handleSubmit()
        }
  }
  return (
    <div >
      <Button className={(isDesktopOrLaptop===false)?classes.topTabBtn:classes.topTabBtn} variant="contained" color="secondary" onClick={handleClickOpen} startIcon={<AddIcon/>}>{(isDesktopOrLaptop===false)?'Nouveau catalogue':'Nouveau'}</Button>
      <Dialog
            className='catalogues'
            open={open}
            onClose={handleClose}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={paperPropsUpd}
       >
       <DialogTitle className={classes.topDivTitle} id="draggable-dialog-title" >Ajouter un nouveau catalogue</DialogTitle>
       <ValidatorForm ref={useRef(null)} onSubmit={handleSubmit} id='cat-form' onKeyPress={e=>detectTouche(e)}/*onError={errors => console.log(errors)}*/ >
            <DialogContent>
            <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA}/>
        <div id='cat'>
            <div id='row-1'>
                <TextField  variant="outlined" className='smallText' size="small" id="fr" label="Français" name="fr" 
                            inputProps={{ tabIndex: "1" ,className:classes.input1}}  value={titreFr} 
                            onChange={e =>setTitreFr(e.target.value)} autoFocus required />
                <TextField variant="outlined" className='smallText' size="small" id="ar" label="Arabe" name="ar" 
                           inputProps={{ tabIndex: "2" ,className:classes.input1}} value={titreAr} 
                           onChange={e =>setTitreAr(e.target.value)}  required />
                <TextField variant="outlined" className='smallText' size="small" id="ar" label="Englais" name="en" 
                           inputProps={{ tabIndex: "2" ,className:classes.input1}} value={titreEn} 
                           onChange={e =>setTitreEn(e.target.value)}  required />
                <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
                    <KeyboardDatePicker disableToolbar value={selectDate}  inputVariant="outlined" label="Date" size="small" placeholder="01/01/2020" onChange={date => setSelectDate(date)}
                                         minDate='01/01/2000' format="dd/MM/yyyy" className='smallText' 
                                         invalidDateMessage="Le format de la date est incorrect" 
                                />
                </MuiPickersUtilsProvider>
            </div>
            <div id='row-2'>
              <Autocomplete
                  disableClearable
                  onChange={(e,newValue) =>{setSelectAct(newValue)}} 
                  onFocus={handleOpenAct}
                  options={act.map((option) => option.nom)} 
                  className='smallText'
                  renderInput={(params) => (
                   <TextField  {...params} className={classes.input11} label="Activités " color="primary" variant="outlined" size="small"  />
               )} />
               <Autocomplete
                  disableClearable
                  onChange={(e,newValue) =>{setOrdre(newValue)}} 
                  onFocus={handleOpenAfter}
                  options={after.map((option) => option.nom)} 
                  className='smallText' 
                  renderInput={(params) => (
                   <TextField  {...params} className={classes.input11} label="Après " color="primary" variant="outlined" size="small"  />
               )} />
               <TextField type='number' variant="outlined" className='smallText' size="small" id="limit" label="Limit" name="limit" 
                          inputProps={{ tabIndex: "1" ,className:classes.input1}}  value={limit} 
                          onChange={e =>setLimit(e.target.value)} required />
            </div>
            <div id='row-3'>
              <div  id='input1'>
              <FormControlLabel  label="New" 
                control={<Checkbox  checked={New}  className={classes.checkBox} onChange={e=>{setNew(e.target.checked)}} inputProps={{ 'aria-label': 'controlled' }}  />}
              />  
             </div>
              <div  id='input1'>
              <FormControlLabel control={<Checkbox  checked={vip}  className={classes.checkBox}
                                         onChange={e=>{setVip(e.target.checked)}} inputProps={{ 'aria-label': 'controlled' }}  />}
                                         label="VIP" /> 
              </div>
            </div>
            <div id='div-file' style={{display:'flex',gridGap:'5px',fontSize:'13px',fontWeight:'bold',alignItems:'center'}}>
              <Button variant="contained" component="label" className={(isDesktopOrLaptop===false)?classes.fieldButton:classes.fieldBtn}>
                Choisir une image
                <input 
                  type="file" hidden accept=".png" 
                  onChange={e=>{setImg(e.target.files[0]);setNom(e.target.files[0].name)}}
                />
              </Button>
              <p>{nom}</p>
            </div>
            </div>
           </DialogContent>               
            <DialogActions id='btn-div'>
              <Button variant="contained" type='submit' color="primary" startIcon={<DoneAll/>}>VALIDER</Button>
              <Button variant="contained"  onClick={handleClose} color="secondary">FERMER</Button>
            </DialogActions>
        </ValidatorForm>
      </Dialog>
      </div>
  );
}
