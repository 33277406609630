import React,{useState,useRef} from 'react';
import {DialogActions,DialogContent,DialogTitle,Paper,Button,Dialog,TextField} from '@material-ui/core';
import Draggable from 'react-draggable';
import { DoneAll} from '@material-ui/icons';
import { ValidatorForm} from 'react-material-ui-form-validator';
import AddIcon from '@material-ui/icons/Add';
import { useStyles, paperPropsSmall} from '../../../css/muiStyle';
import {AlertCompo} from '../../../js/components'
import { MuiPickersUtilsProvider,KeyboardDatePicker} from "@material-ui/pickers";
import { fr } from "date-fns/locale";
import DateFnsUtils from "@date-io/date-fns";
import '../../../css/catalogue.css'
import { ChromePicker } from "react-color";


//Add a new role
function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function Cat_add_col(props) {
    const classes=useStyles();
    const Gfunc=require('../../../Gfunc')
    const [background, setBackground] = useState({h: 250,s: 0,l: 0.2,a: 1});
    const [opacityBg, setOpacityBg] = useState("1");
    const [colorPreview, setColorPreview] = useState("#333333");
    const [selectDate,setSelectDate]=useState(new Date());
    const [nom,setNom]=useState('');
    const [opacity,setOpacity]=useState('');
    const [open, setOpen] = React.useState(false);
    const [openA, setOpenA] =useState(false)
    const [messageA, setMessageA] =useState('');
    const [severity, setSeverity] =useState('info');
    const service="_col.php"
    //const isDesktopOrLaptop = useMediaQuery({query: '(max-width: 600px)'})


    const handleAlert=(open,severity,message)=>{
      setOpenA(true)
      setSeverity(severity)
      setMessageA(message)
      setTimeout(function(){
        setOpenA(false)
      }, 5000);
      }

    const handleClickOpen = () => { 
        setOpen(true); 
        setNom('')
        setOpacity('')
    }
    const handleClose = () => { setOpen(false); };
   
    const handleSubmit= async(e) =>{
      const response = await Gfunc.axiosPostAction(service ,'add',{nom:nom,color:colorPreview.substr(1),alpha:opacityBg*100,opacity:opacity,date:Gfunc.formatDate(selectDate)})
      if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,"error",response.data.err)
      }else{
      Gfunc.axiosResponse(response,()=>{
        if(response.data.jData){
          setOpen(false)
          props.fetch(1)
        }
      })
      }
    }
    //valider formulaire avec touche d'entrer
    const detectTouche= (e)=>{
        if (e.keyCode === 13 && (e.target.name==="addBtn")) {
            handleSubmit()
          }
    }
  
    const handleChangeComplete = data => {
      if (data.hsl !== background) {
        setColorPreview(data.hex);
        setBackground(data.hsl);
        setOpacityBg(data.hsl.a);
      }
    };

   return (
    <div >
      <Button className={classes.topTabBtn} variant="contained" color="secondary" onClick={handleClickOpen} startIcon={<AddIcon/>}>
              Nouvelle Couleur</Button>
      <Dialog
            open={open}
            onClose={handleClose}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={paperPropsSmall}
       >
       <DialogTitle className={classes.topDivTitle} id="draggable-dialog-title" >Ajouter une nouvelle couleur</DialogTitle>
       <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA}/>
       <DialogContent>
        <ValidatorForm ref={useRef(null)} onSubmit={handleSubmit} id='form' onKeyPress={e=>detectTouche(e)}/*onError={errors => console.log(errors)}*/ >
            <div id='col'>
            <ChromePicker color={background} onChange={handleChangeComplete} />
            <div className='forms'> 
              <div  id='input'>
                <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
                    <KeyboardDatePicker disableToolbar value={selectDate} label='Date:'  inputVariant="outlined" size="small" placeholder="01/01/2020" onChange={date => setSelectDate(date)}
                                         minDate='01/01/2000' format="dd/MM/yyyy" className='smallText'
                                         inputProps={{ tabIndex: "1" ,className:classes.input1}} 
                                         invalidDateMessage="Le format de la date est incorrect" 
                                />
                </MuiPickersUtilsProvider>
              </div>
              <div id='input'>
                <TextField  variant="outlined" className='smallText' label='Nom :' size="small"  value={nom} 
                        onChange={e =>setNom(e.target.value)}  fullWidth autoFocus required />
              </div>
              <div  id='input'>
                <TextField  variant="outlined" className='smallText' label='Code :' size="small" value={colorPreview} fullWidth  required />
              </div>
              <div  id='input'>
                <TextField variant="outlined" className='smallText' label='Alpha :' size="small"  value={opacityBg*100} fullWidth  required />
              </div>

              <div  id='input'>
                <TextField variant="outlined" className='smallText' label='Opacite :' size="small" value={opacity}  
                           onChange={e =>setOpacity(e.target.value)} fullWidth  required />
              </div>
            </div>  
            </div>
           </ValidatorForm>
           </DialogContent>               
           <DialogActions id='btn-div'>
              <Button variant="contained" type='submit' form='form' color="primary" startIcon={<DoneAll/>}>VALIDER</Button>
              <Button variant="contained"  onClick={handleClose} color="secondary">FERMER</Button>
           </DialogActions>
        
      </Dialog>
      </div>
  );
}