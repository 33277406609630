import React ,{useRef,useState} from "react";
import {Paper} from '@material-ui/core';
import {Button,CircularProgress,Backdrop,Typography } from '@material-ui/core';
import { ValidatorForm} from 'react-material-ui-form-validator';
import { MuiPickersUtilsProvider,KeyboardDatePicker} from "@material-ui/pickers";
import {DoneAll} from '@material-ui/icons';
import { fr } from "date-fns/locale";
import DateFnsUtils from "@date-io/date-fns";
import { useStyles } from '../../css/muiStyle';
import {NoDataComponent} from '../../js/components'
import '../../css/reporting.css'
import { useMediaQuery } from 'react-responsive'
import LineCharts from "../../compo/LineChart";


export default function Rep_txc(){
    const Gfunc=require('../../Gfunc')
    const classes = useStyles();
    const [loading,setLoading]=useState(false)
    const [selectDateD,SetSelectDateD]=useState(Gfunc.DateDeb(new Date()))
    const [selectDateF,SetSelectDateF]=useState(new Date())
    const [data,SetData]=useState([])
    const [datacon,SetDataCon]=useState([])
    const [datacom,SetDataCom]=useState([])
    const [err,SetErr]=useState(false)
    const message='Aucune donnée trouvée'
    const isDesktopOrLaptop = useMediaQuery({query: '(max-width: 850px)'})
      
  
    //valider le formulaire 
    const handleSubmit=async()=>{
      setLoading(true)
      const response=await Gfunc.axiosPostAction("_rep.php","getcon",{date1:Gfunc.formatDate(new Date(selectDateD)),date2:Gfunc.formatDate(selectDateF)})
      setLoading(false)
      Gfunc.axiosResponse(response,()=>{
        var elem=[];var dat=[];var cnx=[];var com=[]
        var ordercn=response.data.jData.connexions.sort(Gfunc.custom_sort) 
        var ordercm=response.data.jData.commandes.sort(Gfunc.custom_sort) 
        if(response.data.jData.connexions.length!==0){
         elem.push(ordercn[0])
         elem.push(ordercn[response.data.jData.connexions.length-1])
        }
        if(response.data.jData.commandes.length!==0){
          elem.push(ordercm[0])
          elem.push(ordercm[response.data.jData.commandes.length-1])
        }
        if(response.data.jData.commandes.length!==0||response.data.jData.connexions.length!==0)
        {var test=elem.sort(Gfunc.custom_sort)
        var min=test[0].day
        var max=test[test.length-1].day
        var dateParts = min.split("-");
        var dateObject = new Date(dateParts[2], dateParts[1]-1, dateParts[0]);
        var dateParts1 = max.split("-");
        var dateObject1 = new Date(dateParts1[2], dateParts1[1]-1, dateParts1[0]);
        var tes=Gfunc.getDates(dateObject,dateObject1) 
        tes.map((el)=>{
          let date= Gfunc.formatDate(el)
          dat.push(date)
          return true
                  }) 
        dat.map((el)=>{
          var ind=ordercn.findIndex((obj => obj.day ===el));
          if(ind!==-1){
           cnx.push({day:el,connexions:ordercn[ind].connexions})
          }else{
            cnx.push({day:el,connexions:0})
          }
          return true
        }) 
        dat.map((el)=>{
          var ind=ordercm.findIndex((obj => obj.day ===el));
          if(ind!==-1){
            com.push(ordercm[ind])
          }else{
            com.push({day:el,phone:0,airtime:0,accessoire:0,digital:0})
          }
          return true
        }) 
        com.map(un=>{
          if(!un.hasOwnProperty("airtime")){un["airtime"]=0}
          if(!un.hasOwnProperty("digital")){un["digital"]=0}
          if(!un.hasOwnProperty("accessoire")){un["accessoire"]=0}
          if(!un.hasOwnProperty("phone")){un["phone"]=0}
          return true
        })   
        SetData(response.data.jData)
        SetDataCon(cnx)
        SetDataCom(com)}
        if(response.data.jData.commandes.length===0 && response.data.jData.connexions.length===0){SetErr(true)}
      })
    } 
  

    //valider formulaire avec touche d'entrer
   const detectTouche= (e)=>{
    if (e.keyCode === 13 && (e.target.name==="addBtn")) {
        handleSubmit()
      }
    }

return (
  <div className="content">
    <Backdrop className={classes.backdrop} open={loading} >
          <CircularProgress color="inherit" />
  </Backdrop>
    <fieldset className="fieldsetTop" id="txc">
          <legend>Analyser le rapport entre les connexions et les commandes</legend>
          <ValidatorForm noValidate id="formAdd" ref={useRef(null)} onSubmit={handleSubmit} onKeyPress={e=>detectTouche(e)}  >
            <div className="rowGrid" id="row-1">
                <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
                    <KeyboardDatePicker disableToolbar value={selectDateD} variant="inline" placeholder="01/01/2020" onChange={date => SetSelectDateD(date)}
                                minDate="01/01/2020" format="dd/MM/yyyy"  className='smallText' margin="dense" inputVariant="outlined"
                                invalidDateMessage="Le format de la date est incorrect" 
                  />
                </MuiPickersUtilsProvider>
                 <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
                    <KeyboardDatePicker disableToolbar value={selectDateF} variant="inline" placeholder="01/01/2020" onChange={date => SetSelectDateF(date)}
                                minDate="01/01/2020" format="dd/MM/yyyy"  className='smallText' margin="dense" inputVariant="outlined"
                                invalidDateMessage="Le format de la date est incorrect" 
                  />
                </MuiPickersUtilsProvider>
                <Button className={classes.fieldButton} variant="contained" tabIndex="3" type='submit' id="bouton" name="addBtn" color="primary" 
                        startIcon={<DoneAll/>}>VALIDER</Button>
           </div>
          </ValidatorForm>
  </fieldset>
 { 
 (data.length!==0)?
 <div id='charts-cou'>
    <div id='conChar1' >
     <Paper id='chart-cou' elevation={8}>
        <div id='chart-cou1'>
        <LineCharts data={datacom} dataKeyX='day' dataKeyY='phone' color='#ff0000' height={320}/>
        </div>
            <Typography className='titleCharts'>Nombre de commandes</Typography>
    </Paper>
    <Paper id='chart-cou' elevation={8}>
        <LineCharts data={datacon} dataKeyX='day' dataKeyY='connexions' color='#659DB4' height={320}/>
            <Typography className='titleCharts'>Nombre de connexions</Typography>
    </Paper>
    <Paper id='chart-cou' elevation={8}>
        <div id='chart-cou1'>
        <LineCharts data={datacom} dataKeyX='day' dataKeyY='accessoire' color='#82ca9d' height={320}/>
        </div>
            <Typography className='titleCharts'>Nombre de commandes</Typography>
    </Paper>
    </div>
    <div id='conChar1'> 
     <Paper id='chart-cou' elevation={8}>
        <div id='chart-cou1'>
        <LineCharts data={datacom} dataKeyX='day' dataKeyY='airtime' color='#FFA500' height={320}/>
        </div>
            <Typography className='titleCharts'>Nombre de commandes</Typography>
    </Paper>
    <Paper id='chart-cou' elevation={8}>
    <LineCharts data={datacon} dataKeyX='day' dataKeyY='connexions' color='#659DB4' height={320}/>
            <Typography className='titleCharts'>Nombre de connexions</Typography>
    </Paper>
    <Paper id='chart-cou' elevation={8}>
        <div id='chart-cou1'>
        <LineCharts data={datacom} dataKeyX='day' dataKeyY='digital' color='#0000ff' height={320}/>
        </div>
            <Typography className='titleCharts'>Nombre de commandes</Typography>
    </Paper>
    </div>
 </div>
 :<></>
 }
{ (data.length===0 && err===true)?<NoDataComponent message={message}/>:<></>}
</div>   
  );
}