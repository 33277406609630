import React ,{useCallback,useState,useMemo,useEffect,useContext} from "react";
import DataTable from "react-data-table-component";
import ContentEditable from 'react-contenteditable';
import { customStyles} from '../../css/datatable-custom'
import Timber  from '../../img/postageStamp.png';
import ScreenPhone  from '../../img/cellphoneScreenshot.png';
import CableData  from '../../img/cableData.png';
import MouseVariant  from '../../img/mouseVariant.png';
import DeskPhone  from '../../img/deskphone.png';
import AccountVoice from '../../img/accountVoice.png';
import AccountHardHat from '../../img/accountHardHat.png';
import CellphoneBasic from '../../img/cellphoneBasic.png';
import Signal4G from '../../img/signal4G.png';
import {PhoneAndroid,Tablet,Receipt,CreditCard,SimCard,VpnKey,Search,Email,Comment,Phone,Smartphone,Computer, Add, Dashboard, Remove} from '@material-ui/icons';
import {MenuItem,FormControl,Backdrop,CircularProgress,ClickAwayListener, TextareaAutosize, Box, Tooltip} from '@material-ui/core';
import { useStyles } from '../../css/muiStyle';
import TextField from '@material-ui/core/TextField';
import DialogBox from '../content/modals/cpt_new_act'
import DialogBoxRst from '../content/modals/cpt_new_rst'
import Cosmetic from '../../img/cosmetics.png'
import Tabac from '../../img/cigarettes.png'
import { Autocomplete } from '@material-ui/lab';
import {FicheClient,AlertCompo,NotAllowedComponent, AutocompleteS,TelUpd} from "../../js/components"
import ParamContext from '../../Contexte';
import Compte from './modals/cpt_new_compte' 
import {ReactComponent as Fidelite} from '../../img/icons/fidilite.svg'
import {ReactComponent as NoFidelite} from '../../img/icons/noFidilite.svg'
import {ReactComponent as Position} from '../../img/icons/position.svg'
import {ReactComponent as StorePosition} from '../../img/icons/storePosition.svg'


export default function Cpt_new(){
  const classes = useStyles();
  const Gfunc=require('../../Gfunc')
  const param=useContext(ParamContext);
  const librery=require('../../js/data_table_librery')
  const [reset,setReset]=useState(true);
  const [reset2,setReset2]=useState(true);
  const [reset3,setReset3]=useState(true);
  const [st,setSt]=useState([])
  const [acc,setAcc]=useState([])
  const [gros,setGros]=useState([])
  const [phone,setPhone]=useState([])
  const [coms,setComs]=useState([])
  const [at,setAt]=useState([])
  const [rsts,setRsts]=useState([])
  const [air,setAir]=useState([])
  const [cpt,setCpt]=useState([])
  const [selectfiltre,SetSelectfiltre]=useState('')
  //const [selectfiltreF,SetSelectfiltreF]=useState('')
  const [filtre,setFiltre]=useState('')
  const [filtre1,setFiltre1]=useState('')
  const [filtre2,setFiltre2]=useState('')
  const [selecto,setSelecto]=useState('')
  const [Item,setItem]=useState('')
  const [ItemN,setItemN]=useState('')
  const [search,setSearch]=useState('')
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [errMessage,setErrMessage]=useState('');
  const [notAllowed,setNotAllowed]=useState(false);
  const [openA, setOpenA] =useState(false);
  const [messageA, setMessageA] =useState('');
  const [severity, setSeverity] =useState('info');
  const [act,setAct]=useState([])
  const [rst,setRst]=useState([])
  const [,setId]=useState('')
  const [stat, setStat] =useState('')
  const [dat, setdat] =useState('')
  const [dat2, setdat2] =useState('')
  const [currentRow, setCurrentRow] = useState(null);
  const service='_cpt.php'
  const comptes=[{id:'empty',nom:'Tous'},{id:1,nom:'Comptes airtime non affectés'},{id:2,nom:'Comptes phones non affectés'},
                 {id:3,nom:'Comptes accessoires non affectés'},{id:4,nom:'client integrés dans le programme de fidèlité'}]
  const [wils, setWils] = useState([]);
  
  useEffect(() => {
                     let isMounted = true;
                     
                     const fetchWl = async () => {
                         const response = await Gfunc.axiosGetAction('_ref.php', 'getswils');
                         if (response.data.err && response.data.err !== '-1') {
                             handleAlert(true, "error", response.data.err);
                         } else {
                             Gfunc.axiosResponse(response, () => {
                                 if (isMounted) {
                                     setWils(response.data.jData);
                                 }
                             });
                         }
                     };
             
                     if (wils.length === 0) {
                         fetchWl();
                     }
             
                     return () => {
                         isMounted = false; // Set isMounted to false when unmounting
                     };
                 }, [wils])

  const handleAlert=(open,severity,message)=>{
    setOpenA(true)
    setSeverity(severity)
    setMessageA(message)
    setTimeout(function(){
      setOpenA(false)
    }, 5000);
  }

  const fetch= useCallback(async(page,servic = service, size = perPage,val,filtre) => {
      setLoading(true);
      const tes=[]
      const tes2=[]
      const response=await librery.axiosGet(page+'&search='+((val!==undefined)?val:'')+'&filter='+((filtre!=='empty'&&filtre!==undefined)?filtre:''),servic,size)
      setLoading(false);
      if(response.data.err&&response.data.err!=='-1'){
        setErrMessage(response.data.err)
        setNotAllowed(true)
      }else{
      Gfunc.axiosResponse(response,()=>{
        setCpt(response.data.data)  
       if(response.data.data)
        {
          response.data.data.map(el=>{
          tes.push({id:el.DT_RowId,activities:el.activities})
          tes2.push({id:el.DT_RowId,restrictions:el.restrictions})
          return true
          })
          setRst(tes2)
          setAct(tes)
        }
        setTotalRows(response.data.recordsFiltered)
        setLoading(false);

    })
  }
  },[Gfunc,param.urlService,perPage]);

    
  useEffect(() => {
    if(cpt!==undefined&&cpt.length===0) fetch(1);
  },[fetch,cpt]);
  
  const filtrer=async(val,size = perPage)=>{
    const tes=[]
    const tes2=[]
    setLoading(true);
     const response=await librery.axiosGet(1+'&search='+search+'&filter='+((val!==undefined)?val:''),service,size)
     if(response.data.err&&response.data.err!==-1){
        handleAlert(true,'error',response.data.err)
     }else{
      if(response.data.data)
        Gfunc.axiosResponse(response,()=>{
              response.data.data.map(el=>{
                tes.push({id:el.DT_RowId,activities:el.activities})
                tes2.push({id:el.DT_RowId,restrictions:el.restrictions})
                return true
              })
          setRst(tes2)
          setAct(tes)
          setCpt(response.data.data)  
          setTotalRows(response.data.recordsFiltered)
        })
        setLoading(false);
  }
    }

  const  HandleOnChange=(val)=>{
        const ind=comptes.findIndex((obj => obj.nom === val));
        const cptt=comptes[ind].id
        SetSelectfiltre(cptt)
        setFiltre(cptt)
        filtrer(cptt)
    }

  const  HandleOnChangeF=(val)=>{
     const ind=at.findIndex((obj => obj.nom === val));
     const actt=(val==="Toutes")?at[ind].id:'act-'+at[ind].id
     setFiltre1(actt)
     SetSelectfiltre(actt)
     filtrer(actt)
  }

  const  HandleOnChangeR=(val)=>{
     const ind=rsts.findIndex((obj => obj.nom === val));
     const rs=(val==="Toutes")?rsts[ind].id:'rst-'+rsts[ind].id
     setFiltre2(rs)
     SetSelectfiltre(rs)
     filtrer(rs)
  }

  const handleSearch=async(size = perPage)=>{
     const tes=[];
     const tes2=[];
     setLoading(true);
     //const filtre=(selectfiltre!=='')?selectfiltre:selectfiltreF
      const recherche=search;
      const response=await librery.axiosSearch(service,recherche,1,size,'get&filter='+selectfiltre)
      setLoading(false);
      if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,'error',response.data.err)
      }else{
      Gfunc.axiosResponse(response,()=>{
       if(response.data.data)
       { 
         response.data.data.map(el=>{
          tes.push({id:el.DT_RowId,activities:el.activities})
          tes2.push({id:el.DT_RowId,restrictions:el.restrictions})
          return true
        })
        setAct(tes)
        setRst(tes2)
       }
        setCpt(response.data.data);
        setTotalRows(response.data.recordsFiltered);
      })   
    }
      return recherche
      }
    
  const handleOnSort=async(column, sortdirection,page=currentPage, size = perPage)=>{
    const tes=[]
    const tes2=[]
    setLoading(true);
    const response=await librery.axiosSort(column,sortdirection,page,size,service,'get')
    setLoading(false);
    Gfunc.axiosResponse(response,()=>{
      response.data.data.map(el=>{
        tes.push({id:el.DT_RowId,activities:el.activities})
        tes2.push({id:el.DT_RowId,restrictions:el.restrictions})
        return true
      })
      setAct(tes)
      setRst(tes2)
      setCpt(response.data.data);
      setTotalRows(response.data.recordsFiltered);
    })     
  }

  const fetchAt= useCallback(async() => {
    const response = await Gfunc.axiosGetAction(service ,'getactivities')
    if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,"error",response.data.err)
    }else{
    Gfunc.axiosResponse(response,()=>{
        response.data.jData.unshift({nom:'Toutes',id:'empty'})
        setAt(response.data.jData)  
    })
  }
  },[Gfunc,param.urlService]);

  const fetchRsts= useCallback(async() => {
    const response = await Gfunc.axiosGetAction(service ,'getrestrs')
    if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,"error",response.data.err)
    }else{
    Gfunc.axiosResponse(response,()=>{
        response.data.jData.unshift({nom:'Toutes',id:'empty'})
        setRsts(response.data.jData)  
    })
  }
  },[Gfunc,param.urlService]);

   const fetchAir= useCallback(async() => {
    const response = await Gfunc.axiosGetAction(service ,'getcliat')
    if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,"error",response.data.err)
    }else{
    Gfunc.axiosResponse(response,()=>{
        response.data.jData.unshift({id:null, nom: "Aucun",owner: null})
        setAir(response.data.jData)  
    })
    }
    },[Gfunc,param.urlService]);

 const fetchSt= useCallback(async() => {
    const response = await Gfunc.axiosGetAction(service ,'getstatus')
    if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,"error",response.data.err)
    }else{
    Gfunc.axiosResponse(response,()=>{
        setSt(response.data.jData)  
    })
  }
},[Gfunc,param.urlService]);

 const fetchPhone= useCallback(async() => {
    const response = await Gfunc.axiosGetAction(service ,'getcliph')
    if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,"error",response.data.err)
    }else{
    Gfunc.axiosResponse(response,()=>{
        response.data.jData.unshift({id:null, nom: "Aucun"})
        setPhone(response.data.jData)  
    })
  }
},[Gfunc,param.urlService]);

 const fetchAcc= useCallback(async() => {
    const response = await Gfunc.axiosGetAction(service ,'getcliacc')
    if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,"error",response.data.err)
    }else{
    Gfunc.axiosResponse(response,()=>{
        response.data.jData.unshift({id:null, nom: "Aucun"})
        setAcc(response.data.jData)  
    })
  }
},[Gfunc,param.urlService]);

 const fetchGros= useCallback(async() => {
    var arr=[];
    const response = await Gfunc.axiosGetAction(service ,'getsubcom')
    if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,"error",response.data.err)
    }else{
    Gfunc.axiosResponse(response,()=>{
        response.data.jData.map((el)=>{
          arr.push({id:el.id,nom:el.owner+'-'+el.nom})
        })

        arr.unshift({id:null, nom: "Aucun"})
        setGros(arr)  
    })
  }
},[Gfunc,param.urlService]);

 const fetchCom= useCallback(async() => {
    //const response = await Gfunc.axiosPostAction('_ref.php' ,'getswilcoms',{wil:row.wilayaId})
    const response = await Gfunc.axiosPostAction('_ref.php' ,'getscoms')
    if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,"error",response.data.err)
    }else{
    Gfunc.axiosResponse(response,()=>{
        setComs(response.data.jData)  
    })
  }
},[Gfunc]);
  
const handleOpenSt= useCallback(async () => {
  if(st.length===0)
  fetchSt()
},[st,fetchSt])

const handleOpenAt= useCallback(async () => {
  if(at.length===0)
  fetchAt()
},[at,fetchAt])

const handleOpenRsts= useCallback(async () => {
  if(rsts.length===0)
  fetchRsts()
},[rsts,fetchRsts])

const handleOpenAir= useCallback(async () => {
  if(air.length===0) fetchAir()
},[air,fetchAir])

const handleOpenPh= useCallback(async () => {
  if(phone.length===0) fetchPhone()
},[phone,fetchPhone])

const handleOpenAcc= useCallback(async () => {
  if(acc.length===0)
  fetchAcc()
},[acc,fetchAcc])

const handleOpenGros= useCallback(async () => {
  if(gros.length===0) fetchGros()
},[gros,fetchGros])



const handleOpenCom= useCallback(async () => {
  if(coms.length===0) fetchCom()
},[coms,fetchCom])

  //edit champs
 const handleOnEdit=useCallback(async(e,row,att)=>{
    e.preventDefault();
    const edt=e.target.innerHTML
    const edt1=Gfunc.DeleteSpace(edt)
        if(row[att]!==edt1&& edt1!=='--'){
          const attrib=row[att];
          const response=await librery.axiosEdit(service,row,edt1,att) 
          if(response.data.err&&response.data.err!=='-1'){
            handleAlert(true,"error",response.data.err)
          }else{
          Gfunc.axiosResponse(response,()=>{row[att]=edt1;e.target.innerHTML=edt1})
          }
          if(response.data.err) {e.target.innerHTML=attrib}
        }else {e.target.innerHTML=edt1}
  },[librery,Gfunc,service]);


  const handleSelect=useCallback((row,att)=>{
      setSelecto(row[att])
      setItem(row.DT_RowId)
      setItemN(att)
   },[])


  const handleOnEditSelect=useCallback(async(e,row,attId,attV,list,attList,f=()=>{})=>{ 
    if(attV!=='commune')
   { 
     if(row[attId]!==e.target.value){
      const edt=e.target.value
      const attrib=row[attId];
      const response=await librery.axiosEdit(service,row,edt,attId) 
      if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,"error",response.data.err)
      }else{
      Gfunc.axiosResponse(response,()=>{
        const objIndex =list.findIndex((obj => obj.id === edt)); 
        row[attV]=(list[objIndex][attList]==='Aucun')?'':list[objIndex][attList]
        row[attId]=edt
        setItem("");
        setItemN("");
        f();
     })
     }
     if(response.data.err) {setItem(""); setItemN("");row[attId]=attrib;}
     }
    }else {
      if(row[attId]!==e){
        const edt=e
        const attrib=row[attId];
        const response=await librery.axiosEdit(service,row,edt,attId) 
        if(response.data.err&&response.data.err!=='-1'){
          handleAlert(true,"error",response.data.err)
        }else{
        Gfunc.axiosResponse(response,()=>{
          const objIndex =list.findIndex((obj => obj.id === edt));
          row[attV]=list[objIndex][attList]
          row[attId]=edt
          setItem("");
          setItemN("");
          f();
        })
        }
        if(response.data.err) {row[attId]=attrib;}
      }
    }
  
  },[Gfunc,librery])
  
  const fetchMdp= useCallback(async(row) => {
    const response = await Gfunc.axiosPostAction('_cpt.php' ,'newpass',{id:row.DT_RowId.substr(1)})
    if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,"error",response.data.err)
    }else{
    Gfunc.axiosResponse(response,()=>{
      handleAlert(true,"success",response.data.jData)
    })
   }
 },[Gfunc]);

const handleTest=useCallback((act)=>{
  setAct(act)
  setId('')
},[])

const handleData=useCallback((val,idf,str)=>{
   const dat=act
   const ind=dat.findIndex((obj => obj.id === idf));
    if(ind!==-1){ 
    delete act[ind].activities
    act[ind]['activities']=val
    act[ind]['activities'].length=val.length
    }
    setId(idf)
    setStat(str)
    setdat(val)
    handleTest(act)
 },[act,handleTest])

const handleTestRst=useCallback((rst)=>{
  setRst(rst)
  setId('')
},[])

const handleDataRst=useCallback((val,idf,str)=>{
   const dat=rst
   const ind=dat.findIndex((obj => obj.id === idf));
    if(ind!==-1){ 
    delete rst[ind].restrictions
    rst[ind]['restrictions']=val
    rst[ind]['restrictions'].length=val.length
    }
    setId(idf)
    setStat(str)
    setdat2(val)
    handleTestRst(rst)
 },[rst,handleTestRst])

  const ExpanableComponent =({data,wils}) => {
    const [descFr, setDescFr] = useState('');
    


     return (
        <div className='info-expender'>
         <div id='div-photo-profil'><FicheClient row={data} nbr='365' val='cpt' /></div>
         <div className='div-info'>
            <div id='div-info1'>
              <div id='rows'>
                 <div id='row-1'>
                      <Email id='icon' />
                      <ContentEditable
                        html={(data.mail)?data.mail:'--'} name="compte" data-column="item" className="content-editable-compte" disabled={false}
                        onBlur={e => handleOnEdit(e,data,"mail")}
                      />
                      <StorePosition id='icon'/>
                      <ContentEditable html={(data.adresse)?data.adresse:'--'} name="compte" data-column="item" 
                                       className="content-editable-compte" disabled={false}
                                       onBlur={e => handleOnEdit(e,data,"adresse")}
                      />
                      <Position id='icon'/>
                      <AutocompleteS service={service} action={'upd'} defaultValue={data.wilaya} row={data} list={wils} 
                                     className='content-editable-compte'className1='SelectDT' id={'wilayaId'} name='wilaya' attr='name'  
                                     handleAlert={handleAlert} />
                 </div>
                 <div id='row-2'>
                      <Phone id='icon'/> 
                      <TelUpd datein={data.date} agentin={data.agent} dateupd={data.dateUpd} agentupd={data.agentUpd}
                              service={service} action='upd' className="content-editable-compte "
                              row={data} att="tel_1" value={data.tel_1} id='DT_RowId' cpt='cpt' handleAlert={handleAlert}
                      />
                      <Phone id='icon'/>
                      <TelUpd
                        datein={data.date} agentin={data.agent} dateupd={data.dateUpd} agentupd={data.agentUpd}
                        service={service} action='upd' className="content-editable-compte "
                        row={data} att="tel_2" value={data.tel_2} id='DT_RowId' cpt='cpt' 
                        handleAlert={handleAlert}
                      /> 
                      <Phone id='icon'/>
                      <TelUpd
                        datein={data.date} agentin={data.agent} dateupd={data.dateUpd} agentupd={data.agentUpd}
                        service={service} action='upd' className="content-editable-compte "
                        row={data} att="tel_3" value={data.tel_3} id='DT_RowId' cpt='cpt' 
                        handleAlert={handleAlert}
                      />
                 </div>
              </div>
              <div style={{display:"flex",alignItems:"center"}}>
                <div style={{width:'90%',textAlign:'center'}}><Comment id='icon'/></div>
                <TextareaAutosize  onChange={(e)=>{setDescFr(e.target.value);}} minRows={3} 
                                  value={(descFr)?descFr:(data.note??"")} className='content-editable-compte2'
                                  onBlur={e=>handleOnEdit(e,data,"note")} placeholder="Commentaire" />
              </div>
            </div>
            <div id='div-info2'>     
            {
                 (act&&act[act.findIndex((obj => obj.id === data.DT_RowId))]!==undefined&&act[act.findIndex((obj => obj.id === data.DT_RowId))]&&act[act.findIndex((obj => obj.id === data.DT_RowId))].activities.length>0)?
                  act[act.findIndex((obj => obj.id === data.DT_RowId))].activities.map((el,index)=>{
                   return (
                     <div key={index} id='elem-act'>
                       <div style={{width:'100%'}}>
                        <div id='icon-act'>
                         {
                          (el.activite==='Air Time (Flexy)')?<img src={AccountVoice} alt='Air Time' id='icon1'/>
                          :(el.activite==='Carte de recharge')? <CreditCard id='icon1'/>
                          :(el.activite==='Idoom / 4g')?<img src={Signal4G} alt='Idoom / 4g' id='icon1'/>
                          :(el.activite==='TPE (bon)')?<Receipt  id='icon1'/>
                          :(el.activite==='Timbres')?<img src={Timber} alt='Timbres' id='icon1'/>
                          :(el.activite==='Activation')?<SimCard id='icon1'/>
                          :(el.activite==='Smartphones')?<PhoneAndroid id='icon1'/>
                          :(el.activite==='Téléphones')? <img src={CellphoneBasic} alt='Téléphones' id='icon1'/>
                          :(el.activite==='Audio / Chargeurs / Cables')? <img src={CableData} alt='Audio / Chargeurs / Cables' id='icon1'/>
                          :(el.activite==='Glass / Antichoc / Batteries')? <img src={ScreenPhone} alt='Glass / Antichoc / Batteries' id='icon1'/>
                          :(el.activite==='Tablettes')? <Tablet id='icon1'/>
                          :(el.activite==='Informatique')?<img src={MouseVariant} alt='Informatique' id='icon1'/>
                          :(el.activite==='Téléphones fixes')? <img src={DeskPhone} alt='Téléphones fixes' id='icon1'/>
                          :(el.activite==='Réparation')? <img src={AccountHardHat} alt='Réparation' id='icon1'/>
                          :null
                         }
                         </div> 
                         </div>
                         <b id='act'>{el.activite}</b>
                     </div>
                   );
                 }):<div style={{width:'100%',textAlign:'center',fontWeight:'bold',fontSize:'12px'}}>Aucune activité.</div>
                }
                <div id='btn-act'><DialogBox data={data.activities} Gdata={data} handleData={handleData} check={dat}/></div>
            </div>
            <div id='div-info2'>     
            {
                 (rst&&rst[rst.findIndex((obj => obj.id === data.DT_RowId))]!==undefined&&rst[rst.findIndex((obj => obj.id === data.DT_RowId))]&&rst[rst.findIndex((obj => obj.id === data.DT_RowId))].restrictions.length>0)?
                 rst[rst.findIndex((obj => obj.id === data.DT_RowId))].restrictions.map((el,index)=>{
                   return (
                     <div key={index} id='elem-act'>
                       <div style={{width:'100%'}}>
                         {
                          (el.type)?<div id='icon-act' ><Add className='blackIcon' fontSize="small" /></div> 
                          : <div id='icon-act' style={{background:"red"}}> <Remove className='whiteIcon' id='icon1'/></div> 
                         }
                         </div>
                         <b id='act'>{el.restriction}</b>
                     </div>
                   );
                 }):<div style={{width:'100%',textAlign:'center',fontWeight:'bold',fontSize:'12px'}}>Aucune restriction.</div>
                }
                <div id='btn-act'><DialogBoxRst data={data.restrictions} Gdata={data} handleData={handleDataRst} check={dat2}/></div>
            </div>
         </div>
        </div>
    )
}
 const EtatControl=useMemo(()=>[
    {
      when: row => row.status==='Complet',
        style: {
          color:"limegreen",
        }
    }, 
    {
      when: row =>row.status==='Temporaire',
        style: {
          color:"red",
        }
    }, 
    {
      when: row =>row.status==='Innactif',
        style: {
          color:"gray",
        }
    }
  ],[])


  const GetType=(val)=>{
    switch(val) {
      case 'Téléphonie':
        return <Smartphone id='icon'/>;
      case 'Cosmétiques':
        return < img src={Cosmetic} alt='cosmetic'/>;
      case 'Informatique':
        return <Computer id='icon'/>;
      case 'Tabac':
        return < img src={Tabac} alt='tabac'/>;
      default : return null
    }
  }

  const flagMan = useCallback(async(row,att,id)=>{
    const response= await Gfunc.axiosPostAction('flagman.php','1',({ srv:'iuser', id: parseInt(row[id].substr(1)),att:att, val:(row[att])?1:0 }))
    Gfunc.axiosResponse(response,()=>{
      if(response.data.jData){
        const updateCpt= cpt.map((compte) => {
          var value=(row[att]===true)?false:true
          if (compte.DT_RowId===row.DT_RowId) {
            return { ...compte,fidelity:value}; 
          }
          return compte; 
        });
        setCpt(updateCpt)
      }
     })
  },[Gfunc,cpt]);

  const columns =useMemo(
    () => [ 
      {
        name: "Nom",
        selector:row=> row.nom,
        sortable: true,
        minWidth:'110px !important',
        sortField:"nom",
        cell: (row) => {
            return (
                <ContentEditable
                  html={(row.nom)?row.nom:""}
                  name="compte"
                  data-column="item"
                  className="content-editable-edit"
                  disabled={false}
                  onBlur={e => handleOnEdit(e,row,"nom")}
                />
             );
           },
     }, 
      {
          name: "Prénom",
          selector:row=> row.prenom,
          sortable: true,
          minWidth:'100px !important',
          sortField:"prenom",
          cell: (row) => {
            return (
                <ContentEditable
                  html={(row.prenom)?row.prenom:''}
                  name="compte"
                  data-column="item"
                  className="content-editable-edit"
                  disabled={false}
                  onBlur={e => handleOnEdit(e,row,"prenom")}
                />
             );
           },
      },
      {
        name: "Boutique",
        selector:row=> row.mag,
        sortable: true,
        minWidth:'180px !important',
        sortField:"mag",
        cell: (row) => {
            return (
              <Tooltip title={row.magtyp}>
               <div  style={{display:'flex',alignItems:'center'}} >
                  {GetType(row.magtyp)}
                  <ContentEditable
                    html={(row.mag)?row.mag:""}
                    name="compte"
                    data-column="item"
                    className="content-editable-edit"
                    disabled={false}
                    onBlur={e => handleOnEdit(e,row,"mag")}
                  />
               </div>
             </Tooltip>
             );
           },
      },
     {
        name: "Date",
        selector:row=> row.date,
        sortable: true,
        width:'97px !important',
        sortField:"date",
     },
     {
        name: "État",
        selector:row=> row.status,
        sortable: true,
        width:'98px !important',
        conditionalCellStyles:EtatControl,
        sortField:"status",
        cell: (row) => { 
            return (
            <div className="cpt-stat">  
            {
            (row.DT_RowId===Item && row.status===selecto && ItemN==="status")?
             <ClickAwayListener onClickAway={()=>setItem('')}>
             <FormControl onClick={(e) => e.stopPropagation()}>
                <TextField select className={classes.selectEdit} inputProps={{className:classes.inputEdit,onBlur:()=>setSelecto('')}} variant="outlined" size="small"
                  value={selecto}
                  onFocus={handleOpenSt}
                  onChange={(e)=>handleOnEditSelect(e,row,"statusId","status",st,"status",setSelecto(e.target.value))}
                  SelectProps={{ MenuProps: { disablePortal: true } }}
                ><MenuItem value={row.status}>{row.status}</MenuItem>
                  {
                  st.map((el,index)=>{
                    return <MenuItem key={index} value={el.id}>{el.status}</MenuItem>
                  })}
                </TextField>
            </FormControl>
            </ClickAwayListener>:(row.status!==null)?<div className="content-editable-edit"  onClick={()=>{handleSelect(row,'status')}}>{row.status}</div>
                          :<div className="div-vide" onClick={()=>{handleSelect(row,'status')}}></div>
            }
            </div> 
            );
        }
     },
     {
      name: "Airtime",
      selector:row=> row.airtime,
      sortable: true,
      sortField:"airtime",
      minWidth:'100px !important',
      cell: (row) => { 
        return (
          <div>
            {
              (!row.subCom)?
              <AutocompleteS service={service} action={'upd'} noOpt='Aucun' chaine='true' defaultValue={row.airtime} row={row} list={air} 
                             className="content-editable-edit" id={'airtimeId'} name='airtime' attr='nom' attr2='owner' separ='['
                             onOpen={handleOpenAir} handleAlert={handleAlert}
                             handleResponse={
                              (edt)=>{
                                setGros([])
                                var ind=cpt.findIndex((obj => obj.airtime ===row.airtime));
                                if(ind!==-1){
                                  cpt[ind].airtime=edt
                                  setCpt(cpt)
                                }
                              }
                            }
                             />
              :<div>{row.airtime}</div>
            }
          </div>
          );
      }
    },
    {
      name: "Phone",
      selector:row=> row.phone,
      sortable: true,
      sortField:"phone",
      minWidth:'100px !important',
      cell: (row) => { 
        return (
            <AutocompleteS service={service} action={'upd'}  noOpt='Aucun' defaultValue={row.phone} row={row} list={phone} 
                           className="content-editable-edit" id={'phoneId'} name='phone' attr='nom' onOpen={handleOpenPh} 
                           handleAlert={handleAlert}/>
        );
    }
    },
    {
      name: "Accessoire",
      selector:row=> row.accessoire,
      sortable: true,
      sortField:"accessoire",
      minWidth:'110px !important',
      cell: (row) => { 
        return (
        <AutocompleteS service={service} action={'upd'}  noOpt='Aucun'  defaultValue={row.accessoire} row={row} list={acc} 
                       className="content-editable-edit" id={'accessoireId'} name='accessoire' attr='nom' onOpen={handleOpenAcc} 
                       handleAlert={handleAlert}/>

        );
    }
    },
    {
      name: "Gros",
      selector:row=> row.subCom,
      sortable: true,
      sortField:"subCom",
      minWidth:'110px !important',
      cell: (row) => { 
        return (
            <div>
              { 
                (!row.airtime)?
                 <AutocompleteS service={service} action={'upd'}  noOpt='Aucun' defaultValue={row.subCom} row={row} list={gros} id={'subComId'} 
                                className="content-editable-edit"  name='subCom' attr='nom' onOpen={handleOpenGros} handleAlert={handleAlert} 
                                handleResponse={
                                  (edt)=>{
                                    setAir([])
                                    var ind=cpt.findIndex((obj => obj.subCom ===row.subCom));
                                    if(ind!==-1){
                                      cpt[ind].subCom=edt
                                      setCpt(cpt)
                                    }
                                  }
                                }
                 />
                :<div>{row.subCom}</div>
              }
            </div>   
        );
    }
    },
    {
      name: "Commune",
      selector:row=> row.commune,
      sortable: true,
      sortField:"commune",
      width:'150px !important',
      cell: (row) => { 
        return (
          <AutocompleteS service={service} action={'upd'}  noOpt='Aucun' defaultValue={row.commune} row={row} list={coms} 
                         className="content-editable-edit"id={'communeId'} name='commune' attr='name' from='inf' onOpen={handleOpenCom} 
                         handleAlert={handleAlert} />  
        );
    }
    }, 
    {
        width:"55px",
        right:true,
        cell: (row) => {
          return (
            
              <div style={{display:'flex',gridTemplateColumns:'33% 33% 33%',alignItems:"center",gridGap:'2px'}}>
                <Tooltip title="Fidéliser le client">
                  <div onClick={()=>flagMan(row,'fidelity','DT_RowId')}>
                    {
                      (row.fidelity)?<Fidelite  style={{height:'20px',width:'20px',cursor:"pointer"}} />
                      :<NoFidelite style={{height:'20px',width:'20px',cursor:"pointer"}}/>
                    }
                  </div>
                </Tooltip>
                <Compte row={row} fetch={fetch}/>
                <Tooltip title='Changer le mot de passe'>
                  <VpnKey fontSize='small' className={classes.redIcon} onClick={()=>fetchMdp(row)} />
                </Tooltip>
              </div>
            )
       }
    }
    ],[handleSelect,fetchMdp,handleOnEdit,handleOnEditSelect,handleOpenAcc,handleOpenCom,handleOpenPh,handleOpenSt,handleOpenAir,flagMan,
       handleOpenGros,gros,phone,classes.inputEdit,classes.redIcon,classes.selectEdit,air,EtatControl,service,ItemN,Item,selecto,acc,coms,st,cpt]);
  
function handlePageChange(page) {
  setCurrentRow(null)
  fetch(page,service,perPage,search,(filtre!=='')?filtre:(filtre1!=='')?filtre1:filtre2);
  setCurrentPage(page);
}
const handlePerRowsChange = async (newPerPage, page) => {
   setCurrentRow(null)
   fetch(page,service, newPerPage,search,(filtre!=='')?filtre:(filtre1!=='')?filtre1:filtre2);
   setPerPage(newPerPage);
}

function liveSearch(e,search){
  if(param.liveSearch&&search.length>3){
     e.preventDefault();
     handleSearch();
  }else{ if (e.keyCode === 13) {
            e.preventDefault();
            handleSearch();
          }
  }
}

function liveSearch2() {
  handleSearch();
}

return (
  <div className="content">
      <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA}/>
      <Backdrop className={classes.backdrop} open={loading} >
        <CircularProgress color="inherit" />
     </Backdrop>
      {(notAllowed===false)?
      <div >
      <div  className="table-header" >
       <div className='filtre-head'>
          <Autocomplete
              id="iCompte"
              key={"c"+reset}
              disableClearable
              onChange={(e,newValue) =>{setReset2(!reset2);setReset3(!reset3);HandleOnChange(newValue)}} 
              className='smallAutoFiltre' 
              options={comptes.map((option) => option.nom)} 
              renderInput={(params) => (
                  <TextField  {...params} className={classes.input11} label="COMPTE..." color="primary" variant="outlined" size="small"/>
                        )} 
                />
           <Autocomplete
              id="iAct"
              key={"a"+reset2}
              disableClearable
              onChange={(e,newValue) =>{setReset(!reset);setReset3(!reset3);HandleOnChangeF(newValue)}} 
              onFocus={handleOpenAt}
              className='smallAutoFiltre' 
              options={at.map((option) => option.nom)} 
              renderInput={(params) => (
                  <TextField  {...params} className={classes.input11} label="ACTIVITÉ..." color="primary" variant="outlined" size="small"/>
                        )} 
                />
           <Autocomplete
              id="iAct"
              key={"r"+reset3}
              disableClearable
              onChange={(e,newValue) =>{setReset(!reset);setReset2(!reset2);HandleOnChangeR(newValue)}} 
              onFocus={handleOpenRsts}
              className='smallAutoFiltre' 
              options={rsts.map((option) => option.nom)} 
              renderInput={(params) => (
                  <TextField  {...params} className={classes.input11} label="RESTRICTIONS..." color="primary" variant="outlined" size="small"/>
                        )} 
                />
        </div>
        <div className='search' >
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <TextField className={classes.search} variant="outlined"  label="Recherche" value={search} 
                               InputProps={{startAdornment: (<Search onClick={() => handleSearch()}
                               style={{ marginRight: '5px',zIndex:'1000',width:'20px',height:'20px',cursor:"pointer"}} />),}}
                               size="small" margin='none'  onKeyUp={e => liveSearch(e)} onChange={e => setSearch(e.target.value)} /> 
              </Box>
        </div>
     </div>
     </div>:null
     }
{ 
    (notAllowed===false)?
    <div id='cpt_new'>
     <DataTable 
              columns={columns}
              data={cpt}
              defaultSortAsc={true}
              persistTableHead={true}
              noDataComponent={param.ErreurData}
              pagination
              paginationServer
              paginationRowsPerPageOptions={[20,30,50,100]}
              paginationTotalRows={totalRows}
              paginationDefaultPage={currentPage}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              paginationComponentOptions={{ rowsPerPageText: 'Élément par page:', rangeSeparatorText: 'sur',selectAllRowsItemText: 'Tous',selectAllRowsItem: true}}
              paginationPerPage={20}
              sortServer={true}
              onSort={handleOnSort}
              highlightOnHover
              customStyles={customStyles} 
              onRowClicked={(row) => setCurrentRow(row)}
              onRowExpandToggled={(bool, row) => setCurrentRow(row)}
              expandableRowExpanded={(row) => (row === currentRow)}  
              expandOnRowClicked
              expandableRows 
              expandableRowsComponent={(props)=><ExpanableComponent {...props} wils={wils}/>} 
        />
      </div>:<div className='compAllow'><NotAllowedComponent message={errMessage}/></div>
        }
        
  </div>
  );
 }