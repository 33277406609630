import React ,{useCallback,useState,useMemo,useEffect} from "react";
import DataTable from "react-data-table-component";
import {customStyles} from '../../css/datatable-custom'
import {Delete, Search} from '@material-ui/icons';
import {Grid,CircularProgress,Backdrop, Box, Tooltip} from '@material-ui/core';
import { useStyles } from '../../css/muiStyle';
import TextField from '@material-ui/core/TextField';
import {AlertCompo,NotAllowedComponent} from "../../js/components"
import { useContext } from 'react';
import ParamContext from '../../Contexte';
import Modele from "./modals/ani_mod_add";
import ContentEditable from "react-contenteditable";

export default function Ani_mod(){
   const classes = useStyles();
   const Gfunc=require('../../Gfunc')
   const param=useContext(ParamContext);
   const librery=require('../../js/data_table_librery')
   const [mod,setmod]=useState([])
   const [search,setSearch]=useState('')
   const [loading, setLoading] = useState(false);
   const [called, setCalled] = useState(false);
   const [totalRows, setTotalRows] = useState(0);
   const [perPage, setPerPage] = useState(10);
   const [currentPage, setCurrentPage] = useState(1);
   const [openA, setOpenA] =useState(false);
   const [errMessage,setErrMessage]=useState('');
   const [notAllowed,setNotAllowed]=useState(false);
   const [messageA, setMessageA] =useState('');
   const [severity, setSeverity] =useState('info');
   const service='_ntf.php'
  

  const handleAlert=(open,severity,message)=>{
    setOpenA(true)
    setSeverity(severity)
    setMessageA(message)
    setTimeout(function(){
      setOpenA(false)
    }, 5000);
  }
  const fetch= useCallback(async(page,servic = service, size = perPage,val) => {
      setCalled(true)
      setLoading(true);
      const response=await librery.axiosGet(page+'&search='+((val!==undefined)?val:''),servic,size)
      setLoading(false);
      if(response.data.err&&response.data.err!=='-1'){
        setErrMessage(response.data.err)
        setNotAllowed(true)
      }else{
      Gfunc.axiosResponse(response,()=>{
        setTotalRows(response.data.recordsFiltered)
        if(response.data.data){
            setmod(response.data.data)  
        }
    })
  }
  },[Gfunc,param.urlService,perPage]);
 
  useEffect(() => {
    if(!called) fetch(1);
  },[fetch,called]);

  const HandleDelet=useCallback(async(row)=>{
    const response=await Gfunc.axiosPostAction(service,"del",{notifId:parseInt(row.DT_RowId.substr(1))})
    if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,"error",response.data.err)
    }
    else{
      Gfunc.axiosResponse(response,()=>{
        if (response.data.jData) {fetch(1);}
    })
  } 
  },[fetch])

   //edit champs
  const handleOnEdit=useCallback(async(e,row,att)=>{
    e.preventDefault();
    const edt=e.target.innerHTML
    const edt1=Gfunc.DeleteSpace(edt)
        if(row[att]!==edt1&& edt1!=='--'){
          const attrib=row[att];
          const response=await librery.axiosEdit(service,row,edt1,att) 
          if(response.data.err&&response.data.err!=='-1'){
            handleAlert(true,"error",response.data.err)
          }else{
          Gfunc.axiosResponse(response,()=>{row[att]=edt1;e.target.innerHTML=edt1})
          }
          if(response.data.err) {e.target.innerHTML=attrib}
        }else {e.target.innerHTML=edt1}
  },[librery,Gfunc,service]);

 const columns =useMemo(
    () => [ 
      {
        name: "Titre français",
        minWidth:'180px !important',
        selector:row=> row.titleFr,
        sortable: true,
        sortField:'messageFr',
        cell: (row) => {
          return (
            <div className="addedCell">
              <ContentEditable
                html={(row.titleFr)?row.titleFr:""}
                name="titleFr"
                data-column="item"
                className="content-editable-edit"
                disabled={false}
                onBlur={e => handleOnEdit(e,row,"titleFr")}
              />
           </div>
           );
         },
      },
      {
        name: "Titre anglais",
        minWidth:'150px !important',
        selector:row=> row.titleEn,
        sortable: true,
        sortField:'titleEn',
        cell: (row) => {
          return (
              <ContentEditable
                html={(row.titleEn)?row.titleEn:""}
                name="titleEn"
                data-column="item"
                className="content-editable-edit"
                disabled={false}
                onBlur={e => handleOnEdit(e,row,"titleEn")}
              />
           );
         }
      },
      {
        name: "Titre arabe",
        minWidth:'150px !important',
        selector:row=> row.titleAr,
        sortable: true,
        sortField:'titleAr',
        cell: (row) => {
          return (
              <ContentEditable
                html={(row.titleAr)?row.titleAr:""}
                name="titleAr"
                data-column="item"
                className="content-editable-edit"
                disabled={false}
                onBlur={e => handleOnEdit(e,row,"titleAr")}
              />
           );
         }
      },
      {
        name: "Contenu français",
        selector:row=> row.messageFr,
        minWidth:"200px !important",
        sortable: true,
        sortField:'messageFr',
        cell: (row) => {
          return (
              <ContentEditable
                html={(row.messageFr)?row.messageFr:""}
                name="messageFr"
                data-column="item"
                className="content-editable-edit"
                disabled={false}
                onBlur={e => handleOnEdit(e,row,"messageFr")}
              />
           );
         },
      },
      {
        name: "Contenu anglais",
        selector:row=> row.messageEn,
        minWidth:"200px !important",
        sortable: true,
        sortField:'messageEn',
        cell: (row) => {
          return (
              <ContentEditable
                html={(row.messageEn)?row.messageEn:""}
                name="messageEn"
                data-column="item"
                className="content-editable-edit"
                disabled={false}
                onBlur={e => handleOnEdit(e,row,"messageEn")}
              />
           );
         },
      },
      {
        name: "Contenu arabe",
        selector:row=> row.messageAr,
        minWidth:"200px !important",
        sortable: true,
        sortField:'messageAr',
        cell: (row) => {
          return (
              <ContentEditable
                html={(row.messageAr)?row.messageAr:""}
                name="messageAr"
                data-column="item"
                className="content-editable-edit"
                disabled={false}
                onBlur={e => handleOnEdit(e,row,"messageAr")}
              />
           );
         },
      },
      {
        name: "Agent",
        width:'100px !important',
        selector:row=> row.agent,
        sortable: true,
        sortField:'agent',
      },
      {
        name: "Date",
        width:'100px !important',
        selector:row=> row.date,
      },
      {
        width:"40px",
        rigth:true,
        cell: (row) => {
          return (
            <Tooltip title='Supprimer le modèle'>
              <div onClick={()=>HandleDelet(row)} ><Delete fontSize='small' className={classes.redIcon}/></div>
            </Tooltip>
            )
       }
      }
    ],[handleOnEdit,HandleDelet]);
  
function handlePageChange (page) {
  fetch(page,service,perPage,search);
  setCurrentPage(page);
}
const handlePerRowsChange = async (newPerPage, page) => {
   fetch(page,service, newPerPage,search);
   setPerPage(newPerPage);
}

const handleSearch=async(size = perPage)=>{
     console.log(mod)
     setmod([])
     setLoading(true);
     const recherche=search;
     const response=await librery.axiosSearch(service,recherche,1,size,'get')
     setLoading(false);
     if(response.data.err&&response.data.err!=='-1'){
       handleAlert(true,'error',response.data.err)
     }else{
     Gfunc.axiosResponse(response,()=>{
      console.log(response.data.recordsFiltered)
        setTotalRows(response.data.recordsFiltered);
        if(response.data.data){
            setmod(response.data.data);
        }
     })   
    }
     return recherche
     }
   
 const handleOnSort=async(column, sortdirection,page=currentPage, size = perPage)=>{
   setLoading(true);
   const response=await librery.axiosSort(column,sortdirection,1,size,service,'get')
   setLoading(false);
   if(response.data.err&&response.data.err!=='-1'){
     handleAlert(true,'error',response.data.err)
   }else{
   Gfunc.axiosResponse(response,()=>{
    setTotalRows(response.data.recordsFiltered);
    if(response.data.data){
        setmod(response.data.data);
    }
   }) 
  }    
}

function liveSearch(e,search){
  if(param.liveSearch&&search.length>3){
     e.preventDefault();
     handleSearch();
  }else{ if (e.keyCode === 13) {
            e.preventDefault();
            handleSearch();
          }
  }
}

 return (
  <div className="content">
      <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA}/>
      <Backdrop className={classes.backdrop} open={loading} >
        <CircularProgress color="inherit" />
      </Backdrop>
      {(notAllowed===false)?
      <div>
      <div  className="table-header">
       <Modele fetch={fetch}/>
      <div className='search' >
          <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <TextField className={classes.search} variant="outlined"  label="Recherche" value={search} 
                               InputProps={{startAdornment: (<Search onClick={() => handleSearch()}
                               style={{ marginRight: '5px',zIndex:'1000',width:'20px',height:'20px',cursor:"pointer"}} />),}}
                               size="small" margin='none'  onKeyUp={e => liveSearch(e)} onChange={e => setSearch(e.target.value)} /> 
          </Box>
      </div>
     </div>
     </div>:null
     }
{ 
    (notAllowed===false)?
        <div  className="Modeles">
          <DataTable 
              columns={columns}
              data={mod}
              defaultSortAsc={true}
              persistTableHead={true}
              //progressPending={loading}
              noDataComponent={param.ErreurData}
              pagination
              paginationServer
              paginationRowsPerPageOptions={[10,20,30,100]}
              paginationTotalRows={totalRows}
              paginationDefaultPage={currentPage}
              paginationPerPage={perPage}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              paginationComponentOptions={{ rowsPerPageText: 'Élément par page:', rangeSeparatorText: 'sur',selectAllRowsItemText: 'Tous',selectAllRowsItem: true}}
              sortServer={true}
              onSort={handleOnSort}
              highlightOnHover
              customStyles={customStyles} 
            /></div>:<div className='compAllow'><NotAllowedComponent message={errMessage}/></div>
        }
  </div>
  );
 }