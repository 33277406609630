import React ,{useCallback,useState,useMemo,useEffect} from "react";
import DataTable from "react-data-table-component";
import {customStyles1} from '../../css/datatable-custom'
import {Search,IndeterminateCheckBox, Tune} from '@material-ui/icons';
import {Grid,CircularProgress,Backdrop, Box, Tooltip} from '@material-ui/core';
import {axiosPostAction,axiosResponse} from'../../Gfunc'
import { useStyles } from '../../css/muiStyle';
import TextField from '@material-ui/core/TextField';
import {AlertCompo,NotAllowedComponent} from "../../js/components"
import Send from '../../img/send.png'
import { useContext } from 'react';
import ParamContext from '../../Contexte';
import SMS from "./modals/sms_srv_add";
import '../../css/sms.css'


export default function Sms_srv(){
   const classes = useStyles();
   const Gfunc=require('../../Gfunc')
   const param=useContext(ParamContext);
   const librery=require('../../js/data_table_librery')
   const [msg,setMsg]=useState([])
   const [search,setSearch]=useState('')
   const [loading, setLoading] = useState(false);
   const [totalRows, setTotalRows] = useState(0);
   const [perPage, setPerPage] = useState(30);
   const [currentPage, setCurrentPage] = useState(1);
   const [openA, setOpenA] =useState(false);
   const [errMessage,setErrMessage]=useState('');
   const [notAllowed,setNotAllowed]=useState(false);
   const [messageA, setMessageA] =useState('');
   const [severity, setSeverity] =useState('info');
   const service='_sms.php'
  

  const handleAlert=(open,severity,message)=>{
    setOpenA(true)
    setSeverity(severity)
    setMessageA(message)
    setTimeout(function(){
      setOpenA(false)
    }, 5000);
  }

  const fetch= useCallback(async(page,servic = service, size = perPage,val) => {
      setLoading(true);
      const response=await librery.axiosGet(page+'&search='+((val!==undefined)?val:''),servic,size)
      setLoading(false);
      if(response.data.err&&response.data.err!=='-1'){
        setErrMessage(response.data.err)
        setNotAllowed(true)
      }else{
      Gfunc.axiosResponse(response,()=>{
        setMsg(response.data.data)  
        setTotalRows(response.data.recordsFiltered)
    })
  }
  },[Gfunc,param.urlService,perPage]);
 
  useEffect(() => {
    if(msg!==undefined&&msg.length===0) fetch(1);
  },[fetch,msg]);


 const HandleDelet=useCallback(async(row)=>{
    const respon=await axiosPostAction(service,"del",{idm:parseInt(row.DT_RowId.substr(1))})
    if(respon.data.err&&respon.data.err!=='-1'){
      handleAlert(true,"error",respon.data.err)
    }
    else{
    axiosResponse(respon,()=>{
            if (respon.data.jData) fetch(1);
    })
  } 
},[fetch])

 const handleResend=useCallback(async(row)=>{
    const respon=await axiosPostAction(service,"resend",{idm:row.DT_RowId.substr(1)})
    if(respon.data.err&&respon.data.err!=='-1'){
      handleAlert(true,"error",respon.data.err)
    }
    else{
    axiosResponse(respon,()=>{
            if (respon.data.jData) fetch(1)
    })
  } 
 },[fetch])

 const EtatControl=useMemo(()=>[
    {
      when: row => row.status==='Reçu',
        style: {
          color:"limegreen",
        }
    }, 
    {
      when: row =>row.status==='Echec',
        style: {
          color:"red",
        }
    }, 
    {
      when: row =>row.status==='Envoyé',
        style: {
          color:"blue",
        }
    }
  ],[])
 const EtatControl1=useMemo(()=>[
    {
      when: row => row.DT_RowId!=='',
        style: {
          fontSize:"12.5px",
        }
    } , 
  ],[])

 const columns =useMemo(
    () => [ 
      {
        name: "Date",
        minWidth:'100px !important',
        selector:row=> row.date,
        sortable: true,
        sortField:'date',
      },
      {
        name: "Numéro",
        minWidth:'120px !important',
        selector:row=> row.numero,
        sortable: true,
        sortField:'numero',
      },
     {
        name: "Message",
        selector:row=> row.message,
        minWidth:'480px !important',
        conditionalCellStyles:EtatControl1,
        sortField:'message',
        sortable: true,
        cell: (row) => { 
            var index = row.message.indexOf("Nouveau pass:"); 
            var message='Mot de passe modifié'   
            return (
              <Tooltip title={(index===-1)?row.message:message}>
                <div className="addedCell" >
                  {(index===-1)?row.message:message}
                </div>
              </Tooltip>
           );
         },
     },
     {
        name: "User",
        selector:row=> row.agent,
        minWidth:'100px !important',
        sortable: true,
        sortField:'agent',
     },
     {
      name: "Status",
      selector:row=> row.status,
      sortable: true,
      minWidth:"100px !important",
      conditionalCellStyles:EtatControl,
      sortField:'status',
    },
    {
      name: "Envoie",
      selector:row=> row.send_hour,
      minWidth:"100px !important",
      sortField:'send_hour',
    },
    {
      name: "Accusé",
      selector:row=> row.hour_delivery,
      minWidth:"100px !important",
      sortField:'hour_delivery',
    },
    {
      name: "Server",
      selector:row=> row.server,
      minWidth:"100px !important",
      sortable: true,
      sortField:'server',
    },
    {
        //this line was causing an offset
        //width:'55px !important',
        right:true,
        cell: (row) => {
          return (
            <div style={{display:'flex',gridGap:'3px',alignItems:'center'}}>
              <Tooltip title='Renvoyer le message'> 
                <img src={Send} style={{width:'19px',height:'19px'}} alt='send' className={classes.blackIcon} onClick={()=>handleResend(row)} />
              </Tooltip>
              <Tooltip title='Supprimer le message'>
                <IndeterminateCheckBox  style={{width:'19px',height:'19px'}} fontSize='small' className={classes.redIcon}  onClick={()=>HandleDelet(row)}/>
              </Tooltip>
            </div>
            )
       }
    }
    ],[HandleDelet,classes.redIcon,handleResend,EtatControl,EtatControl1]);
  
function handlePageChange (page) {
  fetch(page,service,perPage,search);
  setCurrentPage(page);
}
const handlePerRowsChange = async (newPerPage, page) => {
   fetch(page,service, newPerPage,search);
   setPerPage(newPerPage);
}

const handleSearch=async(size = perPage)=>{
     setLoading(true);
     const recherche=search;
     const response=await librery.axiosSearch(service,recherche,1,size,'get')
     setLoading(false);
     if(response.data.err&&response.data.err!=='-1'){
       handleAlert(true,'error',response.data.err)
     }else{
     Gfunc.axiosResponse(response,()=>{
       setCurrentPage(1)
       setMsg(response.data.data);
       setTotalRows(response.data.recordsFiltered);
     })   
    }
     return recherche
     }
   
 const handleOnSort=async(column, sortdirection,page=currentPage, size = perPage)=>{
   setLoading(true);
   const response=await librery.axiosSort(column,sortdirection,1,size,service,'get')
   setLoading(false);
   if(response.data.err&&response.data.err!=='-1'){
     handleAlert(true,'error',response.data.err)
   }else{
   Gfunc.axiosResponse(response,()=>{
    setCurrentPage(1)
     setMsg(response.data.data);
     setTotalRows(response.data.recordsFiltered);
   }) 
  }    
}

function liveSearch(e,search){
  if(param.liveSearch&&search.length>3){
     e.preventDefault();
     handleSearch();
  }else{ if (e.keyCode === 13) {
            e.preventDefault();
            handleSearch();
          }
  }
}
const conditionalRowStyles= [
  {
    when: row => row.DT_RowId!=='',
    style: {
      height:'25px',
      minHeight: '35px'
    },
  },
];

 return (
  <div className="content">
      <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA}/>
      <Backdrop className={classes.backdrop} open={loading} >
        <CircularProgress color="inherit" />
      </Backdrop>
      {(notAllowed===false)?
      <div>
      <div  className="table-header">
        <SMS fetch={fetch}/>
        <div className='search' >
            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <TextField className={classes.search} variant="outlined"  label="Recherche" value={search} 
                               InputProps={{startAdornment: (<Search onClick={() => handleSearch()}
                               style={{ marginRight: '5px',zIndex:'1000',width:'20px',height:'20px',cursor:"pointer"}} />),}}
                               size="small" margin='none'  onKeyUp={e => liveSearch(e)} onChange={e => setSearch(e.target.value)} /> 
            </Box>
        </div>
     </div>
     </div>:null
     }
{ 
    (notAllowed===false)?
     <DataTable 
              columns={columns}
              data={msg}
              defaultSortAsc={true}
              persistTableHead={true}
              //progressPending={loading}
              noDataComponent={param.ErreurData}
              pagination
              paginationServer
              paginationRowsPerPageOptions={[30,50,100]}
              paginationTotalRows={totalRows}
              paginationDefaultPage={currentPage}
              paginationPerPage={perPage}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              paginationComponentOptions={{ rowsPerPageText: 'Élément par page:', rangeSeparatorText: 'sur',selectAllRowsItemText: 'Tous',selectAllRowsItem: true}}
              sortServer={true}
              onSort={handleOnSort}
              highlightOnHover
              customStyles={customStyles1} 
              conditionalRowStyles={conditionalRowStyles}  
            />:<div className='compAllow'><NotAllowedComponent message={errMessage}/></div>
        }
  </div>
  );
 }