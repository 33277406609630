import React ,{useRef,useCallback,useState} from "react";
import {Paper} from '@material-ui/core';
import {Typography} from '@material-ui/core';
import {Button,CircularProgress,Backdrop } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { ValidatorForm} from 'react-material-ui-form-validator';
import { MuiPickersUtilsProvider,KeyboardDatePicker} from "@material-ui/pickers";
import {Check, DoneAll} from '@material-ui/icons';
import { fr } from "date-fns/locale";
import DateFnsUtils from "@date-io/date-fns";
import { useStyles } from '../../css/muiStyle';
import {NoDataComponent,AlertCompo} from '../../js/components'
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useMediaQuery } from 'react-responsive'
import BarCharts from "../../compo/BarChart";
import { useContext } from 'react';
import ParamContext from '../../Contexte';
import '../../css/reporting.css'


export default function Rep_mtq (){
    const Gfunc=require('../../Gfunc')
    const param=useContext(ParamContext);
    const classes = useStyles();
    const [loading,setLoading]=useState(false)
    const [prd,SetPrd]=useState([])
    const [mtq,Setmtq]=useState([])
    const [cpt,SetCpt]=useState([])
    const [selectDateD,SetSelectDateD]=useState(Gfunc.DateDeb(new Date()))
    const [selectDateF,SetSelectDateF]=useState(new Date())
    const [data,SetData]=useState([])
    const [err,SetErr]=useState(false)
    const [openA, setOpenA] =useState(false);
    const [messageA, setMessageA] =useState('');
    const [severity, setSeverity] =useState('info');
    const [selectMtq,SetSelectMtq]=useState('')
    const [selectCpt,SetSelectCpt]=useState('')
    const [selectPrd,SetSelectPrd]=useState('')
    const message='Aucune donnée trouvée'
    const service='_rep.php'
    
    const isDesktopOrLaptop = useMediaQuery({query: '(max-width: 850px)'})

    const handleAlert=(open,severity,message)=>{
      setOpenA(true)
      setSeverity(severity)
      setMessageA(message)
      setTimeout(function(){
        setOpenA(false)
      }, 5000);
    }
    const fetchMtq= useCallback(async() => {
        const response = await Gfunc.axiosPostAction(service,'getmetrics')
        if(response.data.err&&response.data.err!=='-1'){
          handleAlert(true,"error",response.data.err)
        }else{
        Gfunc.axiosResponse(response,()=>{
            response.data.jData.catalogues.unshift({id:'empty',name:'Tous'});
            Setmtq(response.data.jData.catalogues);   
        })
      }
    },[Gfunc,param.urlService]);
    
    const fetchProd= useCallback(async() => {
      var prod=[]
      const response = await Gfunc.axiosPostAction('_prd.php','gets')
      if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,"error",response.data.err)
      }else{
       Gfunc.axiosResponse(response,()=>{
        response.data.jData.map((el)=>{
           prod.push({id:el.id,nom:el.nom+' ['+el.couleur+']'})
           return true
        })
        prod.unshift({id:'empty',nom:'Tous'});
          SetPrd(prod);   
      })
      }
    },[Gfunc,param.urlService]);

    const fetchCpt= useCallback(async() => {
      var cpt=[]
      const response = await Gfunc.axiosPostAction('_cpt.php','gets')
      if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,"error",response.data.err)
      }else{
       Gfunc.axiosResponse(response,()=>{
        response.data.jData.map((el)=>{
          cpt.push({id:el.id,nom:el.nom+' '+el.prenom+'  ['+el.mag+']'})
          return true
        })
        cpt.unshift({id:'empty',nom:'Tous'});
        SetCpt(cpt)  
      })
     }
    },[Gfunc,param.urlService]);

    //valider le formulaire 
    const handleSubmit=async()=>{
      SetErr(false)
      setLoading(true)
      const response=await Gfunc.axiosPostAction('_rep.php',"getmtcnt",{date1:Gfunc.formatDate(new Date(selectDateD)),date2:Gfunc.formatDate(selectDateF),compte:(selectCpt!=='empty')?selectCpt:'',
                                                 metric:(selectMtq!=='empty')?selectMtq:'',produit:(selectPrd!=='empty')?selectPrd:''})
      setLoading(false)
      Gfunc.axiosResponse(response,()=>{
         var elem=[];var dat=[];var cnx=[];
        var ordercn=response.data.jData.sort(Gfunc.custom_sortDate)
        if(response.data.jData.length!==0){
         elem.push(ordercn[0])
         elem.push(ordercn[response.data.jData.length-1])
        }
        if(elem.length!==0)
        {
          var test=elem.sort(Gfunc.custom_sortDate)
        var min=test[0].date
        var max=test[test.length-1].date
        var dateParts = min.split("-");
        var dateObject = new Date(dateParts[2], dateParts[1]-1, dateParts[0]);
        var dateParts1 = max.split("-");
        var dateObject1 = new Date(dateParts1[2], dateParts1[1]-1, dateParts1[0]);
        var tes=Gfunc.getDates(dateObject,dateObject1) 
        tes.map((el)=>{
          let date= Gfunc.formatDate(el)
          dat.push(date)
          return true
                  }) 
        dat.map((el)=>{
          var ind=ordercn.findIndex((obj => obj.date ===el));
          if(ind!==-1){
           cnx.push({date:el,count:ordercn[ind].count})
          }else{
            cnx.push({date:el,count:0})
          }
          return true
        }) 
      }
      SetData(cnx)
      SetErr(true)
      })
    } 
    
    //remplir Select Commune
    const handleOpenMtq= useCallback(async () => {
      if(mtq.length===0)
      fetchMtq()
    },[mtq,fetchMtq])

     //remplir Select Commune
    const handleOpenProd= useCallback(async () => {
      if(prd.length===0)
      fetchProd()
    },[prd,fetchProd])

    const handleOpenCpt= useCallback(async () => {
      if(cpt.length===0)
      fetchCpt()
    },[cpt,fetchCpt])

    //valider formulaire avec touche d'entrer
   const detectTouche= (e)=>{
    if (e.keyCode === 13 && (e.target.name==="addBtn")) {
        handleSubmit()
      }
    }
   function CustomTooltip({ payload, label, active,...props }) {
      if (active) {
        var dateParts = payload[0].payload.date.split("-");
        var dateObject = new Date(dateParts[2], dateParts[1]-1, dateParts[0]);
        return (
             <div className="custom-tooltip" style={{border:'2px solid'+payload[0].color}}>
                 <p className="intro" ><b>{Gfunc.DateDay(dateObject)}</b>{'  '+payload[0].payload.date}</p>
                 <p ><b style={{color:payload[0].color}}> cliques:</b>{' '+(payload.length!==0)?new Intl.NumberFormat().format(`${payload[0].value}`):0 }</p>
             </div>
               );
             }
        return null;
   }
return (
  <div className='content'>
  <Backdrop className={classes.backdrop} open={loading} >
        <CircularProgress color="inherit" />
 </Backdrop>
 <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA}/>
  <fieldset className={"fieldsetTop"} id={"mtq"}>
        <legend>Analyser l'activité des comptes (nombre de cliques)</legend>
        <ValidatorForm noValidate id="formAdd" ref={useRef(null)} onSubmit={handleSubmit} onKeyPress={e=>detectTouche(e)}  >
            <div className="rowGrid" id="row-1">
                <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
                    <KeyboardDatePicker disableToolbar value={selectDateD} variant="inline" placeholder="01/01/2020" onChange={date => SetSelectDateD(date)}
                                minDate="01/01/2020" format="dd/MM/yyyy"  className='smallText' margin="dense" inputVariant="outlined"
                                invalidDateMessage="Le format de la date est incorrect" 
                  />
                </MuiPickersUtilsProvider>
                 <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
                    <KeyboardDatePicker disableToolbar value={selectDateF} variant="inline" placeholder="01/01/2020" onChange={date => SetSelectDateF(date)}
                                minDate="01/01/2020" format="dd/MM/yyyy"  className='smallText' margin="dense" inputVariant="outlined"
                                invalidDateMessage="Le format de la date est incorrect" 
                  />
                </MuiPickersUtilsProvider>
                <Autocomplete
                    disableClearable
                    onChange={(e,newValue) =>{SetSelectMtq(mtq[Gfunc.getIndex(mtq,newValue,"name")].id)}} 
                    onFocus={handleOpenMtq}
                    options={mtq.map((option) => option.name)}
                    className='smallText'          
                    inputprops={{className:classes.input}}     
                    renderInput={(params) => (
                        <TextField {...params}  label="Métrique..." color="primary" variant="outlined" size="small"  />
                 )} />
             
                <Autocomplete
                    disableClearable
                    onChange={(e,newValue) =>{SetSelectPrd(prd[Gfunc.getIndex(prd,newValue,"nom")].id)}} 
                    onFocus={handleOpenProd}
                    options={prd.map((option) => option.nom)}
                    inputprops={{className:classes.input}}   
                    className='smallText'          
                    renderInput={(params) => (
                        <TextField {...params}  label="Produit..." color="primary" variant="outlined" size="small"  />
                 )} />
             
                <Autocomplete
                    disableClearable
                    onChange={(e,newValue) =>{SetSelectCpt(cpt[Gfunc.getIndex(cpt,newValue,"nom")].id)}} 
                    onFocus={handleOpenCpt}
                    options={cpt.map((option) => option.nom)}
                    inputprops={{className:classes.input}} 
                    className='smallText'            
                    renderInput={(params) => (
                          <TextField {...params} label="Compte..." color="primary" variant="outlined" size="small"  />
               )} />
              <Button  className={classes.fieldButton} variant="contained" tabIndex="3" type='submit' id="bouton" name="addBtn" color="primary"
                       startIcon={<DoneAll/>}>VALIDER</Button>
           </div>
    </ValidatorForm>
  </fieldset>

{
   (data.length!==0)?
     <div id='pie-mtq'>
          <Paper id='chart-pie-mtq' elevation={8}>  
            <BarCharts width={(isDesktopOrLaptop===false)?600:380} height={300} data={data}
                       margin={{ top: 15, right:0, left:(isDesktopOrLaptop===false)?20:0, bottom:0 }} 
                       dataKeyX="date" padding={{ left: 10, right: 10 }} dataKeyY="count" fill="#8884d8" name='cliques' 
                       CustomTooltip={CustomTooltip} />        
            <Typography className='titleCharts'>Nombre de cliques </Typography>
          </Paper>
    </div>:<></>
    
}
{ (data.length===0 && err===true)?<NoDataComponent message={message}/>:<></>}
</div>   
  );
}