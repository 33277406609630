import React,{useState,useCallback,useMemo} from 'react';
import {DialogActions,DialogContent,DialogTitle,Paper,Button,Dialog,CircularProgress, Tooltip} from '@material-ui/core';
import Draggable from 'react-draggable';
import DataTable from "react-data-table-component";
import {ModeComment} from '@material-ui/icons/';
import { useStyles,paperPropsComs } from '../../../css/muiStyle';
import {customStyles} from '../../../css/datatable-custom'
import { useContext } from 'react';
import ParamContext from '../../../Contexte';
import {AlertCompo} from '../../../js/components'

//Add a new role
function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function Prd_new_AllComments(props) {
    const classes=useStyles();
    const Gfunc=require('../../../Gfunc')
    const param=useContext(ParamContext);
    const [open, setOpen] = useState(false);
    const [comments, setComments] = useState([]);
    const [openA, setOpenA] =useState(false)
    const [messageA, setMessageA] =useState('');
    const [severity, setSeverity] =useState('info');
    const service="_prd.php"

    const conditionalRowStyles= [
      {
        when: row => row.new===true,
        style: {
          color: '#990000',
        },
      },
    ];

    //handle Alerts
    const handleAlert=(open,severity,message)=>{
      setOpenA(true)
      setSeverity(severity)
      setMessageA(message)
      setTimeout(function(){
        setOpenA(false)
      }, 5000);
     }
    
    const handleClickOpen = () => { 
      setOpen(true); 
      fetch(); 
      const objIndex = comments.findIndex((obj => obj.new ===true )); 
      if(objIndex!==-1) { handleChangeStatueCom()}
    };

    const handleClose = () => { setOpen(false); };
    
       //fetch the users that have the selected role
    const fetch= useCallback(async() => {
        const response=await Gfunc.axiosGetAction(service,"getcoms")
        if(response.data.err&&response.data.err!=='-1'){
          handleAlert(true,"error",response.data.err)
        }else{
            Gfunc.axiosResponse(response,()=>{
              if(response.data.jData){
                setComments(response.data.jData);
              }
            })
        }
      },[Gfunc])
    
    const handleChangeStatueCom=useCallback(async() => {
        const response=await Gfunc.axiosPostAction(service,"setcomviewed")
        if(response.data.err&&response.data.err!=='-1'){
          handleAlert(true,"error",response.data.err)
        }else{
          Gfunc.axiosResponse(response,()=>{
            //console.log(response)
          })
        }
    },[Gfunc])

    const displayInf=(row)=>{
      return (
        <div>
           {(row.mag)?<div>{row.nom+' '+row.prenom+' ['+row.mag+'] '}</div>:<div>{row.nom+' '+row.prenom}</div> }
        </div>
      ) 
    }

    const columns =useMemo(
      () => [ 
          {
            name: "Produit",
            selector: row=>row.produit,
            sortable: true, 
            minWidth:"200px !important"
          }, 
          {
            name: "Commentaire",
            selector: row=>row.comment,
            minWidth:"350px !important",
            sortable: true, 
            cell: (row) => {
              return (
                <div className="note-container">
                {((row.comment&&row.comment.match(/\r/g) || []).length>=1)?
                 <Tooltip title={row.comment.replaceAll("\r", "/ ")}>
                     <div className='note-content'>{row.comment.substring(0, row.comment.indexOf("\r"))+"..."}</div>
                 </Tooltip>:
                   <Tooltip title={row.comment?row.comment:''}>
                 <div className='note-content'>{row.comment}</div></Tooltip>}
                 </div>
               );
             },
          },
          {
            name: "Par",
            sortable: true,
            minWidth:"160px !important",
            cell: (row) => {
              return (
                <div>{displayInf(row)}</div>
               );
             },
          },
          {
            name: "Date",
            selector: row=>row.date_comment,
            sortable: true,
            minWidth:"130px !important",
          },
          {
            name: "Commune",
            selector: row=>row.commune,
            sortable: true,
            minWidth:'130px !important',
          },
          {
            name: "Tel",
            selector: row=>row.tel,
            sortable: true,
            width:'100px !important',
          },
          
    ],[]);


  return (
    <div>
      <Button className={classes.topTabBtn} variant="contained" color="secondary" onClick={handleClickOpen} 
              startIcon={<ModeComment/>}>Consulter tous les commentaires</Button>
      <Dialog
            open={open}
            onClose={handleClose}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={paperPropsComs}
       >
        <DialogTitle className={classes.topDivTitle} id="draggable-dialog-title" >La liste des commentaires  </DialogTitle>
        <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA}/>  
        <DialogContent>
            <div id='child-1'>
              <DataTable
                key={Math.floor(Math.random() * 100)}
                columns={columns}
                data={comments}
                noDataComponent={param.ErreurData}
                defaultSortAsc={true}
                paginationRowsPerPageOptions={[5, 10, 20, 30]}
                pagination
                highlightOnHover
                customStyles={customStyles}
                selectableRowsComponentProps={{ className: classes.checkStelect }}
                paginationComponentOptions={{ rowsPerPageText: 'Élément par page:', rangeSeparatorText: 'sur', selectAllRowsItemText: 'Tous',selectAllRowsItem: true}}
                contextMessage={{ singular: 'élément sélectionné', plural: 'éléments sélectionnés' }}
                progressComponent={<CircularProgress className="circularProgress" />}
                conditionalRowStyles={conditionalRowStyles}
              />  
           </div>  
        </DialogContent>
        <DialogActions id='btn-div'>
              <Button variant="contained"  onClick={handleClose} color="secondary">FERMER</Button>
        </DialogActions>
      </Dialog>
      </div>
  );
}
