import { useState ,useCallback, Fragment} from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {CircularProgress,Backdrop,TextField,TextareaAutosize,Box,Tab, Tooltip} from '@material-ui/core';
import {TabContext,TabList,TabPanel} from '@material-ui/lab';
import { useStyles } from '../../css/muiStyle';
import '../../css/catalogue.css'
import {Delete, DragIndicator ,Title,Person} from '@material-ui/icons';
import {AlertCompo,NotAllowedComponent,NoDataComponent,InputNbrEditable, AutocompleteS} from "../../js/components"
import TablePagination from '@material-ui/core/TablePagination';
import { useMediaQuery } from 'react-responsive'
import Autocomplete from '@material-ui/lab/Autocomplete';
import DialogAdd from '../content/modals/cat_dig_add'
import DialogPub from '../content/modals/cat_add_profil'
import {ReactComponent as FrFlag} from '../../img/icons/fr.svg'
import {ReactComponent as DzFlag} from '../../img/icons/dz.svg'
import {ReactComponent as UkFlag} from '../../img/icons/uk.svg' 
import {ReactComponent as Date} from '../../img/icons/date.svg' 
import {ReactComponent as Money} from '../../img/icons/money.svg' 
import ContentEditable from 'react-contenteditable';
import { useContext } from 'react';
import ParamContext from '../../Contexte';
import axios from "axios";

export default function Cat_dig() {
const classes = useStyles();
const Gfunc=require('../../Gfunc')
const param=useContext(ParamContext);
const librery=require('../../js/data_table_librery')
const [data,setData]=useState([]);
const [cat,setCat]=useState([]);
const [libId,setLibId]=useState([]);
const [loading, setLoading] = useState(false);
const [selectcat,setSelectcat]=useState('');
const [totalRows, setTotalRows] = useState(0);
const [rowsPerPage, setRowsPerPage] =useState(10);
const [page, setPage] =useState(1);
const [errMessage,setErrMessage]=useState('');
const [notAllowed,setNotAllowed]=useState(false);
const [openA, setOpenA] =useState(false);
const [messageA, setMessageA] =useState('');
const [severity, setSeverity] =useState('info');
const [records, setRecords] =useState('');
const [noData,setNoData]=useState(false); 
const service='_dig.php'

const isDesktopOrLaptop = useMediaQuery({query: '(max-width: 850px)'})
 
const handleAlert=(open,severity,message)=>{
  setOpenA(true)
  setSeverity(severity)
  setMessageA(message)
  setTimeout(function(){
    setOpenA(false)
  }, 5000);
  }

  const fetchLib= useCallback(async() => {
    const response = await axios.get( param.urlService+service+'?do=getlborods',{ headers: Gfunc.getHeader()});
    if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,"error",response.data.err)
    }else{
     Gfunc.axiosResponse(response,()=>{
        setLibId(response.data.jData)  
     })
     return response.data.jData
  }
},[Gfunc,param.urlService]);

  const fetch= useCallback(async(page,val,servic = service, size = rowsPerPage) => {
    setData([])
    setLoading(true);
    const response=await librery.axiosGet((page+1)+"&catalogId="+val,servic,size)
    setLoading(false);
    if(response.data.err&&response.data.err!=='-1'){
      setErrMessage(response.data.err)
      setNotAllowed(true)
    }else{
    Gfunc.axiosResponse(response,async()=>{
      setRecords(response.data.recordsFiltered)
    if(response.data.data){
      const resp=await fetchLib();
      response.data.data.map((el,index)=>{ 
        var lib=(Gfunc.getIndex(resp,el.liberoId,"id")!==-1)?resp[Gfunc.getIndex(resp,el.liberoId,"id")].nom:null
        el['libroName']=lib
      })
      setData(response.data.data)
      setTotalRows(response.data.recordsFiltered)
      setNotAllowed(false)
      setNoData(true)
    }
  })
  }
  },[Gfunc,param.urlService,rowsPerPage,fetchLib]);

const fetchCat= useCallback(async() => {
    const response = await Gfunc.axiosGetAction(service ,'getcats')
    if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,"error",response.data.err)
    }else{
    Gfunc.axiosResponse(response,()=>{
        setCat(response.data.jData)  
    })
  }
},[Gfunc,param.urlService]);

const handleOnEdit=useCallback(async(e,row,att)=>{
    e.preventDefault();
    const edt=e.target.innerHTML
    const edt1=Gfunc.DeleteSpace(edt)
    if(row[att]!==edt1){
      const attrib=row[att];
      const response=await librery.axiosEdit(service,row,edt1,att) 
      if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,"error",response.data.err)
      }else{
      Gfunc.axiosResponse(response,()=>{row[att]=edt1;e.target.innerHTML=edt1
              if(att==="nomFr"){
                Gfunc.updateElemS('title-fix'+row.DT_RowId,edt1)
              }
            })
      }
      if(response.data.err) {e.target.innerHTML=attrib}
    }else {e.target.innerHTML=edt1}
  },[librery,Gfunc,data]);

  const handleDragEnd =async (e) => {
    if (!e.destination) return null;
    let tempData = Array.from(data);
    let [source_data] = tempData.splice(e.source.index, 1);
    tempData.splice(e.destination.index, 0, source_data); 
    setData(tempData);
    const response = await Gfunc.axiosPostAction1(service ,'order',{this_prod:parseInt(tempData[e.destination.index].DT_RowId.substr(1)),
                                  before_that:(e.destination.index!==0)?parseInt(tempData[e.destination.index-1].DT_RowId.substr(1)):''
                                })
    if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,"error","vous n'avez pas le droit d'effectuer cette action")
        setData(data)
      }else{
      Gfunc.axiosResponse(response,()=>{
        if(response.data.jData===true){
        }
        })
      }
  };

   //handle update note
 const handleOnEditNote=useCallback(async(note,row,att)=>{
  const edt1=note
  var edt=Gfunc.DeleteSpace(edt1);
  edt=(edt==='')?null:edt
  if(row[att]!==edt){
    const attrib=row[att];
    const response=await librery.axiosEdit(service,row,edt,att) 
    if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,'error',response.data.err)
    }else
    Gfunc.axiosResponse(response,()=>{row[att]=edt})
    if(response.data.err) {row[att]=attrib}
  }else {row[att]=edt}
},[librery,Gfunc]);

    //remplir Select catalogue
const handleOpenCat= useCallback(async () => {
        if(cat.length===0)
        fetchCat()
      },[cat,fetchCat])

const handleChangePage = (event, newPage) => {
        fetch(newPage,selectcat,service,rowsPerPage);
        setPage(newPage+1);
};
      
const handleChangeRowsPerPage = (event) => {
          fetch(0,selectcat,service,(event.target.value==="Tous")?-1:parseInt(event.target.value,10));
          setRowsPerPage((event.target.value==="Tous")?totalRows:event.target.value);
          setPage(0);
      };
      
const DeletProduct=async(row)=>{
  const response= await Gfunc.axiosPostAction(service,'delfromcat',({ produitId:row.DT_RowId }))
    if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,"error",response.data.err)
    }else{
    Gfunc.axiosResponse(response,()=>{
        if(response.data.jData){
          fetch(0,selectcat)
        }
       })
      
    }
}

const HandleChange=(val)=>{
  setData([])
    fetch(0,val)
}


const Tableau=(props)=>{
  const [value, setValue] =useState('1');
  const [descFr,setDescFr] = useState('');
  const [descAr,setDescAr] = useState('');
  const [descEn,setDescEn] = useState('');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return(
   <div id='tab-dig'>
    <Box sx={{ width: '90%' }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} >
            <Tab label={<FrFlag fill='black' style={{height:'20px',width:'20px',padding:"1px"}}/>} value="1" />
            <Tab label={<UkFlag fill='black' style={{height:'20px',width:'20px',padding:"1px"}}/>} value="2" />
            <Tab label={<DzFlag fill='black' style={{height:'20px',width:'20px',padding:"1px"}}/>} value="3" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <Tooltip title="Titre Français">
            <div id='row-grid' >
              <Title id='icon' />
              <div style={{width:'80%'}}>
                  <ContentEditable
                      html={(props.el.nomFr)?props.el.nomFr:''}
                      name="compte"
                      data-column="item"
                      disabled={false}
                      className="content-editable-compte"
                      onBlur={e => handleOnEdit(e,props.el,"nomFr")}
                  /> 
                  </div>
            </div>
          </Tooltip>
          <Tooltip title="Description Français" >
           <div  style={{marginLeft:"12px",width:"90%"}}>
             <TextareaAutosize className="descPrd" onChange={(e)=>{setDescFr(e.target.value);}} minRows={4} 
                               value={(descFr)?descFr:props.el.descFr} 
                               onBlur={e=>handleOnEditNote(e.target.value,props.el,"descFr","upd")} placeholder="Description" />
           </div>
         </Tooltip>
        </TabPanel>
        <TabPanel value="2">
        <Tooltip title="Titre Englais" >
          <div id='row-grid' >
              <Title id='icon' />
              <div style={{width:'80%'}}>
                <ContentEditable
                    html={(props.el.nomEn)?props.el.nomEn:''}
                    name="compte"
                    data-column="item"
                    disabled={false}
                    className="content-editable-compte"
                    onBlur={e => handleOnEdit(e,props.el,"nomEn")}
                /> 
              </div>
          </div>
         </Tooltip>
         <Tooltip title="Description Englais" >
          <div  style={{marginLeft:"12px",width:"90%"}}>
            <TextareaAutosize className="descPrd" onChange={(e)=>{setDescEn(e.target.value);}} minRows={4} value={(descEn)?descEn:props.el.descEn} 
                            onBlur={e=>handleOnEditNote(e.target.value,props.el,"descEn","upd")} placeholder="Description" />
           </div>
         </Tooltip>
        </TabPanel>
        <TabPanel value="3">
          <Tooltip title="Titre Arabe" >
            <div id='row-grid' >
                <Title id='icon' />
                <div style={{width:'80%'}}>
                 <ContentEditable
                      html={(props.el.nomEn)?props.el.nomAr:''}
                      name="compte"
                      data-column="item"
                      disabled={false}
                      className="content-editable-compte"
                      onBlur={e => handleOnEdit(e,props.el,"nomAr")}
                  /> 
                </div >
             </div>
          </Tooltip>
          <Tooltip title="Description Arabe" >
            <div style={{marginLeft:"12px",width:"90%"}}>
              <TextareaAutosize className="descPrd" onChange={(e)=>{setDescAr(e.target.value);}} minRows={4} placeholder="Description"
                                value={(descAr)?descAr:props.el.descAr} onBlur={e=>handleOnEditNote(e.target.value,props.el,"descAr","upd")} />
            </div>
          </Tooltip>
        </TabPanel>
      </TabContext>
    </Box>
  </div>
 )
}

  return (
    <div className="content">
     <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA}/>
     <Backdrop className={classes.backdrop} open={loading} >
        <CircularProgress color="inherit" />
     </Backdrop>
     <div className='table-header'>
             <div className="button-flex-wrapper">
                <DialogAdd handleAlert={handleAlert} fetch={fetch} data={data} setSelectcat={setSelectcat}/>
                <div id='cat-digit' className="smallAutoFiltre">
                  <Autocomplete
                      disableClearable
                      onChange={(e,newValue) =>{setSelectcat(cat[Gfunc.getIndex(cat,newValue,"name")].id);
                                                HandleChange(cat[Gfunc.getIndex(cat,newValue,"name")].id)}} 
                      onFocus={handleOpenCat}
                      options={cat.map((option) => option.name)} 
                      renderInput={(params) => (
                            <TextField className="smallAutoFiltre" {...params}  label="CATALOGUE..." color="primary" variant="outlined" size="small"  />
                          )} />

                </div>
             </div>
             <div className="search">
            </div>
     </div>
     
      { 
      (notAllowed===false)?
      <div>
      <DragDropContext onDragEnd={handleDragEnd}>
        <table className="table-borderd">
          <Droppable droppableId="droppable-1">
            {(provider) => (
              <tbody
                className="text-capitalize"
                ref={provider.innerRef}
                {...provider.droppableProps}
              >
                {data.map((el,index) => (
                  <Draggable
                    key={el.DT_RowId}
                    draggableId={el.DT_RowId}
                    index={index}
                  >
                    {(provider) => (
                      <Fragment >
                      <tr {...provider.draggableProps} ref={provider.innerRef} id="tr-cat" >
                        <td  style={{backgroundColor:'white'}} {...provider.dragHandleProps} ref={provider.innerRef} colSpan={2} title='Glisser'> <DragIndicator/></td>
                        <td colSpan={5}>
                          <div id={(isDesktopOrLaptop===false)?'cat-prod':'cat-prod1'}>
                            <div id='cat-img'><DialogPub id={el.DT_RowId} row={el} handleAlert={handleAlert} digit='digit'/></div>
                            <div id="cat-inf">
                            <div id='div-cat-head'>
                             <div id='div-cat-title' >
                                <div id={'title-fix'+el.DT_RowId} className="title-fix" >{el.nomFr}</div>
                             </div>
                             <Tooltip title="Nom libero" >
                             <div style={{width:'70%'}}>
                              <AutocompleteS service={service} action={'upd'} className='content-editable-cat-ar' className1='SelectDT'
                                             defaultValue={el.libroName} row={el} list={libId} id={'liberoId'} name='libroName' attr='nom' from='inf'
                                             onOpen={fetchLib} handleAlert={handleAlert}/>
                             </div>
                             </Tooltip >
                          </div>
                          <div style={{paddingLeft:'15px',paddingTop:'25px'}}>
                                <Tooltip title="Date d'ajout" >
                                <div className="div-row" >
                                    <Date fill='black' style={{height:'18px',width:'18px'}}/>
                                    <p style={{marginLeft:'9px'}}>{el.dateIn}</p>
                                </div>
                                </Tooltip>
                                <Tooltip title="Agent">
                                  <div className="div-row">                                
                                    <Person fill='black' style={{height:'18px',width:'18px'}}/> 
                                    <p style={{marginLeft:'9px'}}>{el.agentIn}</p>
                                  </div>
                                </Tooltip>
                                <Tooltip title="Prix">
                                  <div id="div-cat-prx" className="div-row">
                                      <Money fill='black' style={{height:'18px',width:'18px'}}/> 
                                      <InputNbrEditable
                                        service={service} action='upd' min={1} className='content-editable-compte' from='catalogue'
                                        row={el} att="prix" value={el.prix} id='DT_RowId' idi={el.DT_RowId}
                                        handleAlert={handleAlert}
                                      />
                                      <b>DA</b>
                                  </div>
                                </Tooltip>
                            </div>
                            </div>
                            <div id="div-cat-infoo">
                               <div id='div-dig-desc'>
                                 <Tableau el={el}/>
                               </div>
                            </div>
                          </div>                        
                       </td>
                       <td id='td-sup' onClick={()=>DeletProduct(el)} title="Supprimer du catalogue"><Delete style={{color:'red'}}/></td>
                      </tr>
                      <tr style={{height:'12px',border:'none'}}></tr>
                      </Fragment>
                    )}
                  </Draggable>
                ))}
                {provider.placeholder}
              </tbody>

            )}
          </Droppable>
        </table>
      </DragDropContext>
       
      {(data.length!==0)?
       <TablePagination
          component="div"
          labelRowsPerPage='Élément par page:'
          count={totalRows}
          rowsPerPageOptions={[10,30,50,100,'Tous']}
          page={page-1}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
       />:<></>}   
      </div>:
      (notAllowed)?<NotAllowedComponent message={errMessage}/>:(noData ||records===0)?<NoDataComponent message="Aucun résultat trouvé"/>:null}
    </div>
  );
}

