import React,{useState,useContext, useRef, useMemo, useCallback} from 'react';
import {DialogContent,Typography,Paper,Dialog,IconButton, Button, DialogActions} from '@material-ui/core';
import Draggablee from 'react-draggable';
import { paperPropsBon} from '../../../css/muiStyle';
import {Close,Print} from '@material-ui/icons';
import { withStyles} from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import ParamContext from '../../../Contexte';
import {AlertCompo} from '../../../js/components'
import {useStyles } from '../../../css/muiStyle';
import ReactToPrint from 'react-to-print';
import LogoBq from '../../../img/logos/logo_bq.png'
import {numberColumnTicket,customStylesTicket,customStylesTicket2} from '../../../css/datatable-custom'
import DataTable from "react-data-table-component";
import '../../../css/ticket-bon.css'


const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });
const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle  className={classes.root} {...other}>
        <Typography >{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <Close />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });


//Add a new role
function PaperComponent(props) {
  return (
    <Draggablee handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggablee>
  )
}


export default function Cmd_acc_ticket(props) {
    const componentRef = useRef();
    const classes = useStyles();
    const param=useContext(ParamContext);
    const Gfunc=require('../../../Gfunc')
    const [detail,setDetail]=useState([])
    const [open,setOpen]=useState(false)
    const [openA, setOpenA] =useState(false)   
    const [loading, setLoading] =useState(false)
    const [messageA, setMessageA] =useState('');
    const [severity, setSeverity] =useState('info');
    const service='_cmd.php'


    const handleAlert=(open,severity,message)=>{
        setOpenA(true)
        setSeverity(severity)
        setMessageA(message)
        setTimeout(function(){
          setOpenA(false)
        }, 5000);
        }
    
    const handleClickOpen = () => { 
        setOpen(true);
        fetch();
    };
    const handleClose = () => { setOpen(false); };

    const fetch=useCallback(async() => {
      const response=await Gfunc.axiosPostAction(service,"gettiket",{idt:props.row.ticketId,mag:props.do})
      if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,"error",response.data.err)
      }else{
        Gfunc.axiosResponse(response,()=>{
          if(response.data.jData){
            setDetail(response.data.jData)
          }
        })
      }
    },[props,Gfunc])

    const columns = useMemo(()=>[   
      {
          name: "Produit",
          selector: row=>row.produit,
          sortable: false,
          width:'132px !important'
      },
      {
          name: "QTE.",
          selector: row=>row.qte,
          sortable: false,
          width:'40px !important',
          style:numberColumnTicket,
          cell:(row)=>{
            return (
            <>
              {new Intl.NumberFormat().format(row.qte)}
            </>
            )
          }
      },
      {
          name: "Prix",
          sortable: false,
          style:numberColumnTicket,
          width:'56px !important',
          cell:(row)=>{
            return (
            <>
              {new Intl.NumberFormat().format(row.prix)}
            </>
            )
          }
      },
      {
          name: "Total",
          selector: row=>row.mtn,
          style:numberColumnTicket,
          sortable: false,
          cell:(row)=>{
            return (
            <>
              {new Intl.NumberFormat().format(row.mtn)}
            </>
            )
          }
      },
    ],[])

    return (
    <div >
        <div id='ticket-div' title={"Imprimer le ticket avec le numéro: "+props.row.ticketNum}  onClick={handleClickOpen} >
          <Print/> <p>{props.row.ticketNum}</p>
        </div>
        <Dialog 
            open={open}
            onClose={handleClose}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={paperPropsBon}
        >
            <DialogTitle id="simple-dialog-title" onClose={handleClose}/>
            <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA}/>
            <DialogContent >  
            <div  ref={componentRef}>
            <div  id="ticket" >
            <div id='ticketHead'>
                <img src={LogoBq} id='logo_ticket' alt='logo'/>
            </div>
            <hr />
              <div className="rowGrid" id="row-2">
                <p>Ticket n: <b>{detail.numero}</b></p>
                <p>Date: <b>{detail.date_tiket}</b></p>
              </div>
              <div className="rowGrid" id="row-2">
                <p>Client: <b>{(detail.client)?detail.client.substr(0,3):detail.client}</b></p>
                <p>Vendeur: <b>{detail.vendeur}</b></p>
              </div>
              <div className="rowGrid" id="row-2">
                {(detail.tel!=null)?<p>Tel client: <b>{new Intl.NumberFormat().format(detail.tel)}</b></p>:<p></p>}
                <p>Ancien solde: <b>{new Intl.NumberFormat().format(Math.round(detail.ex_solde))}</b></p>
              </div>
             { ((!detail.ventes&&detail.versement>0)||(detail.ventes&&detail.ventes.length===1&&detail.ventes[0].id==null&&detail.versement>0))?<div id='empty-ventes'>Ticket de versement.</div>
                :<><DataTable 
                      persistTableHead
                      columns={columns}
                      data={detail.ventes}
                      noHeader={true}
                      customStyles={(localStorage.getItem('tiketWidth')==="1")?customStylesTicket2:customStylesTicket}
                      defaultSortAsc={true}
                      noDataComponent={param.ErreurData}    
                      highlightOnHover
                      paginationComponentOptions={{ rowsPerPageText: 'Element par page:', rangeSeparatorText: 'sur'}}               
                    />
                <div className="rowGrid" id="row-3">
                <p>Nombre d'article: <b>{(detail.ventes)?detail.ventes.length:0}</b></p>
                <p >Total: <b>{(detail.total!==undefined)?new Intl.NumberFormat().format(Math.round(detail.total)):0}</b></p>
              </div>
              </>
                }
                <br/>
               <div className="rowGrid" id="row-4">
                <p>Versement: <b>{(detail.versement!==undefined)?new Intl.NumberFormat().format(Math.round(detail.versement)):0}</b></p>
                <p>Nouveau solde: <b>{(detail.new_solde!==undefined)?new Intl.NumberFormat().format(Math.round(detail.new_solde)):0}</b></p>
              </div>
              <hr />
              <p style={{textAlign:"center",fontWeight:'bold'}}>Merci pour votre fidélité</p>
              </div>
              
            </div>
            </DialogContent>
            <DialogActions>
              <ReactToPrint trigger={() => <Button  variant="contained"  color="primary" startIcon={<Print/>}>Imprimer</Button>} 
                                            content={() => componentRef.current} />
            </DialogActions>
        </Dialog>
      </div>
  );
}
