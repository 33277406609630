import React,{useEffect, useState,useCallback} from "react";
import { useContext } from 'react';
import ParamContext from '../../Contexte';
import axios from 'axios'
import {Button,Card,Checkbox,CircularProgress,Divider,FormControlLabel, Tooltip} from "@material-ui/core";
import Err1 from "../../img/photo.png"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import DropdownColor from '../../../src/compo/DropDownColor'
import ContentEditable from "react-contenteditable";
import { InputNbrEditable, AlertCompo } from "../../js/components";
import { DragIndicator,Delete,VisibilityOff} from "@material-ui/icons";
import { useStyles } from '../../css/muiStyle';
import CatAdd from './modals/cat_prd_cat_add'
import ProdAdd from './modals/cat_prd_addLL'
import Comments from './modals/prd_new_comments'
import Favories from './modals/prd_new_favories'
import CatOrdre from './modals/cat_onl_ordre'
import $ from 'jquery'
import '../../css/catalogue.css'


export default function Cat_onl() { 
  const classes = useStyles();
  const Gfunc=require('../../Gfunc')
  const librery=require('../../js/data_table_librery')
  const param=useContext(ParamContext);
  const [loadingExp,setLoadingExp]=useState(false)
  const [data,setData]=useState([]);
  const [prod,setProd]=useState([]);
  const [colL,setColL]=useState([]);
  const [,settest] = useState(false);
  const [countp,setCountp]=useState('');
  const [openA, setOpenA] =useState(false);
  const [messageA, setMessageA] =useState('');
  const [severity, setSeverity] =useState('info');
  const service='_prd.php'
  const service1='_cat.php'
  const srv='produit'

  const handleAlert=(open,severity,message)=>{
    setOpenA(true)
    setSeverity(severity)
    setMessageA(message)
    setTimeout(function(){
      setOpenA(false)
    }, 5000);
  }

  $(document).ready(function() {
    const cards = document.querySelectorAll('.card');
    cards.forEach(card => {
      card.addEventListener('click', () => {
        try {
          const previouslyColoredCard = document.querySelector('.card.colored');
          if (previouslyColoredCard) {
            previouslyColoredCard.classList.remove('colored');
          }
          card.classList.add('colored');
        } catch (error) {
          console.error('Une erreur s\'est produite lors de la sélection de la carte :', error)
        }
      });
    });
  });
  
  
  const handlePrdCat=async(el)=>{
    setProd([]);setCountp('')
    setLoadingExp(true)
    const response=await Gfunc.axiosPostAction(service,"getprdbycat",{catalogId:parseInt(el.DT_RowId.substr(1))})
    setLoadingExp(false)
    if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,"error",response.data.err)
    }else{
    Gfunc.axiosResponse(response,()=>{
      if(response.data.jData){
        response.data.jData.map((item)=>{
          item['catalogId']=el.DT_RowId
        })
        setProd(response.data.jData)    
        setCountp(el)
      }
    })
    }
  }
      
  const fetch= useCallback(async(page,servic = service1) => {
    const response = await axios.get( param.urlService+service1+'?do=get&page='+(page)+"&per_page="+(-1),{ headers: Gfunc.getHeader()});
    if(response.data.err&&response.data.err!=='-1'){
     handleAlert(true,'error',response.data.err)
    }else{
    Gfunc.axiosResponse(response,()=>{
      if(response.data.data){
          setData(response.data.data)
          handlePrdCat(response.data.data[0])
          const firstCard = document.querySelector('.card:first-of-type');
          //const firstCard = document.querySelector('.card:nth-of-type(2)');
          if(firstCard!==null){
            firstCard.classList.add('colored');
          }
      }
    })
  }
  },[Gfunc,param.urlService]);

  //fetch color
  const fetchCol= useCallback(async(serv='_col.php', act = "gets") => {
    const response = await Gfunc.axiosGetAction(serv,act);
    if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,'error',response.data.err)
    }else
      Gfunc.axiosResponse(response,()=>{
        if(response.data.jData){
          setColL(response.data.jData);    
        }
    })
  },[Gfunc])
  
  useEffect(() => {
    if(data!==undefined&&data.length===0) {
      fetch(1);fetchCol();
    }
  },[fetch,fetchCol,data]);

  const handleDragEnd =async (e,val) => {
    if (!e.destination) return null;
    let tempData = Array.from(prod);
    let [source_data] = tempData.splice(e.source.index, 1);
    tempData.splice(e.destination.index, 0, source_data); 
    setProd(tempData);
    const response = await Gfunc.axiosPostAction1(service ,'orderprd',{this_product:parseInt(tempData[e.destination.index].produitId),
                                  before_that:(e.destination.index!==0)?parseInt(tempData[e.destination.index-1].produitId):'',
                                  catalogId:parseInt(val.DT_RowId.substr(1)),
                                })
    if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,"error","vous n'avez pas le droit d'effectuer cette action")
        setProd(prod)
      }else{
      Gfunc.axiosResponse(response,()=>{
        if(response.data.jData===true){
        }
        })
      }
  };

  const handleChange=(val)=>{
    settest(val)
  }
  
  const handleOnEdit=useCallback(async(e,row,att)=>{
    var edt='';var edt1='';var val=''
      if(att!=='colorId'){
        edt=e.target.innerHTML
        edt1=(edt!=='---')?Gfunc.DeleteSpace(edt):null
        val=(row[att]!==null)?row[att].trim().replace(/\s{2,}|\n|\r/g, "\n"):null
      }else{
          edt1=e.id
          val=row[att]
      }
      if(val!==edt1){
        const attrib=row[att];
        const response=await librery.axiosEdit2(service,'upd',row,edt1,att,row.produitId)
                                        
        if(response.data.err&&response.data.err!=='-1'){
          handleAlert(true,"error",response.data.err)
        }else{
        Gfunc.axiosResponse(response,()=>{
          row[att]=edt1;
          if(att!=='colorId'){
            e.target.innerHTML=(edt1!==null)?edt1:'---'
          }else{
            var objIndex = colL.findIndex((obj => obj.color ===row.colorCode));
            row.colorCode=e.color
            if(objIndex!==-1){row.color=colL[objIndex].nom}
            settest(true)
            handleChange(false)
          }
        })
        }
        if(response.data.err) {e.target.innerHTML=attrib}
      }else {e.target.innerHTML=edt1}
  },[librery,Gfunc,colL,handleChange]);

  const handleOnFocus=(e)=>{
    var txt=e.target.innerHTML
    if(txt==='---'){
      e.target.innerHTML=''
    }else{
       e.target.innerHTML=txt
    }
  }

  const handleDel=async(row)=>{
    const x=window.confirm("Êtes-vous sûrs de supprimer cet élément?");
    if(x){
      const response=await Gfunc.axiosPostAction(service,"delprdcat",{produitId:row.produitId,catalogId:parseInt(countp.DT_RowId.substr(1))})
      if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,"error",response.data.err)
      }else{
      Gfunc.axiosResponse(response,()=>{
        if(response.data.jData===true){
          handleAlert(true,"success"," Le produit a été supprimé avec succès")
          var objIndex = prod.findIndex((obj => obj.produitId ===row.produitId));
          if(objIndex!==-1){
            prod.splice(objIndex,1)
          }
        }
      })
      }
    }
  }

  const handleMrqV=async(row)=>{
      const response=await Gfunc.axiosPostAction(service,"setpunviw",{produitId:row.produitId})
      if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,"error",response.data.err)
      }else{
      Gfunc.axiosResponse(response,()=>{
        handleAlert(true,"success",'Le produit a été marqué comme non vue.')
      })
      }
    
  }

  const flagMan = useCallback(async(e,row,att)=>{
    const response= await Gfunc.axiosPostAction('flagman.php','1',({ srv:srv, id: row.produitId,att:att, val:(row[att] ===true)?1:0 }))
    if(response.data.err&&response.data.err!=="-1"){
      handleAlert(true,'error',response.data.err)
      row[att]=row[att]
    }else{
      Gfunc.axiosResponse(response,()=>{
        if(response.data.jData===true){
          row[att]=!row[att]
        }
              })
    }  
  },[Gfunc])

  return (
      <div className="content">
        <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA}/>
        <div id='div-onl'>
          <div>
           <div id='div-cat'>
           {
            data.map((el)=>{
              return(
                <Card onClick={()=>handlePrdCat(el)} id={'card-cat-onl'} className="card">
                  <p style={{marginBottom:'3px'}}>{el.titre_fr}</p>
                  <p style={{marginBottom:'5px'}}>{el.titre_ar}</p>
                  <img src={param.CatImg+el.DT_RowId.substr(1)+'.png'} style={{height:'70px'}}
                       onError={(e) => {
                                e.target.onerror = null
                                e.target.src = Err1 }}/>
                </Card>
              )
            })
           }
           </div>
          </div>
          {
            (loadingExp)?<div id="empty-expender"><CircularProgress /></div>:
            <div>
                   
            <div id="onl-content">
              <div id='div-nbr-prd'>
                {countp.titre_fr} : {prod.length} produit(s) disponibles
                <ProdAdd handleAlert={handleAlert} countp={countp} handlePrdCat={handlePrdCat}/>
              </div>
              {(prod.length!==0)?
              <div style={{maxHeight:'610px',overflowY:'auto'}}>
                <DragDropContext onDragEnd={e=>handleDragEnd(e,countp)}>
                <Droppable droppableId="droppable-1">
                  {(provider) => (
                    <div
                      ref={provider.innerRef}
                      {...provider.droppableProps}
                    >
                      {prod.map((el,index) => (
                        <Draggable
                          key={el.produitId}
                          draggableId={el.produitId.toString()}
                          index={index}
                        >
                          {(provider) => (
                            <div  {...provider.draggableProps} ref={provider.innerRef} >
                              <div id='onl-prod'>
                                  <div  {...provider.dragHandleProps} ref={provider.innerRef} title='Glisser'><DragIndicator/></div>
                                  <img src={param.ProdImg+el.mainPhotoId+'.jpg'} style={{height:'100px'}}
                                       onError={(e) => {
                                       e.target.onerror = null
                                       e.target.src = Err1 }}/>
                                  <div>
                                      <div id='div-name1' >
                                       <div title={(el.color!==null)?el.color:"Aucune"} key={Math.floor(Math.random() * (1000 - 1 + 1)) + 1} >
                                          <DropdownColor data={el} col={colL} handleOnEdit={handleOnEdit} style={{height:'300px'}}/>
                                        </div>
                                        <ContentEditable
                                          html={(el.nameFr!==null)?el.nameFr:'---'} data-column="item" onFocus={e=>{handleOnFocus(e)}}
                                          className="content-editable-prod-name" disabled={false} onBlur={e => handleOnEdit(e,el,"nameFr")}
                                        />
                                        <div id='prixs'>
                                        <div style={{textAlign:'center'}}>
                                            <p id='elem-title'>Prix gros</p>
                                            <InputNbrEditable
                                                  service={service} action='upd' min={1} className='content-editable-prod'
                                                  row={el} att="wholePrice" value={(el.wholePrice)?el.wholePrice:0} id={el.produitId} idi={'produitId'}
                                                  handleAlert={handleAlert}
                                                            />
                                        </div>
                                        <div style={{textAlign:'center'}}>
                                            <p id='elem-title'>Prix détail</p>
                                            <InputNbrEditable
                                                  service={service} action='upd' min={1} className='content-editable-prod'
                                                  row={el} att="retailPrice" value={(el.retailPrice)?el.retailPrice:0} id={el.produitId} idi={'produitId'}
                                                  handleAlert={handleAlert}
                                                            />
                                        </div>
                                      </div>
                                      </div>
                                    <div id='div-det-prd'>
                                    <div id='det-prd'>
                                      <FormControlLabel  label="Non disponible" 
                                            control={<Checkbox  defaultChecked={el.unavailable} style={{transform: "scale(0.9)"}} 
                                            onChange={e=>{flagMan(e,el,"unavailable")}} inputProps={{ 'aria-label': 'controlled' }}  />}
                                          />  
                                      <FormControlLabel  label="Nouveau" 
                                            control={<Checkbox  defaultChecked={el.new} style={{transform: "scale(0.9)"}} 
                                            onChange={e=>{flagMan(e,el,"new")}} inputProps={{ 'aria-label': 'controlled' }}  />}
                                          />  
                                      <FormControlLabel  label="Promo" 
                                            control={<Checkbox   defaultChecked={el.promo} style={{transform: "scale(0.9)"}} 
                                            onChange={e=>{flagMan(e,el,"promo")}} inputProps={{ 'aria-label': 'controlled' }}  />}
                                          />   
                                      <Tooltip title="Marquer le produit comme non vue">
                                        <Button className={classes.iconBtnBig} variant="contained"  onClick={()=>{handleMrqV(el)}} color="primary">
                                          <VisibilityOff fontSize="small"/>
                                        </Button>
                                      </Tooltip>
                                      <Tooltip  title="Supprimer le produit du catalogue">
                                        <Button className={classes.iconBtnBig} variant="contained"  onClick={()=>{handleDel(el)}} color="primary" >
                                          <Delete fontSize="small"/>
                                        </Button>
                                      </Tooltip>
                                      <CatAdd data={el} handleAlert={handleAlert} fetch={fetch} from='online'/>
                                      <Tooltip  title="Trier le produit dans le catalogue">
                                        <CatOrdre row={el} handleAlert={handleAlert} fetch={handlePrdCat} countp={countp}/>
                                      </Tooltip>
                                      <div id='div-interc0'>
                                        <Favories nbr={el.loves} row={el} from='online' />{el.loves}
                                        <Comments nbr={el.comments} row={el} from='online'/>{el.comments}
                                      </div>
                                  </div>
                                  </div>
                                  </div>
                                  
                                </div>
                            {(index!==prod.length-1)?<div style={{width:'80%',marginLeft:'auto',marginRight:'auto',marginBottom:'5px'}}><Divider /></div>:<></>  }
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provider.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
            :<p id='ErreurData'>{param.ErreurData}</p>
         }          
        </div>
            </div>
          }
        
        </div>
      </div>
    );
}
