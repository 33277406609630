import React from "react";
import { styled, useTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Circle from "@material-ui/icons/FiberManualRecord";
import ArrowRight from "@material-ui/icons/ArrowRight";
import Divider from "@mui/material/Divider";
import { NavLink} from 'react-router-dom';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import FaceIcon from '@material-ui/icons/Face';
import ExitToApp from '@material-ui/icons/ExitToApp';
import { createAppTheme } from '../css/theme'
import '../css/index.css'
import DialogPass from '../components/changepass'
const Gfunc =require("../Gfunc") 

const drawerWidth = 240;
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open"
})(({theme,open}) => ({
  background:theme.palette.primary.main,
  color:"white",
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    background:theme.palette.primary.main,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}));

const DrawerHeader = styled("div")(({theme}) => ({
  display: "flex !important",
  alignItems: "center !important",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end !important"
}));


export default function PersistentDrawerLeft(props) {
  const themes = useTheme();
  const [open, setOpen] = React.useState(false);
  const [openM, setOpenM] = React.useState(false);
  const [clicked, setClicked] = React.useState('');
  const [scope, setScope] = React.useState(props.scope);
  const theme = createAppTheme(props.param.app === "1")
  
  /*useEffect(() => {
    fetch(1);
  }, [fetch]);*/

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleOpenSubM = () => {
    setOpenM(!openM);
  };

  return (
    <ThemeProvider theme={theme}>
    <Box sx={{ display: "block" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div" >
            <div className="flex-wrapper">
           <p> {props.menu[props.cscope][3]}</p><ArrowRight/><p>{(scope)?props.menu[scope][3]:props.menu[props.dp[props.cscope]][3]}</p>
           </div>
          </Typography>
          <div id="minfos" >
            <FaceIcon style={{ fontSize: 18,color: 'white' }} />
            <DialogPass getInfo={Gfunc.getInfo}/>
            <ExitToApp style={{ color: 'white',fontSize: 19, cursor: 'pointer' }} onClick={props.logout} titleAccess="Déconnexion" />
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box !important"
          }
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {themes.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
              {(props.menu!==undefined)?
                     Object.keys(props.menu).map((key, index)=> {
                            var value=props.menu[key];
                            if(value[2]===1){
                              return <ListItem button key={index}  sx={{padding:"10px 15px !important"}} onClick={handleOpenSubM} >
                              <ListItemIcon sx={{minWidth:'40px'}}>
                              {(value[0]===props.cscope) ? <Circle fontSize="small" style={{color:theme.palette.primary.main}}/> :null}
                              </ListItemIcon>
                              <ListItemText primary={<NavLink  activeClassName="mselected" className="link" aria-current="page" to={'/'+key+'/'+props.dp[key]} id={key}  onClick={()=>{props.hendleSub(key);setClicked(key);setScope('')}}>{value[3]}</NavLink>
                              } />
                              {(value[0]===props.cscope)? <ExpandLess />:<ExpandMore />}
                              </ListItem>
                            } else return (
                              (<Collapse key={index} in={value[2]===2 &&value[0]===props.cscope} timeout="auto" unmountOnExit>
                              <ListItem button sx={{ pl: 4 }}>
                                  <ListItemIcon>
                                  {((key===props.dp[value[0]]&&clicked===value[0])||(key===props.scope&&!clicked)) ? <ArrowRight fontSize="large" style={{color:theme.palette.primary.main}}/> :null}
                                  </ListItemIcon>
                                  <ListItemText primary={<NavLink id={key} activeClassName='mselected2' className="link2" aria-current="page" to={'/'+props.cscope+'/'+key} onClick={()=>{props.hendleSubsub(key);setClicked('');setScope(key)}}>{value[3] }</NavLink>} />
                                </ListItem>
                            </Collapse>)
                            ) ;
                    }):null}
        </List>
      </Drawer>
    </Box>
    </ThemeProvider>
  );
}

