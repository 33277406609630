import React,{useState,useRef} from 'react';
import {DialogActions,DialogContent,DialogTitle,Paper,Button,Dialog,TextField} from '@material-ui/core';
import Draggable from 'react-draggable';
import { DoneAll } from '@material-ui/icons';
import { ValidatorForm} from 'react-material-ui-form-validator';
import AddIcon from '@material-ui/icons/Add';
import {axiosPostAction,axiosResponse} from'../../../Gfunc'
import { useStyles,paperPropsSmall } from '../../../css/muiStyle';
import {AlertCompo} from '../../../js/components'


//Add a new role
function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function Adm_rol_add(props) {
    const [name,setName]=useState('');
    const [note,setNote]=useState('');
    const [open, setOpen] = React.useState(false);
    const [openA, setOpenA] =useState(false);
    const [messageA, setMessageA] =useState('');
    const [severity, setSeverity] =useState('info');
    const nom="role"
    const addMessage="Ajouter un nouveau "+nom
    const labelText="Nom"
    const labelTextN='Note'
    const service="_rol.php"
    const classes=useStyles();

    
    const handleClickOpen = () => { 
      setName('');
      setNote('');
      setOpen(true); 
      
    };
    const handleClose = () => { setOpen(false); };

    const handleAlert=(open,severity,message)=>{
      setOpenA(true)
      setSeverity(severity)
      setMessageA(message)
      setTimeout(function(){
        setOpenA(false)
      }, 5000);
    }
    const handleSubmit= async(e) =>{
        e.preventDefault();
        const data={name:name,note:note}
        const respon=await axiosPostAction(service,"add",data)
        if(respon.data.err&&respon.data.err!=='-1'){
          handleAlert(true,"error",respon.data.err)
        }
        else{
          axiosResponse(respon,()=>{
                setOpen(false);
                if (respon.data.jData){
                  setOpen(false)
                  props.handleAlert(true,'success','Le role a été ajouté')
                  props.callback();
                } 
          })
      }
    }
  return (
    <div>
      <Button className={classes.topTabBtn} variant="contained" color="secondary" onClick={handleClickOpen} startIcon={<AddIcon/>}>NOUVEAU ROLE</Button>
      <Dialog
            open={open}
            onClose={handleClose}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={paperPropsSmall}
       >
        <DialogTitle className={classes.topDivTitle} id="draggable-dialog-title" >{addMessage}</DialogTitle>
        <ValidatorForm ref={useRef(null)} onSubmit={handleSubmit} onError={errors => console.log(errors)} >
            <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA}/>
            <DialogContent>
              <TextField  variant="outlined" className='smallText' size="small"  label={labelText} name="name" value={name} 
                          onChange={e =>setName(e.target.value)} fullWidth autoFocus required inputProps={{ tabIndex: "1" }} />
                          
              <TextField className='smallNote' label={labelTextN} id="iNote" name="iNote" value={note} margin='dense' 
                         variant='outlined' onChange={(e)=>{setNote(e.target.value);}}  multiline fullWidth minRows={3} />
            </DialogContent>
            <DialogActions>
              <Button variant="contained" type='submit' color="primary" startIcon={<DoneAll/>}>VALIDER</Button>
              <Button variant="contained"  onClick={handleClose} color="secondary">FERMER</Button>
            </DialogActions>
        </ValidatorForm>
      </Dialog>
      </div>
  );
}
