import React,{useState,useRef,useCallback} from 'react';
import {DialogActions,DialogContent,DialogTitle,Paper,Button,Dialog,TextField} from '@material-ui/core';
import Draggable from 'react-draggable';
import {DoneAll,Add} from '@material-ui/icons';
import { ValidatorForm} from 'react-material-ui-form-validator';
import { useStyles,paperPropsUpd } from '../../../css/muiStyle';
import {AlertCompo} from '../../../js/components'
import Autocomplete from '@mui/material/Autocomplete';
import { useContext } from 'react';
import ParamContext from '../../../Contexte';
import axios from "axios"
import '../../../css/animation.css'


//Add a new role
function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function Cat_dig_add(props) {
    const Gfunc=require('../../../Gfunc')
    const param=useContext(ParamContext);
    const [titreFr,setTitreFr]=useState('');
    const [titreAr,setTitreAr]=useState('');
    const [titreEn,setTitreEn]=useState('');
    const [disFr,setDisFr]=useState('');
    const [disAr,setDisAr]=useState('');
    const [disEn,setDisEn]=useState('');
    const [cat,setCat]=useState([]);
    const [prodAdd,setProdAdd]=useState([]);
    const [after,setAfter]=useState([]);
    const [libId,setLibId]=useState([]);
    const [selectlibId,setSelectLibId]=useState('');
    const [ordre,setOrdre]=useState('');
    const [selectCat,setSelectCat]=useState('');
    const [prix,setPrix]=useState('');
    const [open, setOpen] = useState(false);
    const [errCat, setErrCat] = useState(false);
    const [openA, setOpenA] =useState(false);
    const [messageA, setMessageA] =useState('');
    const [severity, setSeverity] =useState('info');
    const service="_dig.php"
    const classes=useStyles();

   // const isDesktopOrLaptop = useMediaQuery({query: '(max-width: 600px)'})

const handleAlert=(open,severity,message)=>{
      setOpenA(true)
      setSeverity(severity)
      setMessageA(message)
      setTimeout(function(){
        setOpenA(false)
      }, 5000);
      }
const handleClickOpen = () => { 
        setOpen(true); setErrCat(false);setAfter([])
        setTitreAr('');setTitreEn('');setTitreFr('');setOrdre('');setDisAr('');setDisEn('');setDisFr('');setPrix('');setSelectCat('');
    }
const handleClose = () => { setOpen(false); };


const fetchCat= useCallback(async() => {
      const response = await axios.get( param.urlService+service+'?do=getcats',{ headers: Gfunc.getHeader()});
      if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,"error",response.data.err)
      }else{
       Gfunc.axiosResponse(response,()=>{
          setCat(response.data.jData)  
       })
    }
},[Gfunc,param.urlService]);
  
//remplir Select catalogue
const handleOpenCat= useCallback(async () => {
  if(cat.length===0) {fetchCat()}
},[cat,fetchCat])

const fetchLib= useCallback(async() => {
      const response = await axios.get( param.urlService+service+'?do=getlborods',{ headers: Gfunc.getHeader()});
      if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,"error",response.data.err)
      }else{
       Gfunc.axiosResponse(response,()=>{
          setLibId(response.data.jData)  
       })
    }
},[Gfunc,param.urlService]);
  
//remplir Select catalogue
const handleOpenLib= useCallback(async () => {
  if(libId.length===0) {fetchLib()}
},[libId,fetchLib])

const fetchProd= useCallback(async() => {
      const response = await axios.get( param.urlService+service+'?do=getprodtoadd',{ headers: Gfunc.getHeader()});
      if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,"error",response.data.err)
      }else{
       Gfunc.axiosResponse(response,()=>{
        response.data.jData.sort((a, b) => {
          const elementA = a.nom.toLowerCase();
          const elementB = b.nom.toLowerCase();
          if (elementA < elementB) {return -1;}
          if (elementA > elementB) {return 1;}
          return 0; 
        });            
        setProdAdd(response.data.jData)  
       })
    }
},[Gfunc,param.urlService]);
  
//remplir Select catalogue
const handleOpenProd= useCallback(async () => {
  if(prodAdd.length===0) {fetchProd()}
},[prodAdd,fetchProd])

const fetchAfter= useCallback(async() => {
      if(selectCat===''){setErrCat(true)}
      if(selectCat!==''){
        const objIndex = cat.findIndex((obj => obj.name ===selectCat));
        const value=cat[objIndex].id
        const response = await Gfunc.axiosPostAction(service ,'gets',{cat:value})      
        if(response.data.err&&response.data.err!=='-1'){
          handleAlert(true,"error",response.data.err)
        }else{
          Gfunc.axiosResponse(response,()=>{
            setAfter(response.data.jData)  
          })
      }
    }
},[Gfunc,selectCat,cat]);
  
//remplir Select after
const handleOpenAfter= useCallback(async () => {
  if(after.length===0) {fetchAfter()}
},[after,fetchAfter,selectCat])

const handleSubmit= async(e) =>{
    var value='';var value1='' ;var value2=''
    if(selectCat!==''){
      const objIndex = cat.findIndex((obj => obj.name ===selectCat));
      value=cat[objIndex].id
    }
    if(selectlibId!==''){
      const ind= libId.findIndex(obj => obj.nom === selectlibId);
      value1=libId[ind].id
    } 
    if(ordre!==''){
     const indd= after.findIndex(obj => obj.nom === ordre);
     value2=after[indd].id
    }
  
    const response = await Gfunc.axiosPostAction(service ,'addprodtocat',
                   {prix:parseFloat(prix),liberoId:value1,before_that:value2,nomFr:titreFr,nomAr:titreAr,
                     nomEn:titreEn,descFr:disFr,descAr:disAr,descEn:disEn,catalogId:value})
      if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,"error",response.data.err)
      }else{
      Gfunc.axiosResponse(response,()=>{
        if(response.data.jData){
          setOpen(false)
          props.fetch(0,value)
        }
      })
      }
   }

  const handleInputChange = (event, newInputValue) => {
    setTitreFr(newInputValue);
  };

  const handleProductChange = (event, newSelectedProduct) => {
    setTitreFr(newSelectedProduct);
  };

 


  return (
    <div >
      <Button className={classes.topTabBtn} variant="contained" color="secondary" onClick={handleClickOpen} 
              startIcon={<Add/>}>Nouveau produit</Button>
      <Dialog
            id='dig-add'
            open={open}
            onClose={handleClose}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={paperPropsUpd}
       >
       <DialogTitle className={classes.topDivTitle} id="draggable-dialog-title" >Ajouter un nouveau produit</DialogTitle>
       <ValidatorForm ref={useRef(null)} id='cat-form' onSubmit={handleSubmit} >
        <DialogContent>
          <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA}/>
          <div id='catalog'>
            <div id='row-1'>
                <Autocomplete
                  disableClearable
                  freeSolo 
                  value={titreFr}
                  onChange={handleProductChange}
                  inputValue={titreFr}
                  onInputChange={handleInputChange}
                  onFocus={handleOpenProd}
                  options={prodAdd.map((product) => product.nom)} 
                  className='smallText'
                  renderInput={(params) => (
                      <TextField required {...params}  label="Titre en français" color="primary" variant="outlined" size="small" autoFocus/>
                  )}
                />
                <TextField variant="outlined" className='smallText' size="small" id="ar" label="Titre en anglais"  value={titreEn} 
                           onChange={e =>setTitreEn(e.target.value)}  required />
              
                <TextField variant="outlined" className='smallText' size="small" id="ar" label="Titre en arabe"  value={titreAr} 
                           onChange={e =>setTitreAr(e.target.value)}  required />
            </div>
            <div id='row-2'>
               <TextField variant="outlined" type='number' className='smallText' size="small"  label="Prix"  
                           value={prix}  onChange={e =>setPrix(e.target.value)}  required />
               <Autocomplete
                  disableClearable
                  onFocus={handleOpenCat} 
                  onChange={(e,newValue) =>{setSelectCat(newValue);setErrCat(false)}}
                  options={cat.map((option) => option.name)} 
                  className='smallText' 
                  renderInput={(params) => (
                   <TextField required {...params}  label="Catalogue" color="primary" variant="outlined" size="small"  />
                )} />
               <Autocomplete
                  disableClearable
                  onChange={(e,newValue) =>{setOrdre(newValue)}} 
                  onFocus={handleOpenAfter}
                  options={after.map((option) => option.nom)} 
                  className='smallText' 
                  renderInput={(params) => (
                   <TextField  {...params}  error={errCat} helperText={(errCat)?'Vous devez choisir un catalogue en amont':null}
                               label="Après" color="primary" variant="outlined" size="small"  />
               )} />
               <Autocomplete
                 disableClearable
                 onChange={(e,newValue) =>{setSelectLibId(newValue)}} 
                 onFocus={handleOpenLib}
                 options={libId.map((option) => option.nom)}
                 className='smallText' 
                 renderInput={(params) => (
                   <TextField required  {...params} className={classes.input11} label="Libero" color="primary" variant="outlined" size="small"  />
                )} 
               />
            </div>
            <div id='row-3'>               
                <TextField variant="outlined" className='smallNote' label='Description français' size="small" value={disFr} 
                           onChange={e =>setDisFr(e.target.value)} required  multiline minRows={3}/>
                <TextField variant="outlined" className='smallNote' label='Description anglais' size="small" value={disEn} 
                           onChange={e =>setDisEn(e.target.value)}  required multiline minRows={3}/>
                <TextField variant="outlined"  className='smallNote' label='Description Arabe'  size="small"   value={disAr} 
                           onChange={e =>setDisAr(e.target.value)}  required multiline minRows={3}/>
            </div>
         </div>    
        </DialogContent>               
        <DialogActions id='btn-div'>
            <Button variant="contained" type='submit' color="primary" startIcon={<DoneAll/>}>VALIDER</Button>
            <Button variant="contained"  onClick={handleClose} color="secondary">FERMER</Button>
        </DialogActions>
        </ValidatorForm>
      </Dialog>
      </div>
  );
}
