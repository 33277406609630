import React,{ useState, useEffect ,useCallback,useMemo} from "react";
import DataTable from "react-data-table-component";
import ContentEditable from 'react-contenteditable';
import TextField from '@material-ui/core/TextField';
import {CircularProgress,Backdrop, ClickAwayListener, TextareaAutosize, Tooltip, Box} from '@material-ui/core';
import {Search,Lock,LockOpen} from '@material-ui/icons';
import DraggableDialog from './modals/adm_rol_add'
import RolDialog from './modals/adm_rol_usr'
import PrevDialog from './modals/adm_rol_privilege'
import {NotAllowedComponent,AlertCompo} from '../../js/components'
import {conditionalRowStyles,customStyles, numberColumn}from '../../css/datatable-custom'
import { useStyles ,textAreaEdit} from '../../css/muiStyle';
import { useContext } from 'react';
import ParamContext from '../../Contexte';


export default function Adm_rol(){
  const classes=useStyles();
  const param=useContext(ParamContext);
  const Gfunc=require('../../Gfunc')
  const librery=require('../../js/data_table_librery')
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errMessage,setErrMessage]=useState('');
  const [notAllowed,setNotAllowed]=useState(false);
  const [severity, setSeverity] =useState('info');
  const [openA, setOpenA] =useState(false);
  const [messageA, setMessageA] =useState('');
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [search,setSearch]=useState('');
  const [Icon,setIcon]=useState('');
  const [selecto,setSelecto]=useState('')
  const [Item,setItem]=useState('')
  const [ItemN,setItemN]=useState('')
  const [note,setNote]=useState('')
  const service='_rol.php'
  const srv='role'
  
  const handleAlert=(open,severity,message)=>{
    setOpenA(true)
    setSeverity(severity)
    setMessageA(message)
    setTimeout(function(){
      setOpenA(false)
    }, 5000);
  }
  
  const fetch= useCallback(async(page,servic = service, size = perPage) => {
    setLoading(true);
    const response=await librery.axiosGet(page,servic,size)
    setLoading(false);
    if(response.data.err&&response.data.err!=='-1'){
      setErrMessage(response.data.err)
      setNotAllowed(true)
    }
    else{
    Gfunc.axiosResponse(response,()=>{
      if(response.data.data!==undefined){
        setData(response.data.data);
        setTotalRows(response.data.recordsFiltered);
      }
    })
   }
  },[Gfunc,perPage,librery]);

  useEffect(() => {
    fetch(1);
  }, [fetch]);
  
  const flagMan = useCallback(async( row)=>{
    const response= await Gfunc.axiosPostAction('flagman.php','1',({ srv:srv, id: row.DT_RowId.substr(1),att:'activ', val: row.activ }))
    Gfunc.axiosResponse(response,()=>{
        setIcon(row.DT_RowId) 
            })
  },[Gfunc]);

  //update field
  const handleOnEdit=useCallback(async(e,row,att)=>{
    const edt1=e.target.innerHTML
    const edt=Gfunc.DeleteSpace(edt1);
    if(att==='nom' ){
      if(row.role!==edt){
        const attrib=row.role;
        const response=await librery.axiosEdit(service,row,edt,att) 
        if(response.data.err&&response.data.err!=='-1'){
          handleAlert(true,"error",response.data.err)
        }else{
        Gfunc.axiosResponse(response,()=>{ row.role=edt ;e.target.innerHTML=edt})
        }
        if(response.data.err) {e.target.innerHTML=attrib}
      }else{e.target.innerHTML=edt}
    }
    else
        if(row[att]!==edt){
          const attrib=row[att];
          const response=await librery.axiosEdit(service,row,edt,att) 
          if(response.data.err&&response.data.err!=='-1'){
            handleAlert(true,"error",response.data.err)
          }else{
          Gfunc.axiosResponse(response,()=>{row[att]=edt;e.target.innerHTML=edt})
          }
          if(response.data.err) {e.target.innerHTML=attrib}
        }else {e.target.innerHTML=edt}
  },[librery,Gfunc]);

  const handleLockIcon=useCallback((val)=>{
      setIcon(val);
  },[]);
  
  useEffect((val) => {
    handleLockIcon(val);
  }, [handleLockIcon]);

  const handleSelect=useCallback((row,att)=>{
    setSelecto(row[att])
    setItem(row.DT_RowId)
    setItemN(att)
  },[])

  //handle update note
  const handleOnEditNote=useCallback(async(note,row,att,action)=>{
      const edt1=note
      var edt=Gfunc.DeleteSpace(edt1);
      if(row[att]!==edt){
        edt=edt.replaceAll("\n", "\r")
        const attrib=row[att];
        const response=await librery.axiosEdit2(service,action,row,edt,att) 
        if(response.data.err&&response.data.err!=='-1'){
          handleAlert(true,'error',response.data.err)
        }else
        Gfunc.axiosResponse(response,()=>{row[att]=edt})
        if(response.data.err&&response.data.err!=="-1") {row[att]=attrib}
      }else {row[att]=edt}
      setNote('')
      setSelecto('')
    },[librery,Gfunc]);


  const columns =useMemo(
    () => [   
      {
          name: "NOM",
          selector: row=>row.role,
          sortable: true,
          sortField:"role",
          minWidth:'150px !important',
          cell: (row,column) => {
            return (
              <Tooltip title={row.role}>
                <ContentEditable
                    html={(row.role)?row.role:''}
                    name="rol"
                    data-column="item"
                    className="content-editable-edit"
                    disabled={false} 
                    onBlur={e => handleOnEdit(e,row,"nom")}
                  />
              </Tooltip>
             );
           },
      },
      {
        name: "DATE",
        selector: row=>row.datin,
        sortable: true,
        minWidth:'100px !important',
        sortField:"datin",
      },
      {
        name: "UTILISATEUR",
        selector:row=>row.user,
        sortable: true,      
        minWidth:'125px !important',
        sortField:"user",
      },
      {
        name: "Note",
        selector:row=>row.note,
        sortable: true,
        sortField:"note",
        minWidth:'180px !important',
        cell: (row) => {
          return (
            <div className="note-container">
              { (row.note===selecto && ItemN==='note' && row.DT_RowId===Item)?
              <ClickAwayListener onClickAway={()=>{(note)?handleOnEditNote(note,row,"note",'upd'):setSelecto('')}}>
                <TextareaAutosize style={textAreaEdit} value={note}  onChange={(e)=>{setNote(e.target.value);}}/>
              </ClickAwayListener>:
              ((row.note&&row.note.match(/\r/g) || []).length>=1)?
              <Tooltip title={row.note.replaceAll("\r", "/ ")}>
                  <div className="content-editable-edit" onClick={()=>{setNote(row.note);handleSelect(row,'note')}}>{row.note.substring(0, row.note.indexOf("\r"))+"..."}</div>
              </Tooltip>:
                <Tooltip title={(row.note)?row.note:''}>
              <div className="content-editable-edit" onClick={()=>{setNote(row.note);handleSelect(row,'note')}} >{row.note}</div></Tooltip>}
            </div>
           );
         },
      },
      {
        name: "privilèges",
        selector:row=>row.nbPrivs,
        sortable: true,
        minWidth:'115px !important',
        style:numberColumn,  
        sortField:"nbPrivs",     
      },
      {
        width:'80px !important',
        cell: (row) => {
          if(Icon===row.DT_RowId){
            if(row.activ===1)
            row.activ=0
            else
            row.activ=1   
            setTimeout(() => { handleLockIcon('')},100)         
          }
          return (
            <div id="icons">
              <div className='rowGrid' id="row-11">
                <PrevDialog handleAlert={handleAlert} row={row} callback={()=>fetch(1)} />
                <RolDialog handleAlert={handleAlert} row={row}/>
                <Tooltip title="Activer / Desactiver">
                  <div onClick={()=>flagMan(row)}>{(row.activ)?
                   <LockOpen className={classes.redIcon} fontSize="small"/>:
                   <Lock className={classes.greenIcon} fontSize="small"/> }
                  </div>  
                </Tooltip>      
              </div>
            </div>
          );
         },
      }   
  ],[Icon,classes,note,selecto,Item,ItemN,flagMan,handleOnEdit,handleLockIcon,fetch,handleOnEditNote,handleSelect]);
  
 
  const handleOnSort=async(column, sortdirection,page=currentPage, size = perPage)=>{
      setLoading(true);
      const response=await librery.axiosSort(column,sortdirection,page,size,service,'get')
      setLoading(false);
      if(response.data.err&&response.data.err!=='-1'){
         handleAlert(true,'error',response.data.err)
      }else{
      Gfunc.axiosResponse(response,()=>{
        setData(response.data.data);
        setTotalRows(response.data.recordsFiltered);
      })  
    }   
  }

  const handleSearch=async(e,page=currentPage, size = perPage)=>{
    setLoading(true);
    const recherche=search;
    const response=await librery.axiosSearch(service,recherche,page,size,'get')
    setLoading(false);
    if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,'error',response.data.err)
    }else{
    Gfunc.axiosResponse(response,()=>{
      setData(response.data.data);
      setTotalRows(response.data.recordsFiltered);
    }) 
  }  
    return recherche
  }

  function handlePageChange (page) {
    fetch(page,service,perPage);
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    fetch(page,service, newPerPage);
    setPerPage(newPerPage);
  };

  function liveSearch(e,search){
    if(param.liveSearch&&search.length>3){
      e.preventDefault();
      handleSearch();
    }else{ if (e.keyCode === 13) {
              e.preventDefault();
              handleSearch();
            }
    }
  }

  return (
    <div className="content">
    <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA}/>
    <Backdrop className={classes.backdrop} open={loading} >
        <CircularProgress color="inherit" />
    </Backdrop>
    <div className='table-header'>
         <DraggableDialog callback={()=>fetch(currentPage)} handleAlert={handleAlert}/>
         { 
          (notAllowed===false)?
           <div className='search'>
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <TextField className={classes.search} variant="outlined"  label="Recherche" value={search} 
                               InputProps={{startAdornment: (<Search onClick={() => handleSearch()}
                               style={{ marginRight: '5px',zIndex:'1000',width:'20px',height:'20px',cursor:"pointer"}} />),}}
                               size="small" margin='none'  onKeyUp={e => liveSearch(e)} onChange={e => setSearch(e.target.value)} /> 
              </Box>
            </div>:null}
        </div>
        
       {
        (notAllowed===false)?
        <DataTable 
              className="table"
              columns={columns}
              data={data}
              noHeader={true}
              defaultSortAsc={true}
              persistTableHead={true}
              noDataComponent={param.ErreurData}
              paginationRowsPerPageOptions={[10,30,50,100]}
              //progressPending={loading} 
              progressComponent={<CircularProgress className={classes.circularProgress} />}
              pagination
              paginationServer
              paginationTotalRows={totalRows}
              paginationDefaultPage={currentPage}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              sortServer={true}
              onSort={handleOnSort}
              highlightOnHover
              customStyles={customStyles}
              paginationComponentOptions={{ rowsPerPageText: 'Élément par page:', rangeSeparatorText: 'sur', selectAllRowsItem: true,selectAllRowsItemText: 'Tous'}}
              conditionalRowStyles = { conditionalRowStyles }
        />:<div className='compAllow'><NotAllowedComponent message={errMessage}/></div>
       }
    </div>    

  );
}