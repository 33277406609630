import { createTheme } from "@material-ui/core";


export const palette1 = {
  primary: { light: "#dcedc8", main: "#4cb050", dark: "#086634" },//vert
  secondary: { light: "#f6f6f6", main: "#f0f0f0", dark: "#434343" },//gris
  error: { main: "#e53935" },//rouge foncé
  success: { main: "#388e3c" },//vert
  bleu: { light: '#c5cae9', main: '#3f51b5', dark: "#0e073a" }
};

export const palette2 = {
  primary: { light: "#BECFF2", main: "#2D65D6", dark: "#1D4189" }, // red
  secondary: { light: "#f6f6f6", main: "#f0f0f0", dark: "#434343" }, // blue
  error: { main: "#f44336" }, // rouge foncé
  success: { main: "#4caf50" }, // vert
  jaune: { light: '#fff9c4', main: '#ffeb3b', dark: "#fbc02d" } // custom color
};

export const createAppTheme = (usePalette1) => {
  const palette = usePalette1 ? palette1 : palette2;

  return createTheme({
    palette: palette,
    typography: {
      button: {
        fontSize: 13,
        fontWeight: "bold",
      }
    },
    overrides: {
      MuiAutocomplete: {
        paper: {
          width:'fit-content',
          minWidth:'100%',
          whiteSpace:"nowrap",
        },
        option: {
          background:'white !important',
          '&[data-focus="true"]': {
            backgroundColor: '#eee !important',
          },
        },
      },
    },
  })
}