import React ,{useCallback,useState,useMemo,useEffect} from "react";
import {Search, Send} from '@material-ui/icons';
import {Grid,CircularProgress, Checkbox, FormControlLabel, TablePagination, Button, Backdrop, Divider, Box} from '@material-ui/core';
import { useStyles } from '../../css/muiStyle';
import TextField from '@material-ui/core/TextField';
import {AlertCompo} from "../../js/components"
import { useContext } from 'react';
import ParamContext from '../../Contexte';
import Personn from '../../img/person.png'
import Autocomplete from '@mui/material/Autocomplete';
import CompReceive from '../content/modals/ani_ntf_compte_notif'


export default function Ani_ntf(){
   const classes = useStyles();
   const Gfunc=require('../../Gfunc')
   const param=useContext(ParamContext);
   const librery=require('../../js/data_table_librery')
   const [received,setreceived]=useState([])
   const [cpt,setCpt]=useState([])
   const [fltr,setFtlr]=useState([])
   const [news,setNews]=useState([])
   const [modl,setModl]=useState([])
   const [selectFiltre,setSelectFiltre]=useState('')
   const [selectedNew,setSelectedNew]=useState('')
   const [selectModele,setSelectModele]=useState('')
   const [img,setImg]=useState('')
   const [search,setSearch]=useState('')
   const [respons,setRespons]=useState(false)
   const [loading, setLoading] = useState(false);
   const [called, setCalled] = useState(false);
   const [checked, setChecked] = useState(false);
   const [check, setCheck] = useState([]);
   const [totalRows, setTotalRows] = useState(0);
   const [perPage, setPerPage] = useState(10);
   const [currentPage, setCurrentPage] = useState(1);
   const [openA, setOpenA] =useState(false);
   //const [errMessage,setErrMessage]=useState('');
   const [notAllowed,setNotAllowed]=useState(false);
   const [messageA, setMessageA] =useState('');
   const [severity, setSeverity] =useState('info');
   const service='_ntf.php'
  

  const handleAlert=(open,severity,message)=>{
    setOpenA(true)
    setSeverity(severity)
    setMessageA(message)
    setTimeout(function(){
      setOpenA(false)
    }, 5000);
  }
  
  const fetch= useCallback(async(page,servic = '_cpt.php', size = perPage,val,filtre) => {
    setCalled(true)
    setLoading(true);
    const response=await librery.axiosGet(page+'&search='+((val!==undefined)?val:'')+'&filter='+((filtre!=='empty'&&filtre!==undefined)?filtre:''),servic,size)
    setLoading(false);
    if(response.data.err&&response.data.err!=='-1'){
      //setErrMessage(response.data.err)
      setNotAllowed(true)
    }else{
      Gfunc.axiosResponse(response,()=>{
        setTotalRows(response.data.recordsFiltered)
            if(response.data.data){
                setCpt(response.data.data)
            }
        })
    }
},[Gfunc,param.urlService,perPage]);
 
useEffect(() => {
    if(!called) fetch(1);
  },[fetch,called]);
 
const handleSearch=async(size = perPage)=>{
     setCpt([])
     setLoading(true);
     const recherche=search;
     const response=await librery.axiosSearch('_cpt.php',recherche,1,size,'get')
     setLoading(false);
     if(response.data.err&&response.data.err!=='-1'){
       handleAlert(true,'error',response.data.err)
     }else{
     Gfunc.axiosResponse(response,()=>{
        setTotalRows(response.data.recordsFiltered);
        if(response.data.data){
            setCpt(response.data.data);
        }
     })   
    }
     return recherche
}

function liveSearch(e,search){
  if(param.liveSearch&&search.length>3){
     e.preventDefault();
     handleSearch();
  }else{ if (e.keyCode === 13) {
            e.preventDefault();
            handleSearch();
          }
  }
}

const handleChange=useCallback((e,val)=>{
    setFtlr([])
    const ind=check.findIndex((obj => obj.DT_RowId ===val.DT_RowId));
    if(e.target.checked===true ){
       if(ind===-1){check.push(val)}
    }
    else{
        check.splice(ind,1)
    }
    setCheck(check)
},[check])

const handleChangePage = (event, newPage) => {
    setChecked(false);setRespons(false)
    fetch(newPage+1,'_cpt.php',perPage);
    setCurrentPage(newPage+1);
  };
  
const handleChangeRowsPerPage = (event) => {
    setChecked(false);setRespons(false)
    fetch(1,'_cpt.php',(event.target.value==="Tous")?-1:parseInt(event.target.value,10));
    setPerPage((event.target.value==="Tous")?totalRows:event.target.value);
    setCurrentPage(1);
};

 //fetch modeles
const fetchModele= useCallback(async(serv=service, act = "gets") => {
    const response = await Gfunc.axiosGetAction(serv,act);
    if(response.data.err&&response.data.err!=='-1'){
    handleAlert(true,'error',response.data.err)
    }else
    Gfunc.axiosResponse(response,()=>{
      response.data.jData.unshift({id:'',nom:"Aucun"})
        setModl(response.data.jData);    
    })
},[Gfunc])

const handleOpenModele= useCallback(async () => {
    if(modl.length===0) fetchModele()
},[modl,fetchModele])

 //fetch modeles
const fetchFiltre= useCallback(async(serv=service, act = "getftls") => {
    const response = await Gfunc.axiosGetAction(serv,act);
    if(response.data.err&&response.data.err!=='-1'){
    handleAlert(true,'error',response.data.err)
    }else
    Gfunc.axiosResponse(response,()=>{
        response.data.jData.unshift({id:'',nom:"Aucun"})
        setFtlr(response.data.jData);    
    })
},[Gfunc])

const handleOpenFiltre= useCallback(async () => {
  if(fltr.length===0) fetchFiltre()
},[fltr,fetchFiltre])


 //fetch modeles
const fetchNews= useCallback(async(serv="_new.php", act = "gets") => {
    const response = await Gfunc.axiosGetAction(serv,act);
    if(response.data.err&&response.data.err!=='-1'){
    handleAlert(true,'error',response.data.err)
    }else
    Gfunc.axiosResponse(response,()=>{
        response.data.jData.unshift({id:'',titre_fr:"Aucun"})
        setNews(response.data.jData);    
    })
},[Gfunc])

const handleOpenNews= useCallback(async () => {
  if(news.length===0) fetchNews()
},[news,fetchNews])




const CheckAll=useCallback(async(e)=>{
   setFtlr([])
   if(e.target.checked===true){
     setCheck(cpt)
   }else{
     setCheck([])
   }
},[cpt])

const HandleSubmit=async()=>{
    var srv='';var data={};
    if(check.length!==0){
       srv='notifs';var cl=[];
       check.map((el)=>{
        cl.push(parseInt(el.DT_RowId.substr(1)))
       })
      data = { notifId: (selectModele !== "") ? selectModele : null, ids: cl, img: img, newsId: (selectedNew === "") ? null : selectedNew.id }
    }
    if(selectFiltre!==''&&selectFiltre!=='Aucun'){
      srv='notiff';
      const index = fltr.findIndex((obj => obj.nom === selectFiltre)); 
      data = { notifId: (selectedNew === "") ? selectModele : null, filtreId: fltr[index].id, img: img, newsId: (selectedNew === "") ? null : selectedNew.id }
    }
    if(srv!==''){
        const response=await Gfunc.axiosPostAction(service,srv,data)
        if(response.data.err&&response.data.err!=='-1'){
          handleAlert(true,"error",response.data.err)
        }
        else{
          Gfunc.axiosResponse(response,()=>{
            setRespons(true)
            setreceived(response.data.jData)
            if(response.data.jData!==0){
              setCheck([]);setSelectFiltre('');setChecked(false)
              handleAlert(true,'success',response.data.jData.length+" notification(s) a/ont été envoyée(s) avec succès aux destinataires")
            }else{
              setCheck([]);setSelectFiltre('');setChecked(false)
              handleAlert(true,'warning',"Aucune notification n'a été envoyée")
            }
        })
    }
  } 
}

 return (
  <div className="content">
      <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA}/>
      <Backdrop className={classes.backdrop} open={loading} >
        <CircularProgress color="inherit" />
      </Backdrop>
      <></>
      <fieldset className="fieldsetTop" id="Con">
      <legend>Envoi des notifications</legend>
      <div id='forms-ntf'>
         <div style={{width:'100%',display:'grid',gridTemplateColumns:"30% 50%",gridGap:"10px",justifyContent:'center'}}>
         <div style={{display:'grid',gridGap:"6px"}}>
           <div >
              <Autocomplete 
                  //key={"mdl"+selectedNew}
                  disableClearable
                  value={(modl.length>0)?modl[Gfunc.getIndex(modl,selectModele,"id")].nom:null}
                  onChange={(e,newValue) =>{
                    const id=modl[Gfunc.getIndex(modl,newValue,"nom")].id;
                    setSelectModele(id);setSelectedNew("");setImg("");}} 
                  onFocus={handleOpenModele}
                  options={modl.map((option) => option.nom)} 
                  className='smallAutoFiltre'
                  renderInput={(params) => (
                   <TextField  {...params} label="Modèle" color="primary" variant="outlined" size="small" 
                               required/>
               )}
             />
            </div>
             <div>
              <Autocomplete
                  disableClearable
                  onChange={(e,newValue) =>{setSelectFiltre(newValue);setRespons(false)}} 
                  //value={selectFiltre}
                  onFocus={handleOpenFiltre}
                  options={fltr.map((option) => option.nom)} 
                  className='smallAutoFiltre'
                  disabled={(check.length>0)} 
                  renderInput={(params) => (
                   <TextField  {...params} label="Filtre" color="primary" variant="outlined" size="small" 
                               />
               )}
             />
            </div>
             <div>
              <Autocomplete
                  //key={"new"+selectModele}
                  disableClearable
                  onChange={(e,newValue) =>{
                    setSelectModele("")
                   const id = newValue.id;
                   setSelectedNew(newValue); setImg(param.NewImg + id + ".jpg"); setRespons(false)
                 }}
                 value={(selectedNew === "") ? null : selectedNew}
                  onFocus={handleOpenNews}
                 options={news}
                 getOptionLabel={(option) => (option.titre_fr + " [" + (option.date ?? "") + " ]")}
                 //getOptionSelected={(option, value) => option.id === value.id}
                  className='smallAutoFiltre'
                  //disabled={(check.length>0)} 
                  renderInput={(params) => (
                   <TextField  {...params} label="News" color="primary" variant="outlined" size="small" 
                               />
               )}
             />
             </div>
           </div> 
           <div style={{ display: "grid", gap: "5px", gridTemplateRows: "30px auto" }}>
             <div>         <TextField required value={img} onChange={e => setImg(e.target.value)} color="primary" variant="outlined" className='smallText' fullWidth label="URL de l'image" size="small" inputProps={{ /*tabIndex: "2" ,*/className: classes.input1 }} />
             </div>
             {(img === "") ?
               <div style={{ backgroundColor: '#eee', border: '1px solid rgba(0, 0, 0, 0.23)', padding: "7px", borderRadius: '8px' }}>
                 <p style={{ fontSize: '11px', marginTop: '2px', marginLeft: '2px' }}>
                   {(selectModele !== "") ? modl[modl.findIndex((obj => obj.id === selectModele))].message : '---'}
                 </p>
               </div> : <img src={img} style={{ height: "70px", margin: "auto", borderRadius: "5px" }} />}
         </div>
        </div>
        </div>
        <div>
      </div>
      <div>
            {(notAllowed===false)?
            <div>
            <div  className="table-header">
              <div className='search' >
                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <TextField className={classes.search} variant="outlined"  label="Recherche" value={search} 
                               InputProps={{startAdornment: (<Search onClick={() => handleSearch()}
                               style={{ marginRight: '5px',zIndex:'1000',width:'20px',height:'20px',cursor:"pointer"}} />),}}
                               size="small" margin='none'  onKeyUp={e => liveSearch(e)} onChange={e => setSearch(e.target.value)} /> 
                </Box>
              </div> 
            </div>
            </div>:null
            }
            {
                (cpt.length!==0)?
                <div>
                <div id='ntf-cli' disabled={selectFiltre!==''&&selectFiltre!=='Aucun'}>
                <div style={{display:'grid',gridTemplateColumns:'45% 50%',textAlign:'start',alignItems:"center",fontWeight:'bold'}}>
                    <FormControlLabel key={Math.floor(Math.random() * (1000 - 1 + 1)) + 1}
                                      control={<Checkbox  defaultChecked={checked} style={{transform: "scale(0.7)"}}
                                      onChange={e=>{CheckAll(e);setChecked(e.target.checked)}} inputProps={{ 'aria-label': 'controlled' }}  
                                      disabled={selectFiltre!==''&&selectFiltre!=='Aucun'}/>}
                                           /> 
                    <p>Choisir un / des client(s)</p>
                </div> 
                <div id='cpts-div' >
                   {
                    cpt.map((el,index)=>{
                         return(
                          <div>
                             <div id='ntf-cpt' key={"s" + index}>
                                <FormControlLabel key={Math.floor(Math.random() * (1000 - 1 + 1)) + 1}
                                                  control={<Checkbox  defaultChecked={(Gfunc.getIndex(check,el.DT_RowId,"DT_RowId"))>-1}  style={{transform: "scale(0.7)"}}
                                                  onChange={e=>{handleChange(e,el)}} inputProps={{ 'aria-label': 'controlled' }} 
                                                  disabled={selectFiltre!==''&&selectFiltre!=='Aucun'} />}
                                           />  
                                <div className="ntf-div-img">
                                 <img src={param.userProfileImg+'/'+parseInt(el.DT_RowId.substr(1))+'.jpg'} alt='' className='img-ntf' title="Image client"
                                      onError={(e) => {
                                         e.target.onerror = null
                                         e.target.src = Personn }}/> 
                                 </div>
                                 <div>
                                   <p style={{fontSize:"14px",fontWeight:'bold'}}>{el.nom+' '+el.prenom}</p>
                                   <p style={{fontSize:"12px",fontWeight:'normal'}}>{el.mag}</p>
                                 </div>
                                 <div style={{wordBreak: 'break-word'}}>
                                   <p style={{fontSize:"14px",fontWeight:'normal'}}>{el.adresse}</p>
                                 </div>
                                 <div>
                                   <p style={{fontSize:"12px",fontWeight:'normal'}}>{el.commune}</p>
                                   <p style={{fontSize:"12px",fontWeight:'normal'}}>{el.wilaya}</p>
                                 </div>
                             </div>
                             {(index+1<cpt.length)?<div style={{width:'85%',margin:'5px auto'}}><Divider/></div>:<></>}
                            </div>
                         )
                   })
                   }
                </div>
             </div>
             <TablePagination
                component="div"
                height='35px'
                labelRowsPerPage='Élément par page:'
                count={totalRows}
                rowsPerPageOptions={[10,30,50,100,'Tous']}
                page={currentPage-1}
                onPageChange={handleChangePage}
                rowsPerPage={perPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                    />
             </div>:<p style={{textAlign:"center",fontWeight:'bold',marginTop:'5px',marginBottom:'30px'}}>{param.ErreurData}</p>
            }
        </div>
        <div style={{justifyContent:'center',display:'flex',gridGap:'5px',width:'100%'}}>
              <Button variant="contained" type='submit' color="primary" className={classes.fieldButton} onClick={()=>HandleSubmit()}
                      startIcon={<Send style={{height:'18px',width:'18px'}}/>}>Envoyer</Button>
              {(respons)?<CompReceive received={received}/>:null}
        </div>
        <br/>
      </fieldset>
  </div>
  );
 }