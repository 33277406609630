  import axios from "axios"
  const param=JSON.parse(localStorage.getItem("param"));
  const Gfunc=require('../Gfunc')


  export const axiosGet=async (page,service, size)=>{
          const response = await axios.get( param.urlService+service+'?do=get&page='+page+"&per_page="+size,{ headers: Gfunc.getHeader()})
          .catch(function(error){
            error["data"]=""
            return error
          })
          return response ;
  }

  export const axiosGet2=async (page,service,action, size)=>{
    const response = await axios.get( param.urlService+service+'?do='+action+'&page='+page+"&per_page="+size,{ headers: Gfunc.getHeader()})
    .catch(function(error){
      error["data"]=""
      return error
    })
    return response ;
  }

  export function axiosEdit(service,row,edt,attt){
    const response= axios({
      method: 'POST',
      url:param.urlService+service+'?do=upd',
      headers: Gfunc.getHeader(),
      data:JSON.stringify({ id: row.DT_RowId,att: attt,val:edt})})
      .catch(function(error){
        error["data"]=""
        return error
      })
      return response;
}

export function axiosEdit2(service,action,row,edt,attt,idd){
  var id=row.DT_RowId
  if(!row.DT_RowId)
  {id='c'+idd
   edt=(typeof edt === "number")?parseInt(edt):edt
  }
  const response= axios({
    method: 'POST',
    url:param.urlService+service+'?do='+action,
    headers: Gfunc.getHeader(),
    data:JSON.stringify({ id:id,att: attt,val:edt})})
    .catch(function(error){
      error["data"]=""
      return error
    })
    return response;
}
  export function axiosEditD(service,row,edt,attt){
    const response= axios({
    method: 'POST',
    url:param.urlService+service+'?do=upd',
    headers: Gfunc.getHeader(),
    data:JSON.stringify({ id: row.DT_RowId.substr(1),att: attt,val:edt.toString()})})
    .catch(function(error){
      error["data"]=""
      return error
    })
   return response;
}
  export function axiosSort(column,sortdirection,page,size,service,action){
      const response =axios.get( param.urlService+service+'?do='+action+'&column='+column.sortField+'&direct='+sortdirection+'&page='+page+"&per_page="+size,{ headers: Gfunc.getHeader()})
      .catch(function(error){
        error["data"]=""
        return error
      })
      return response
  }

  export function axiosSearch(service,recherche,page,size,action){
    const response =axios.get( param.urlService+service+'?do='+action+'&search='+recherche+'&page='+page+"&per_page="+size,{ headers: Gfunc.getHeader()})
    .catch(function(error){
      error["data"]=""
      return error
    })
    return response
  }

  
  