import React,{useState,useCallback,useMemo} from 'react';
import {DialogActions,DialogContent,DialogTitle,Paper,Button,Dialog,CircularProgress} from '@material-ui/core';
import Draggable from 'react-draggable';
import DataTable from "react-data-table-component";
import {FavoriteBorder} from '@material-ui/icons/';
import { useStyles, paperPropsComs } from '../../../css/muiStyle';
import {customStyles} from '../../../css/datatable-custom'
import { useContext } from 'react';
import ParamContext from '../../../Contexte';
import {AlertCompo} from '../../../js/components'

//Add a new role
function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function Ani_new_AllFavories(props) {
    const classes=useStyles();
    const Gfunc=require('../../../Gfunc')
    const param=useContext(ParamContext);
    const [open, setOpen] = React.useState(false);
    const [lovers, setLovers] = useState([]);
    const [openA, setOpenA] =useState(false)
    const [messageA, setMessageA] =useState('');
    const [severity, setSeverity] =useState('info');
    const service="_new.php"

     //handle Alerts
    const handleAlert=(open,severity,message)=>{
      setOpenA(true)
      setSeverity(severity)
      setMessageA(message)
      setTimeout(function(){
        setOpenA(false)
      }, 5000);
    }

    const conditionalRowStyles= [
      {
        when: row => row.new===true,
        style: {
          color: '#990000',
        },
      },
    ];

    const handleClickOpen = () => { 
        setOpen(true); 
        fetch();
        const objIndex = lovers.findIndex((obj => obj.new ===true )); 
        if(objIndex!==-1) {handleChangeStatueFav();}
    };

    const handleClose = () => { setOpen(false); };
    
    //fetch all favories for a new
    const fetch= useCallback(async() => {
        const response=await Gfunc.axiosGetAction(service,"getlovs")
        if(response.data.err&&response.data.err!=='-1'){
          handleAlert(true,"error",response.data.err)
        }else{
          Gfunc.axiosResponse(response,()=>{
           if(response.data.jData){
             setLovers(response.data.jData);
           }
          })
        }
      },[Gfunc])

    //set a new favories as viewed
    const handleChangeStatueFav=useCallback(async() => {
        const response=await Gfunc.axiosPostAction(service,"setlovviewed")
        if(response.data.err&&response.data.err!=='-1'){
          handleAlert(true,"error",response.data.err)
        }else{
          Gfunc.axiosResponse(response,()=>{
            //console.log(response)
          })
        }
    },[Gfunc])

    const displayInf=(row)=>{
      return (
        <div>
           {(row.mag)?<div>{row.nom+' '+row.prenom+' ['+row.mag+'] '}</div>:<div>{row.nom+' '+row.prenom}</div> }
        </div>
      ) 
    }
    
    const columns =useMemo(
       () => [
          {
            name: "News",
            selector: row=>row.title,
            sortable: true, 
            minWidth:"280px !important",
          }, 
          {
            name: "Par",
            sortable: true,
            minWidth:"180px !important",
            cell: (row) => {
              return (
                <div>{displayInf(row)}</div>
               );
              },
          },
          {
            name: "Date",
            selector: row=>row.date_love,
            sortable: true,
            minWidth:"130px !important",
          },
          {
            name: "Commune",
            selector: row=>row.commune,
            sortable: true,
            minWidth:"130px !important",
          },
          {
            name: "Tel",
            selector: row=>row.tel,
            sortable: true,
            minWidth:"130px !important",
          },
        ],[]);

   return (
    <div>
      <Button className={classes.topTabBtn} variant="contained" color="secondary" onClick={handleClickOpen} startIcon={<FavoriteBorder/>}>Consulter tous les favories</Button>
      <Dialog
            open={open}
            onClose={handleClose}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={paperPropsComs}
       >
        <DialogTitle className={classes.topDivTitle} id="draggable-dialog-title" >La liste des favories </DialogTitle>
        <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA}/>  
        <DialogContent>
             <DataTable
                key={Math.floor(Math.random() * 100)}
                columns={columns}
                data={lovers}
                noDataComponent={param.ErreurData}
                defaultSortAsc={true}
                paginationRowsPerPageOptions={[5, 10, 20, 30]}
                pagination
                highlightOnHover
                customStyles={customStyles}
                selectableRowsComponentProps={{ className: classes.checkStelect }}
                paginationComponentOptions={{ rowsPerPageText: 'Élément par page:', rangeSeparatorText: 'sur', selectAllRowsItemText: 'Tous',selectAllRowsItem: true}}
                contextMessage={{ singular: 'élément sélectionné', plural: 'éléments sélectionnés' }}
                progressComponent={<CircularProgress className="circularProgress" />}
                conditionalRowStyles={conditionalRowStyles}

             />
        </DialogContent>
        <DialogActions id='btn-div'>
          <Button variant="contained"  onClick={handleClose} color="secondary">FERMER</Button>
        </DialogActions>
      </Dialog>
      </div>
  );
}
