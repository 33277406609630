import React,{useState,useMemo,useCallback,useEffect} from 'react';
import {DialogActions,DialogContent,DialogTitle,Paper,Button,Dialog,CircularProgress, Tooltip} from '@material-ui/core';
import DataTable from "react-data-table-component";
import Draggable from 'react-draggable';
import {IndeterminateCheckBox,Add, AssignmentInd} from '@material-ui/icons';
import {axiosResponse,axiosPostAction,axiosGetAction} from'../../../Gfunc'
import { useStyles,paperProps2} from '../../../css/muiStyle';
import '../../../css/admin.css'
import {customStyles}from '../../../css/datatable-custom'
import {AlertCompo} from "../../../js/components"
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { useContext } from 'react';
import ParamContext from '../../../Contexte';

//the dialog that adds previlege to a role**********
function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function Adm_rol_previlege(props) {
    const param=useContext(ParamContext);
   // const Gfunc=require('../../../Gfunc')
    const [open, setOpen] =useState(false);
    const [option, setOption] = useState([]);
    const [selectOption, setSelectOption] = useState([]);
    const [privsAll, setPrivsAll] = useState([]);
    const [privs, setPrivs] = useState([]);
    const classes=useStyles();
    const [,setDel]=useState(false);
    const [openA, setOpenA] =useState(false);
    const [test, setTest] =useState(false);
    const [messageA, setMessageA] =useState('');
    const [severity, setSeverity] =useState('info');
    const [loading, setLoading] = useState(false);
    const service="_rol.php"

    //dialog functions************************
    const handleClickOpen = () => { 
      setPrivs([])
      setOpen(true)
      fetchPrivs();
      setSelectOption('');
    };

    const handleClose = () => {
       setOpen(false); 
       if(test) {props.callback(1)}
    };

     //handle alerts properties
    const handleAlert=(open,severity,message)=>{
      setOpenA(true)
      setSeverity(severity)
      setMessageA(message)
      setTimeout(function(){
        setOpenA(false)
      }, 5000);
    }
    
    //fetch role prives
    const fetchRolePrivs=useCallback(async() => {
        const datY=[];const datN=[]
        setLoading(true)
        const response=await axiosPostAction(service,"getrprvs",{idr:props.row.DT_RowId.substr(1)})
        setLoading(false)
        axiosResponse(response,()=>{
              privsAll.map(el=>{
                const objIndex = response.data.jData.findIndex((obj => obj.id ===el.id));
                if(objIndex!==-1){
                  datY.push(el)
                }else{
                 datN.push(el)
                }
                return true 
              })  
              setPrivs(datY)
              setOption(datN) 
               })
     },[privsAll,props])

       //fetch all prives
    const fetchPrivs= useCallback(async() => {
        const response=await axiosGetAction(service,"getprivs")
        if(response.data.err){
          handleAlert(true,'error',response.data.err)
        }else
        axiosResponse(response,()=>{
          setPrivsAll(response.data.jData)
        }); 
    },[service]);
    
    useEffect(() => {
      if(privsAll.length!==0) {
          fetchRolePrivs();}
      }, [fetchRolePrivs,privsAll]);

    
  const handleDeleteUser=useCallback(async(row)=>{
    const response=await axiosPostAction(service,"delrpriv",{idp:row.id,idr:props.row.DT_RowId.substr(1)})
    if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,'error',response.data.err)
    }else
      axiosResponse(response,()=>{
        if(response.data.jData===true){
          const objIndex = privs.findIndex((obj => obj.id ===row.id));
          privs.splice(objIndex,1)   
          setDel(true)  
          handleTest(false) 
          setTest(true)
        }  
    })   
  
  },[privs,props])

  const columns =useMemo(
    () => [   
      {
          name: "Privilège",
          selector:row=> row.function,
          sortable: true,
          width:"380px !important",
      },
      {
          name: "Module",
          selector:row=> row.module,
          sortable: true,
          width:"250px !important",
      },
      {
        right:true,
        cell: (row) => {

          return (
            <div>
            <div onClick={()=>handleDeleteUser(row)} title="Supprimer utilisateur"><IndeterminateCheckBox className={classes.redIcon} fontSize="small"/></div>   
            </div>
          );
         },
      }  
    ],[handleDeleteUser,classes]);
  
  const handleTest=(val)=>{
     setDel(val)
  }   

  //Add users to role
  const handleAddUsr=useCallback(async() => {
    const objIndex = privsAll.findIndex((obj => obj.function ===selectOption));
    const id=privsAll[objIndex].id
    const response=await axiosPostAction(service,"setrpriv",{idp:id,idr:props.row.DT_RowId.substr(1)})
    if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,'error',response.data.err)
    }else
    axiosResponse(response,()=>{
      if(response.data.jData===true){
        privs.unshift({id: id, function:selectOption, module:privsAll[objIndex].module} )     
         setDel(true)  
         handleTest(false) 
         setSelectOption('')
         setTest(true)
      }  
    })   
  },[privsAll,privs,selectOption,props])

  return (
    <div>   
          <Tooltip title="Ajouter / Supprimer un privilège">
            <AssignmentInd  style={{height:'22px',width:'30px',padding:"1px"}} className={classes.blueIcon} onClick={handleClickOpen} />
          </Tooltip>
          <Dialog
              open={open}
              onClose={handleClose}
              PaperComponent={PaperComponent}
              aria-labelledby="draggable-dialog-title"
              PaperProps={paperProps2}
            >
                <DialogTitle className={classes.topDivTitle} id="draggable-dialog-title" >Les privilèges de <b>{props.row.role}</b></DialogTitle>
                <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA} dialg={true}/>
                <DialogContent>
                  <fieldset className="fieldsetTop3" id="priv">
                      <legend>Ajouter des privilèges</legend>
                        <div id='row-1'>
                            <Autocomplete
                                  disableClearable
                                  value={selectOption}
                                  onChange={(e,newValue) =>{setSelectOption(newValue)}}
                                  id='iPriv'
                                  options={option.map((option) => option.function)}
                                  className='smallText'
                                  renderInput={(params) => <TextField
                                  margin='dense'  size='small'{...params} variant="outlined"  label="Privilège..." autoFocus
                                  inputProps={{...params.inputProps,tabIndex:'4'}}
                                  SelectProps={{ MenuProps: {closeAfterTransition:true } }}/>}
                                />
                            <Button  className={classes.fieldButton} onClick={()=>handleAddUsr()} variant="contained" tabIndex="3" type='submit' 
                                     id="bouton" name="addBtn" color="primary" startIcon={<Add/>}>Ajouter</Button>
                      </div>
                  </fieldset><br/>
                    <div >
                        <DataTable
                            key={Math.floor(Math.random() * 100)}
                            className="table"
                            persistTableHead
                            progressComponent={<CircularProgress className={classes.circularProgress} />}
                            progressPending={loading}
                            columns={columns}
                            data={privs}
                            noHeader={true}
                            customStyles={customStyles}
                            defaultSortAsc={true}
                            noDataComponent={param.ErreurData} 
                            pagination   
                            highlightOnHover
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained"  onClick={handleClose} color="secondary">FERMER</Button>
                </DialogActions>
            </Dialog>
      </div>
  );
}
