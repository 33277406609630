import React,{useState,useRef} from 'react';
import {DialogActions,DialogContent,DialogTitle,Paper,Button,Dialog,TextField} from '@material-ui/core';
import Draggable from 'react-draggable';
import { ValidatorForm} from 'react-material-ui-form-validator';
import {Add,DoneAll} from '@material-ui/icons';
import {axiosPostAction,axiosResponse} from'../../../Gfunc'
import { useStyles, paperProps } from '../../../css/muiStyle';
import {AlertCompo} from '../../../js/components'

//Add a new message
function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function Ani_mod_add(props) {
    const classes=useStyles();
    const [titleFr,setTitleFr]=useState('');
    const [titleAr,setTitleAr]=useState('');
    const [titleEn,setTitleEn]=useState('');
    const [contentFr,setContentFr]=useState('');
    const [contentAr,setContentAr]=useState('');
    const [contentEn,setContentEn]=useState('');
    const [open, setOpen] =useState(false);
    const [openA, setOpenA] =useState(false);
    const [messageA, setMessageA] =useState('');
    const [severity, setSeverity] =useState('info');
    const service="_ntf.php"

    const handleAlert=(open,severity,message)=>{
        setOpenA(true)
        setSeverity(severity)
        setMessageA(message)
        setTimeout(function(){
          setOpenA(false)
        }, 5000);
    }

    const handleClickOpen = () => { 
      setOpen(true); 
      setTitleFr('');setTitleAr('');setTitleEn('');setContentAr('');setContentFr('');setContentEn('');
    };

    const handleClose = () => { setOpen(false); };

    const handleSubmit= async(e) =>{
        const response=await axiosPostAction(service,"add",{messageFr:contentFr,messageAr:contentAr,messageEn:contentEn,titleFr:titleFr,
                                                          titleAr:titleAr,titleEn:titleEn})
        setOpen(false);
        if(response.data.err&&response.data.err!=='-1'){
          handleAlert(true,"error",response.data.err)
        }
        else{
        axiosResponse(response,()=>{
                if (response.data.jData){
                  props.fetch(1);
                }
        })
      }
    }


  return (
    <div>
      <Button className={classes.topTabBtn} variant="contained" color="secondary" onClick={handleClickOpen} startIcon={<Add/>}>
              NOUVEAU modèle</Button>
      <Dialog
            open={open}
            onClose={handleClose}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={paperProps}
       >
        <DialogTitle className={classes.topDivTitle} id="draggable-dialog-title" >Ajouter un nouveau modèle</DialogTitle>
        <ValidatorForm ref={useRef(null)} onSubmit={handleSubmit} >
            <DialogContent className='modl-add'>
              <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA}/>
              <div id='row-1'>
                <TextField variant="outlined" className='smallText' label='Titre français' size="small" value={titleFr} 
                           inputProps={{ tabIndex: "1" ,className:classes.input1}} 
                           onChange={e =>setTitleFr(e.target.value)}  fullWidth autoFocus required 
                       />
                <TextField variant="outlined" className='smallText' label='Titre englais' size="small"  value={titleEn} 
                           inputProps={{ tabIndex: "1" ,className:classes.input1}} 
                           onChange={e =>setTitleEn(e.target.value)}  fullWidth  required 
                       />
                <TextField variant="outlined" className='smallText' label='Titre arabe' size="small"  value={titleAr} 
                           inputProps={{ tabIndex: "1" ,className:classes.input1}} 
                           onChange={e =>setTitleAr(e.target.value)}  fullWidth  required 
                       />
              </div>
              <div id='row-2'>
                <TextField  variant="outlined" className='smallNote' label='Contenu français' size="small"  value={contentFr} style={{marginTop:'10px'}}
                            inputProps={{ tabIndex: "1" ,className:classes.input1}} 
                            onChange={e =>setContentFr(e.target.value)}  fullWidth required  multiline rows={2}
                        />
                <TextField  variant="outlined" className='smallNote' label='Contenu englais' size="small"  value={contentEn} style={{marginTop:'10px'}}
                            inputProps={{ tabIndex: "1" ,className:classes.input1}} 
                            onChange={e =>setContentEn(e.target.value)}  fullWidth required  multiline rows={2}
                        />
                <TextField  variant="outlined" className='smallNote' label='Contenu arabe' size="small"  value={contentAr} style={{marginTop:'10px'}}
                            inputProps={{ tabIndex: "1" ,className:classes.input1}} multiline rows={2}
                            onChange={e =>setContentAr(e.target.value)}  fullWidth  required
                        />
              </div>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" type='submit' color="primary" startIcon={<DoneAll/>}>VALIDER</Button>
              <Button variant="contained"  onClick={handleClose} color="secondary">FERMER</Button>
            </DialogActions>
        </ValidatorForm>
      </Dialog>
      </div>
  );
}
