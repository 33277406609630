import React,{useState,useCallback,useEffect} from 'react';
import {CircularProgress,Backdrop, Button, TextField, TablePagination, Paper, Box} from '@material-ui/core';
import { AddShoppingCart,Clear,Comment,Computer,Done,HourglassEmpty,LocationOn,Phone,Search, Smartphone, Store } from "@material-ui/icons";
import {textAreaEditGray2, useStyles } from '../css/muiStyle';
import { useContext } from 'react';
import ParamContext from '../Contexte';
import axios from 'axios';
import { MuiPickersUtilsProvider,KeyboardDatePicker} from "@material-ui/pickers";
import { fr } from "date-fns/locale";
import DateFnsUtils from "@date-io/date-fns";
import { AlertCompo, NotAllowedComponent } from '../js/components';
import {DirectionsCar, DoneAll, Person, Storefront } from '@mui/icons-material';
import { Autocomplete, Card, CardContent, TextareaAutosize, Typography } from '@mui/material';
import { FicheClient } from '../js/components';
import Cosmetic from '../img/cosmetics.png'
import Tabac from '../img/cigarettes.png'
import DialogPrepare from '../components/content/modals/cmd_acc_prepare'
import DialogDetails from '../components/content/modals/cmd_acc_det'
import Stepper from '../compo/Stepper'
import Ticket from '../components/content/modals/cmd_acc_ticket'
import CommLiv from '../components/content/modals/cmd_acc_livr'
import Bon from '../img/icons/bon.png'
import '../css/commandes.css'

export default function Commandes(props) {
    const classes = useStyles();
    const Gfunc=require('../Gfunc')
    const param=useContext(ParamContext);
    const librery=require('../js/data_table_librery')
    const [data,setData]=useState([]);
    const [stats,setStats]=useState([]);
    const [livrs,setLivrs]=useState([]);
    const [selectDate,SetSelectDate]=useState(new Date())
    const [selectStat,SetSelectStat]=useState('')
    const [search,setSearch]=useState('')
    const [test, setTest] = useState(false);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [openA, setOpenA] =useState(false);
    const [errMessage,setErrMessage]=useState('');
    const [notAllowed,setNotAllowed]=useState(false);
    const [messageA, setMessageA] =useState('');
    const [severity, setSeverity] =useState('info');
    const [page, setPage] =useState(1);
    const [rowsPerPage, setRowsPerPage] =useState(10);
    const [called,setCalled]=useState(false);
    const [,setUpdate]=useState(false);
    const [reject,setReject]=useState(false);
    const [validate,setValidate]=useState(false);
    const steps = ['Attente','Préparation','Prête', 'En route', 'Livrée','Reçue'];
    const service=props.service


    const handleAlert=(open,severity,message)=>{
        setOpenA(true)
        setSeverity(severity)
        setMessageA(message)
        setTimeout(function(){
          setOpenA(false)
        }, 5000);
    }

    const fetch= useCallback(async(page,s,stat,date,servic = service, size = rowsPerPage) => {
        setTest(false)
        setData([])
        setLoading(true);
        setCalled(true)
        const response = (stat!==undefined&&stat!=='')?
        await axios.get( param.urlService+service+'?do=get&page='+(page)+"&per_page="+size+"&d="+Gfunc.formatDate(date)+'&s='+((stat!==undefined)?stat:'')+'&a='+props.do+'&search='+((s!==undefined)?s:''),{ headers: Gfunc.getHeader()})
        :await axios.get( param.urlService+service+'?do=get&page='+(page)+"&per_page="+size+"&d="+Gfunc.formatDate(date)+'&a='+props.do+'&search='+((s!==undefined)?s:''),{ headers: Gfunc.getHeader()});
        setLoading(false);
        if(response.data.err&&response.data.err!=='-1'){
        setErrMessage(response.data.err)
        setNotAllowed(true)
        }else{
        Gfunc.axiosResponse(response,()=>{
            setTest(true)
            setTotalRows(response.data.recordsFiltered)
            if(response.data.recordsFiltered===0){setErrMessage('Aucun résultat.')}
            if(response.data.data){
              setData(response.data.data)
              setNotAllowed(false)
            }
    })
    }
    },[Gfunc,param.urlService,rowsPerPage]);

    const fetchStats= useCallback(async() => {
        const st=[]
        const response = await Gfunc.axiosPostAction(service,'getstat')
        if(response.data.err&&response.data.err!=='-1'){
          handleAlert(true,"error",response.data.err)
        }else{
        Gfunc.axiosResponse(response,()=>{
            let jDataValues = Object.values(response.data.jData);
            jDataValues.map((value,index) => {
             if(value!=='Non renseigné' && value!=='Supprimée'){
                st.push({id:index,name:value})
             }
            });
            st.push({id:'',name:'Toutes'})
            setStats(st)  
        })
    }
    },[Gfunc]);

    useEffect(() => {
        if(!called) {fetch(1,search,undefined,selectDate);fetchStats()}
    },[fetch]);

    const handleChangePage = (event, newPage) => {
        fetch(newPage+1,search,(selectStat!=='')?selectStat:undefined,selectDate,service,rowsPerPage);
        setPage(newPage+1);
       
      };
      
    const handleChangeRowsPerPage = (event) => {
          fetch(1,search,(selectStat!=='')?selectStat:undefined,selectDate,service,(event.target.value==="Tous")?-1:parseInt(event.target.value,10));
          setRowsPerPage((event.target.value==="Tous")?totalRows:event.target.value);
          setPage(1);
      };
    
    const handleSearch=async(size = rowsPerPage)=>{  
         setTest(false)
         setData([])
         setLoading(true);
         const recherche=search; 
         const response=(selectStat!=='')?await librery.axiosSearch(service,recherche,1,size,'get&d='+Gfunc.formatDate(selectDate)+'&s='+((selectStat!==undefined)?selectStat:'')+'&a=acc')
                         :await librery.axiosSearch(service,recherche,1,size,'get&d='+Gfunc.formatDate(selectDate)+'&a=acc')
         setLoading(false);
         if(response.data.err&&response.data.err!=='-1'){
           handleAlert(true,'error',response.data.err)
         }else{
         Gfunc.axiosResponse(response,()=>{
          setTotalRows(response.data.recordsFiltered)
          if(response.data.recordsFiltered===0){setErrMessage('Aucun résultat.')}
          if(response.data.data){
            setData(response.data.data)
          }
         
         })   
       }
         return recherche
    }
    
    function liveSearch(e,search){
        if(param.liveSearch&&search.length>3){
           e.preventDefault();
           handleSearch();
        }else{ if (e.keyCode === 13) {
                  e.preventDefault();
                  handleSearch();
                }
        }
    }
       
    function liveSearch2() {
        handleSearch();
    }
    const IconButton=(value)=>{
        switch( value )
        {
            case "Attente":return <HourglassEmpty style={{width:'15px',height:'20px'}}/>;
            case "Préparation":return <AddShoppingCart style={{width:'15px',height:'20px'}}/>;
            case "Rejetée":return <Clear style={{width:'15px',height:'20px'}}/>;
            case "Prête":return <Done style={{width:'15px',height:'20px'}}/>;
            case "Livraison":return <DirectionsCar style={{width:'15px',height:'20px'}}/>;
            case "En route":return <DirectionsCar style={{width:'15px',height:'20px'}}/>;
            case "Livrée":return <Storefront style={{width:'15px',height:'20px'}}/> ;
            case "Reçue":return <Person style={{width:'15px',height:'20px'}}/> ;
            case "Toutes":return <DoneAll style={{width:'15px',height:'15px'}}/>;
        }
    }
    const Color=(value)=>{
        switch( value )
        {
            case "Supprimée":return 'red'
            case "Attente":return 'gray';
            case "Préparation":return 'green';
            case "Rejetée":return 'red';
            case "Prête":return '#FFD700';
            case "Livraison":return 'orange';
            case "Livrée":return 'blue' ;
            case "Reçue":return 'rgba(0,120,240,0.5)' ;
            case "Non renseigné":return '';
        }
    }

    const GetType=(val)=>{
        switch(val) {
          case 'Téléphonie':
            return <Smartphone id='icon'/>;
          case 'Cosmétiques':
            return < img src={Cosmetic} alt='cosmetic'/>;
          case 'Informatique':
            return <Computer id='icon'/>;
          case 'Tabac':
            return < img src={Tabac} alt='tabac'/>;
          default : return <Store id='icon'/>
        }
    }

    const handleOnFiltre=(row)=>{
        SetSelectStat(row.id)
        fetch(1,search,row.id,selectDate,service,rowsPerPage);
    }

    const HandleChangeDate=(date)=>{
        setPage(1)
        if (!isNaN(new Date(date).getTime())){
          SetSelectDate(date)
          fetch(1,search,undefined,date,service,rowsPerPage)
        }
    }
     
    const ActivElems=(row)=>{
        switch(row.state)
        {
            case "Supprimée":return 0;
            case "Rejetée":return 0;
            case "Livraison":return steps.indexOf('En route');
            default:return  steps.indexOf(row.state);
        }
    }

    const UserElem=(row,index)=>{
      const user=[row.userAffect,row.userPrepare,row.userDeliver,row.userDeliver,row.userDeliver]
        return user[index]
    }

    const TimeElem=(row,index)=>{
      const time=[(row.dateOrder!==null)?row.dateOrder.substring(11,16):'',(row.datePrepare!==null)?row.datePrepare.substring(11,16):'',
                  (row.dateReady)?row.dateReady.substring(11,16):'',(row.dateDeliver)?row.dateDeliver.substring(11,16):'',
                  (row.dateDone)?row.dateDone.substring(11,16):'',(row.dateReceive)?row.dateReceive.substring(11,16):'']
      return time[index]
    }

    const fetchLiv= useCallback(async() => {
      const response = await Gfunc.axiosGetAction(service ,'getsliv')
      if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,"error",response.data.err)
      }else{
      Gfunc.axiosResponse(response,()=>{
          response.data.jData.unshift({id:0,nom:'Main propre'})
          setLivrs(response.data.jData)  
      })
    }
    },[Gfunc]);
    
    const handleOpenLiv= useCallback(async () => {
      if(livrs.length===0){fetchLiv()}
    },[livrs,fetchLiv])

    const handleUpdate=(val)=>{
      setUpdate(false)
    }


    const handleOnChangeLivr=async(el,newValue)=>{
      var livreur=''
      const index=livrs.findIndex((obj => obj.nom ===newValue));
      if(index!==-1){livreur=livrs[index].id}
      const response = await Gfunc.axiosPostAction(service ,'setliv',{userDeliverId:livreur,orderId:parseInt(el.DT_RowId.substr(1))})
      if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,"error",response.data.err)
      }else{
        Gfunc.axiosResponse(response,()=>{
          if(response.data.jData){
            el.userDeliver=newValue
            el.userDeliverId=livreur
            el.stateId=4
            el.state='Livraison'
          }
          setUpdate(true)
          handleUpdate(false)
        })
      }
    }
   
    const HandleReject=()=>{
      setReject(true)
    }


    const HandleValidate=(row,somme)=>{
      const commandesUpdate=data.map((commande)=>{
          if(row.DT_RowId===commande.DT_RowId){
            return {...commande,state:"Prête",stateId:2,mtn:somme}
          }
          return commande
      })
      setData(commandesUpdate)
      setValidate(true)
    }

    const handleChangeState=(row)=>{
      const commandesUpdate=data.map((commande)=>{
          if(row.DT_RowId===commande.DT_RowId){
            return {...commande,state:"Préparation",stateId:1}
          }
          return commande
      })
      setData(commandesUpdate)
    }
 
  
  return (
    <div className="content">
      <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA}/>
       <Backdrop className={classes.backdrop} open={loading} >
        <CircularProgress color="inherit" />
       </Backdrop>
      <div>
      <div  className="table-header">
      <div className='button-flex-wrapper'>
          <div id='date-picker' style={{width:'140px',marginRight:'3px'}}>
             <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
              <KeyboardDatePicker disableToolbar value={selectDate} variant="inline" placeholder="01/01/2020" onChange={date =>HandleChangeDate(date)}
                                  minDate="01/01/2020" format="dd/MM/yyyy"  className='smallText' margin="dense" inputVariant="outlined"
                                  invalidDateMessage="Le format de la date est incorrect" 
              />
            </MuiPickersUtilsProvider>

          </div>
           <CommLiv date={selectDate} do={props.do}/>
            {
                stats.map((el,index)=>{
                    return(
                        <Button key={index} className={classes.topTabBtnFiltre} onClick={()=>handleOnFiltre(el)}
                                variant="contained" color="secondary" startIcon={IconButton(el.name)}>{el.name}</Button>
                    )
                })           
            }
         </div>
      {
      (notAllowed===false)?
      <div className='search' >
             <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <TextField className={classes.search} variant="outlined"  label="Recherche" value={search} 
                               InputProps={{startAdornment: (<Search onClick={() => handleSearch()}
                               style={{ marginRight: '5px',zIndex:'1000',width:'20px',height:'20px',cursor:"pointer"}} />),}}
                               size="small" margin='none'  onKeyUp={e => liveSearch(e)} onChange={e => setSearch(e.target.value)} /> 
              </Box>
      </div>:null
     }
      </div>
     </div>
      <div id='allCmd' key={Math.floor(Math.random() * (1000 - 1 + 1)) + 3}>
        {
          (data.length!==0)?
           <div>
             {
                data.map((el,index)=>{
                    return(
                      <Card id='card-cmd' key={index} >
                          <div style={{ position: 'relative' }}>
                                <div className='up' style={{borderColor:Color(el.state)+' transparent transparent transparent'}}>
                                  <p style={{fontSize:'14px',color:"white"}}>
                                    <span style={{fontSize:'11px',color:"white"}}>{el.dateOrder.substr(0,10)}</span><br/>
                                    {el.dateOrder.substr(11,5)}
                                  </p>
                                  <div className='p-stat'>
                                    {IconButton(el.state)}
                                    {el.state}
                                  </div>
                                </div>
                              <CardContent>
                              <div id='div-com-content'>
                                  <div id='info-com'>
                                      <div className='client-profile-img-div' style={{backgroundColor:Color(el.state)}}>
                                        <FicheClient row={el} val='com' nbr='365'/>
                                      </div>
                                      <Paper elevation={5} id='info-client'>
                                          <div style={{display:'flex',gridGap:'5px',alignItems:'center',fontSize:'14px'}} title='Magasin'>
                                              <LocationOn style={{height:'16px',width:'16px'}}/>
                                              <Typography variant="h7" component="div">{el.accountCommune}</Typography>
                                          </div>
                                          <div style={{display:'flex',gridGap:'5px',alignItems:'center',fontSize:'14px'}} title='Magasin'>
                                              <Phone style={{height:'16px',width:'16px'}}/>
                                              <Typography variant="h7" component="div">{el.accountTel}</Typography>
                                          </div>
                                      </Paper>
                                  </div>
                                  <div>
                                      <div style={{display:'grid',gridTemplateColumns:'80% auto',width:'100%',alignItems:'center'}}>
                                          <Typography variant="h6" component="div" style={{fontWeight:'600'}}>{el.accountName}</Typography>
                                          <div style={{padding:"3px",color:'white',textAlign:'center',backgroundColor:'red',fontWeight:'bold',
                                                      fontSize:'20px'}}> {new Intl.NumberFormat().format(el.mtn)}  </div>
                                      </div>
                                      <div style={{display:'flex',gridGap:'5px',alignItems:'center'}} title='Magasin'>
                                      {GetType(el.accountMagTyp)}
                                      <Typography variant="h7" component="div">{el.accountMag}</Typography>
                                      </div>
                                      {
                                        (el.clientId)?
                                        <div id='com-btn' >
                                        {(ActivElems(el)<=2)?
                                         <DialogPrepare row={el} HandleReject={HandleReject} HandleValidate={HandleValidate}
                                                        handleChangeState={handleChangeState}
                                                        validate={validate} comfirm={reject} handleAlert={handleAlert}/>
                                        :(validate || ActivElems(el)>2)?<DialogDetails row={el} do={props.do} />:null
                                        }
                                        
                                        <Autocomplete
                                            disableClearable
                                            defaultValue={el.userDeliver}
                                            onChange={(e,newValue) =>{handleOnChangeLivr(el,newValue)}} 
                                            onFocus={handleOpenLiv}
                                            options={livrs.map((option) => option.nom)}
                                            disabled={ActivElems(el)<2||ActivElems(el)>4}
                                            className='smallAutoFiltre' 
                                                renderInput={(params) => (
                                                    <TextField required  {...params} className={classes.input11} label="Livreur" color="primary" 
                                                      variant="outlined" size="small"  />
                                                    )} 
                                        />
                                        {
                                        (props.do==='phn'||props.do==='acc')?
                                          (ActivElems(el)>=1&&el.ticketId!==null)?
                                          <Ticket row={el} do={props.do}/>
                                          :(el.ticketId!==null&&el.ticketNum!==null)?
                                            <div style={{width:'98%',display:"flex",fontSize:"16px",fontWeight:'bold',gridGap:'5px'}}>
                                              <img src={Bon} alt="Bon d'achat" style={{height:"18px"}}/>
                                              <p>{el.ticketNum}</p> 
                                            </div>:<div></div>
                                        :null}
                                      </div>:
                                       <div style={{padding:'8px',width:'100%',textAlign:'center',fontWeight:'bold'}}>
                                        Aucun compte existant
                                       </div>
                                      }
                                      
                                        <Stepper steps={steps} IconButton={IconButton} ActivElems={ActivElems} TimeElem={TimeElem} UserElem={UserElem}
                                                 el={el} fetch={fetch} />

                                        <div className='notesContainer'>
                                             {(el.noteAr!=null&&el.noteAr!="")?<div className="note">
                                                <p>Note client en arabe</p>
                                                <div className="noteDiv" title='Note au client Arabe'>
                                                  <Comment style={{height:"18px"}}/>
                                                  <TextareaAutosize disabled style={textAreaEditGray2} defaultValue={el.noteAr}/>
                                                </div>
                                              </div>:null}

                                            {(el.noteFr!=null&&el.noteFr!="")?<div className="note">
                                              <p>Note client en français</p>
                                              <div className="noteDiv" title='Note au client français'>
                                                <Comment style={{height:"18px"}}/>
                                                <TextareaAutosize disabled style={textAreaEditGray2} defaultValue={el.noteFr}/>
                                              </div>
                                            </div>:null
                                            }
                                          </div>
                                                              
                                  </div>
                              </div>
                              </CardContent>
                          </div>
                      </Card>
                    )
                 })
             }
              <TablePagination
                component="div"
                labelRowsPerPage='Élément par page:'
                count={totalRows}
                rowsPerPageOptions={[10,30,50,100,'Tous']}
                page={page-1}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
           </div>
          :(errMessage!==''&&test)?<div className='compAllow'><NotAllowedComponent message={errMessage}/></div>:<></>
        }
      </div>
    </div>
  );
};

