import React,{useState,useRef,useCallback} from 'react';
import {DialogActions,DialogContent,DialogTitle,Paper,Button,Dialog,TextField,Tooltip} from '@material-ui/core';
import Draggable from 'react-draggable';
import {DoneAll} from '@material-ui/icons';
import { ValidatorForm} from 'react-material-ui-form-validator';
import AddIcon from '@material-ui/icons/Add';
import { useStyles, paperPropsUpd } from '../../../css/muiStyle';
import {AlertCompo} from '../../../js/components'
import { MuiPickersUtilsProvider,KeyboardDatePicker} from "@material-ui/pickers";
import { fr } from "date-fns/locale";
import DateFnsUtils from "@date-io/date-fns";
import '../../../css/animation.css'
import axios from "axios";
import { useContext } from 'react';
import ParamContext from '../../../Contexte';
import Autocomplete from '@material-ui/lab/Autocomplete';

//Add a new role
function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function Ani_new_add(props) {
    const classes=useStyles();
    const Gfunc=require('../../../Gfunc')
    const param=useContext(ParamContext)
    const [titreFr,setTitreFr]=useState('');
    const [titreAr,setTitreAr]=useState('');
    const [selectpub,setSelectpub]=useState('Visible');
    const [navg,setnavg]=useState('');
    const [route,setRoute]=useState('');
    const [param2,setParam2]=useState('');
    const [param1,setParam1]=useState('');
    const [ordre,setOrdre]=useState('');
    const [img,setImg]=useState('');
    const [nom,setNom]=useState('');
    const [selectDate,setSelectDate]=useState(new Date());
    const [open, setOpen] = useState(false);
    const [openA, setOpenA] =useState(false);
    const [pub, setPub] =useState([]);
    const [news, setNews] =useState([]);
    const [messageA, setMessageA] =useState('');
    const [severity, setSeverity] =useState('info');
    const service="_new.php"

    //const isDesktopOrLaptop = useMediaQuery({query: '(max-width: 600px)'})

    const handleAlert=(open,severity,message)=>{
      setOpenA(true)
      setSeverity(severity)
      setMessageA(message)
      setTimeout(function(){
        setOpenA(false)
      }, 5000);
    }

    const handleClickOpen = () => { 
      setOpen(true); 
      setNom('');setImg('');setOrdre('');setParam2('');setParam1('');setPub([]);setnavg('');setRoute('');setTitreAr('');setTitreFr('');
      handleOpenPub();
    }

    const handleClose = () => { setOpen(false); };

    const fetchPub= useCallback(async() => {
      const response = await axios.get( param.urlService+service+'?do=getpubs',{ headers: Gfunc.getHeader()});
      if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,"error",response.data.err)
      }else{
        Gfunc.axiosResponse(response,()=>{
          if(response.data.jData){
            setPub(response.data.jData)  
          }
        })
      }
    },[Gfunc,param.urlService]);

    const fetchNews= useCallback(async() => {
      const response = await axios.get( param.urlService+service+'?do=gets',{ headers: Gfunc.getHeader()});
      if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,"error",response.data.err)
      }else{
        Gfunc.axiosResponse(response,()=>{
          if(response.data.jData){
            response.data.jData.sort((a, b) => {
              const elementA = a.titre_fr.toLowerCase();
              const elementB = b.titre_fr.toLowerCase();
              if (elementA < elementB) {return -1;}
              if (elementA > elementB) {return 1;}
              return 0; 
            });            
            setNews(response.data.jData)  
          }
        })
      }
    },[Gfunc,param.urlService]);

    const handleOpenPub= useCallback(async () => {
      if(pub.length===0) fetchPub();
    },[pub,fetchPub])

    const handleOpenNews= useCallback(async () => {
      if(news.length===0) fetchNews();
    },[news,fetchNews])
  
    const handleSubmit= async(e) =>{
      var publish=(selectpub)?pub[Gfunc.getIndex(pub,selectpub,"published")].id:''
      var formData = new FormData();
      formData.append("titre_ar", titreAr);
      formData.append("titre_fr", titreFr);
      formData.append("date", Gfunc.formatDate(selectDate));
      formData.append("pub_state", publish);
      formData.append("before_that", ordre);
      formData.append("img", img);
      formData.append("route", route);
      formData.append("navigator", navg);
      formData.append("param1",param1);
      formData.append("param2", param2);
     
      const response = await Gfunc.axiosPostAction1(service ,'add',formData)
      if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,"error",response.data.err)
      }else{
        Gfunc.axiosResponse(response,()=>{
          if(response.data.jData){
            setOpen(false)
            props.fetch(1)
          }
        })
      }
    }
 
  return (
    <div >
      <Button className={classes.topTabBtn} variant="contained" color="secondary" onClick={handleClickOpen} 
              startIcon={<AddIcon/>}>Nouvelle news</Button>
      <Dialog
            open={open}
            onClose={handleClose}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={paperPropsUpd}
       >
       <DialogTitle className={classes.topDivTitle} id="draggable-dialog-title" >Ajouter une nouvelle news</DialogTitle>
       <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA} dialog={true}/>
       <ValidatorForm ref={useRef(null)} onSubmit={handleSubmit} >
       <DialogContent>
          <div id='new'>
            <div id='row-1'>
                <TextField  variant="outlined"  className='smallText' size="small" id="fr" label="Français" name="fr" value={titreFr} 
                            inputProps={{ tabIndex: "2" ,className:classes.input1}}
                            onChange={e =>setTitreFr(e.target.value)}   autoFocus required />
                <TextField variant="outlined" className='smallText'  size="small" id="ar" label="Arabe" name="ar" value={titreAr} 
                           inputProps={{ tabIndex: "2" ,className:classes.input1}}  
                           onChange={e =>setTitreAr(e.target.value)} required />
                <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
                    <KeyboardDatePicker disableToolbar value={selectDate} inputVariant="outlined" label="Date" size="small" placeholder="01/01/2020" onChange={date => setSelectDate(date)}
                                         minDate='01/01/2022' format="dd/MM/yyyy" className='smallText' 
                                         invalidDateMessage="Le format de la date est incorrect" 
                                />
                </MuiPickersUtilsProvider>
            </div>
            <div id='row-2'>
            <Autocomplete
                      disableClearable
                      onChange={(e,newValue) =>{setSelectpub(newValue);}} 
                      value={selectpub}
                      //onFocus={handleOpenPub}
                      options={pub.map((option) => option.published)}
                      className='smallText'  
                      inputprops={{className:classes.input}}     
                      renderInput={(params) => (
                        <TextField {...params}  label="Publication" color="primary" variant="outlined" size="small"  />
               )} />
            <Autocomplete
                      disableClearable
                      onChange={(e,newValue) =>{setOrdre(newValue.id);}} 
                      onFocus={handleOpenNews}
                      className='smallText'  
                      inputprops={{className:classes.input}}  
                      options={news}
                      getOptionLabel={(option) =>option.titre_fr}
                      renderOption={(props, option) => {
                        return (
                          <Tooltip title={`créer le: ${props.date}`} placement="bottom">
                             <p >{props.titre_fr+' ['+props.titre_ar+']'}</p>
                          </Tooltip >
                        );
                      }}   
                      renderInput={(params) => (
                        <TextField {...params}  label="Après" color="primary" variant="outlined" size="small"  />
               )} />
            </div>
            <div id='row-3'>
              <div  id='input'>
                <TextField variant="outlined" className='smallText' label='Route' size="small" id="route" name="route"  value={route} 
                           inputProps={{ tabIndex: "2" ,className:classes.input1}}
                           onChange={e =>setRoute(e.target.value)}    />
              </div>
              <div  id='input'>
                <TextField variant="outlined" className='smallText' label='Navigateur' size="small" id="nav" name="nav"  value={navg} 
                           inputProps={{ tabIndex: "2" ,className:classes.input1}}
                           onChange={e =>setnavg(e.target.value)}    />
              </div>
              <div  id='input'>
                <TextField variant="outlined" className='smallText' label='Paramètre1' size="small" id="param1" name="param1"  value={param1} 
                           inputProps={{ tabIndex: "2" ,className:classes.input1}}
                           onChange={e =>setParam1(e.target.value)}     />
              </div>
              <div  id='input'>
                <TextField variant="outlined" className='smallText' label='Paramètre2'  size="small" id="param2" name="param2"  value={param2} 
                           inputProps={{ tabIndex: "2" ,className:classes.input1}}
                           onChange={e =>setParam2(e.target.value)}     />
              </div>
            </div>
            <div id='div-file' style={{display:'flex',gridGap:'10px',fontSize:'13px',fontWeight:'bold',alignItems:'center'}}>
              <Button variant="contained" component="label" className={classes.fieldButton}>
                  Choisir une image
                  <input type="file" hidden accept=".jpg" onChange={e=>{setImg(e.target.files[0]);setNom(e.target.files[0].name)}}/>
              </Button>
              <p>{nom}</p>
            </div>
         </div>    
         </DialogContent>               
         <DialogActions id='btn-div'>
              <Button variant="contained" type='submit' color="primary" startIcon={<DoneAll/>}>VALIDER</Button>
              <Button variant="contained"  onClick={handleClose} color="secondary">FERMER</Button>
            </DialogActions>
        </ValidatorForm>
      </Dialog>
      </div>
  );
}
