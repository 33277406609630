import React,{useState,useMemo,useCallback} from 'react';
import {DialogActions,DialogContent,DialogTitle,Paper,Button,Dialog,CircularProgress, Grid, TextField, Tooltip, Box} from '@material-ui/core';
import DataTable from "react-data-table-component";
import Draggable from 'react-draggable';
import {IndeterminateCheckBox,Add,Search} from '@material-ui/icons';
import {axiosResponse,axiosPostAction,removeItemsByAtt} from'../../../Gfunc'
import Multiselect from 'multiselect-react-dropdown';
import { useStyles,multiSelect, paperPropsComs, paperProps } from '../../../css/muiStyle';
import {customStyles}from '../../../css/datatable-custom'
import {AlertCompo} from "../../../js/components"
import useMediaQuery from '@mui/material/useMediaQuery';
import { useContext } from 'react';
import ParamContext from '../../../Contexte';
import Icon from '@mdi/react';
import { mdiAccountTie } from '@mdi/js';
import {ReactComponent as Group} from '../../../img/icons/group.svg'


//the dialog that marged comptes **********
function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function Cpt_new_compte(props) {
    const param=useContext(ParamContext);
    const [open, setOpen] = useState(false);
    const [option, setOption] = useState([]);
    const [opt, setOpt] = useState([]);
    const [data, setData] = useState([]);
    const [selectedValues, setSelectedValues] = useState([]);
    const [loading, setLoading] = useState(false);
    const classes=useStyles();
    const [,setDel]=useState(-1);
    const [openA, setOpenA] =useState(false);
    const [messageA, setMessageA] =useState('');
    const [severity, setSeverity] =useState('info');
    const [search,setSearch]=useState('')
    const service="_cpt.php"
    var users =[]
    const matches = useMediaQuery('(min-width:150px) and (max-width: 850px)');

    //handle alerts properties
    const handleAlert=(open,severity,message)=>{
        setOpenA(true)
        setSeverity(severity)
        setMessageA(message)
        setTimeout(function(){
          setOpenA(false)
        }, 5000);
    }

    const handleClickOpen = () => { 
      if(option.length===0){fetchCompte();}
      if(data.length===0){fetch();}
      setOpen(true);
    };

    const handleClose = () => { 
          setOpen(false); setSelectedValues([]);setData([]);
          props.fetch(1)
        };

    const fetch= useCallback(async() => {
        var dat=[]
      setLoading(true)
      const response=await axiosPostAction(service,"getcptform",{compte:parseInt(props.row.DT_RowId.substr(1)),unsets:false})
      setLoading(false)
      if(response.data.err&& response.data.err!==-1){
       handleAlert(true,'error',response.data.err)
      }else{
        axiosResponse(response,()=>{
          if(response.data.jData){
            if(response.data.jData){
                response.data.jData.map((el)=>{
                    dat.push({id:el.id,name:el.nom+' '+el.prenom,commune:el.commune,mag:el.mag,subCom:el.subCom,tel:el.tel,wilaya:el.wilaya})
                })

                setData(dat);
            }
          }
          })
      }
    },[props.row.DT_RowId])

    const fetchCompte=useCallback(async() => {
        var op=[]
      const response=await axiosPostAction(service,"getcptform",{compte:parseInt(props.row.DT_RowId.substr(1)),unsets:true})
        axiosResponse(response,()=>{
            if(response.data.jData){
                response.data.jData.map((el)=>{
                    op.push({id:el.id,name:el.nom+' '+el.prenom+' '+'['+ el.tel+ ']'+' '+'['+ el.mag+ ']'+' '+'['+ el.commune+ ']',
                    commune:el.commune,mag:el.mag,subCom:el.subCom,tel:el.tel,wilaya:el.wilaya})
                })
                setOpt(op)
            }
        setOption(response.data.jData);
    })
    },[props.row.DT_RowId])

    function onSelect(selectedList, selectedItem) {
      var dat =[]
      const updatedOptions = [...opt];
      const SelectedIndex = opt.findIndex((item) => item.id === selectedItem.id);
      if (SelectedIndex !== -1) {
        updatedOptions.splice(SelectedIndex, 1);
        setOpt(updatedOptions);
      }
        selectedList.map((el)=>{
          var res=el.name.slice(0, el.name.indexOf("["));
          dat.push({id:el.id,name:res,mag:el.mag,commune:el.commune,subCom:el.subCom,tel:el.tel,wilaya:el.wilaya})
        })
        setSelectedValues(dat)
    }

     //suppression des options
    function onRemove(selectedList, removedItem) {
      if(selectedList.length!==0){
        var dat =[]
        selectedList.map((el)=>{
          var res=el.name.slice(0, el.name.indexOf("["));
          dat.push({id:el.id,name:res,mag:el.mag,commune:el.commune,subCom:el.subCom,tel:el.tel,wilaya:el.wilaya})
        }) 
        setSelectedValues(selectedList)
      }
      const updatedOptions = [...opt];
      removedItem['name']=removedItem['name']+'['+removedItem['tel']+'] ['+removedItem['mag']+'] ['+removedItem['commune']+'] '
      updatedOptions.unshift(removedItem);
      setOpt(updatedOptions);      
    }

    //Add users to role
    const handleAddCompte=useCallback(async() => {
        var idrss=[]
      selectedValues.map((opt)=>{
        idrss.push(opt.id) 
        return true 
      })
      const response= await axiosPostAction(service ,"mergecpts",{compte:parseInt(props.row.DT_RowId.substr(1)), idcpt:idrss})
      if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,"error",response.data.err)
      }
      else{
        axiosResponse(response,()=>{
            if(response.data.jData){
                handleAlert(true,"success",'Le compte a été attribués avec succès aux comptes sélectionnés.')
                selectedValues.map((item)=>{
                 removeItemsByAtt(option,item.id,"id")
                 data.push(item)
                 return true;
                }) 
            }
        }) 
      }
        setSelectedValues([])
  },[data,option,selectedValues,service,props.row.DT_RowId])
   
  const handleSetData=useCallback((row,att)=>{
    removeItemsByAtt(data,row.id,att)
  },[data])
  

  const handleDeleteCompte=useCallback(async(row)=>{
    const response =await axiosPostAction(service,"unmergecpts",{compte1:parseInt(props.row.DT_RowId.substr(1)),compte2:row.id});
    if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,"error",response.data.err)
    }
    else{
    axiosResponse(response,()=>{
        if(response.data.jData){
            handleAlert(true,"success","Le compte a été supprimé avec succès.")
            handleSetData(row,"id")
            setDel(row.id)
            fetchCompte();  
        } 
    })
  }
  },[handleSetData,fetchCompte,props,service])

  const columns =useMemo(
    () => [   
      {
          name: "Compte",
          selector:row=> row.name,
          sortable: true,
          minWidth:'180px !important'
      },
      {
          name: "Boutique",
          selector:row=> row.mag,
          minWidth:'150px !important'
      },
      {
          name: "Tel",
          selector:row=> row.tel,
          minWidth:'110px !important'
      },
      {
          name: "Commune",
          selector:row=> row.commune,
          minWidth:'150px !important'
      },
      {
          name: "Wilaya",
          selector:row=> row.wilaya,
          minWidth:'120px !important'
      },
      {
          name: "Gros",
          selector:row=> row.gros,
          minWidth:'140px !important',
          cell:(row)=>{
            return(
              <Tooltip title='Sous réseau '>
                <div>{(row.subCom)?<Icon path={mdiAccountTie} size={0.8} />:null}</div> 
              </Tooltip>
            )
          }
      },
      {
        right:true,
        width:'25px',
        cell: (row) => {
          return (
            <Tooltip title="Supprimer le compte">
              <div onClick={()=>handleDeleteCompte(row)}><IndeterminateCheckBox className={classes.redIcon} fontSize="small"/></div>  
            </Tooltip> 
          );
         },
      }  
    ],[handleDeleteCompte,classes]);

  const filteredData = data.filter((row) => {
      return Object.keys(row).some((key) => {
        return row[key].toString().toLowerCase().includes(search.toLowerCase());
      });
  });

 

  return (
    <div>
          <Tooltip title="Voir les comptes">
            <Group fill={(props.row.merged)?'black':'gray'} style={{height:'18px',width:'18px',cursor:"pointer"}} 
                   onClick={handleClickOpen}/>
          </Tooltip>
          <Dialog
                open={open}
                onClose={handleClose}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
                PaperProps={paperProps} 
          >
           <DialogTitle className={classes.topDivTitle} id="draggable-dialog-title" >Les comptes du {props.row.nom+' '+props.row.prenom}</DialogTitle>
                <DialogContent>
                <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA}/>
                <fieldset className="fieldsetTop" id="role">
                  <legend>Ajouter des comptes</legend>
                  {
                   (option.length!==0)?
                    <div id='row-1' >
                        <Multiselect
                            style={multiSelect}
                            className='smallText'
                            options={opt} 
                            selectedValues={selectedValues}
                            onSelect={onSelect} 
                            onRemove={onRemove} 
                            emptyRecordMsg="Il n'y a plus d'options"
                            displayValue={"name"}
                            placeholder={(matches)?"les comptes":"Selectioner les comptes"}
                            closeOnSelect={false}
                            hidePlaceholder={true}
                        />
                      <Button className={classes.fieldButton} variant="contained" onClick={handleAddCompte} color="primary" startIcon={<Add/>}>AJOUTER</Button>
                    </div>:<></>
                  }
              </fieldset>
             <div>
             <div className='search' style={{float:'right',margin:'10px 17px'}} >
                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <TextField className={classes.search} variant="outlined"  label="Recherche" value={search} 
                               InputProps={{startAdornment: (<Search 
                               style={{ marginRight: '5px',zIndex:'1000',width:'20px',height:'20px',cursor:"pointer"}} />),}}
                               size="small" margin='none' onChange={e => setSearch(e.target.value)} /> 
                </Box>
              </div>  
            <div id='cpt-div'>
              <DataTable 
                  persistTableHead
                  columns={columns}
                  progressComponent={<CircularProgress className={classes.circularProgress} />}
                  progressPending={loading}
                  data={filteredData}
                  noHeader={true}
                  pagination
                  paginationRowsPerPageOptions={[5,10,15,20,30]}
                  customStyles={customStyles}
                  defaultSortAsc={true}
                  noDataComponent={param.ErreurData}    
                  highlightOnHover
                  paginationComponentOptions={{ rowsPerPageText: 'Element par page:', rangeSeparatorText: 'sur'}}               
               />
            </div>
           
          </div>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={handleClose} color="secondary">FERMER</Button>
                </DialogActions>
            </Dialog>
      </div>
  );
}
