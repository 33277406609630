import React,{ useState, useEffect ,useCallback,useMemo} from "react";
import DataTable from "react-data-table-component";
import TextField from '@material-ui/core/TextField';
import {CircularProgress, Box,Backdrop, Tooltip} from '@material-ui/core';
import {DesktopAccessDisabled, Search} from '@material-ui/icons';
import {NoDataComponent,NotAllowedComponent,AlertCompo} from "../../js/components"
import {conditionalRowStyles,customStyles}from '../../css/datatable-custom.js'
import { useStyles } from '../../css/muiStyle';
import { useContext } from 'react';
import ParamContext from '../../Contexte';


export default function Adm_ses(){
  const classes=useStyles();
  const param=useContext(ParamContext);
  const Gfunc=require('../../Gfunc')
  const librery=require('../../js/data_table_librery')
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [records, setRecords] =useState('');
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [search,setSearch]=useState('');
  const [Icon,setIcon]=useState('');
  const[errMessage,setErrMessage]=useState('');
  const [severity, setSeverity] =useState("info");
  const [open, setOpen] =useState(false);
  const [messageA, setMessageA] =useState('');
  const[noData,setNoData]=useState(false);
  const[notAllowed,setNotAllowed]=useState(false);
  const service='_ses.php'
  

   //handle alerts properties
   const handleAlert=(open,severity,message)=>{
    setOpen(open)
    setSeverity(severity)
    setMessageA(message)
    setTimeout(function(){
      setOpen(false)
    }, 5000);
  }

  const fetch= useCallback(async(page,val,servic = service, size = perPage) => {
    setLoading(true);
    const response=await librery.axiosGet(page+"&search="+((val!==undefined)?val:''),servic,size)
    setLoading(false)
      if(response.data.err&&response.data.err!=="-1"){
        setErrMessage(response.data.err)
        setNotAllowed(true)
      }else
      Gfunc.axiosResponse(response,()=>{
        setRecords(response.data.recordsFiltered)
        if(response.data.data!==undefined){
            setData(response.data.data);
            setTotalRows(response.data.recordsFiltered);
            setNoData(true)
        }else {setData([])}
      })
    setLoading(false)
  },[Gfunc,param.urlService,perPage])

  useEffect(() => {
    fetch(1);
  }, [fetch]);
  
 const KillSession = useCallback(async(row)=>{
    const response= await Gfunc.axiosPostAction(service,'killses',{sessionId:row.DT_RowId.substr(1)})
    if(response.data.err&&response.data.err!=="-1"){
        handleAlert(true,"error",response.data.err)
    }else{
        Gfunc.axiosResponse(response,()=>{ 
            setIcon(row.DT_RowId)      
                })
    }
},[Gfunc])
const handleLockIcon=(val)=>{
  
  setIcon(val);
}
useEffect((val) => {
handleLockIcon(val);
}, []);

const columns =useMemo(
    () => [   
      {
          name: "Magasin",
          selector: row=>row.mag,
          sortable: false,
          minWidth:"120px !important",
      },
      {
          name: "OUVERTURE",
          selector: row=>row.openDate,
          sortable: false,
          minWidth:"140px !important",
      },
      {
        name: "UTILISATEUR",
        selector:row=>row.client,
        sortable: false,   
        minWidth:"120px !important"

      },
      {
          name:'ACTIVITE',
          selector:row=>row.lastAction,
          sortable: false,
          minWidth:"130px !important"
      },
      {
        name:'Version',
        selector:row=>row.version,
        sortable: false,
        minWidth:"130px !important"
        
    },
      {
          name:"IP",
          selector:row=>row.ip,
          sortable: false,
          minWidth:"130px !important"
      },
      {
        name:"MACHINE",
        selector:row=>row.host,
        sortable: false,
        minWidth:"280px !important"
      },
      { 
        width:"40px !important",
        cell: (row) => {
          if(Icon===row.DT_RowId){
            row.closed=!row.closed
           setTimeout(() => { handleLockIcon('')},0) ;          
          }
          return (
            <Tooltip title="Fermer sessions">
              <div onClick={()=>KillSession(row)}>{(!row.closed)?<DesktopAccessDisabled className='redIcon' fontSize="small" />:<></> }</div>
            </Tooltip>        
           );
         },
      }  
    ],[KillSession,Icon]);
  
  const handleOnSort=async(column, sortdirection,page=currentPage, size = perPage)=>{
      setLoading(true);
      const response=await librery.axiosSort(column,sortdirection,page,size,service)
      if(response.data.err&&response.data.err!=="-1"){
        handleAlert(true,"error",response.data.err)
      }else{
        Gfunc.axiosResponse(response,()=>{
            setData(response.data.data);
            setTotalRows(response.data.recordsFiltered);
            setLoading(false);
          }) 
      }
          
  }

  //recherche serverside
const handleSearch=async(size = perPage)=>{
  setLoading(true);
  const recherche=search;
  const response=await librery.axiosSearch(service,recherche,1,size,"get")
  if(response.data.err&&response.data.err!=='-1'){
    handleAlert(true,'error',response.data.err)
  }else{
  Gfunc.axiosResponse(response,()=>{
    if(response.data.data!==undefined){
    setData(response.data.data);
    setTotalRows(response.data.recordsFiltered);
    }else setData([])
  })  
  setLoading(false); 
  }
  return recherche
}

function handlePageChange (page) {
  fetch(page,search,service,perPage);
  setCurrentPage(page);
};
 const handlePerRowsChange = async (newPerPage, page) => {
  fetch(page,search,service, newPerPage);
  setPerPage(newPerPage);
};

function liveSearch(e,search){
  if(param.liveSearch&&search.length>3){
     e.preventDefault();
     handleSearch();
  }else{ if (e.keyCode === 13) {
            e.preventDefault();
            handleSearch();
          }
  }
}



return (
    <div className="content">
      <AlertCompo severity={severity} open={open} setOpen={setOpen} message={messageA} />
      <Backdrop className={classes.backdrop} open={loading} >
        <CircularProgress color="inherit" />
     </Backdrop>
       <div className='table-header'>
          {(notAllowed===false)?
            <div className='search' >
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <TextField className={classes.search} variant="outlined"  label="Recherche" value={search} 
                               InputProps={{startAdornment: (<Search onClick={() => handleSearch()}
                               style={{ marginRight: '5px',zIndex:'1000',width:'20px',height:'20px',cursor:"pointer"}} />),}}
                               size="small" margin='none'  onKeyUp={e => liveSearch(e)} onChange={e => setSearch(e.target.value)} /> 
              </Box>
            </div>:null}
        </div>
        {(notAllowed===false)?
        <div>
          <DataTable className="table"
                persistTableHead
                columns={columns}
                data={data}
                noHeader={true}
                defaultSortAsc={true}
                noDataComponent={param.ErreurData}
                paginationRowsPerPageOptions={[20,30,50,100]}
                //progressPending={loading}
                progressComponent={<CircularProgress className={classes.circularProgress} />}
                pagination
                paginationServer
                paginationTotalRows={totalRows}
                paginationDefaultPage={currentPage}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                sortServer={true}
                onSort={handleOnSort}
                highlightOnHover
                customStyles={customStyles}
                paginationComponentOptions={{ rowsPerPageText: 'Élément par page:', rangeSeparatorText: 'sur', selectAllRowsItem: true,selectAllRowsItemText: 'Tous'}}
                conditionalRowStyles = { conditionalRowStyles }
          />
        </div>:(notAllowed)?<NotAllowedComponent message={errMessage}/>:(noData || records===0)?<NoDataComponent message="Aucun résultat trouvé"/>:null}
    </div>    

  );
}