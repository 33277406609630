import React,{useState,useMemo} from 'react';
import {DialogActions,DialogContent,DialogTitle,Paper,Button,Dialog,CircularProgress, TextField, Tooltip, Box} from '@material-ui/core';
import Draggable from 'react-draggable';
import {Add,FiberManualRecord,Search,DoneAll} from '@material-ui/icons';
import { useStyles,paperPropsSmall } from '../../../css/muiStyle';
import {AlertCompo} from '../../../js/components'
import DataTable from 'react-data-table-component';
import ParamContext from '../../../Contexte';
import { customStyles } from '../../../css/datatable-custom';
import { useContext } from 'react';


//Add a new role
function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function Cat_cat_addLL(props) {
    const classes=useStyles();
    const Gfunc=require('../../../Gfunc')
    const librery=require('../../../js/data_table_librery')
    const param=useContext(ParamContext);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [openA, setOpenA] =useState(false);
    const [cats, setCats] =useState([]);
    const [messageA, setMessageA] =useState('');
    const [severity, setSeverity] =useState('info');
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedRows, setSelectedRows] = useState([]);
    const [search, setSearch] =useState('');
    const service="_prd.php"

    const handleAlert=(open,severity,message)=>{
      setOpenA(true)
      setSeverity(severity)
      setMessageA(message)
      setTimeout(function(){
        setOpenA(false)
      }, 5000);
      }
    const handleClickOpen = () => { 
        setOpen(true);
        fetchCat(currentPage)
    }
    const handleClose = () => { setOpen(false);setCats([]);setTotalRows(0);setPerPage(10);setCurrentPage(1);setSearch('')}
  
    //fetch catalogue
    const fetchCat= async(page,s,servic = service, size = perPage) => {
        setLoading(true)
        const response= await librery.axiosGet2(page,servic,'getp4cat&catalogId='+parseInt(props.countp.DT_RowId.substr(1))+'&search='+search,size);
        setLoading(false)
        if(response.data.err&&response.data.err!=="-1"){
           handleAlert(true,'error',response.data.err)
        }else{
           Gfunc.axiosResponse(response,()=>{
            setTotalRows(response.data.recordsFiltered)
            if(response.data.data){
               setCats(response.data.data)
            }
          })
        }
      }

  //valider formulaire avec touche d'entrer
  const detectTouche= (e)=>{
      if (e.keyCode === 13 && (e.target.name==="addBtn")) {
          handleSubmit()
        }
  }

  const columns = useMemo(
    () => [
      {
        name: "PRODUIT",
        selector: row=>row.produit,
        sortField:'produit',
        minWidth:"380px !important",
        cell: (row) => {
          return (
             <div title={row.produit}>{row.produit}</div>
           );
         },
      },
      {
        right:"true",
        cell: (row) => {
            return (
              <div title={row.color}> <FiberManualRecord style={{color:'#'+row.colorCode}} />  </div>     
             );
        },
      }
    ], []);

    /*const handleOnSort = async (column, sortdirection, page = currentPage, size = perPage) => {
        setLoading(true);
        const response = await librery.axiosSort(column, sortdirection, page, size,service,'get')
        setLoading(false);
        Gfunc.axiosResponse(response, () => {
          setCats(response.data.data);
          setTotalRows(response.data.recordsFiltered);
        })
    }*/

    const handleSearch=async(size = perPage)=>{  
        setCats([]) 
        setLoading(true);
        const recherche=search;
        const response=await librery.axiosSearch(service,recherche,1,size,'getp4cat&catalogId='+parseInt(props.countp.DT_RowId.substr(1)))
        setLoading(false);
        if(response.data.err&&response.data.err!=='-1'){
          handleAlert(true,'error',response.data.err)
        }else{
        Gfunc.axiosResponse(response,()=>{
         setTotalRows(response.data.recordsFiltered)
         if(response.data.data){
           setCats(response.data.data)
         }
        
        })   
      }
        return recherche
    }
   
    function liveSearch(e,search){
       if(param.liveSearch&&search.length>3){
          e.preventDefault();
          handleSearch();
       }else{ if (e.keyCode === 13) {
                 e.preventDefault();
                 handleSearch();
               }
       }
    }
     
    function liveSearch2() {
       handleSearch();
    }

    function handlePageChange(currentPage) {
        fetchCat(currentPage,search);
        setCurrentPage(currentPage);
    }
    
    const handlePerRowsChange = async (newPerPage, page) => {
        fetchCat(1,search,service,newPerPage);
        setPerPage(newPerPage);
    }

    const handleSelectedRowsChange = (selectedRows) => {
        setSelectedRows(selectedRows.selectedRows);
    };

    const handleSubmit= async() =>{
          const response=await Gfunc.axiosPostAction(service,"addprdcat",{produitId:parseInt(selectedRows[0].DT_RowId.substr(1)),
          catalogId:parseInt(props.countp.DT_RowId.substr(1))})
          if(response.data.err&&response.data.err!=='-1'){
            handleAlert(true,"error","Une erreur s'est produite")
          }else{
           Gfunc.axiosResponse(response,()=>{
              setOpen(false)
              props.handlePrdCat(props.countp)
              props.handleAlert(true,'success',"Le produit a été ajouté au catalogue avec succès")
           })
          }
      }
    

  return (
    <div >
      <Tooltip title={"Ajouter un nouveau produit dans "+props.countp.titre_fr}>
        <div style={{textAlign:'end',padding:"auto"}}>
          <Button className={classes.iconBtnBig} variant="contained" onClick={handleClickOpen} color="primary">
            <Add fontSize="small"/>
          </Button>  
        </div> 
      </Tooltip>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        PaperProps={paperPropsSmall}
      >
       <DialogTitle className={classes.topDivTitle} id="draggable-dialog-title" >Ajouter des produits à {props.countp.titre_fr}</DialogTitle>
       <DialogContent>
            <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA}/>
            <div className='table-header'>
            <div className='search' >
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <TextField className={classes.search} variant="outlined"  label="Recherche" value={search} 
                               InputProps={{startAdornment: (<Search onClick={() => handleSearch()}
                               style={{ marginRight: '5px',zIndex:'1000',width:'20px',height:'20px',cursor:"pointer"}} />),}}
                               size="small" margin='none'  onKeyUp={e => liveSearch(e)} onChange={e => setSearch(e.target.value)} /> 
              </Box>
            </div>
            </div>
            <DataTable
              columns={columns}
              data={cats}
              noDataComponent={param.ErreurData}
              defaultSortAsc={true}
              paginationRowsPerPageOptions={[10, 20, 30]}
              progressPending={loading}
              pagination
              paginationServer
              paginationTotalRows={totalRows}
              paginationDefaultPage={currentPage}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              //onSort={handleOnSort}
              highlightOnHover
              selectableRows
              selectableRowsNoSelectAll
              selectableRowsSingle
              onSelectedRowsChange={handleSelectedRowsChange}
              customStyles={customStyles}
              paginationServerOptions={{persistSelectedRowsOnSortChange: true}}
              paginationComponentOptions={{ rowsPerPageText: 'Élément par page:', rangeSeparatorText: 'sur',selectAllRowsItemText: 'Tous',selectAllRowsItem: true}}
              contextMessage={{ singular: 'élément sélectionné', plural: 'éléments sélectionnés' }}
              progressComponent={<CircularProgress className="circularProgress" />}
            />
           </DialogContent>               
            <DialogActions>
              <Button variant="contained" color="primary" onClick={()=>handleSubmit()} startIcon={<DoneAll/>}>VALIDER</Button>
              <Button variant="contained"  onClick={handleClose} color="secondary">FERMER</Button>
            </DialogActions>
      </Dialog>
      </div>
  );
}
