
import React from 'react'
import { LineChart,Line,XAxis,YAxis,Tooltip ,Legend,CartesianGrid,ResponsiveContainer} from 'recharts';

export default function Linechart(props){
    const Gfunc =require('../Gfunc')
    function CustomTooltip({ payload, label, active,...props }) {
        if (active) {
         var dateParts = payload[0].payload.day.split("-");
         var dateObject = new Date(dateParts[2], dateParts[1]-1, dateParts[0]);
             return (
                  <div className="custom-tooltip" style={{ border:"2px solid"+payload[0].color}}>
                     <p className="intro"><b>{Gfunc.DateDay(dateObject)}</b>{(`  ${payload[0].payload.day}`)}</p>
                     <p className="intro" style={{ color:payload[0].color}}><b>{`${payload[0].dataKey}`}:</b>{(payload.length!==0)?new Intl.NumberFormat().format(`${payload[0].value}`):0 }</p>
                  </div>
                    );
                  }
       return null;
                }
    
    return (
      <div style={{ width: '98%', height: props.height }}>
        <ResponsiveContainer>
          <LineChart data={props.data} margin={{ top: 25,right:0,left:0,bottom:0}}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis  dataKey={props.dataKeyX} tick={{ fill: 'black',fontSize:'11px',dy:3,angle: -20}}/>
              <YAxis tick={{ fill: 'black',fontSize:'13px'}} />
              <Tooltip  content={(props.data.length!==0)?<CustomTooltip/>:null} />
              <Legend />
              <Line type="monotone" dataKey={props.dataKeyY} stroke={props.color} strokeWidth={2}  activeDot={{ r: 8 }} />
          </LineChart>
        </ResponsiveContainer>
      </div>
    );
}