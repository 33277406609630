import React,{ useState, useCallback} from "react";
import { axiosGetAction,axiosPostAction, axiosResponse } from "../../Gfunc";
import {TextField, Accordion,AccordionDetails, AccordionSummary,Typography,Switch} from '@material-ui/core';
import DataTable from "react-data-table-component";
import {ExpandMore} from '@material-ui/icons';
import { useStyles } from '../../css/muiStyle';
import  { merge,groupBy,getDate } from '../../Gfunc'
import {customStyles} from '../../css/datatable-custom'
import {AlertCompo} from '../../js/components'
import { Autocomplete } from "@material-ui/lab";
import { useContext } from 'react';
import ParamContext from '../../Contexte';


export default function Adm_prv(){
  
  const Gfunc=require('../../Gfunc')
  const param=useContext(ParamContext);
  const [data, setData] = useState([]);
  const [privs, setPrivs] = useState([]);
  const [rPrivs, setRprivs] = useState([]);
  const service='_rol.php'
  const [role, setRole] = useState('');
  const classes = useStyles();
  const [det, setDet] = useState([]);
  const [resp, setResp] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [openAccord, setOpenAccord] = useState(false);
  const [switches, setSwitches] = useState('');
  const [openA, setOpenA] =useState(false);
  const [messageA, setMessageA] =useState('');
  const [severity, setSeverity] =useState('info');
  
  const handleAlert=(open,severity,message)=>{
    setOpenA(true)
    setSeverity(severity)
    setMessageA(message)
    setTimeout(function(){
      setOpenA(false)
    }, 5000);
  }


    //fetch roles
    const fetch= useCallback(async() => {
      const response=await axiosGetAction(service,"gets")
      if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,"error",response.data.err)
      }else{
      axiosResponse(response,()=>{
          setData(response.data.jData);
      }) 
    }
    },[]);
    
     //fetch all prives
     const fetchPrivs= useCallback(async() => {
      const response=await axiosGetAction(service,"getprivs")
      if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,"error",response.data.err)
      }else{
      axiosResponse(response,()=>{
        if(response.data.jData!==undefined){
          setPrivs(response.data.jData);
        }
      }); 
    }
     },[]);
  
     //fetch role prives
     const fetchRolePrivs= async(id) => {
        const response=await axiosPostAction(service,"getrprvs",{idr:id})
        if(response.data.err&&response.data.err!=='-1'){
          handleAlert(true,"error",response.data.err)
        }else{
        axiosResponse(response,()=>{
          const rolePrivs=response.data.jData
          const conc=merge(privs,rolePrivs,'id')
          const priv = groupBy(conc, 'module')
          setRprivs(priv);
          handleSwitches("")  
        })
      }
      }
    //fetch role prives
    const fetchRoleDet= async(id) => {
      const response=await axiosPostAction(service,"getroledet",{idr:id})
      if(response.data.err&&response.data.err!==-1){
        handleAlert(true,'error',response.data.err)
      }else{
        axiosResponse(response,()=>{
          setDet(response.data.jData)
      
      })
    }
    }
  //handle select change
  const handleChange =(val) => {
    fetchRoleDet(val)
    setResp(true)  
    setRole(val);
    setOpenAccord(true)
    fetchRolePrivs(val)
  };

  const handleOpenSelect=()=>{
    if(data.length===0)fetch();
    if(privs.length===0) fetchPrivs();
  }

  //handle accordionchange
  const handleChangeAccordion = (panel)=>(event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  //handle switch
  const handleSwitches=(val)=>{  
    setSwitches(val);
  }
  //set or delete privs to a role
  const handlePrivs= async (row)=> {
    var response=null
    if(row.hasOwnProperty("name"))
      response=await axiosPostAction(service,"delrpriv",{idp:row.id,idr:role})
    else 
      response=await axiosPostAction(service,"setrpriv",{idp:row.id,idr:role})
    axiosResponse(response,()=>{
      handleSwitches(row.id)
    })         
   };


  
  return (
    <div className="content">  
        <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA}/>
        <div className='head'>
              <fieldset className="fieldsetTop2" id="privilege">
               <legend>Affectation des privilèges:</legend>
               <div className="rowGrid" id="row-1">
                           <Autocomplete
                                disableClearable
                                id="iRole"
                                onChange={(e,newValue) =>{handleChange(data[Gfunc.getIndex(data,newValue,"name")].id)}}
                                className='smallText'
                                onFocus={handleOpenSelect}
                                options={data.map((option) => option.name)}
                                renderInput={(params) => (
                                   <TextField {...params} variant='outlined' label={'Role'} size="small" required/>
                            )}/>
                            {
                              (resp)?
                              <div>
                                    <div className='grid-wrapper-field'>
                                        <span className="desc" style={{fontSize:'13px'}}>Users:</span>
                                        <span className="inf">{(det.length!==0)?det.users.map((elem,index)=>{
                                             return ((index===det.users.length-1)?elem+ ' . ':elem+ ' - ') 
                                        }
                                        ):'Aucun.'}</span>
                                    </div>
                                    <div className='grid-wrapper-field'>
                                        <span className="desc"style={{fontSize:'13px'}} >Note:</span>
                                        <span className="inf">{(det.note)?det.note:'Aucune.'}</span>
                                    </div>
                            </div>:null
                            }
               </div>
              </fieldset>
              </div>
       
        <div className={classes.root}>
            {   
                 Object.keys(rPrivs).map((key, index) =>{ 
                    const columns =[   
                          {
                              selector:row=>row.function,
                              width:'450px !important'
                          },
                          {
                            cell: (row) => {
                                if(switches===row.id){  
                                  const objIndex = rPrivs[key].findIndex((obj => obj.id === row.id)); 
                                  
                                  if(row.hasOwnProperty("name")){
                                    delete rPrivs[key][objIndex].name
                                    delete rPrivs[key][objIndex].date
                                  }else{
                                    rPrivs[key][objIndex].name=sessionStorage.getItem('name')
                                    rPrivs[key][objIndex].date=getDate()
                                  }
                                  setTimeout(() => {
                                    setSwitches('')
                                  }, 0);  
                                }
                              return (
                                <div>  
                                <Switch size="small"
                                        checked={row.hasOwnProperty("name")}
                                        id={"s"+row.id}
                                        classes={{
                                          track: classes.switch_track, 
                                          switchBase: classes.switch_base,
                                          colorPrimary: classes.switch_primary,
                                        }}
                                        onChange={()=>{handlePrivs(row)}}
                                        name={row.module}
                                        color='primary'
                                    />   
                                </div>  
                              );
                             }
                          },
                          {
                            selector: "name",
                            cell: (row) => {
                            return (
                              <div>  
                               <p className={classes.greenText}>{row.name}</p>
                              </div>  
                            );
                           }        
                          },
                          {
                            selector: "date",
                            cell: (row) => {
                              return (
                                <div>  
                                 <p className={classes.greenText}>{row.date}</p>
                                </div>  
                              );
                             }
                          }
                        ]
                        if(openAccord){
                           return (
                            <Accordion className={classes.accordionRoot} key={index} expanded={expanded === key} onChange={handleChangeAccordion(key)}>
                                <AccordionSummary
                                    className={classes.accordionSummary}
                                    expandIcon={<ExpandMore />}
                                    aria-controls={key+"-content"}
                                    id={key+"-header"}
                                >
                                <Typography className={classes.heading}>{key}</Typography>
                                </AccordionSummary>    
                                <AccordionDetails key={index} className={classes.accordionDetails}>
                                      <div className="accord-table">
                                            <DataTable 
                                                role={role}
                                                customStyles={customStyles}
                                                columns={columns}
                                                data={rPrivs[key]}
                                                noHeader={true}
                                                noTableHead={true}
                                                noDataComponent={param.ErreurData} 
                                                highlightOnHover
                                                paginationComponentOptions={{ rowsPerPageText: 'Élément par page:', rangeSeparatorText: 'sur',selectAllRowsItemText: 'Tous'}}
                                                />
                                      </div>
                                </AccordionDetails>
                            </Accordion>) 
                            }else return <></>     
                    }
                )
            }
        </div> 
    </div>
  );
}