import React,{useState,useContext, useMemo, useCallback} from 'react';
import {DialogContent,Typography,Paper,Dialog,IconButton, Button, Divider} from '@material-ui/core';
import Draggablee from 'react-draggable';
import { paperProps} from '../../../css/muiStyle';
import {Close,Details} from '@material-ui/icons';
import { withStyles} from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import ParamContext from '../../../Contexte';
import {AlertCompo} from '../../../js/components'
import {useStyles } from '../../../css/muiStyle';
import Err1 from "../../../img/photo.png"
import DataTable from "react-data-table-component";
import {numberColumnTicket,customStylesTicket} from '../../../css/datatable-custom'
import '../../../css/ticket-bon.css'
import LogoBq from '../../../img/logos/logo_bq.png'

const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });


const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle  className={classes.root} {...other}>
        <Typography >{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <Close />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });


//Add a new role
function PaperComponent(props) {
  return (
    <Draggablee handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggablee>
  )
}


export default function Cmd_acc_details(props) {
    const classes = useStyles();
    const param=useContext(ParamContext);
    const Gfunc=require('../../../Gfunc')
    const [detail,setDetail]=useState([])
    const [tick,settick]=useState([])
    const [open,setOpen]=useState(false)
    const [openA, setOpenA] =useState(false)   
    const [messageA, setMessageA] =useState('');
    const [severity, setSeverity] =useState('info');
    const service='_cmd.php'

    const conditionalRowStyles=[
      { when: row => row.colorDif==='red',
         style: {
               color: "red",
         }
       },
    ]

    const handleAlert=(open,severity,message)=>{
        setOpenA(true)
        setSeverity(severity)
        setMessageA(message)
        setTimeout(function(){
          setOpenA(false)
        }, 5000);
        }
    
    const handleClickOpen = () => { 
        setOpen(true);
        fetchTick();
        fetch();
    };
    const handleClose = () => { setOpen(false); };

    const fetchTick=useCallback(async() => {
      if(props.row.ticketId!==null){
        const response=await Gfunc.axiosPostAction(service,"gettiket",{idt:props.row.ticketId,mag:props.do})
        if(response.data.err&&response.data.err!=='-1'){
         handleAlert(true,"error",response.data.err)
        }else{
         Gfunc.axiosResponse(response,()=>{
          if(response.data.jData){
            if(response.data.jData.ventes){
              response.data.jData.ventes.map((el)=>{
                el['colorDif']=null
              })
            }
            settick(response.data.jData)
          }
        })
      }
      return response.data.jData
    }
    },[props,Gfunc])

    const fetch=useCallback(async() => {
      const response=await Gfunc.axiosPostAction(service,"getdet",{orderId:parseInt(props.row.DT_RowId.substr(1)),prepare:false})
      if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,"error",response.data.err)
      }else{
        Gfunc.axiosResponse(response,async()=>{
          if(response.data.jData){
            var ticks=await fetchTick()||[];
            response.data.jData.map((el,index)=>{
              var index=(ticks.ventes)?ticks.ventes.findIndex((obj => obj.produit ===el.nomBq)):-1
              if(index!==-1&&ticks.ventes[index].qte!==el.qte){
                el['colorDif']='red'
                ticks.ventes[ticks.ventes.findIndex((obj => obj.produit ===el.nomBq))]['colorDif']='red'
              } 
            })
            setDetail(response.data.jData)
          }
        })
      }
    },[props,Gfunc,fetchTick])

    const columns = useMemo(()=>[   
      {
          name: "Produit",
          selector: row=>row.produit,
          sortable: false,
          width:'132px !important'
      },
      {
          name: "QTE.",
          selector: row=>row.qte,
          sortable: false,
          width:'40px !important',
          style:numberColumnTicket,
          cell:(row)=>{
            return (
            <>
              {new Intl.NumberFormat().format(row.qte)}
            </>
            )
          }
      },
      {
          name: "Prix",
          sortable: false,
          style:numberColumnTicket,
          width:'56px !important',
          cell:(row)=>{
            return (
            <>
              {new Intl.NumberFormat().format(row.prix)}
            </>
            )
          }
      },
      {
          name: "Total",
          selector: row=>row.mtn,
          style:numberColumnTicket,
          sortable: false,
          cell:(row)=>{
            return (
            <>
              {new Intl.NumberFormat().format(row.mtn)}
            </>
            )
          }
      },
    ],[])

    return (
    <div >
        <Button className={classes.fieldButton} variant="contained"  color="primary" startIcon={<Details/>} onClick={handleClickOpen} 
                style={{width:'98%'}} title="Récapitulatif de la commande">Détail commande</Button>
        <Dialog 
            open={open}
            onClose={handleClose}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={paperProps}
        >
            <DialogTitle id="simple-dialog-title" onClose={handleClose}> 
              <div className='title-cmd'>
                <b>Détail de la commande de : {props.row.accountMag}</b>
                <div id='tot-cmd'>
                  <p>Totale: </p>
                  <p>{new Intl.NumberFormat().format(props.row.mtn)}</p>
                </div>
               </div>
            </DialogTitle>
            <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA}/>
            <DialogContent >  
               <div id='com-detail'>
                 <div>
                      <Divider/>
                       <div id='head-cmd-detail'>
                         <p>Produit</p>
                         <p>Qte.</p>
                         <p>Total</p>
                       </div>
                      <Divider/>
                      <div style={{maxHeight:'350px',overflow:'auto'}}>
                         {
                          (detail.length!==0)?
                            detail.map((el,index)=>{
                              return(
                                  <div key={index}>
                                      <div id='prod-cmd-detail' style={{color:el.colorDif}}>
                                          <div style={{display:'flex',gridGap:'10px',alignItems:'center'}}>
                                              <img src={param.ProdImg+el.photoId+'.jpg'} style={{height:"50px"}}
                                                   onError={(e) => {
                                                      e.target.onerror = null
                                                      e.target.src = Err1 }}/>
                                              <div style={{textAlign:'start'}}>
                                                  <p>{el.nom}</p>
                                              </div>
                                          </div>
                                          <div>
                                            <p>{el.qte}</p>
                                          </div>
                                          <div>
                                            <p>{new Intl.NumberFormat().format(el.prx*el.qte)}</p>
                                          </div>
                                      </div>
                                     {(detail.length-1!==index) ?<Divider/>:null}
                                  </div>
                              )
                          })
                          :<p style={{textAlign:"center",fontWeight:'bold',marginTop:'5px'}}>{param.ErreurData}</p>
                         }
                         <br/>
                      </div>
                 </div>
                 <div>
                  <div  id="ticket" style={{width:'11cm'}} >
                    <div id='ticketHead'>
                      <img src={LogoBq} id='logo_ticket' alt='logo'/>
                    </div>
                    <hr />
                      <div className="rowGrid" id="row-2">
                        <p>Ticket n: <b>{tick.numero}</b></p>
                        <p>Date: <b>{tick.date_tiket}</b></p>
                      </div>
                      <div className="rowGrid" id="row-2">
                        <p>Client: <b>{(tick.client)?tick.client.substr(0,3):tick.client}</b></p>
                        <p>Vendeur: <b>{tick.vendeur}</b></p>
                      </div>
                      <div className="rowGrid" id="row-2">
                        {(detail.tel!=null)?<p>Tel client: <b>{new Intl.NumberFormat().format(tick.tel)}</b></p>:<p></p>}
                        <p>Ancien solde: <b>{new Intl.NumberFormat().format(Math.round(tick.ex_solde))}</b></p>
                      </div>
                    { ((!tick.ventes&&tick.versement>0)||(tick.ventes&&tick.ventes.length===1&&tick.ventes[0].id==null&&tick.versement>0))?
                      <div id='empty-ventes'>Ticket de versement.</div>
                        :<><DataTable 
                              persistTableHead
                              columns={columns}
                              data={tick.ventes}
                              noHeader={true}
                              customStyles={customStylesTicket}
                              defaultSortAsc={true}
                              noDataComponent={param.ErreurData}    
                              highlightOnHover
                              conditionalRowStyles = { conditionalRowStyles }
                              paginationComponentOptions={{ rowsPerPageText: 'Element par page:', rangeSeparatorText: 'sur'}}               
                            />
                        <div className="rowGrid" id="row-3">
                        <p>Nombre d'article: <b>{(tick.ventes)?tick.ventes.length:0}</b></p>
                        <p >Total: <b>{new Intl.NumberFormat().format(Math.round(tick.total))}</b></p>
                      </div>
                      </>
                        }
                        <br/>
                      <div className="rowGrid" id="row-4">
                        <p>Versement: <b>{new Intl.NumberFormat().format(Math.round(tick.versement))}</b></p>
                        <p>Nouveau solde: <b>{new Intl.NumberFormat().format(Math.round(tick.new_solde))}</b></p>
                      </div>
                      <hr />
                      <p style={{textAlign:"center",fontWeight:'bold'}}>Merci pour votre fidélité</p>
                      </div>
                 </div>
               </div>
            </DialogContent>
        </Dialog>
      </div>
  );
}
