
import React from 'react'
import { BarChart,XAxis,YAxis,Tooltip ,CartesianGrid,Bar,ResponsiveContainer, Legend} from 'recharts';

export default function DynamicBarchart(props){
    const Gfunc=require('../Gfunc')

    function CustomTooltip({ payload, label, active,...props }) {
        if (active&&payload!=null) {
         var dateParts = payload[0].payload.day.split("-");
         var dateObject = new Date(dateParts[2], dateParts[1]-1, dateParts[0]);
               return (
                <div className="custom-tooltip" style={{ border:"2px solid"+payload[0].color}}>
                     <p className="intro"><b>{Gfunc.DateDay(dateObject)}</b>{(`  ${payload[0].payload.day}`)}</p>
                     {
                      payload.map((el,index)=>{
                        return <p key={index} className="intro" style={{ color:el.color}}>
                                 <b>{(el.dataKey==='digital')?"Binar":el.dataKey.charAt(0).toUpperCase()+ el.dataKey.slice(1)}:</b>{(payload.length!==0)?new Intl.NumberFormat().format(`${el.value}`):0 }
                               </p>
                       })
                      }
                </div>
                    )
         }
       return null;
                }
    
       const RenderLegend = (props) => {
        const { payload } = props;
        return (
          <ul style={{display:'flex', gridGap:'30px',width:'100%',justifyContent:'center'}}>
            {
              payload.map((entry, index) => (
                <li key={`item-${index}`} style={{color:entry.color,listStyle:'square'}}>{(entry.dataKey==='digital')?"Binar":entry.dataKey.charAt(0).toUpperCase()+ entry.dataKey.slice(1)}</li>
              ))
            }
          </ul>
        );
      }

    return (
      <div>
      <ResponsiveContainer width='100%' height={props.height}>
            <BarChart  data={props.data} stackOffset="sign" margin={props.margin}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey={props.dataKeyX}  /*tickFormatter={()=>formatXAxis}*/ tick={{ fill: 'black',fontSize:'11px',dy:3,angle: -20}}/>
                <YAxis type='number' tick={{ fill: 'black',fontSize:'13px'}}/>
                <Tooltip content={(props.data.length!==0)?<CustomTooltip />:null}/>
                <Legend verticalAlign="top" height={36} content={(props.legend==='true')?<RenderLegend />:null}/>
                {
                    props.dataKeyY.map((el,index)=>{
                        return <Bar dataKey={el} stackId={props.stackId[index]} fill={props.fill[index]} index={index} /*label={<CustomizedLabel/>}*//>
                    })
                }
            </BarChart>
      </ResponsiveContainer >
      </div>
    );
}