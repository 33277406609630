import axios from 'axios'
import gradstop from 'gradstop';

    //Global functions that can be used in diffrent projects
    //treat the response from api axios
    export function axiosResponse(response,f=()=>{}){
      if (response.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        //console.log(response.response.data);
        //console.log(response.response.status);
        //console.log(response.response.headers);
       /* if(response.response.status>400){
          localStorage.clear();
        }
        window.location=("/"); */
      } else if (response.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
       // console.log(response);
       // console.log(response.request);
       // console.log(response.message);
        //changer le lien 
        if(response.request.status<100)
       { 
        alert("Une erreur s'est produite");
       // if(localStorage.getItem("netError")!==response.message||!window.location.pathname.split("/")[1].includes("error")){window.location=("/error?message="+response.message); }}
       }
       //else {if(localStorage.getItem("netError")!=="undefined") window.location=("/") }
       //localStorage.setItem("netError",response.message);
      } else {
        // Something happened in setting up the request that triggered an Error
        //console.log('Error', response.message);
      }
      if(response.data) {if(response.data.err==null){
          f();
      }else{  if(localStorage.length!==0)
                { if(response.data.err==="-1"){
                      alert("Votre session est expiré");
                      localStorage.clear();
                      window.location=("/"); 
                  }else{
                      alert(response.data.err);
                  }}
       }}
 }
    //get headers 
    export function getHeader(){
        const param=JSON.parse(localStorage.getItem("param"));
        const headers={
          'content-type': 'application/json , multipart/form-data',
          'session':localStorage.getItem('token'),
          'appID':param.appID,
          //'Access-Control-Allow-Origin' : 'http://algdev.digitarts.cloud:8080/~backend/irahati/',
          //'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
          }
          return headers;
    }
    
    // manage the ajax's errors in datatables 
    export function errorMan(data){
                if (data.err!=null && data.err!==undefined){
                    if(data.err==='-1') {
                        alert('votre session est expiré ');
                        localStorage.setItem('isLoged', false);
                        window.location=("/");  
                    } else alert(data.err);
                }
    }
    //test if an object is empty 
    export function isEmpty(obj) {
      for(var key in obj) {
          if(obj.hasOwnProperty(key))
              return false;
      }
      return true;
    }
    //get response from add service
    export function axiosAdd(service, data){
        const param=JSON.parse(localStorage.getItem("param"));
        const response= axios({
            method: 'POST',
            url:param.urlService+service+'?do=add',
            headers: getHeader(),
            data:JSON.stringify(data)     
        })
        return response
      }

    //check if phone number
    export function phonenumber(inputtxt){
      //var phoneno = /^\d{10}$/;
      var phoneno = '[0][5-7][1-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]';
      if(inputtxt.match(phoneno) && inputtxt.length===10){ return {response:true,message:"C'est le bon format."};}
      else return {response:false,message:"Vous devez insérer le bon format!"};
    }
    
 //get service
 export function axiosGetAction(service ,action){
    const param=JSON.parse(localStorage.getItem("param"));
    const response= axios({
        method: 'GET',
        url:param.urlService+service+'?do='+action,
        headers: getHeader(),    
    }).catch(function(error){
      error["data"]=""
      return error
   })
    return response
  }
 //POST axios
 export function axiosPostAction(service ,action,data){
    const param=JSON.parse(localStorage.getItem("param"));
    const response= axios({
        method: 'POST',
        url:param.urlService+service+'?do='+action,
        headers: getHeader(), 
        data:JSON.stringify(data)    
    }).catch(function(error){
      error["data"]=""
      return error
   })
    return response
  }
  export function axiosPostAction1(service,action,data){
    const param=JSON.parse(localStorage.getItem("param"));
    const response= axios({
        method: 'POST',
        url:param.urlService+service+'?do='+action,
        headers: getHeader(), 
        data:data   
    }).catch(function(error){
      error["data"]=""
      return error
   })
    return response
  }
 //POST axios
 export function axiosFlagman(srv,id,att,val){
    const param=JSON.parse(localStorage.getItem("param"));
    const response=axios({
        method: 'POST',
        url:param.urlService+'flagman.php',
        headers: getHeader(),
        data:JSON.stringify({ srv:srv, id:id,att:att, val: val })    
    }).catch(function(error){
      error["data"]=""
      return error
   })
    return response
  }
    
// group by key function
export const groupBy = (array, key) => {
    return array.reduce((result, currentValue) => {
      (result[currentValue[key]] = result[currentValue[key]] || []).push(
        currentValue
      );
      return result;
    }, {});
  };

  // join two arrays of objects in a prop
  export const merge=(a, b, prop)=> {
    let merged = [];
      for(let i=0; i<a.length; i++) {
        merged.push({
        ...a[i], 
        ...(b.find((itmInner) => itmInner[prop] === a[i][prop]))}
        );
      }
    return merged
  }

  export const getDate=()=>{
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); 
    var yyyy = today.getFullYear();
    today = dd + '-' + mm + '-' + yyyy;
    return today
  }
 /* export const generateColors=(nbr,arr)=>{
    const gradient = gradstop({
      stops: nbr,
      inputFormat: 'hex',
      colorArray: arr
  });
  return gradient
  }*/
  //delete an item from an array of object by id
  export function removeItemsByAtt(arr,value,att) {
    var index = arr.map(x => {
      return x[att];
    }).indexOf(value);
    arr.splice(index, 1);
  }
  
  //eliminer les espaces pour les chaines de caractere
  export const DeleteSpace=(edt)=>{
    do{
      var index = edt.indexOf("&nbsp;");
      if(index!==-1){ 
        edt=edt.replace("&nbsp;","");
        edt=edt.replace(/\s+/g," ").trim()
      }   
    } while (edt.indexOf("&nbsp;")!==-1);
     var text = edt.replace(/<[^>]*>/g, "")
   /* do{
      var ind = edt.indexOf("<div>");
      if(ind!==-1){ 
        edt=edt.replace("<div>"," ");
        edt=edt.replace("<br>"," ");
        edt=edt.replace("</div>"," ");
        edt=edt.replace("<span>"," ");
        edt=edt.replace("</span>"," ");
        edt=edt.replace("<pre>"," ");
        edt=edt.replace("</pre>"," ");
      }   
    } while (edt.indexOf("<div>")!==-1 && edt.indexOf("<pre>")!==-1 && edt.indexOf("<span>")!==-1);*/
   /* do{
      var ind = edt.indexOf("</r>");
      if(ind!==-1){ 
        edt=edt.replace("</r>","");
      }   
    } while (edt.indexOf("/r")!==-1);*/
    return text
  }
  export const DeleteSpace2=(edt)=>{
    edt=edt.replace(/&nbsp;/g,"");
    return edt.replace(/\s\s+/g, ' ').trim();
  }

  //return the index of an Object by a property value
  export function getIndex(arr,val,att) {
    var index = arr.map(x => {
      return x[att];
    }).indexOf(val);
    return index;
  }
  export function getIndexComp(arr,val,att,att1) {
    var value=val.split(' ')
    var ind=arr.findIndex((obj => obj[att] ===value[0] && obj[att1] === value[1]));
    if(ind!==-1){return arr[ind]}
    else return null

    }
  
  export const formatDate=(today)=>{
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); 
    var yyyy = today.getFullYear();
    
    today = dd + '-' + mm + '-' + yyyy;
    return today
  }
  export const formatDate1=(today)=>{
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); 
    var yyyy = today.getFullYear();

    today = mm + '-' + dd + '-' + yyyy;
    return today
  }
  export const formatDateAr=(today)=>{
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); 
    var yyyy = today.getFullYear();

    today = yyyy + '-' + mm + '-' + dd;
    return today
  }
  export const GrapheDate=(datee,att)=>{
    var dat=new Date((datee))  
    if(att==='mm')
    {
      var mm = String(dat.getMonth()).padStart(2, '0'); 
      return mm
    }else if(att==='yy') {
      var yy = String(dat.getFullYear()); 
      return yy
    } else{
      var jj = String(dat.getDate()).padStart(2, '0');
      return jj
    }
  }
export function custom_sort(a, b) {
    var aComps = a.day.split("-");
    var bComps = b.day.split("-");
    var aDate = new Date(aComps[2], aComps[1], aComps[0]);
    var bDate = new Date(bComps[2], bComps[1], bComps[0]);
      return aDate.getTime() - bDate.getTime();
}
export function custom_sortDate(a, b) {
    var aComps = a.date.split("-");
    var bComps = b.date.split("-");
    var aDate = new Date(aComps[2], aComps[1], aComps[0]);
    var bDate = new Date(bComps[2], bComps[1], bComps[0]);
      return aDate.getTime() - bDate.getTime();
}
export  function DateDeb(today){
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth()).padStart(2, '0'); 
  var yyyy = today.getFullYear();
  if(mm==='00'){
    today = (yyyy-1) + '-' + 12 + '-' + dd;
  }
  else{
        today = yyyy + '-' + mm + '-' + dd;

  }  return today
}
export const generateColors=(nbr,arr)=>{
  const gradient =  gradstop({
    stops: nbr,
    inputFormat: 'hex',
    colorArray: arr
});
return gradient;
}
export  const rgbToHex = (rgb) => {
  var index = rgb.indexOf("rgb"); 
  if(index!==-1)
    {
     const rgbExcludeFirst = rgb.split('rgb(')[1]; 
     const rgbExcludeLast = rgbExcludeFirst.split(')')[0]; 
     const rgbValueArray = rgbExcludeLast.split(',');  
     const first= Number(rgbValueArray[0]).toString(16)
     const second=Number( rgbValueArray[1]).toString(16)
     const third=Number(rgbValueArray[2]).toString(16)
     return '#'+first+second+third
    }else return rgb
  };
export const secondToHour=(secondes)=>{
		//On test si les seconds sont bel et bien un nombre entier
		var exp = new RegExp("^[0-9]+$","g");
		if(exp.test(secondes)){
			var nbHour = parseInt(secondes / 3600);
			var nbsecondRestante = (secondes % 3600);
      var nbminuteRestante=parseInt (nbsecondRestante/60)
      var nbseconde= (nbsecondRestante%60)

			if(nbseconde === 0){
        return nbHour + "h"+nbminuteRestante+'min';
      }else if(nbseconde === 0 &&nbminuteRestante===0 ){
        return nbHour + "h"
      }else{
        return (nbHour+"h" +nbminuteRestante +'min'+nbseconde+'s');
      }
		} 
}
export const convertirMin=(minutes)=>{
  //On test que  les  minutes representent un nombre entier
  var Myexp = new RegExp("^[0-9]+$","g");
  if(Myexp.test(minutes)){
    var nbHour = parseInt(minutes / 60);
    var jours=parseInt(nbHour/24)
    var hoursRestant=(nbHour%24)
    var nbminuteRestante = (minutes % 60);
    if(jours===0){jours=''}else jours=jours+'j '
    if(hoursRestant===0){hoursRestant=''} else hoursRestant=hoursRestant+'h '
    if(nbminuteRestante===0){nbminuteRestante=''}else nbminuteRestante=nbminuteRestante+"min"
    return (jours+hoursRestant+ nbminuteRestante);
  }
} 
export const DateDay=(today)=>{
var weekday = ["Dimanche","Lundi","Mardi","Mercredi","Jeudi","Vendredi","Samedi",]
var n = weekday[today.getDay()];
return n
}
 //Check if it is the same day
 export const datesAreOnSameDay = (first, second) =>
 first.getFullYear() === second.getFullYear() &&
 first.getMonth() === second.getMonth() &&
 first.getDate() === second.getDate()
 ;

Date.prototype.addDays = function(days) {
  var dat = new Date(this.valueOf())
  dat.setDate(dat.getDate() + days);
  return dat;
}

export function getDates(startDate, stopDate) {
 var dateArray =[]
 var currentDate = startDate;
 while (currentDate <= stopDate) {
   dateArray.push(currentDate)
   currentDate = currentDate.addDays(1);
 }
 return dateArray;
}

//get next element
export const navObj = (obj, currentKey, direction) => {
  return Object.keys(obj).indexOf(currentKey) + direction;
};

export function getRGB(str) {
  var match = str.match(/rgba?\((\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*(?:\)|,\s*(\d?(?:\.\d+)?)\))/  );
  var arr = [match[1], match[2], match[3]];
  if (match[4]) {          
      arr.push(match[4]);  
  }                        
  return arr;
}
export function getRGBA(str) {
  var match = str.match(/rgba?\((\d{1,3}), ?(\d{1,3}), ?(\d{1,3}), ?(\d{1,3})\)?(?:, ?(\d(?:\.\d?))\))?/);
  var  arr = [match[1], match[2], match[3], match[4]];
  return arr;
}

 //get user infornations
  export const getInfo=()=>{
    const info={
      name:localStorage.getItem('name'),
      session:localStorage.getItem('token'),
      mag:localStorage.getItem('mag'),
      magid:localStorage.getItem('magid'),
      mags:localStorage.getItem('mags'),
    }
 return info;
} 

//fetch update elem number
export const updateElem = (id,val) => {
  var elem = document.getElementById(id);
  if(elem)
  elem.innerHTML= new Intl.NumberFormat().format(val);
}
//fetch update elem string
export const updateElemS = (id,val) => {
  var elem = document.getElementById(id);
  if(elem!=null&&elem!==undefined) elem.innerHTML= val;
}

export const getParams=async()=>{
  var param = null;
  const cacheBuster = Math.random();
  await fetch(`/param.json?cacheBuster=${cacheBuster}`
  ,{
    headers : { 
      'Content-Type': 'application/json',
      'Accept': 'application/json'
     }
  }
  )
    .then(function(response){
      //console.log(response)
      param=response.json();
    })
    .then(function(myJson) {
      //console.log(myJson);
    });
    return param
}

export function isNullUndfEmpStr(obj) {
  for (var key in obj) {
      if (obj[key] != null && obj[key] !== "" && obj[key] !== undefined)
          return false;
  }
  return true;
}

export function FormatStringNumber(str){
const inputString = str;
const stringArray = inputString.split('+');

const formattedArray = stringArray.map((numString) => {
  const num = parseInt(numString, 10); // Convertit la chaîne en nombre
  return new Intl.NumberFormat().format(num); // Formate le nombre avec Intl.js
});

const formattedString = formattedArray.join(' + ');

return formattedString;
}