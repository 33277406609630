import React,{useState,useMemo,useCallback} from 'react';
import {DialogActions,DialogContent,DialogTitle,Paper,Button,Dialog,CircularProgress, Tooltip} from '@material-ui/core';
import DataTable from "react-data-table-component";
import Draggable from 'react-draggable';
import {IndeterminateCheckBox,SupervisorAccount,Add} from '@material-ui/icons';
import {axiosResponse,axiosPostAction,removeItemsByAtt} from'../../../Gfunc'
import Multiselect from 'multiselect-react-dropdown';
import { useStyles,paperProps2,multiSelect } from '../../../css/muiStyle';
import {customStyles}from '../../../css/datatable-custom'
import {AlertCompo} from "../../../js/components"
import '../../../css/admin.css'
import useMediaQuery from '@mui/material/useMediaQuery';
import { useContext } from 'react';
import ParamContext from '../../../Contexte';

//the dialog that adds users to a role**********
function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

function Adm_rol_usr(props) {
    const param=useContext(ParamContext);
    const [open, setOpen] = useState(false);
    const title="Les utilisateurs de"
    const serviceUsrs="_usr.php"
    const service="_rol.php"
    const [option, setOption] = useState([]);
    const [data, setData] = useState([]);
    const [selectedValues, setSelectedValues] = useState([]);
    const [loading, setLoading] = useState(false);
    const classes=useStyles();
    const [,setDel]=useState(-1);
    const [openA, setOpenA] =useState(false);
    const [messageA, setMessageA] =useState('');
    const [severity, setSeverity] =useState('info');
    var users =[]
    const matches = useMediaQuery('(min-width:150px) and (max-width: 850px)');

    //handle alerts properties
    const handleAlert=(open,severity,message)=>{
        setOpenA(true)
        setSeverity(severity)
        setMessageA(message)
        setTimeout(function(){
          setOpenA(false)
        }, 5000);
    }

    //dialog functions************************
    const handleClickOpen = () => { 
      if(option.length===0){fetchUsers();}
      if(data.length===0){fetch();}
      setOpen(true); 
    };

    const handleClose = () => { setOpen(false); setSelectedValues([]);setData([])};

    //fetch the users that have the selected role
    const fetch= useCallback(async() => {
      setLoading(true)
      const dat={idr:props.row.DT_RowId.substr(1),unsets:false}
      const response=await axiosPostAction(service,"getrusrs",dat)
      setLoading(false)
      if(response.data.err&& response.data.err!==-1){
       handleAlert(true,'error',response.data.err)
      }else{
        axiosResponse(response,()=>{
          if(response.data.jData){
            setData(response.data.jData);
          }
          })
      }
    },[props.row.DT_RowId])

    //fetch all users that doesn't have the selected role
    const fetchUsers=useCallback(async() => {
      const dat={idr:props.row.DT_RowId.substr(1),unsets:true}
      const response=await axiosPostAction(service,"getrusrs",dat)
        axiosResponse(response,()=>{
        setOption(response.data.jData);
    })
    },[props.row.DT_RowId])

    //Multiselect functions********************
    function onSelect(selectedList, selectedItem) {
      users=selectedList;
      setSelectedValues(users)
    }

     //suppression des options
    function onRemove(selectedList, removedItem) {
       setSelectedValues(selectedList)
    }

    //Add users to role
    const handleAddUsr=useCallback(async() => {
      try {
        var idrss=[]
        selectedValues.map((opt) => {
          idrss.push(opt.id)
          return true
        })
        const response = await axiosPostAction(service, "setrusers", { idr: props.row.DT_RowId.substr(1), idus: idrss })
        if (response.data.err && response.data.err !== '-1') {
          handleAlert(true, "error", response.data.err)
        }
        else {
          axiosResponse(response, () => {
            handleAlert(true, "success", 'Le role a été attribués avec succès aux utilisateurs sélectionnés.')
            selectedValues.map((item) => {
              removeItemsByAtt(option, item.id, "id")
              data.push(item)
              return true;
            })
          })
        }
        setSelectedValues([])
      } catch (ex) {
        console.log(ex)
      }

  },[data,option,selectedValues,service,props.row.DT_RowId])
   
  const handleSetData=useCallback((row,att)=>{
    removeItemsByAtt(data,row.id,att)
  },[data])
  
  //Delete users from role
  const handleDeleteUser=useCallback(async(row)=>{
    const response =await axiosPostAction(serviceUsrs,"delurole",{idu:row.id, idr:props.row.DT_RowId.substr(1)});
    if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,"error",response.data.err)
    } else{
        axiosResponse(response,()=>{
        handleAlert(true,"success","L'utilisateur a été supprimé avec succès.")
        handleSetData(row,"id")
        setDel(row.id)
        fetchUsers();
      })
  }
  },[handleSetData,fetchUsers,serviceUsrs,props])

  const columns =useMemo(
    () => [   
      {
        name: "UTILISATEURS",
        selector:row=> row.name,
        sortable: true,
      },
      {
        right:true,
        cell: (row) => {
          return (
            <Tooltip title="Supprimer l'utilisateur">
              <div onClick={()=>handleDeleteUser(row)}><IndeterminateCheckBox className={classes.redIcon} fontSize="small"/></div>   
            </Tooltip>
          );
         },
      }  
    ],[handleDeleteUser,classes]);


  return (
    <div>
        <Tooltip title="Ajouter / Supprimer un utilisateur">
          <SupervisorAccount onClick={handleClickOpen}/>
        </Tooltip>
        <Dialog
          open={open}
          onClose={handleClose}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          PaperProps={paperProps2} 
        >
           <DialogTitle className={classes.topDivTitle} id="draggable-dialog-title" >{title} {props.row.role}</DialogTitle>
           <DialogContent>
                <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA}/>
                <fieldset className="fieldsetTop" id="role">
                  <legend>Ajouter des utilisateurs</legend>
                  {
                   (option.length!==0)?
                    <div id='row-1'>
                        <Multiselect
                            style={multiSelect}
                            className='smallText'
                            options={option} 
                            selectedValues={selectedValues}
                            onSelect={onSelect} 
                            onRemove={onRemove} 
                            emptyRecordMsg="Il n'y a plus d'options"
                            displayValue="name" 
                            placeholder={(matches)?"les utilisateurs":"Selectioner les utilisateurs"}
                            closeOnSelect={false}
                            hidePlaceholder={true}
                        />
                      <Button className={classes.fieldButton} variant="contained" onClick={handleAddUsr} color="primary" startIcon={<Add/>}>AJOUTER</Button>
                    </div>:<></>
                  }
            </fieldset>
            <br/>
            <div>
                <DataTable 
                  persistTableHead
                  columns={columns}
                  progressComponent={<CircularProgress className={classes.circularProgress} />}
                  progressPending={loading}
                  data={data}
                  noHeader={true}
                  customStyles={customStyles}
                  defaultSortAsc={true}
                  noDataComponent={param.ErreurData}    
                  highlightOnHover
                  pagination
                  paginationComponentOptions={{ rowsPerPageText: 'Element par page:', rangeSeparatorText: 'sur'}}               
               />
            </div>
         </DialogContent>
         <DialogActions>
             <Button variant="contained" onClick={handleClose} color="secondary">FERMER</Button>
         </DialogActions>
       </Dialog>
      </div>
  );
}
export default Adm_rol_usr;