import React,{useState,useContext} from 'react';
import {DialogContent,Typography,Paper,Dialog,IconButton,FormControlLabel, Checkbox, CardActionArea, CardContent, Divider} from '@material-ui/core';
import Draggablee from 'react-draggable';
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {paperPropsComs} from '../../../css/muiStyle';
import {Close, Delete, FiberManualRecord} from '@material-ui/icons';
import '../../../css/compte.css'
import { withStyles} from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Err1 from "../../../img/photo.png"
import ParamContext from '../../../Contexte';
import ImgDialog from './cat_prd_profil_add'
import PlacePhoto from "../../../img/PhotoNAdd.png"
import { makeStyles } from '@material-ui/core/styles';
import {AlertCompo} from '../../../js/components'
import { useMediaQuery } from 'react-responsive'
import PhZoom from './cat_prd_profil_img'

const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });
const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle  className={classes.root} {...other}>
        <Typography >{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <Close />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });
  const useStyless = makeStyles((theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      margin: theme.spacing(2),
    },
    card: {
      width:200,
      maxWidth: 250,
      background:'rgb(240, 234, 234)'
    },
    media: {
      height: 140,
    },
    input: {
      display: 'none',
    },
  }));

//Add a new role
function PaperComponent(props) {
  return (
    <Draggablee handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggablee>
  )
}

const grid = 8;

/*const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  padding: grid * 2,
  margin: `0 ${grid}px 0 0`,
  background: isDragging ? "lightgreen" : "grey",

  ...draggableStyle
});*/

const getListStyle = (isDraggingOver, itemsLength,width) => ({
  background: isDraggingOver ? "lightgray" : "white",
  display: "flex",
  padding: grid,
  width:width,
  overflow:'auto',
  justifyContent:'center'
});

export default function Cat_prd_profil(props) {
    const classess = useStyless();
    const param=useContext(ParamContext);
    const Gfunc=require('../../../Gfunc')
    const [,setUpd]=useState(false)
    const [open,setOpen]=useState(false)
    const [imgs,setImgs]=useState([])
    const [openA, setOpenA] =useState(false)
    const [messageA, setMessageA] =useState('');
    const [severity, setSeverity] =useState('info');
    const service='_prd.php'

   const isDesktopOrLaptop = useMediaQuery({query: '(max-width: 1150px)'})

    const handleAlert=(open,severity,message)=>{
        setOpenA(true)
        setSeverity(severity)
        setMessageA(message)
        setTimeout(function(){
          setOpenA(false)
        }, 5000);
        }
    
    const handleClickOpen = () => { 
        setOpen(true);setImgs([]);
        getImg();
    };
    const handleClose = () => { setOpen(false); };

    const getImg=async()=>{
        const response = await Gfunc.axiosPostAction1(service ,'getpphoto',{produitId:parseInt(props.data.DT_RowId.substr(1))})
        if(response.data.err&&response.data.err!=='-1'){
          handleAlert(true,"error",response.data.err)  
        }else{
          Gfunc.axiosResponse(response,()=>{
            setImgs(response.data.jData)
          })
        }
      }
   
    const handleUpd=(val)=>{setUpd(val)}

    const handlePhMain=async(row)=>{
        const response = await Gfunc.axiosPostAction(service ,'setphmain',{produitId:parseInt(props.data.DT_RowId.substr(1)),photoId:parseInt(row.photoId)})
        if(response.data.err&&response.data.err!=='-1'){
          handleAlert(true,"error",response.data.err)  
        }else{
          Gfunc.axiosResponse(response,()=>{
           if(response.data.jData){
            const objIndex = imgs.findIndex((obj => obj.main ===true));
            const objIndexR = imgs.findIndex((obj => obj.photoId ===row.photoId));
            if(objIndex!==-1){
              imgs[objIndex].main=false
            }
            if(objIndexR!==-1){
              imgs[objIndexR].main=true
            }
            setUpd(true)
            handleUpd(false)
           }
          })
        }
      }
   
    const handlePhDel=async(row)=>{
      const x=window.confirm("Êtes-vous sûrs de supprimer cet élément?");
      if(x){
        const response = await Gfunc.axiosPostAction(service ,'delphprod',{produitId:parseInt(props.data.DT_RowId.substr(1)),photoId:parseInt(row.photoId)})
        if(response.data.err&&response.data.err!=='-1'){
          handleAlert(true,"error",response.data.err)  
        }else{
          Gfunc.axiosResponse(response,()=>{
           if(response.data.jData){
             handleAlert(true,"success"," L'image a été supprimé avec succès")
             const objIndex = imgs.findIndex((obj => obj.photoId ===row.photoId));
             imgs.splice(objIndex,1)
           }
          })
        }
      }
    }
   
      const handleDragEnd =async (e) => {
        if (!e.destination) return null;
        let tempData = Array.from(imgs);
        let [source_data] = tempData.splice(e.source.index, 1);
        tempData.splice(e.destination.index, 0, source_data); 
        setImgs(tempData);
        const response = await Gfunc.axiosPostAction(service ,'orderph',{produitId:parseInt(props.data.DT_RowId.substr(1)),this_photo:parseInt(tempData[e.destination.index].photoId),
                                                      before_that:(e.destination.index!==0)?parseInt(tempData[e.destination.index-1].photoId):''})
        if(response.data.err&&response.data.err!=='-1'){
            handleAlert(true,"error","vous n'avez pas le droit d'effectuer cette action")
            setImgs(imgs)
          }else{
          Gfunc.axiosResponse(response,()=>{
            if(response.data.jData===true){
            }
            })
          }
      };

    const handleAddNewPh = async(event) => {
        var formData = new FormData();
        formData.append("img", event.target.files[0]);
        formData.append("produitId", props.data.DT_RowId.substr(1));
        const response = await Gfunc.axiosPostAction1(service ,'addph',formData)
        if(response.data.err&&response.data.err!=='-1'){
           handleAlert(true,"error",response.data.err)
        }else{
        Gfunc.axiosResponse(response,()=>{
          if(response.data.jData){
            imgs.push({photoId:response.data.jData.photoId,main:false,ordre:null})
            handleAlert(true,"success","L'image a été ajouté avec succès")

           }
           })
        }
    };

  return (
    <div >
    <img src={param.ProdImg+props.data.mainPhotoId+'.jpg'} onClick={handleClickOpen} className='image-prod'
        onError={(e) => {
        e.target.onerror = null
        e.target.src = Err1 }}/>
      <Dialog 
            id='photos-dialog'
            open={open}
            onClose={handleClose}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={paperPropsComs}
      >
        <DialogTitle id="simple-dialog-title" onClose={handleClose}></DialogTitle>
        <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA}/>
        <DialogContent>  
         <div id='div-name' style={{marginBottom:'5px',marginLeft:'10px',fontSize:'14px',fontWeight:'bold'}}>
            <p >{props.data.nameFr}</p>
            <FiberManualRecord style={{color:props.data.colorCode}}/>
         </div>
         <Divider /><br/>  
         <div id='add-photo-container'>
        <DragDropContext onDragEnd={handleDragEnd} >
          <Droppable droppableId="droppable-1" direction="horizontal">
            {(provider,snapshot) => (
              <div
                //id="photos-card"
                ref={provider.innerRef}
                style={getListStyle(
                  snapshot.isDraggingOver,
                  imgs.length,(isDesktopOrLaptop)?'100%':'fit-content'
                )}
                {...provider.droppableProps}
              >
                {
                imgs.map((el,index) => (
                  <Draggable
                    key={el.photoId}
                    draggableId={el.photoId.toString()}
                    index={index}
                  >
                    {(provider,snapshot) => (
                      <div {...provider.draggableProps}  {...provider.dragHandleProps} ref={provider.innerRef}>
                       <Paper elevation={5}  style={{border:'1px solid #f0eaea',borderRadius: '8px',backgroundColor:'white',maxHeight:'280px',justifyContent:'center',
                              minWidth:'180px',padding:'0px',margin:'8px'}}>
                                <div id="div-head" key={Math.floor(Math.random() * 100)}>
                                    <FormControlLabel  label="Principale" 
                                                       control={<Checkbox defaultChecked={el.main} style={{transform: "scale(0.9)"}} 
                                                       onChange={e=>{handlePhMain(el)}} inputProps={{ 'aria-label': 'controlled' }}  />}
                                                /> 
                                    <div onClick={()=>{handlePhDel(el)}} style={{textAlign:'end',cursor:'pointer'}}><Delete style={{color:'red',width:'22px',height:'22px'}} /></div>
                                </div>
                                <PhZoom data={el}/>
                      </Paper>
                      </div>
                    )}
                  </Draggable>
                ))
                }
                {provider.placeholder}
              </div>
            )}
          </Droppable>
      </DragDropContext>
      <div style={{display:'flex'}}>
        <ImgDialog data={props.data} handleAlert={handleAlert} setOpen={setOpen} imgs={imgs}/>
        <Paper style={{border:'1px solid #f0eaea',borderRadius: '8px',backgroundColor:'white',maxHeight:'280px',justifyContent:'center',
                      minWidth:'150px',marginLeft:'8px'}}  elevation={5}>
                      <CardActionArea>
                          <CardContent>
                          <input
                              accept=".jpg"
                              className={classess.input}
                              id="photo-input"
                              multiple
                              type="file"
                              onChange={handleAddNewPh}
                          />
                          <label htmlFor="photo-input">
                              <img src={PlacePhoto} style={{height:'220px',width:'100%',cursor:'pointer'}}/>
                          </label>
                          </CardContent>
                      </CardActionArea>
        </Paper>
        </div>
      </div>
        </DialogContent>
      </Dialog>
      </div>
  );
}
