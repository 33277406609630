import React,{useState,useRef, useCallback, useContext} from 'react';
import {DialogActions,DialogContent,DialogTitle,Paper,Button,Dialog,TextField} from '@material-ui/core';
import Draggable from 'react-draggable';
import { ValidatorForm} from 'react-material-ui-form-validator';
import { DoneAll,Add } from '@material-ui/icons';
import {axiosPostAction,axiosResponse} from'../../../Gfunc'
import { useStyles,paperPropsSmall } from '../../../css/muiStyle';
import {AlertCompo} from '../../../js/components'
import Autocomplete from '@mui/material/Autocomplete';
import ParamContext from '../../../Contexte';

//Add a new message
function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function Sms_add_add(props) {
    const Gfunc=require('../../../Gfunc')
    const param=useContext(ParamContext);
    const classes=useStyles();
    const [usr,setUsr]=useState([]);
    const [num,setNum]=useState('');
    const [msg,setMsg]=useState('');
    const [error, setError] = useState(false);
    const [open, setOpen] = useState(false);
    const [openA, setOpenA] =useState(false);
    const [messageA, setMessageA] =useState('');
    const [severity, setSeverity] =useState('info');
    const service="_sms.php"

  
    const handleAlert=(open,severity,message)=>{
        setOpenA(true)
        setSeverity(severity)
        setMessageA(message)
        setTimeout(function(){
          setOpenA(false)
        }, 5000);
    }

    const handleClickOpen = () => { 
      setOpen(true); 
      setNum('');setMsg('');setError(false)
    };
    const handleClose = () => { setOpen(false); };

    const fetchUsr= useCallback(async() => {
      const response = await Gfunc.axiosGetAction('_cpt.php' ,'gets')      
      if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,"error",response.data.err)
      }else{
        Gfunc.axiosResponse(response,()=>{
          response.data.jData.map((el)=>{
            el['compte']=el.nom+' '+el.prenom+' ['+el.mag+'] '
          })
          setUsr(response.data.jData)  
        })
      }
    },[Gfunc,param.urlService]);

    const handleOpenUsr= useCallback(async () => {
      if(usr.length===0)
      fetchUsr();
    },[usr,fetchUsr])

    const handleSubmit= async(e) =>{
        const phoneRegex = /^(00213|\+213|0)(5|6|7)[0-9]{8}$/;
        const tableau = num.split(',');
        const tableauFiltre = tableau.filter(element => element !== ' ');
        tableauFiltre.map(async(el)=>{
          if(phoneRegex.test(el)){
            const respon=await axiosPostAction(service,"add",{numero:el,message:msg})
          if(respon.data.err&&respon.data.err!=='-1'){
            handleAlert(true,"error",respon.data.err)
          }
          else{
          axiosResponse(respon,()=>{
                  setOpen(false);
                  if (respon.data.jData) 
                  props.fetch(1);
          })
        }
        }else{
           setError(true)
        }
          
        })
    }

    const testPhone = (e) => {
      const re = /^[0-9\b]+$/;
      const value = e.target.value;
      if (value === '' || re.test(value)) {
        if (value.length <= 10) {
          setNum(value);
        }
      }
    };
  
  const handleChangeCli=(newValue)=>{
    const index= usr.findIndex((obj => obj.compte ===newValue));
    const user = [usr[index].tel, usr[index].tel1, usr[index].tel2];
    const tableauFiltre = user.filter(element => element !== null);
    const UserEnChaine = tableauFiltre.join(', ');
    setNum(UserEnChaine)
  }

  return (
    <div>
      <Button className={classes.topTabBtn} variant="contained" color="secondary" onClick={handleClickOpen} startIcon={<Add/>}>NOUVEAU SMS</Button>
      <Dialog
            id='sms'
            open={open}
            onClose={handleClose}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={paperPropsSmall}
       >
        <DialogTitle className={classes.topDivTitle} id="draggable-dialog-title" >Ajouter un nouveau sms</DialogTitle>
        <ValidatorForm ref={useRef(null)} onSubmit={handleSubmit} onError={errors => console.log(errors)} >
            <DialogContent> 
                <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA}/>
                <div id='sms-row'>
                  <Autocomplete
                      disableClearable
                      onChange={(e,newValue) =>{handleChangeCli(newValue)}} 
                      onFocus={handleOpenUsr}
                      options={usr.map((option) => option.compte)} 
                      className='smallText'
                      renderInput={(params) => (
                      <TextField  {...params} className={classes.input11} label="Compte :" color="primary" variant="outlined" size="small" autoFocus />
                    )} />
                  <TextField variant="outlined" className='smallText' label='Numéro de téléphone :' size="small" value={num} 
                           id='tel-edit' inputProps={{ tabIndex: "1" ,className:classes.input1}} error={error}
                           helperText={(error)?"Format du numéro de téléphone est incorrect":''}
                           onChange={e =>{testPhone(e);setError(false)}}  fullWidth  required 
                       />
               </div>
                <TextField  variant="outlined" className='smallText' label='Message :' size="small"  value={msg} style={{marginTop:'10px'}}
                            inputProps={{ tabIndex: "1" ,className:classes.input1}} 
                            onChange={e =>setMsg(e.target.value)}  fullWidth required  
                        /> 
            </DialogContent>
            <DialogActions>
              <Button variant="contained" type='submit' color="primary" startIcon={<DoneAll/>}>VALIDER</Button>
              <Button variant="contained"  onClick={handleClose} color="secondary">FERMER</Button>
            </DialogActions>
        </ValidatorForm>
      </Dialog>
      </div>
  );
}
