import React,{useState,useRef,useCallback} from 'react';
import {DialogActions,DialogContent,DialogTitle,Paper,Button,Dialog,TextField, Tooltip} from '@material-ui/core';
import Draggable from 'react-draggable';
import { Category,DoneAll} from '@material-ui/icons';
import { ValidatorForm} from 'react-material-ui-form-validator';
import { useStyles,paperPropsSmall } from '../../../css/muiStyle';
import {AlertCompo} from '../../../js/components'
import Autocomplete from '@mui/material/Autocomplete';

//Add a new role
function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function Cat_cat_add(props) {
    const classes=useStyles();
    const Gfunc=require('../../../Gfunc')
    const [open, setOpen] = useState(false);
    const [openA, setOpenA] =useState(false);
    const [cats, setCats] =useState([]);
    const [selectcat, setSelectCat] =useState([]);
    const [messageA, setMessageA] =useState('');
    const [severity, setSeverity] =useState('info');
    const service="_prd.php"

    const handleAlert=(open,severity,message)=>{
      setOpenA(true)
      setSeverity(severity)
      setMessageA(message)
      setTimeout(function(){
        setOpenA(false)
      }, 5000);
      }
    const handleClickOpen = () => { 
        setOpen(true); setCats([])
    }
    const handleClose = () => { setOpen(false); }
  
    //fetch catalogue
    const fetchCat= useCallback(async(serv=service, act = "getcats") => {
        const response = await Gfunc.axiosPostAction(serv,act,{produitId:(props.from==='online')?props.data.produitId:props.data.DT_RowId.substr(1)});
        if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,'error',response.data.err)
        }else
        Gfunc.axiosResponse(response,()=>{
            setCats(response.data.jData);    
        })
    },[Gfunc])
    
    const handleOpenCat= useCallback(async () => {
        if(cats.length===0) fetchCat()
    },[cats,fetchCat])

    const handleSubmit= async() =>{
        var objIndex = cats.findIndex((obj => obj.nom ===selectcat));
        var cat=cats[objIndex].id
        const response=await Gfunc.axiosPostAction(service,"addprdcat",{produitId:(props.from==='online')?props.data.produitId:props.data.DT_RowId.substr(1),
        catalogId:cat})
        if(response.data.err&&response.data.err!=='-1'){
          handleAlert(true,"error","Une erreur s'est produite")
        }else{
         Gfunc.axiosResponse(response,()=>{
           if(props.from==='online'){
              setOpen(false)
             props.handleAlert(true,"success","Le produit a été ajouté à "+selectcat+" avec succès")
           }else{
             handleAlert(true,"success","Le produit a été ajouté au catalogue avec succès")
              props.fetch(1,props.search)
           }
         })
        }
    }
  //valider formulaire avec touche d'entrer
  const detectTouche= (e)=>{
      if (e.keyCode === 13 && (e.target.name==="addBtn")) {
          handleSubmit()
        }
  }
  return (
    <div >
      <Tooltip  title="Ajouter le produit au catalogue">
          <Button className={classes.iconBtnBig} variant="contained" onClick={handleClickOpen} color="primary" >
            <Category fontSize="small"/>
          </Button>  
      </Tooltip>
      <Dialog
            open={open}
            onClose={handleClose}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={paperPropsSmall}
      >
       <DialogTitle className={classes.topDivTitle} id="draggable-dialog-title" >{'Ajouter '+props.data.nameFr+' à un catalogue'}</DialogTitle>
       <ValidatorForm ref={useRef(null)} onSubmit={handleSubmit} onKeyPress={e=>detectTouche(e)} >
            <DialogContent>
            <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA}/>
              <Autocomplete
                  disableClearable
                  onChange={(e,newValue) =>{setSelectCat(newValue)}} 
                  onFocus={()=>{handleOpenCat()}}
                  options={cats.map((option) => option.nom)} 
                  className='smallText'
                  renderInput={(params) => (
                   <TextField  {...params} className={classes.input11} label="Catalogue:" color="primary" variant="outlined" size="small"  />
               )} />
            
           </DialogContent>               
            <DialogActions>
              <Button variant="contained" type='submit' color="primary" startIcon={<DoneAll/>}>VALIDER</Button>
              <Button variant="contained"  onClick={handleClose} color="secondary">FERMER</Button>
            </DialogActions>
        </ValidatorForm>
      </Dialog>
      </div>
  );
}
