import React,{useState,useCallback} from 'react';
import {DialogActions,DialogContent,DialogTitle,Paper,Button,Dialog,TextField} from '@material-ui/core';
import Draggable from 'react-draggable';
import {DoneAll} from '@material-ui/icons';
import { ValidatorForm} from 'react-material-ui-form-validator';
import {axiosPostAction,axiosResponse} from'../../../Gfunc'
import { useStyles,paperPropsSmall } from '../../../css/muiStyle';
import {AlertCompo} from '../../../js/components'
import {ReactComponent as SortIcon} from '../../../img/icons/sort.svg' 
import { Autocomplete } from '@material-ui/lab';
import { useContext } from 'react';
import ParamContext from '../../../Contexte';
import axios from 'axios'

//Add a new role
function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function Cat_onl_ordre(props) {
    const classes=useStyles();
    const Gfunc=require('../../../Gfunc')
    const param=useContext(ParamContext);
    const [selectProd,setSelectProd]=useState('');
    const [prod,setProd]=useState([]);
    const [open, setOpen] = useState(false);
    const [openA, setOpenA] =useState(false);
    const [messageA, setMessageA] =useState('');
    const [severity, setSeverity] =useState('info');
    const service='_prd.php'

    
    const handleClickOpen = () => { 
      setOpen(true); 
      setSelectProd('')
      
    };

    const handleClose = () => { setOpen(false); };

    const handleAlert=(open,severity,message)=>{
      setOpenA(true)
      setSeverity(severity)
      setMessageA(message)
      setTimeout(function(){
        setOpenA(false)
      }, 5000);
    }

    const fetchProdByCat= useCallback(async() => {
        const response=await Gfunc.axiosPostAction(service,"getprdbycat",{catalogId:parseInt(props.row.catalogId.substr(1))})
        if(response.data.err&&response.data.err!=='-1'){
          handleAlert(true,"error",response.data.err)
        }else{
          Gfunc.axiosResponse(response,()=>{
            if(response.data.jData){
              const objIndex = response.data.jData.findIndex((obj => obj.nameFr===props.row.nameFr));
              if(objIndex!==-1){
                response.data.jData.splice(objIndex,1)
              }
              response.data.jData.map((el)=>{
                el['desc']=(el.color)?el.nameFr+' ['+el.color+' ]':el.nameFr
              })
              response.data.jData.sort((a, b) => {
                const elementA = a.nameFr.toLowerCase();
                const elementB = b.nameFr.toLowerCase();
                if (elementA < elementB) {return -1;}
                if (elementA > elementB) {return 1;}
                return 0; 
              });
              setProd(response.data.jData)  
            }
          })
        }
    },[Gfunc,param.urlService]);
    
    const handleOpenProdbycat= useCallback(async () => {
        if(prod.length===0) fetchProdByCat();
    },[prod,fetchProdByCat])

    const handleSubmit= async(e) =>{
        var produit='';
        const objIndex = prod.findIndex((obj => obj.desc===selectProd));
        if(objIndex!==-1){
          produit=prod[objIndex].produitId
        }
        const response=await axiosPostAction(service,"orderprd",{this_product:props.row.produitId,before_that:produit,
                                           catalogId:parseInt(props.row.catalogId.substr(1))})
        if(response.data.err&&response.data.err!=='-1'){
          handleAlert(true,"error",response.data.err)
        }
        else{
          axiosResponse(response,()=>{
                if (response.data.jData){
                  setOpen(false)
                  props.fetch(props.countp); 
                } 
          })
        }
      
    }
  return (
    <div>
      <Button className={classes.iconBtnBig} variant="contained" color="primary" onClick={handleClickOpen} >
         <SortIcon fill='white' style={{height:'18px',width:'18px'}}/>
      </Button>
      <Dialog
            open={open}
            onClose={handleClose}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={paperPropsSmall}
       >
        <DialogTitle className={classes.topDivTitle} id="draggable-dialog-title" >Ajouter un ordre à {props.row.nameFr}</DialogTitle>
            <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA}/>
            <DialogContent>
              <ValidatorForm id='form' onSubmit={handleSubmit} onError={errors => console.log(errors)} >

                <Autocomplete
                      disableClearable
                      onChange={(e,newValue) =>{setSelectProd(newValue);}} 
                      value={selectProd}
                      onFocus={handleOpenProdbycat}
                      options={prod.map((option) => option.desc)}
                      className='smallText'  
                      renderInput={(params) => (
                        <TextField {...params}  label="Après" color="primary" variant="outlined" size="small"  />
                 )} />
                          
              </ValidatorForm>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" form='form' type='submit' color="primary" startIcon={<DoneAll/>}>VALIDER</Button>
              <Button variant="contained"  onClick={handleClose} color="secondary">FERMER</Button>
            </DialogActions>
      </Dialog>
      </div>
  );
}
