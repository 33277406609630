import React,{useState,useCallback,useMemo} from 'react';
import {DialogActions,DialogContent,DialogTitle,Paper,Button,Dialog,CircularProgress, Tooltip} from '@material-ui/core';
import Draggable from 'react-draggable';
import DataTable from "react-data-table-component";
import {FavoriteBorder} from '@material-ui/icons';
import { useStyles,paperProps } from '../../../css/muiStyle';
import {customStyles} from '../../../css/datatable-custom'
import { useContext } from 'react';
import ParamContext from '../../../Contexte';
import {AlertCompo} from '../../../js/components'

//Add a new role
function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function Favories(props) {
    const classes=useStyles();
    const Gfunc=require('../../../Gfunc')
    const param=useContext(ParamContext);
    const [open, setOpen] = React.useState(false);
    const [lovers, setLovers] = useState([]);
    const [openA, setOpenA] =useState(false)
    const [messageA, setMessageA] =useState('');
    const [severity, setSeverity] =useState('info');
    const service="_prd.php"

    
    const conditionalRowStyles= [
      {
        when: row => row.new===true,
        style: {
          color: '#990000',
        },
      },
    ];

    //handle Alerts
    const handleAlert=(open,severity,message)=>{
      setOpenA(true)
      setSeverity(severity)
      setMessageA(message)
      setTimeout(function(){
        setOpenA(false)
      }, 5000);
    }

    const handleClickOpen = () => { 
      setOpen(true); 
      fetch();
      const objIndex = lovers.findIndex((obj => obj.new ===true )); 
      if(objIndex!==-1) {handleChangeStatueFav();}
    };

    const handleClose = () => { setOpen(false); };
    
    //fetch the users that have the selected role
    const fetch= useCallback(async() => {
        const response=await Gfunc.axiosPostAction(service,"getplovs",{idp:(props.from==='online')?props.row.produitId:parseInt(props.row.DT_RowId.substr(1))})
        if(response.data.err&&response.data.err!=='-1'){
          handleAlert(true,"error",response.data.err)
        }else{
          Gfunc.axiosResponse(response,()=>{
            if(response.data.jData){
              setLovers(response.data.jData);
            }
          })
        }
    },[props.row.DT_RowId,Gfunc])

    //change statut of favories
    const handleChangeStatueFav=useCallback(async() => {
        const response=await Gfunc.axiosPostAction(service,"setnlovviewed",{idn:(props.from==='online')?props.row.produitId:props.row.DT_RowId.substr(1)})
        if(response.data.err&&response.data.err!=='-1'){
          handleAlert(true,"error",response.data.err)
        }else{
            Gfunc.axiosResponse(response,()=>{
              //console.log(response)
            })
        }
      },[props.row.DT_RowId,Gfunc])
    
    const columns =useMemo(
      () => [ 
          {
            name: "Par",
            selector: row=>row.nom+' '+row.prenom,
            sortable: true,
            minWidth:'130px !important'
          },
          {
            name: "Date",
            selector: row=>row.date_love,
            sortable: true,
            minWidth:'130px !important'
          },
          
          {
            name: "Commune",
            selector: row=>row.commune,
            sortable: true,
            minWidth:'130px !important'
          },
          
          {
            name: "Magasin",
            selector: row=>row.mag,
            sortable: true,
            minWidth:'150px !important'
          },
          {
            name: "Tel",
            selector: row=>row.tel,
            sortable: true,
            minWidth:'150px !important'
          },
          
        ],[]);

  return (
    <div>
      <Tooltip title={props.nbr+"  j'aime"}>
        <FavoriteBorder fontSize='small' onClick={handleClickOpen} className={classes.blackIcon}/>
      </Tooltip>
      <Dialog
            open={open}
            onClose={handleClose}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={paperProps}
        >
        <DialogTitle className={classes.topDivTitle} id="draggable-dialog-title" >La liste des favories</DialogTitle>
        <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA}/>  
        <DialogContent>
            <DataTable
                key={Math.floor(Math.random() * 100)}
                columns={columns}
                data={lovers}
                noDataComponent={param.ErreurData}
                defaultSortAsc={true}
                paginationRowsPerPageOptions={[5, 10, 20, 30]}
                pagination
                highlightOnHover
                customStyles={customStyles}
                selectableRowsComponentProps={{ className: classes.checkStelect }}
                paginationComponentOptions={{ rowsPerPageText: 'Élément par page:', rangeSeparatorText: 'sur', selectAllRowsItemText: 'Tous',selectAllRowsItem: true}}
                contextMessage={{ singular: 'élément sélectionné', plural: 'éléments sélectionnés' }}
                progressComponent={<CircularProgress className="circularProgress" />}
                conditionalRowStyles={conditionalRowStyles}
             />
        </DialogContent>
        <DialogActions id='btn-div'>
            <Button variant="contained"  onClick={handleClose} color="secondary">FERMER</Button>
        </DialogActions>
      </Dialog>
      </div>
  );
}
