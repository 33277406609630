import React,{useEffect, useMemo, useState} from 'react';
import {DialogActions,DialogContent,DialogTitle,Paper,Button,Dialog,circularProgress, Grid, TextField, Box} from '@material-ui/core';
import Draggable from 'react-draggable';
import { useStyles, paperPropsComs } from '../../../css/muiStyle';
import DataTable from "react-data-table-component";
import { customStyles } from '../../../css/datatable-custom';
import { Search } from '@material-ui/icons';
import {ReactComponent as SendNtf} from '../../../img/icons/sendMessage.svg'
import $ from 'jquery'

//Add a new message
function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function Ani_ntf_compte_notif(props) {
    const lodashClonedeep = require('lodash.clonedeep');
    const classes=useStyles();
    const [open,setOpen]=useState(false);
    const [search,setSearch]=useState('');
    const [data,setData]=useState(props.received);
    const Tous=lodashClonedeep(props.received)
   
    const handleClickOpen = () => { 
      setOpen(true); setData(props.received); setSearch('')
    };

    useEffect(() => {
      $(document).ready(function() {
        const bouton =document.getElementById('clickB')
        bouton.click()
      });
    }, []);

    const handleClose = () => { setOpen(false); };

    const columns =useMemo(
        () => [   
          {
              name: "Client",
              selector:row=> row.nom+'  '+row.prenom,
              minWidth:'180px !important',
              sortable: true,
          },
          {
              name: "Tel",
              selector:row=> row.tel,
              minWidth:'150px !important',
              sortable: true,
          }, 
          {
              name: "Boutique",
              selector:row=> row.mag,
              minWidth:'150px !important',
              sortable: true,
          }  
      ],[classes]);
      
      const filtrer=(val)=>{
        var test=Tous.filter((el) =>  JSON.stringify(el).toLowerCase().indexOf(val.toLowerCase()) !== -1)
        setData(test)
      }
 
      const  HandleOnChange=(val)=>{
         filtrer(val)
      }

  
   return (
    <div>
      <Button variant="contained" type='submit' id='clickB' className={classes.fieldButton}  onClick={()=>handleClickOpen()} color="primary" 
              startIcon={<SendNtf fill='white' style={{height:'18px',width:'18px',cursor:"pointer"}} />}>
        Listes des clients notifiées
      </Button>
      <Dialog
            open={open}
            onClose={handleClose}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={paperPropsComs}
       >
        <DialogTitle className={classes.topDivTitle} id="draggable-dialog-title" >La liste des clients notifiées</DialogTitle>
            <DialogContent >
              <div style={{display:'block !important'}}>
                <div className='table-header'>
                 <div className='search' >
                  <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <TextField className={classes.search} variant="outlined"  label="Recherche" value={search} 
                               InputProps={{startAdornment: (<Search style={{ marginRight: '5px',zIndex:'1000',width:'20px',
                                      height:'20px',cursor:"pointer"}} />),}}
                               size="small" margin='none'  onChange={e =>{HandleOnChange(e.target.value);setSearch(e.target.value)}}  /> 
                  </Box>
                 </div>
                </div>
              <div>
              {
                (data.length!==0)?
                  <div>
                    <DataTable 
                        persistTableHead
                        columns={columns}
                        data={data}
                        noHeader={true}
                        customStyles={customStyles}
                        defaultSortAsc={true}
                        highlightOnHover
                        pagination
                        paginationComponentOptions={{ rowsPerPageText: 'Element par page:', rangeSeparatorText: 'sur'}}               
                     />  
                </div>:<div style={{width:'100%',textAlign:"center",fontWeight:'bold',fontSize:"12px"}}>Aucun clients n'a été notifié</div>
              }
              </div>
              </div>
            </DialogContent>
            <DialogActions>
              <Button variant="contained"  onClick={handleClose} color="secondary">FERMER</Button>
            </DialogActions>
      </Dialog>
      </div>
  );
}
