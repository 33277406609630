import React from 'react';
import '../css/Stepper.css';

const Stepper = ({ steps ,IconButton,ActivElems,UserElem,TimeElem,el}) => {

  /*const handleClick = (index) => {
    setActiveStep(index);
  };*/

  return (
    <div className="stepper">
      {steps.map((step, index) => (
        <div className="step" key={index}>
          <div className="date">{TimeElem(el,index)}</div>
          <div className={`circle ${index <=ActivElems(el) ? 'active' : ''}`}>{IconButton(step)}</div>
          <div className="text">{step}</div>
          {index <=(steps.length - 1)&& index>0 && (
            <div className="connector-container">
              <div className="connector-text">{(index <= ActivElems(el)) ?UserElem(el,index-1):''}</div>
              <div className={`connector ${index <= ActivElems(el) ? 'active' : ''}`} ></div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default Stepper;
