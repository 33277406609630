import React, { useState } from 'react';
import { Button, Box, Typography, TextField } from '@mui/material';
import { Add, Remove } from '@mui/icons-material';
import {useStyles } from '../css/muiStyle';



export default function NumberPicker(props) {
  const classes = useStyles();
  const [count, setCount] = useState(props.count);
  const [editable, setEditable] = useState(false);

  const handleIncrement = () => {
    const newCount = count + props.step;
    props.handleEditQte(props.row, newCount, props.att);
  };

  const handleDecrement = () => {
    const newCount = count - props.step;
    props.handleEditQte(props.row, newCount, props.att);
  };

  const handleCountChange = (event) => {
    event.preventDefault();
    const newCount = parseInt(event.target.value, 10) || undefined;
    setCount(newCount)
  };

  const handleEditableOpen = () => {
      setEditable(true);
    };

  const handleChangeClose = () => {
      updateValues(count);
      setEditable(false);
    };

  const updateValues = (newCount) => {
    if(newCount !==undefined){
      if (newCount <= props.max2 || props.att==='prx') {
        setCount(newCount);
        props.handleEditQte(props.row, count, props.att);
      }else{
        setCount(props.count)}
    }
  };

  return (
    <div className={props.className}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        id="number-input"
      >
        <Button className={classes.iconBtnTransp} disabled={count <= 0 } onClick={handleDecrement}>
          <Remove style={{ height: '18px',width: '18px',color:count <= 0  ? 'gray' : 'green', }}/>
        </Button>
        {editable && !props.bool ? (
          <TextField
            type="number"
            variant="standard"
            value={count}
            //value={count}
            inputProps={{min: props.min,max: props.max2,inputMode: 'numeric',step: props.step,
                        style: { width: props.width, maxWidth: props.maxWidth }}}
            onChange={handleCountChange}
            onBlur={handleChangeClose}
          />
        ) : (
          <Typography variant="p" onClick={handleEditableOpen} sx={{ cursor:(!props.bool)?'pointer':'default' }}> {count}</Typography>
        )}
        <Button className={classes.iconBtnTransp} disabled={ count >= props.max2  } onClick={handleIncrement}>
          <Add style={{ height: '18px',width: '18px',color:count >= props.max2 ?'gray':'green' }}/>
        </Button>
      </Box>
    </div>
  );
}


