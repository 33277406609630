
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  checkboxColor: {
    color: theme.palette.secondary.dark
  },
  container: {
    display: 'flex', 
    flexDirection: 'row',
    justifyContent: 'center',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  /*switch style ***** */
  root: {
    width: '100%',
  },
  root1: {
    position:'fixed',
    top:"90px",
    zIndex: theme.zIndex.drawer + 1,
    width: '95%',
    marginLeft:'2.5%',
    marginRight:'2.5%',
  },
  switch_track: {
    backgroundColor: theme.palette.secondary.dark,
  }, 
  switch_base: {
    color: "white",
    "&.Mui-checked + .MuiSwitch-track": {
        backgroundColor: '#bb0000',
    }
  },
  switch_primary: {
    "&.Mui-checked": {
        color:'#bb0000',
    },
  },
  greenText:{
    width: "fit-content",
    height:"fit-content",
    color:"green",
 },
 //accordion styke ****
 accordionRoot:{
  width:"96%",
  marginLeft:"2%",
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
  '&.Mui-expanded':{
    margin:0,
    marginLeft:"2%",
  }
},
 accordionSummary:{
  height:"35px",
  minHeight:"30px",
  backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)': 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
  "&.MuiAccordionSummary-root":{
    '&.Mui-expanded':{
      height:"35px",
      minHeight:"30px",
    }
    
  }
},
accordionDetails:{
  padding: "5px 10px",
  background: 'var(--primary-light)',
  borderTop: '1px solid rgba(0, 0, 0, .125)',
},
  searchInput:{
    background:"white",
    color:"black",
   },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    color: "black",
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  circularProgress: {
    color: theme.palette.primary.main,
  },
  checkStelect: {
    color: theme.palette.primary.light,
    "&.MuiCheckbox-colorSecondary": {
      color: theme.palette.primary.light,
    },
  },
  //mydesign
  /*topTabBtn: {
    textDecoration: 'none',
    padding: '2px 15px',
    margin: '2px 3px',
    border: "solid 1px gray",
    color:"white"
  },*/
  smallBtn: {
    textDecoration: 'none',
    padding: '0px 15px !important',
    height:'25px',
    margin: '5px 3px 5px 3px !important',
    border: "solid 1px gray !important",
    color:'white'
  },
  topTabBtn: {
    textDecoration: 'none',
    padding: '2px 10px',
    margin: '2px 3px',
    border: "solid 1px #f1f1f1",
    borderRadius:'10px',
    boxShadow:'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
    '&:hover': {
      backgroundColor: 'gray',
      border: "solid 1px gray"
    },
  },

  topTabBtnFiltre: {
    textDecoration: 'none',
    padding: '3px 6px !important',
    margin: '0px 3px !important',
    border: "solid 1px #eee",
    borderRadius:'10px',
    fontSize:'11px',
    '&:hover': {
      backgroundColor: 'gray',
      border: "solid 1px gray"
    },
    // background:"#e0e0e0",
    // color:'black',
   // borderRadius:'6%',tn
   // wight:'bold'
  },
  topTabBtnLiv: {
    textDecoration: 'none',
    padding: '3px 6px !important',
    margin: '0px 3px !important',
    border: "solid 1px #4cb050",
    borderRadius:'10px',
    fontSize:'11px',
    '&:hover': {
      backgroundColor: '#4cb050',
      border: "solid 1px #4cb050" 
    },
  },
  topTabBtn1: {
    textDecoration: 'none',
    padding: '0px 0px !important',
    margin: '2px 3px !important',
    border: "solid 1px gray",
    // background:"#e0e0e0",
    // color:'black',
   // borderRadius:'6%',
   // wight:'bold'
  },
  input: {
    padding: "3px 0px",
    background:theme.palette.secondary.light,
    "&:focus":{
      background: theme.palette.secondary.light,
    },
  },
  inputSearch: {
    padding: "0px 0px",
    background:theme.palette.secondary.light,
    "&:focus":{
     // background: 'white',
    },
  },
  input1: {
    padding: "9px 16px",
    background:theme.palette.secondary.light,
    "&:focus":{
      background: "white"
    },
  },
  inputFiltre: {
    padding: "5px 10px !important",
    background:theme.palette.secondary.light,
    "&:focus":{
      background: theme.palette.secondary.light,
    },
  },
  input11: {
    padding: "0px 0px",
    borderRadius:'10px',
    background:theme.palette.secondary.light,
    "&:focus":{
      background: theme.palette.secondary.light,
    },
  },
  inputForm: {
    padding: "11px 16px !important ",
    background:theme.palette.secondary.light+'!important',
    "&:focus":{
      background: "white"
    },
  },
  dataInput: {
    fontSize: 13,
    fontWeight: "bold",
    padding: "3px 0px !important",
    color:"black",
    background:theme.palette.secondary.main+"!important",
   },
   dataLabel:{
    fontSize: 13,
    fontWeight: "bold",
    color:"gray !important",
    padding:"0px",
    margin:"0px",
    transform: "translate(14px, 6px) scale(1) !important"
  },
  //datatable text field 
  dataheadInput:{
  //paddingRight:"10px",
  //marginBottom:"10px",
  minWidth:"120px",
  width:"auto",
  minHeight:"15px",
  height:"15px",
  },
  CatBtn: {
    textDecoration: 'none',
    color:'white !important',
    background:theme.palette.primary.main+'!important',
    padding: '6px 5px',
    paddingBottom:"10px",
    margin: '2px 3px',
    border: "solid 1px gray",
    minWidth:"100px",
    minHeight:"30px",
    height:"42px",
  },
  verticalDivider: {
    height: '50%',
    margin: 0,
    borderRight: '1px solid gray',
  },
  iconBtn: {
    textDecoration: 'none',
    padding: '2px !important',
    height:'fit-content !important',
    margin: '5px 3px 2px 3px !important',
    border: "solid 1px gray !important",
    width: "fit-content !important",
    minWidth:"fit-content !important",
  },
  iconBtnBig: {
    textDecoration: 'none',
    padding: '2px !important',
    height:'27px !important',
    margin: '1px 1px!important',
    border: "none",
    width: "27px!important",
    minWidth:"0 !important",
    color:'white',
    background:theme.palette.primary.main,
  },
  iconBtnTransp: {
    textDecoration: 'none',
    padding: '2px !important',
    height:'27px !important',
    margin: '1px 1px!important',
    border: "none",
    width: "27px!important",
    minWidth:"0 !important",
    color:'green',
    background:'transparent',
  },
  fieldBtn: {
    textDecoration: 'none',
    color:'white !important',
    background:theme.palette.primary.main+'!important',
    padding: '8px 5px',
    paddingBottom:"10px",
    margin: '2px 3px',
    border: "solid 1px gray",
    minWidth:"100px",
    minHeight:"30px",
    height:"32px",
  },
  SwitchBtn: {
    textDecoration: 'none',
    color:'white !important',
    background:'#e53935 !important',
    padding: '8px 5px',
    paddingBottom:"10px",
    margin: '2px 3px',
    border: "solid 1px gray",
    minWidth:"100px",
    minHeight:"30px",
    height:"32px",
  },
  fieldBtn1: {
    textDecoration: 'none',
    color:'white !important',
    background:theme.palette.primary.main+'!important',
    padding: 'auto 2px !important',
    paddingBottom:"10px",
    margin: '2px 3px',
    border: "solid 1px gray",
    minWidth:"100px",
    minHeight:"30px",
    height:"32px",
  },
  fieldButton: {
    textDecoration: 'none',
    color:'white !important',
    background:theme.palette.primary.main+'!important',
    padding: '8px !important',
    paddingBottom:"10px",
    margin: '0px',
    border: "solid 1px gray",
    minWidth:"100px",
    minHeight:"20px",
    height:"29px",
    justifyContent:"center",
    borderRadius:'10px'
  },
  fieldButtonReject: {
    textDecoration: 'none',
    color:'black !important',
    background:'red',
    padding: '0 2px !important',
    paddingBottom:"10px",
    margin: '2px 3px',
    border: "none",
    minWidth:"120px",
    minHeight:"36px",
    height:"36px",
    justifyContent:"center",
    '&:hover': {
      backgroundColor:"#FF5C5C"
    },
  },
  topTabLink: {
    textDecoration: 'none',
    padding: '0px 5px',
    margin: '2px 3px',
    border: "solid 1px gray",
    backgroundColor: theme.palette.secondary.main,
    color:'black',
    borderRadius:'10%',
    fontWeight:'normal'

  },
  topDivTitle: {
    width: '100% !important',
    color:"white",
    cursor: 'move',
    background:theme.palette.primary.dark,
    textDecoration: 'none',
    padding: '0px 5px !important',
    margin: '0px',
    textAlign: "center"
  },
  textInput: {
    width: '90%',
    background:theme.palette.secondary.light,
    textDecoration: 'none',
    padding: '5px 10px',
    margin: '0px',
    borderTopLeftRadius: "5px",
    borderTopRightRadius: "5px",
  },
  headInput:{
    paddingRight:"10px",
    width:"95%",
    minHeight:"30px",
   // height:"60px",
    minWidth:"100px",
  },
  headInputCo:{
    width:"100% ",
    minHeight:"30px",
    minWidth:"100px",
  },
  headInputV:{
    paddingRight:"10px",
    width:"97%",
    minHeight:"30px",
   // height:"60px",
    minWidth:"100px",
  },
  headInputtext:{
    paddingRight:"10px",
    width:"89%",
    minHeight:"30px",
   // height:"60px",
    minWidth:"100px",
  },
  headInputResp:{
    paddingRight:"10px",
    width:"103%",
    minHeight:"30px",
   // height:"60px",
    minWidth:"100px",
  },
  textInput2: { 
    width: '92%',
    background:theme.palette.primary.dark,
    textDecoration: 'none',
    borderRadius:'5px',
    color:'white'
  },
  resize:{
    fontSize:5
  },
  textInput3: {
    width: '97%',
    background:theme.palette.secondary.light,
    textDecoration: 'none',
    borderRadius:'5px',
    color:'white'
  },
  InputComponent:{
    paddingRight:"10px !important",
    width:"45% !important",
    minHeight:"30px !important",
    height:"30px !important",
    minWidth:"100px !important",
  },
  headInput1:{
    paddingRight:"10px",
    width:"18%",
    minHeight:"30px",
    height:"30px",
    minWidth:"100px",
    float:'right',
    right:'20px',
    marginBottom:'13px'
  },
  headFiltreResp:{
    //paddingRight:"10px",
    width:"38%",
    minHeight:"30px",
    height:"30px",
    minWidth:"100px",
    float:'right',
    right:'2px',
    marginBottom:'13px'
  },
  selectOpt: {
    width: '90%',
    background:theme.palette.secondary.light,
    textDecoration: 'none',
    padding: '5px 10px',
    margin: '0px',
    borderTopLeftRadius: "5px",
    borderTopRightRadius: "5px",
  },
  diagFormLabel:{
    fontSize: "13px",
    fontWeight: "bold",
    color:"black",
    padding:"0px",
    margin:"0px",
    transform: "translate(14px, 6px) scale(1) !important"
  },
  diagFormNoteText:{
    minHeight:"10px",
    minWidth:"30px", 
    width:"98%",
    height:"40px", 
    marginTop:'0px',
    "&.MuiTextField-root":{
      background:"white", 
      borderRadius:"5px",
      height:'40px',
    }
  },
  diagFormInput: {
    fontSize:"13px",
    margin:"0px",
    padding: "2px 0px",
    paddingLeft:'10px',
    color:"black",
    height:"17px",
    background:"white", 
    "&:focus":{
      background:"white", 
      borderRadius:"5px"
    },
  }, 
  InputPass: {
    width: '100%',
    marginBottom:'5px'
  },
  tdIcon:{
    cursor:'pointer'
  },
  tdIconButton:{
    cursor:'pointer',
    background: "white",
    border: "0",
    height: "25px",
    marginTop: "7px",
    maxWidth: "25px",
    minWidth: "25px",
    boxShadow: "0 0",
  },
  backdrop: {
    //top:"86px",
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  styleBtn:{
    padding:'8px 14px',
  },
  TableForm:{
    width:'100px'
  },
  paperBody:{
    background:theme.palette.secondary.light,  //"#fff176",
    width:"100%",
    height:"auto",
    paddingBottom:"10px",
    margin:"10px",
  },
  Dialog:{
    cursor: 'move', width: '400px' ,
    color:'red'
    },
    fieldSet:{
      margin: 10,
      paddingHorizontal: 10,
      paddingBottom: 10,
      borderRadius: 5,
      borderWidth: 1,
      alignItems: 'center',
      borderColor: '#000'
    },
    legend:{
        position: 'absolute',
        top: -10,
        left: 10,
        fontWeight: 'bold',
        backgroundColor: 'white'
    },
    //Icons ...
    redIcon:{
      color:"red",
      cursor:"pointer"
    },
    greenIcon:{
      color:"green",
      cursor:"pointer"
    },
    blackIcon:{
      color:"black",
      cursor:"pointer"
    },
    blueIcon:{
      color:"blue",
      cursor:"pointer"
    },
    //edited select textfield...
  selectEdit:{
    minHeight:"10px !important",
    minWidth:"30px !important", 
    width:"auto !important",
    height:"15px !important",
    alignSelf:'center !important',
    margin:"0 10px !important",
  },
  //select pour filtrer
  inputEdit: {
    minHeight:"10px !important",
    minWidth:"30px !important",
    fontSize:"12px !important",
    margin:"0px !important",
    padding: "0px 30px !important",
    paddingLeft:'8px !important',
    color:"white !important",
    height:"17px !important",
    background:theme.palette.primary.dark+" !important", 
    "&:focus":{
      background:theme.palette.primary.dark+' !important', 
      borderRadius:"5px !important"
    },
  },
  selectEdit2:{
    minHeight:"10px",
    minWidth:"50px", 
    width:"fit-content",
    height:"17px",
    "&.MuiTextField-root":{
      background:theme.palette.primary.dark, 
      borderRadius:"5px"
    }
  },
  inputEdit2: {
    fontSize:"13px !important" ,
    width:"80px !important",
    margin:"0px",
    padding: "0px 0px !important",
    paddingLeft:'10px !important',
    color:"white !important",
    height:"17px !important",
    //background:theme.palette.primary.dark, 
    "&:focus":{
      background:theme.palette.primary.dark+' !important', 
      borderRadius:"5px"
    },
    "&.MuiInputBase-root":{
      background:"green !important"
    }
  },
  checkBox:{
    root: {
      '&$checked': {
        color: '#000',
      },
    },
    checked:{
      color:'red'
    }
  },
  whiteSmallIcon:{color:"white",fontSize:"15px"},
  connector: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(1, 0),
  },
  icon: {
    fontSize: 24,
  },
  completed: {
    color: theme.palette.success.main,
  },
  active: {
    color: theme.palette.primary.main,
  },
  disabled: {
    color: theme.palette.grey[500],
  },
}
  ));
  export const textAreaEdit={
    background: "var(--primary-dark)",
    color:"white",
    borderColor: "var(--primary-dark)",
    width:'100%',
    borderRadius:'5px',
    opacity:"1",
    outline:"0",
    minHeight:'85%',
    height:'auto',
    fontFamily:"inherit",
    overflow:'auto'
  }
  
  export const textAreaEdit2={
    background: "var(--secondary-light)",
    color:"black",
    borderColor: "var(--secondary-dark)",
    borderRadius:'5px',
    opacity:"1",
    outline:"0",
    margin:'5px',
    padding:'5px',
    width:"400px",
    height:'fit-content',
    minHeight:"25px",
    textAlign:'start'
  }
export const textAreaEditGray={
  background: "var(--secondary-light)",
    color:"black",
  borderColor: "var(--secondary-main)",
    width:'100%',
    minWidth:'250px',
    borderRadius:'5px',
    opacity:"1",
    outline:"0",
    fontFamily:"inherit",
    paddingLeft:'3px'
  }
export const textAreaEditGray2={
  background: ("var(--secondary-light)"),
    color:"black",
  borderColor: "var(--secondary-main)",
    width:'100%',
    minWidth:'200px',
    borderRadius:'5px',
    opacity:"1",
    outline:"0",
    fontFamily:"inherit",
    paddingLeft:'3px'
  }
export const paperProps={
   style:{
     width:"1000px",
     maxWidth:"2000px",
     maxHeight:"95%",
    // height:"1000px",
     overflow:"auto",
     overflowX:"hidden"
   }
}
export const paperPropsComs={
   style:{
     width:"1300px",
     maxWidth:"2000px",
     maxHeight:"95%",
    // height:"1000px",
     overflow:"auto",
     overflowX:"hidden"
   }
}
export const paperPropsPh={
   style:{
     width:"800px",
     height:"900px",
     overflow:"hidden",
     overflowX:"hidden"
   }
}
export const paperPropsHFixed={
   style:{
     minWidth:'850px',
     width:"fit-content",
     height:"1200px",
     overflowY:"hidden !important",
   }
}
export const paperPropsQst={
   style:{
     width:"1000px",
     maxWidth:"2000px",
     //maxHeight:"95%",
     height:"1000px",
     overflow:"auto",
     overflowX:"hidden"
   }
}
export const paperProps2={
  style:{
    width:"900px",
    maxWidth:"2000px",
    maxHeight:"95%",
    minHeight:"400px",
   // height:"1000px",
    overflow:"hidden"
  }
}
export const paperPropsUpd={
  style:{
    width:"900px",
    maxWidth:"2000px",
    height:"fit-content",
    minHeight:"200px",
    overflow:"hidden"
  }
}

export const paperPropsSms={
  style:{
    maxWidth:"2000px",
    maxHeight:"1000px",
   // height:"1000px",
    width:"600px",
    height:"450px",
    overflow:"hidden"
  }
}
export const paperPropsSmall={
  style:{
    maxWidth:"2000px",
    maxHeight:"1000px",
   // height:"1000px",
    width:"600px",
    height:"fit-content",
    overflow:"hidden"
  }
}
export const paperPropsPass={
  style:{
    maxWidth:"2000px",
    maxHeight:"1000px",
    width:"400px",
    height:"fit-content",
    overflow:"hidden"
  }
}
  
export const paperPropsSmall1={
  style:{
    maxWidth:"2000px",
    maxHeight:"1000px",
   // height:"1000px",
    width:"600px",
    height:"300px",
    overflow:"hidden"
  }
}

export const paperPropsBon={
  style:{
    width:"350px",
    maxWidth:"600px",
    maxHeight:"95%",
   // height:"1000px",
    overflow:"auto",
    overflowX:"hidden"
  }
}

export const paperPropsTick={
  style:{
    maxWidth:"300px",
    maxHeight:"600px",
    width:"600px",
    height:"fit-content",
    overflow:"hidden"
  }
}
export const paperPropsSmallTall={
  style:{
    maxWidth:"650px",
    maxHeight:"10000px",
    width:"500px",
    height:"fit-content",
    overflow:'hidden'  
}
}

  
export const multiSelect={
    
  multiselectContainer: { // To change css for multiselect (Width,height,etc..)
    background: "var(--secondary-light)",
    borderRadius:"10px",
  },
  searchBox: { // To change search box element look
    border: "1px solid gray",
    fontSize: "10px",
    minHeight: "20px",
  },
  inputField: { // To change input field position or margin
    margin: "5px",
  },
  chips: { // To change css chips(Selected options)
    background: "var(--primary-main)",
  },
  optionContainer: { // To change css for option container 
    border: "1px solid gray",
    borderTop:'none',
    height:'200px',
    borderRadius:"10px !important",
  },
  option: { // To change css for dropdown options 
    "&:hover":{
      background: "var(--primary-dark)"
    }   
  },
  groupHeading: { // To chanage group heading style
  } 
}


