import React,{useState,useRef,useCallback} from 'react';
import {DialogActions,DialogContent,DialogTitle,Paper,Button,Dialog,TextField} from '@material-ui/core';
import Draggable from 'react-draggable';
import {DoneAll,Add} from '@material-ui/icons';
import { ValidatorForm} from 'react-material-ui-form-validator';
import { useStyles,paperPropsUpd } from '../../../css/muiStyle';
import {AlertCompo} from '../../../js/components'
import Autocomplete from '@mui/material/Autocomplete';
import { useContext } from 'react';
import ParamContext from '../../../Contexte';
import axios from "axios"
import '../../../css/animation.css'


//Add a new role
function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function Cat_dig_add(props) {
    const classes=useStyles();
    const Gfunc=require('../../../Gfunc')
    const param=useContext(ParamContext);
    const [titreFr,setTitreFr]=useState('');
    const [titreAr,setTitreAr]=useState('');
    const [titreEn,setTitreEn]=useState('');
    const [nom,setNom]=useState('');
    const [img,setImg]=useState('');
    const [after,setAfter]=useState([]);
    const [libId,setLibId]=useState([]);
    const [selectlibId,setSelectLibId]=useState('');
    const [ordre,setOrdre]=useState('');
    const [selectCat,setSelectCat]=useState('');
    const [prix,setPrix]=useState('');
    const [open, setOpen] = useState(false);
    const [errCat, setErrCat] = useState(false);
    const [openA, setOpenA] =useState(false);
    const [messageA, setMessageA] =useState('');
    const [severity, setSeverity] =useState('info');
    const service="_bdl.php"

   // const isDesktopOrLaptop = useMediaQuery({query: '(max-width: 600px)'})

const handleAlert=(open,severity,message)=>{
      setOpenA(true)
      setSeverity(severity)
      setMessageA(message)
      setTimeout(function(){
        setOpenA(false)
      }, 5000);
      }
const handleClickOpen = () => { 
        setOpen(true); setAfter([])
        setTitreAr('');setTitreEn('');setTitreFr('');setOrdre('');setPrix('');setSelectCat('');setImg('');setNom('');
    }
const handleClose = () => { setOpen(false); };

const fetchLib= useCallback(async() => {
      const response = await axios.get( param.urlService+service+'?do=getlibbdl',{ headers: Gfunc.getHeader()});
      if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,"error",response.data.err)
      }else{
       Gfunc.axiosResponse(response,()=>{
          setLibId(response.data.jData)  
       })
    }
},[Gfunc,param.urlService]);
  
//remplir Select catalogue
const handleOpenLib= useCallback(async () => {
  if(libId.length===0) {fetchLib()}
},[libId,fetchLib])

const fetchAfter= useCallback(async() => {
    const response = await Gfunc.axiosGetAction(service ,'gets')      
    if(response.data.err&&response.data.err!=='-1'){
          handleAlert(true,"error",response.data.err)
    }else{
        Gfunc.axiosResponse(response,()=>{
            response.data.jData.sort((a, b) => {
              const elementA = a.nom_fr.toLowerCase();
              const elementB = b.nom_fr.toLowerCase();
              if (elementA < elementB) {return -1;}
              if (elementA > elementB) {return 1;}
              return 0; 
            });
            response.data.jData.map((el)=>{
              el['desc']=el.nom_fr+' ['+el.nom_en+'] ['+el.nom_ar+'] '
            })
            setAfter(response.data.jData)  
        })
    }
},[Gfunc]);
  
//remplir Select after
const handleOpenAfter= useCallback(async () => {
  if(after.length===0) {fetchAfter()}
},[after,fetchAfter,selectCat])

const handleSubmit= async(e) =>{
    var formData = new FormData();
    var lib=(selectlibId)?libId[Gfunc.getIndex(libId,selectlibId,"nom")].id:''
    var ord=(ordre)?after[Gfunc.getIndex(after,ordre,"desc")].id:''
    formData.append("titreAr", titreAr);
    formData.append("titreFr", titreFr);
    formData.append("titreEn", titreEn);
    formData.append("prix", prix);
    formData.append("order", ord);
    formData.append("img", img);
    formData.append("liberoId",lib);
  
    const response = await Gfunc.axiosPostAction1(service ,'add',formData)
    if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,"error",response.data.err)
    }else{
        Gfunc.axiosResponse(response,()=>{
          if(response.data.jData){
            setOpen(false)
            props.fetch(1) 
          }
        })
      }
}


  return (
    <div >
      <Button className={classes.topTabBtn} variant="contained" color="secondary" onClick={handleClickOpen} 
              startIcon={<Add/>}>Nouveau bundle</Button>
      <Dialog
            id='dig-add'
            open={open}
            onClose={handleClose}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={paperPropsUpd}
       >
       <DialogTitle className={classes.topDivTitle} id="draggable-dialog-title" >Ajouter un nouveau bundle</DialogTitle>
       <ValidatorForm ref={useRef(null)} id='cat-form' onSubmit={handleSubmit} >
        <DialogContent>
          <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA}/>
          <div id='bundle'>
            <div id='row-1'>
                <TextField variant="outlined" className='smallText' size="small" id="ar" label="Titre en français"  value={titreFr} 
                           onChange={e =>setTitreFr(e.target.value)}  required />

                <TextField variant="outlined" className='smallText' size="small" id="ar" label="Titre en anglais"  value={titreEn} 
                           onChange={e =>setTitreEn(e.target.value)}  required />
              
                <TextField variant="outlined" className='smallText' size="small" id="ar" label="Titre en arabe"  value={titreAr} 
                           onChange={e =>setTitreAr(e.target.value)}  required />
            </div>
            <div id='row-2'>
                <TextField variant="outlined" type='number' className='smallText' size="small"  label="Prix"  
                           value={prix}  onChange={e =>setPrix(e.target.value)}  />
                <Autocomplete
                  disableClearable
                  onChange={(e,newValue) =>{setOrdre(newValue)}} 
                  onFocus={handleOpenAfter}
                  options={after.map((option) => option.desc)} 
                  className='smallText' 
                  renderInput={(params) => (
                   <TextField  {...params}  label="Après" color="primary" variant="outlined" size="small"  />
               )} />
                <Autocomplete
                  disableClearable
                  onChange={(e,newValue) =>{setSelectLibId(newValue)}} 
                  onFocus={handleOpenLib}
                  options={libId.map((option) => option.nom)}
                  className='smallText' 
                  renderInput={(params) => (
                    <TextField  {...params} label="Libero" color="primary" variant="outlined" size="small"  />
                )} 
               />
            </div>
         </div>   
         <div id='div-file' style={{display:'flex',gridGap:'10px',fontSize:'13px',fontWeight:'bold',alignItems:'center'}}>
              <Button variant="contained" component="label" className={classes.fieldButton}>
                  Choisir une image
                  <input type="file" hidden accept=".png" onChange={e=>{setImg(e.target.files[0]);setNom(e.target.files[0].name)}}/>
              </Button>
              <p>{nom}</p>
         </div>
        </DialogContent>               
        <DialogActions >
            <Button variant="contained" type='submit' color="primary" startIcon={<DoneAll/>}>VALIDER</Button>
            <Button variant="contained"  onClick={handleClose} color="secondary">FERMER</Button>
        </DialogActions>
        </ValidatorForm>
      </Dialog>
      </div>
  );
}
