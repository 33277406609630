import React,{useState,useContext, useRef, useMemo, useCallback} from 'react';
import {DialogContent,DialogTitle,Paper,Dialog, Button, TextField, DialogActions,Divider} from '@material-ui/core';
import Draggablee from 'react-draggable';
import { paperProps} from '../../../css/muiStyle';
import {Print} from '@material-ui/icons';
import DataTable from "react-data-table-component";
import ParamContext from '../../../Contexte';
import {AlertCompo} from '../../../js/components'
import {useStyles } from '../../../css/muiStyle';
import {customStyles} from '../../../css/datatable-custom'
import '../../../css/ticket-bon.css'
import { Autocomplete } from '@material-ui/lab';
import ReactToPrint from 'react-to-print';
import { NumberToLetter } from 'convertir-nombre-lettre';
import $ from 'jquery'

//Add a new role
function PaperComponent(props) {
  return (
    <Draggablee handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggablee>
  )
}


export default function Cmd_acc_livr(props) {
    const componentRef = useRef();
    const classes = useStyles();
    const param=useContext(ParamContext);
    const Gfunc=require('../../../Gfunc')
    const [bons,setBons]=useState([])
    const [open,setOpen]=useState(false)
    const [openA, setOpenA] =useState(false)   
    const [loading, setLoading] =useState(false)
    const [messageA, setMessageA] =useState('');
    const [severity, setSeverity] =useState('info');
    const [selectLiv,setSelectLiv]=useState('');
    const [selectMag,setSelectMag]=useState('');
    const [display, setDisplay] =useState(false);
    const [choix, setChoix] =useState("Tous");
    const [livrs,setLivrs]=useState([]);
    const [mags,setMags]=useState([]);
    const [data,setData]=useState({});
    const [ind,setInd]=useState(null);
    const service='_cmd.php'
    const array=[{id:0,nom:"Tous"},{id:1,nom:"Bon de livraison"},{id:2,nom:"Facture"},{id:3,nom:"Bon de Collising"}]


    const handleAlert=(open,severity,message)=>{
        setOpenA(true)
        setSeverity(severity)
        setMessageA(message)
        setTimeout(function(){
          setOpenA(false)
        }, 5000);
        }
    
    const handleClickOpen = () => { 
        setOpen(true);
        fetch();
        setDisplay(false);setChoix('Tous')
        setSelectMag('');setSelectLiv('');setData({});setMags([])
    };

    const handleClose = () => { setOpen(false); };

    const fetch=async()=>{
      setDisplay(false)
      const response=await Gfunc.axiosGetAction(service,"getminfo")
      if(response.data.err&&response.data.err!=='-1'){
        //handleAlert(true,"error",response.data.err)
      }else{
        Gfunc.axiosResponse(response,()=>{
          if(response.data.jData){
           setData(response.data.jData.source)
           setMags(response.data.jData.destinations)

          }
        })
      } 
    }

    const fetchBon=async(UserDeliv)=>{
      var val=null
      const index=livrs.findIndex(obj=>obj.nom===UserDeliv)
      if(index!==-1){
         val=livrs[index].id
      }
      const response=await Gfunc.axiosPostAction(service,"getbons",{dat:Gfunc.formatDateAr(props.date),mod:props.do,userDeliverId:parseInt(val)})
      if(response.data.err&&response.data.err!=='-1'){
        //handleAlert(true,"error",response.data.err)
      }else{
        Gfunc.axiosResponse(response,()=>{
          if(response.data.jData){
           setBons(response.data.jData)
          }
        })
      }
    }

    const fetchLiv= useCallback(async() => {
      const response = await Gfunc.axiosGetAction(service ,'getsliv')
      if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,"error",response.data.err)
      }else{
      Gfunc.axiosResponse(response,()=>{
          setLivrs(response.data.jData)  
      })
    }
    },[Gfunc]);
    
    const handleOpenLiv= useCallback(async () => {
      if(livrs.length===0){fetchLiv()}
    },[livrs,fetchLiv])

    const HandleValidate=(value)=>{
      const index=mags.findIndex(obj=>obj.nom===value)
      setInd(index)
      setDisplay(true)
    }

    const HeadComp=({data,mags,ind,title,numero})=>{
      return(
        <div id='info-header'>
        <div id='header1'>
          <p id='date'>le <b>{Gfunc.formatDate(props.date)}</b></p>
          <p id='titre'>{title}</p>
        </div>
          <hr style={{ height: '8px', background: "var(--primary-light)", border: 'none' }} />
        <div id='info'>
        {
           data.length!==0 && <div id='head-src'>
             <p>De <b>{data.nom}</b></p>
             <p><b>{data.adresse}</b></p>
             <p>Article: <b>{data.article}</b></p>
             <p>NIF: <b>{data.nif}</b></p>
             <p>NIS: <b>{data.nis}</b></p>
             <p>NRC: <b>{data.nrc}</b></p>
           </div>
        }
         {
           numero!=='' && <div id='head-title'>
              <p>№ {numero}</p>
           </div>
          }
        { 
          mags.length!==0 && <div id='head-dst'>
            <p>Expédie à <b>{mags[ind].nom}</b></p>
            <p> <b>{mags[ind].adresse}</b></p>
            <p>Article: <b>{mags[ind].article}</b></p>
            <p>NIF: <b>{mags[ind].nif}</b></p>
            <p>NIS: <b>{mags[ind].nis}</b></p>
            <p>NRC: <b>{mags[ind].nrc}</b></p>
          </div>
        }
        </div>
          <hr style={{ height: '8px', background: "var(--primary-light)", border: 'none' }} />
        </div>
      )
    }

    const TableCompo=({dataBon,columns})=>{
      return(
        <div>
              <DataTable 
                  persistTableHead
                  columns={columns}
                  data={dataBon}
                  noHeader={false}
                  customStyles={customStyles}
                  defaultSortAsc={true}
                  noDataComponent={param.ErreurData}    
                  highlightOnHover
                  paginationComponentOptions={{ rowsPerPageText: 'Element par page:', rangeSeparatorText: 'sur'}}               
                /> 
        </div>
      )
    }

    const BonLivCompo=({data,mags,display,ind,dataBon,numero})=>{

      const columns = useMemo(()=>[   
        {
            name: "Article",
            selector: row=>row['article'],
        },
        {
            name: "Qte.",
            selector: row=>row['qte'],
            cell: (row) => {
              return (
                 <div>{new Intl.NumberFormat().format(row.qte)} </div>      
               );
            }, 
        },
      ],[dataBon])

      return(
        <div class='screen-liv'>
          <HeadComp data={data} mags={mags} display={display} ind={ind} title="Bon de livraison" numero={numero} />
          <TableCompo dataBon={dataBon} columns={columns}/>
           <div id='row-sign'>
            <p id='acc'>Accusé de réception</p>
            <p id='sign'>Signature</p>
           </div>
        </div>
      )
    }

    const FacteurCompo=({data,mags,display,ind,dataBon,numero,ht,tva,ttc,str})=>{
      const columns = useMemo(()=>[   
        {
            name: "Article",
            selector: row=>row.article,
            minWidth:'350px !important',
        },
        {
            name: "Qte.",
            selector: row=>row.qte,
            cell: (row) => {
              return (
                 <div>{new Intl.NumberFormat().format(row.qte)} </div>      
               );
            }, 
        },
        {
            name: "Prix",
            selector: row=>row.prix,
            cell: (row) => {
                return (
                   <div>{new Intl.NumberFormat().format(row.prix)} </div>      
                 );
            }, 
        },
        {
            name: "Total",
            selector: row=>row.total,
            cell: (row) => {
                return (
                   <div>{new Intl.NumberFormat().format(row.total)} </div>      
                 );
            }, 
        },
      ],[])
      return(
        <div className={str==='All'?'scren-colis':''}>
          <HeadComp data={data} mags={mags} display={display} ind={ind}  title="Facture" numero={numero} />
          <TableCompo dataBon={dataBon} columns={columns}/>
          <div id='mtns-fact'>
            <p>HT : <b>{new Intl.NumberFormat().format(ht)}</b></p>
            <p>TVA : <b>{new Intl.NumberFormat().format(tva)}</b></p>
            <p>TTC : <b>{new Intl.NumberFormat().format(ttc)}</b></p>
          </div>
          <div id='mtn-ltr'>
           <p>Totale de la facture : <b>{NumberToLetter(ttc)+' dinars.'}</b></p>
          </div>
          <div id='row-sign'>
            <p id='acc'>Accusé de réception</p>
            <p id='sign'>Signature</p>
          </div>
        </div>
      )
    }

    const BonColCompo=({data,mags,display,ind,dataBon,numero,str})=>{

      const columns = useMemo(()=>[   
        {
            name: "Client",
            selector: row=>row.client,
        },
        {
            name: "Montants",
            selector: row=>row.mtns,
            cell: (row) => {
              return (
                 <div>{Gfunc.FormatStringNumber(row.mtns)} </div>      
               );
            }, 
        },
        {
            name: "Colis",
            selector: row=>row.nombre,
            cell: (row) => {
              return (
                 <div>{new Intl.NumberFormat().format(row.nombre)} </div>      
               );
            }, 
        },
        ],[])
        
      return(
        <div className={(str==='All')?'scren-colis':''}>
          <HeadComp data={data} mags={mags} display={display} ind={ind} title="Bon de colisage" numero={numero} />
          <TableCompo dataBon={dataBon} columns={columns}/>
          <div id='tot-colis'>
            {
              dataBon && dataBon.length !== 0 && <p>Total:  {new Intl.NumberFormat().format(dataBon.reduce((acc, item) => acc + item.nombre, 0))}</p>
            }
          </div>
          <div id='row-sign'>
            <p id='acc'>Accusé de réception</p>
            <p id='sign'>Signature</p>
           </div>
           <div className="page-footer"></div>
        </div>
      )
    }

    const AllCompo=({data,mags,display,ind,bons})=>{
      return(
        <div>
          <BonLivCompo data={(data.length!==0)?data:[]} mags={(mags.length!==0)?mags:[]} 
                      display={display} ind={ind} 
                      dataBon={(bons.length!==0)?bons.bls.data:[]} 
                      title="Bon de livraison" 
                      numero={(bons.length!==0)?bons.bls.numero:null} />
          <Divider id='devider'/>
          <FacteurCompo data={(data.length!==0)?data:[]} mags={(mags.length!==0)?mags:[]} display={display} ind={ind} 
                        dataBon={(bons.length!==0)?bons.facts.data:null}
                        title="Facture" numero={(bons.length!==0)?bons.bls.numero:null}
                        ht={(bons.length!==0)?bons.facts.ht:null} tva={(bons.length!==0)?bons.facts.tva:null} 
                        ttc={(bons.length!==0)?bons.facts.ttc:null} str='All'/>
          <Divider id='devider'/>
          <BonColCompo data={(data.length!==0)?data:[]} mags={(mags.length!==0)?mags:[]} display={display} ind={ind} 
                       dataBon={(bons.length!==0)?bons.colis.data:null} title="Bon de colisage" 
                       numero={(bons.length!==0)?bons.colis.numero:null} str='All'/>
        </div>
      )
    }

    const componentToPrint=(choix,data,mags,display,ind,bons)=>{
      var c=null
      const index=array.findIndex(obj=>obj.nom===choix)
      if(index!==-1) { c=array[index].id}
      switch(c){
        case 0 :return <AllCompo data={(data!==undefined)?data:[]} mags={mags} display={display} ind={ind} bons={(bons.length!==0)?bons:[]}/>
        case 1 :return <BonLivCompo data={(data!==undefined)?data:[]} mags={(mags!==undefined)?mags:[]} 
                                    display={display} ind={ind} dataBon={(bons.length!==0)?bons.bls.data :[]} title="Bon de livraison" 
                                    numero={(bons.length!==0)?bons.bls.numero:null} />
        case 2 :return <FacteurCompo data={(data!==undefined)?data:[]} mags={mags} display={display} ind={ind} 
                                     dataBon={(bons.length!==0)?bons.facts.data :[]}  title="Facture" 
                                    numero={(bons.length!==0)?bons.facts.numero:null} ht={(bons.length!==0)?bons.facts.ht:null} 
                                    tva={(bons.length!==0)?bons.facts.tva:null} ttc={(bons.length!==0)?bons.facts.ttc:null}/>
        case 3 :return <div>
                           <BonColCompo data={(data!==undefined)?data:[]} mags={mags} display={display} ind={ind} 
                           dataBon={(bons.length!==0)?bons.colis.data :[]} title="Bon de colisage" 
                             numero={(bons.length!==0)?bons.colis.numero:null}/>
                          <div id='hidden-for-screen' >
                            <BonColCompo data={(data!==undefined)?data:[]} mags={mags} display={display} ind={ind} 
                              dataBon={(bons.length!==0)?bons.colis.data :[]} title="Bon de colisage" 
                             numero={(bons.length!==0)?bons.colis.numero:null}/>
                          </div> 
                       </div>
      }
      
    }


    return (
    <div >
        <Button  className={classes.topTabBtnLiv} onClick={handleClickOpen} variant="contained" color="primary" 
                 startIcon={<Print/>}>Commandes livreurs</Button>
        <Dialog 
            open={open}
            onClose={handleClose}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={paperProps}
        >
          <DialogTitle className={classes.topDivTitle} id="draggable-dialog-title" >Commandes des livreurs </DialogTitle>
          <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA}/>
          <DialogContent > 
          <fieldset className="fieldsetTop" >
          <legend>Commandes livreur </legend>
              <div id='liv-head'>
                <Autocomplete
                    disableClearable
                    value={selectLiv}
                    onChange={(e,newValue) =>{setSelectLiv(newValue);fetchBon(newValue)}} 
                    onFocus={handleOpenLiv}
                    options={livrs.map((option) => option.nom)}
                    className='smallText' 
                    renderInput={(params) => (
                      <TextField {...params} className={classes.input11} label="Livreur" color="primary" 
                                 variant="outlined" size="small"  
                      />)} 
                />
                <Autocomplete
                    disableClearable
                    value={selectMag}
                    onChange={(e,newValue) =>{setSelectMag(newValue);HandleValidate(newValue)}} 
                    options={mags.map((option) => option.nom)}
                    className='smallText' 
                    disabled={(selectLiv==='')?true:false}
                    renderInput={(params) => (
                      <TextField {...params}  className={classes.input11} label="Magasin" color="primary" 
                                 variant="outlined" size="small"  
                      />)} 
                />
                <Autocomplete
                    disableClearable
                    value={choix}
                    onChange={(e,newValue) =>{setChoix(newValue);}} 
                    options={array.map((option) => option.nom)}
                    className='smallText' 
                    renderInput={(params) => (
                      <TextField {...params} className={classes.input11} label="Choix" color="primary" 
                                 variant="outlined" size="small"  
                      />)} 
                /> 
               {/*<Button className={classes.fieldButton} variant="contained" tabIndex="3" type='submit' id="bouton" name="addBtn" 
                        onClick={()=>{HandleValidate()}} color="primary" startIcon={<DoneAll/>}>VALIDER</Button>*/}
              </div> 
            </fieldset>
            {
              (display)?
                  <div ref={componentRef} id='div-print'  key={Math.floor(Math.random() * (1000 - 1 + 1)) + 3}>
                    {componentToPrint(choix,data,mags,display,ind,bons)}
                  </div>
              :null
            }
            
          </DialogContent>
          <DialogActions>
            <ReactToPrint
                 trigger={() => <Button variant="contained" color="primary"  startIcon={<Print/>}>Imprimer</Button>}
                 content={() => componentRef.current} 
            />           
            <Button variant="contained"  onClick={handleClose} color="secondary">FERMER</Button>
          </DialogActions>
        </Dialog>
      </div>
  );
}
