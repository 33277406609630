import { useState ,useCallback,useEffect} from "react";
import {CircularProgress,Backdrop,TablePagination,FormControlLabel, Checkbox, Button,  TextField, ClickAwayListener, TextareaAutosize, Box, Tooltip} from '@material-ui/core';
import { textAreaEdit, useStyles } from '../../css/muiStyle';
import {AlertCompo, AutocompleteS, InputNbrEditable, NotAllowedComponent} from "../../js/components"
import AllComments from './modals/prd_new_AllComments'
import AllFavories from './modals/prd_new_AllFavories'
import Comments from './modals/prd_new_comments'
import Favories from './modals/prd_new_favories'
import CatAdd from './modals/cat_prd_cat_add'
import { useContext } from 'react';
import ParamContext from '../../Contexte';
import ProdImg from '../content/modals/cat_prd_profil'
import { Add, CalendarTodayOutlined, Delete, Description,Search } from "@material-ui/icons";
import { ContentCopy } from "@mui/icons-material";
import ContentEditable from "react-contenteditable";
import DropdownColor from '../../../src/compo/DropDownColor'
import axios from "axios"
import '../../css/animation.css'

export default function Cat_prd() {
const classes = useStyles();
const Gfunc=require('../../Gfunc')
const param=useContext(ParamContext);
const librery=require('../../js/data_table_librery')
const [data,setData]=useState([]);
const [colL,setColL]=useState([]);
const [search,setSearch]=useState('')
const [,settest] = useState(false);
const [loading, setLoading] = useState(false);
const [totalRows, setTotalRows] = useState(0);
const [openA, setOpenA] =useState(false);
const [errMessage,setErrMessage]=useState('');
const [notAllowed,setNotAllowed]=useState(false);
const [messageA, setMessageA] =useState('');
const [severity, setSeverity] =useState('info');
const [page, setPage] =useState(1);
const [rowsPerPage, setRowsPerPage] =useState(50);
const [called,setCalled]=useState(false);
const service='_prd.php'
const srv='produit'

const handleAlert=(open,severity,message)=>{
  setOpenA(true)
  setSeverity(severity)
  setMessageA(message)
  setTimeout(function(){
    setOpenA(false)
  }, 5000);
 }

const fetch= useCallback(async(page,s,servic = service, size = rowsPerPage) => {
  setLoading(true);
  setCalled(true)
  const response = await axios.get( param.urlService+service+'?do=get&page='+(page)+"&per_page="+size+"&search="+((s!==undefined)?s:''),{ headers: Gfunc.getHeader()});
  setLoading(false);
  if(response.data.err&&response.data.err!=='-1'){
    setErrMessage(response.data.err)
    setNotAllowed(true)
  }else{
  Gfunc.axiosResponse(response,()=>{
      setTotalRows(response.data.recordsFiltered)
      if(response.data.recordsFiltered===0){setErrMessage('Aucun résultat.')}
      if(response.data.data){
          setData(response.data.data)
          setNotAllowed(false)
      }
})
}
},[Gfunc,param.urlService,rowsPerPage]);

  //fetch color
  const fetchCol= useCallback(async(serv='_col.php', act = "gets") => {
    const response = await Gfunc.axiosGetAction(serv,act);
    if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,'error',response.data.err)
    }else
      Gfunc.axiosResponse(response,()=>{
        if(response.data.jData){
          setColL(response.data.jData);    
        }
    })
  },[Gfunc])

  const handleOpenCol= useCallback(async () => {
    if(colL.length===0)
    fetchCol()
  },[colL,fetchCol])

  useEffect(() => {
    if(!called) {fetch(1);handleOpenCol() }
  },[fetch,handleOpenCol]);

  const handleChange=(val)=>{
    settest(val)
  }
  const handleOnEdit=useCallback(async(e,row,att)=>{
    var edt='';var edt1='';var val=''
      if(att!=='colorId'){
        edt=e.target.innerHTML
        edt1=(edt!=='---')?Gfunc.DeleteSpace(edt):null
        val=(row[att]!==null)?row[att].trim().replace(/\s{2,}|\n|\r/g, "\n"):null
      }else{
          edt1=e.id
          val=row[att]
      }
      if(val!==edt1){
        const attrib=row[att];
        const response=await librery.axiosEdit(service,row,edt1,att) 
        if(response.data.err&&response.data.err!=='-1'){
          handleAlert(true,"error",response.data.err)
        }else{
        Gfunc.axiosResponse(response,()=>{
          row[att]=edt1;
          if(att!=='colorId'){
            e.target.innerHTML=(edt1!==null)?edt1:'---'
          }else{
            var objIndex = colL.findIndex((obj => obj.color ===row.colorCode));
            row.colorCode=e.color
            if(objIndex!==-1){row.color=colL[objIndex].nom}
            settest(true)
            handleChange(false)
          }
        })
        }
        if(response.data.err) {e.target.innerHTML=attrib}
      }else {e.target.innerHTML=edt1}
  },[librery,Gfunc,colL,handleChange]);

  const handleAddProd=async()=>{
    const response=await Gfunc.axiosPostAction(service,"add")
    if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,"error",response.data.err)
      fetch(1)
    }else{
      Gfunc.axiosResponse(response,()=>{
          if(response.data.jData===true){
            fetch(1)
          }         
      })
    }
  }

  const Product=({data,handleOnEdit})=>{
    const [prdL,setPrdL]=useState([]);
    const [catL,setCatL]=useState([]);
    const [magL,setMagL]=useState([]);
    const [srcL,setSrcL]=useState([]);
    const [vrsL,setVrsL]=useState([]);
    const [paysL,setpaysL]=useState([]);
    const [kitL,setKitL]=useState([]);
    const [cofL,setCofL]=useState([]);
    const [qtL,setQtL]=useState([]);
    const [chrgL,setChrgL]=useState([]);
    const [selecto,setSelecto]=useState('')
    const [Item,setItem]=useState('')
    const [ItemN,setItemN]=useState('')
    const [note,setNote]=useState('')
    let arr =(data.catalogs!==null&&data.catalogs.split(",").length!==0)?data.catalogs.split(","):[data.catalogs]


    //fetch catalogue
    const fetchCat= useCallback(async(serv="_cat.php", act = "gets") => {
      arr.push(data.catalog)
      const response = await Gfunc.axiosGetAction(serv,act);
      if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,'error',response.data.err)
      }else
      Gfunc.axiosResponse(response,()=>{
          setCatL(response.data.jData);    
      })
    },[Gfunc])

    const handleOpenCat= useCallback(async () => {
      if(catL.length===0) fetchCat()
    },[catL,fetchCat])

    //fetch categories
    const fetchMag= useCallback(async(serv=service, act = "getmags") => {
      var arr=[];
      const response = await Gfunc.axiosGetAction(serv,act);
      if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,'error',response.data.err)
      }else
      Gfunc.axiosResponse(response,()=>{
        Object.keys(response.data.jData).map(key=> {
          arr.push({id:key,nom:response.data.jData[key]})
          return true
        }) 
          setMagL(arr);    
      })
    },[Gfunc])

    const handleOpenMag= useCallback(async () => {
      if(magL.length===0) fetchMag()
    },[magL,fetchMag])

    //fetch quality
    const fetchQt= useCallback(async(serv=service, act = "getqlty") => {
      var arr=[]
      const response = await Gfunc.axiosGetAction(serv,act);
      if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,'error',response.data.err)
      }else
      Gfunc.axiosResponse(response,()=>{
        Object.keys(response.data.jData).map(key=> {
            arr.push({id:key,name:response.data.jData[key]})
        }) 
        setQtL(arr);    
      })
    },[Gfunc])

    const handleOpenQt= useCallback(async () => {
      if(qtL.length===0) fetchQt()
    },[qtL,fetchQt])

    //fetch source
    const fetchSrc= useCallback(async(serv=service, act = "getsrc") => {
      var arr=[]
      const response = await Gfunc.axiosGetAction(serv,act);
      if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,'error',response.data.err)
      }else
      Gfunc.axiosResponse(response,()=>{
        Object.keys(response.data.jData).map(key=> {
          arr.push({id:key,name:response.data.jData[key]})
        }) 
        setSrcL(arr);    
      })
    },[Gfunc])

    const handleOpenSrc= useCallback(async () => {
      if(srcL.length===0)
      fetchSrc()
    },[srcL,fetchSrc])

    //fetch verions
    const fetchVrs= useCallback(async(serv=service, act = "getvrs") => {
      var arr=[]
      const response = await Gfunc.axiosGetAction(serv,act);
      if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,'error',response.data.err)
      }else
      Gfunc.axiosResponse(response,()=>{
        Object.keys(response.data.jData).map(key=> {
          arr.push({id:key,name:response.data.jData[key]})
        }) 
        setVrsL(arr);    
      })
    },[Gfunc])

    const handleOpenVrs= useCallback(async () => {
      if(vrsL.length===0)
      fetchVrs()
    },[vrsL,fetchVrs])

    const fetchPays= useCallback(async(serv=service, act = "getcnt") => {
      const response = await Gfunc.axiosGetAction(serv,act);
      if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,'error',response.data.err)
      }else
      Gfunc.axiosResponse(response,()=>{
        setpaysL(response.data.jData);    
      })
    },[Gfunc])

    const handleOpenPays= useCallback(async () => {
      if(paysL.length===0)
      fetchPays()
    },[paysL,fetchPays])


    //fetch kit
    const fetchKit= useCallback(async(serv=service, act = "getkit") => {
      var arr=[]
      const response = await Gfunc.axiosGetAction(serv,act);
      if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,'error',response.data.err)
      }else
      Gfunc.axiosResponse(response,()=>{
        Object.keys(response.data.jData).map(key=> {
          arr.push({id:key,name:response.data.jData[key]})
        }) 
        setKitL(arr);    
      })
    },[Gfunc])

    const handleOpenKit= useCallback(async () => {
      if(kitL.length===0)
      fetchKit()
    },[kitL,fetchKit])

    const fetchCof= useCallback(async(serv=service, act = "getcof") => {
      var arr=[]
      const response = await Gfunc.axiosGetAction(serv,act);
      if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,'error',response.data.err)
      }else
      Gfunc.axiosResponse(response,()=>{
        Object.keys(response.data.jData).map(key=> {
          arr.push({id:key,name:response.data.jData[key]})
        }) 
        setCofL(arr);    
      })
    },[Gfunc])

    const handleOpenCof= useCallback(async () => {
      if(cofL.length===0)
      fetchCof()
    },[cofL,fetchCof])

    //fetch charge
    const fetchChrg= useCallback(async(serv=service, act = "getchg") => {
      var arr=[];
      const response = await Gfunc.axiosGetAction(serv,act);
      if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,'error',response.data.err)
      }else
      Gfunc.axiosResponse(response,()=>{
        Object.keys(response.data.jData).map(key=> {
          arr.push({id:key,name:response.data.jData[key]})
        }) 
        setChrgL(arr);    
      })
    },[Gfunc])

    const handleOpenChrg= useCallback(async () => {
      if(chrgL.length===0)
      fetchChrg()
    },[chrgL,fetchChrg])
    
    const fetchPrd= useCallback(async(serv=service, act = "getbqprd") => {
      const response = await Gfunc.axiosPostAction(serv,act,{magId:parseInt(data.magId)});
      if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,'error',response.data.err)
      }else
      Gfunc.axiosResponse(response,()=>{
        if(response.data.jData){
          setPrdL(response.data.jData);    
        }
      })
    },[Gfunc])

    const handleOpenPrd= useCallback(async () => {
      if(prdL.length===0)
      fetchPrd()
    },[prdL,fetchPrd])

    const handleDup=async()=>{
      const response=await Gfunc.axiosPostAction(service,"duplic",{produitId:parseInt(data.DT_RowId.substr(1))})
      if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,"error",response.data.err)
      }else{
      Gfunc.axiosResponse(response,()=>{
        fetch(1)
      })
      }
    }

    const handleDel=async()=>{
      const x=window.confirm("Êtes-vous sûrs de supprimer cet élément?");
      if(x){
        const response=await Gfunc.axiosPostAction(service,"del",{produitId:data.DT_RowId.substr(1)})
        if(response.data.err&&response.data.err!=='-1'){
          handleAlert(true,"error",response.data.err)
        }else{
        Gfunc.axiosResponse(response,()=>{
          fetch(1)
        })
        }
      }
    }
    
    const flagMan = useCallback(async(e,row,att)=>{
      const response= await Gfunc.axiosPostAction('flagman.php','1',({ srv:srv, id: row.DT_RowId.substr(1),att:att, val:(row[att] ===true)?1:0 }))
      if(response.data.err&&response.data.err!=="-1"){
        handleAlert(true,'error',response.data.err)
      }else{
        Gfunc.axiosResponse(response,()=>{
          if(response.data.jData){
            row[att]=!row[att]
          }

                })
      }  
  },[Gfunc])
  
  const handleOnFocus=(e)=>{
    var txt=e.target.innerHTML
    if(txt==='---'){
      e.target.innerHTML=''
    }else{
       e.target.innerHTML=txt
    }
  }

    //handle update note
  const handleOnEditNote=useCallback(async(note,row,att,action)=>{
      var edt1=note
     // var edt=Gfunc.DeleteSpace(edt1);
      if(row[att]!==edt1){
        //edt1=edt1.replaceAll("\n", "\n\r")
        const attrib=row[att];
        const response=await librery.axiosEdit2(service,action,row,edt1,att) 
        if(response.data.err&&response.data.err!=='-1'){
          handleAlert(true,'error',response.data.err)
        }else
        Gfunc.axiosResponse(response,()=>{
          row[att]=edt1
          setSelecto('');setNote('');setItem('');setItemN('')
        })
        if(response.data.err) {row[att]=attrib}
      }else {row[att]=edt1;setSelecto('')}
      setNote('')
      setSelecto('')
  },[librery,Gfunc]);
    
  const handleSelect=useCallback((row,att)=>{
    setSelecto(row[att])
    setItem(row.DT_RowId)
    setItemN(att)
   },[])

  return(
        <div id="card-prod">
        <div id='card-div'>
        <div id='div-img'>
          <ProdImg data={data} handleAlert={handleAlert}/>
        </div>
        <div id='card-info'>
              <div id='info-prod'>
              <div id='names'>
                <div id='div-name' >
                  <Tooltip title="Titre français">
                    <div>
                      <ContentEditable
                          html={(data.nameFr!==null)?data.nameFr:'---'} data-column="item" onFocus={e=>{handleOnFocus(e)}}
                          className="content-editable-prod-name" disabled={false} onBlur={e => handleOnEdit(e,data,"nameFr")} />
                    </div> 
                  </Tooltip>
                  <Tooltip title={(data.color!==null)?data.color:"Aucune"}>
                    <div key={Math.floor(Math.random() * (1000 - 1 + 1)) + 1} >
                    <DropdownColor data={data} col={colL} handleOnEdit={handleOnEdit} style={{height:'300px'}}/>
                    </div>
                  </Tooltip>
                </div>
                <Tooltip title="Magasin">
                  <div style={{width:'55%',marginTop:'-6px'}}>
                   <AutocompleteS
                      service={service} action={'upd'} defaultValue={(data.mag!==null)?data.mag:'---'} row={data} list={magL} 
                      id={'magId'} name='mag' attr='nom' onOpen={handleOpenMag} className="content-editable-prod-bq" handleAlert={handleAlert}/>
                  </div>
                </Tooltip>
                <Tooltip title="Nom boutiqa">
                  <div style={{width:'80%'}} >
                    <AutocompleteS
                      service={service} action={'upd'} defaultValue={data.bqProduct} row={data} list={prdL} men='prd' handleAlert={handleAlert}
                      id={'bqProductId'} name='bqProduct' attr='bqProduct' idi='bqProductId' onOpen={()=>handleOpenPrd(data)} className="content-editable-prod-bq" />   
                  </div>
                </Tooltip>
              </div>
              <div >
                 <Tooltip title={'Ajouté le:  '+Gfunc.formatDate(new Date(data.date.substr(0,10)))}>
                    <div id='dt-add-prd'>
                      <CalendarTodayOutlined style={{height:'16px',width:'16px'}}/>
                      <p>{Gfunc.formatDate(new Date(data.date.substr(0,10)))}</p>
                    </div>
                 </Tooltip>
                  <fieldset className="fieldInf" >
                      <legend> Catalogues:</legend>
                      {(arr.length!==0)?
                             arr.map((elem,index)=>{
                                             return (elem!==null)?((index===arr.length-1)?elem+ ' . ':elem+ ' - '):"Aucun."
                                        }
                                        ):'Aucun.'}
                  </fieldset>
              </div>
              </div>
              <div id='prx-prod'>
                <div>
                  <p id='elem-title'>Prix gros</p>
                  <InputNbrEditable
                        service={service} action='upd' min={1} className='content-editable-prod'
                        row={data} att="wholePrice" value={(data.wholePrice)?data.wholePrice:0} id='DT_RowId' idi={data.DT_RowId}
                        handleAlert={handleAlert}
                                  />
                </div>
                <div>
                  <p id='elem-title'>Prix détail</p>
                  <InputNbrEditable
                        service={service} action='upd' min={1} className='content-editable-prod'
                        row={data} att="retailPrice" value={(data.retailPrice)?data.retailPrice:0} id='DT_RowId' idi={data.DT_RowId}
                        handleAlert={handleAlert}
                                  />
                </div>
                <div>
                  <p id='elem-title'>Prix bonus</p>
                  <InputNbrEditable
                        service={service} action='upd' min={1} className='content-editable-prod'row={data} att="bonusPrice" 
                        value={(data.bonusPrice)?data.bonusPrice:0} id='DT_RowId' idi={data.DT_RowId} handleAlert={handleAlert}
                  />
                </div>
                <div>
                  <p id='elem-title'>Qualité</p>
                  <AutocompleteS
                      service={service} action={'upd'} defaultValue={(data.quality!==null)?data.quality:'---'} row={data} list={qtL} men='prd'
                      id={'qualityId'} name='quality' attr='name' onOpen={handleOpenQt} className="content-editable-prod" handleAlert={handleAlert}/>
                </div>
                <div>
                  <p id='elem-title'>Version</p>
                  <AutocompleteS
                      service={service} action={'upd'} defaultValue={(data.version!==null)?data.version:'---'} row={data} list={vrsL} men='prd'
                      id={'versionId'} name='version' attr='name' onOpen={handleOpenVrs} className="content-editable-prod" handleAlert={handleAlert}/>
                </div>
                <div>
                  <p id='elem-title'>Pays</p>
                  <AutocompleteS
                      service={service} action={'upd'} defaultValue={(data.pays!==null)?data.pays:'---'} row={data} list={paysL} men='prd'
                      id={'paysId'} name='pays' attr='nom' onOpen={handleOpenPays} className="content-editable-prod" handleAlert={handleAlert}/>
                </div>
                <div>
                  <p id='elem-title'>Source</p>
                    <AutocompleteS
                      service={service} action={'upd'} defaultValue={(data.source!==null)?data.source:'---'} row={data} list={srcL} men='prd'
                      id={'sourceId'} name='source' attr='name' onOpen={handleOpenSrc} className="content-editable-prod" handleAlert={handleAlert}/>
                </div>
                <div>
                  <p id='elem-title'>Mémoire</p>
                    <ContentEditable onFocus={e=>{handleOnFocus(e)}}
                      html={(data.memory!==null)?data.memory:'---'} data-column="item" className="content-editable-prod" disabled={false}
                      onBlur={e => handleOnEdit(e,data,"memory")}
                    />
                </div>
                <div>
                  <p id='elem-title'>Batterie</p>
                  <ContentEditable
                      html={(data.battery!==null)?data.battery:'---'} data-column="item" onFocus={e=>{handleOnFocus(e)}}
                      className="content-editable-prod" disabled={false}
                      onBlur={e => handleOnEdit(e,data,"battery")}
                    />
                </div>
                <div>
                  <p id='elem-title'>Capteur</p>
                  <ContentEditable
                      html={(data.captor!==null)?data.captor:'---'} data-column="item" onFocus={e=>{handleOnFocus(e)}}
                      className="content-editable-prod" disabled={false}
                      onBlur={e => handleOnEdit(e,data,"captor")}
                    />
                </div>
                <div>
                  <p id='elem-title'>Ecran</p>
                  <ContentEditable
                      html={(data.screen!==null)?data.screen:'---'} data-column="item" onFocus={e=>{handleOnFocus(e)}}
                      className="content-editable-prod" disabled={false} onBlur={e => handleOnEdit(e,data,"screen")}
                    />
                </div>
                <div>
                  <p id='elem-title'>Système</p>
                  <ContentEditable
                      html={(data.os!==null)?data.os:'---'} data-column="item" onFocus={e=>{handleOnFocus(e)}}
                      className="content-editable-prod" disabled={false} onBlur={e => handleOnEdit(e,data,"os")}
                  />
                </div>
                <div>
                  <p id='elem-title'>Réseau</p>
                  <ContentEditable
                      html={(data.network!==null)?data.network:'---'} data-column="item" onFocus={e=>{handleOnFocus(e)}}
                      className="content-editable-prod" disabled={false} onBlur={e => handleOnEdit(e,data,"network")}
                  />
                </div>
                <div>
                  <p id='elem-title'>CPU</p>
                  <ContentEditable
                      html={(data.cpu!==null)?data.cpu:'---'} data-column="item" onFocus={e=>{handleOnFocus(e)}}
                      className="content-editable-prod" disabled={false}
                      onBlur={e => handleOnEdit(e,data,"cpu")}
                    />
                </div>
                <div>
                  <p id='elem-title'>Garantie</p>
                  <ContentEditable
                      html={(data.warranty!==null)?data.warranty:'---'} data-column="item" onFocus={e=>{handleOnFocus(e)}}
                      className="content-editable-prod" disabled={false} onBlur={e => handleOnEdit(e,data,"warranty")}
                  />
                </div>
                <div>
                  <p id='elem-title'>Catalogue par défaut</p>
                  <AutocompleteS
                      service={service} action={'upd'} defaultValue={(data.catalog!==null)?data.catalog:'---'} row={data} list={catL} men='prd'
                      id='catalogId' name='catalog' attr='nom' onOpen={handleOpenCat} className="content-editable-prod" handleAlert={handleAlert}/>
                </div>
                <div>
                  <p id='elem-title'>Chargeur</p>
                  <AutocompleteS
                      service={service} action={'upd'} defaultValue={(data.charger!==null)?data.charger:'---'} row={data} list={chrgL} men='prd'
                      id={'chargerId'} name='charger' attr='name' onOpen={handleOpenChrg} className="content-editable-prod" handleAlert={handleAlert}/>
                </div>
                <div>
                  <p id='elem-title'>Kit main libre</p>
                  <AutocompleteS
                      service={service} action={'upd'} defaultValue={(data.kit!==null)?data.kit:'---'} row={data} list={kitL} men='prd'
                      id={'kitId'} name='kit' attr='name' onOpen={handleOpenKit} className="content-editable-prod" handleAlert={handleAlert}/>
                </div>
                <div>
                  <p id='elem-title'>Coffret</p>
                  <AutocompleteS
                      service={service} action={'upd'} defaultValue={(data.box!==null)?data.box:'---'} row={data} list={cofL} men='prd'
                      id={'boxId'} name='box' attr='name' onOpen={handleOpenCof} className="content-editable-prod" handleAlert={handleAlert}/>
                </div>
              </div>
              <div id='div-det-prd'>
              <div id='div-interc'>
                <Favories nbr={data.loves} row={data}/>{data.loves}
                <Comments nbr={data.comments} row={data}/>{data.comments}
              </div>
              <div id='det-prd'>
                <FormControlLabel  label="Non disponible" 
                      control={<Checkbox  defaultChecked={data.unavailable} style={{transform: "scale(0.9)"}} 
                      onChange={e=>{flagMan(e,data,"unavailable")}} inputProps={{ 'aria-label': 'controlled' }}  />}
                    />  
                <FormControlLabel  label="Nouveau" 
                      control={<Checkbox  defaultChecked={data.new} style={{transform: "scale(0.9)"}} 
                      onChange={e=>{flagMan(e,data,"new")}} inputProps={{ 'aria-label': 'controlled' }}  />}
                    />  
                <FormControlLabel  label="Promo" 
                      control={<Checkbox   defaultChecked={data.promo} style={{transform: "scale(0.9)"}} 
                      onChange={e=>{flagMan(e,data,"promo")}} inputProps={{ 'aria-label': 'controlled' }}  />}
                    />  
                <Tooltip  title="Duplique le produit"> 
                  <Button className={classes.iconBtnBig} variant="contained"  onClick={()=>{handleDup()}} color="primary">
                    <ContentCopy fontSize="small"/>
                  </Button>
                </Tooltip> 
                <Tooltip  title="Supprimer le produit"> 
                  <Button  className={classes.iconBtnBig} variant="contained"  onClick={()=>{handleDel()}} color="primary" >
                    <Delete fontSize="small"/>
                  </Button>
                </Tooltip>
                <CatAdd data={data} handleAlert={handleAlert} fetch={fetch} search={search}/>
            </div>
            </div>
        </div>
        <div >
            <div id="description">
              <div id='title'>
                  <Description id='icon'/><p>Description:</p>
              </div>
                { 
                (data.description===selecto && ItemN==='description' && data.DT_RowId===Item)?
                  <ClickAwayListener onClickAway={()=>{handleOnEditNote(note,data,"description",'upd');setSelecto('');data.description=note}}>
                  <TextareaAutosize 
                    style={textAreaEdit} 
                    autoFocus onFocus={(e)=>{ e.target.value=(note)?note:note}}  
                    onChange={(e)=>{setNote(e.target.value);}}
                  /></ClickAwayListener>:
                  ((data.description || []).length>=1)?
                      <div className="content-editable-prod" onClick={()=>{setNote(data.description);handleSelect(data,'description')}}>
                          {data.description}</div>
                 :
                <div className="content-editable-prod" onClick={()=>{setNote(data.description);handleSelect(data,'description')}} >{data.description}</div>
              }
            </div>
        </div>
        </div>
        </div>
      )
    }
  const handleChangePage = (event, newPage) => {
    fetch(newPage+1,search,service,rowsPerPage);
    setPage(newPage+1);
  };
  
  const handleChangeRowsPerPage = (event) => {
      fetch(1,search,service,(event.target.value==="Tous")?-1:parseInt(event.target.value,10));
      setRowsPerPage((event.target.value==="Tous")?totalRows:event.target.value);
      setPage(1);
  };

  const handleSearch=async(size = rowsPerPage)=>{  
     setData([])
     setLoading(true);
     const recherche=search;
     const response=await librery.axiosSearch(service,recherche,1,size,'get')
     setLoading(false);
     if(response.data.err&&response.data.err!=='-1'){
       handleAlert(true,'error',response.data.err)
     }else{
     Gfunc.axiosResponse(response,()=>{
      setTotalRows(response.data.recordsFiltered)
      if(response.data.recordsFiltered===0){setErrMessage('Aucun résultat.')}
      if(response.data.data){
        setData(response.data.data)
      }
     
     })   
   }
     return recherche
     }

  function liveSearch(e,search){
    if(param.liveSearch&&search.length>3){
       e.preventDefault();
       handleSearch();
    }else{ if (e.keyCode === 13) {
              e.preventDefault();
              handleSearch();
            }
    }
  }
  
  
  return (
    <div className="content">
       <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA}/>
       <Backdrop className={classes.backdrop} open={loading} >
         <CircularProgress color="inherit" />
       </Backdrop>
    {
      (notAllowed===false)?
      <div>
      <div  className="table-header">
        <div id='Allreacts'>
          <Button className={classes.topTabBtn} variant="contained" color="secondary" onClick={handleAddProd} startIcon={<Add/>}>
                  Nouveau produit</Button>
          <AllFavories/>
          <AllComments/>
        </div>
        <div className='search' style={{marginBottom:'-1px'}}>
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <TextField className={classes.search} variant="outlined"  label="Recherche" value={search} 
                               InputProps={{startAdornment: (<Search onClick={() => handleSearch()}
                               style={{ marginRight: '5px',zIndex:'1000',width:'20px',height:'20px',cursor:"pointer"}} />),}}
                               size="small" margin='none'  onKeyUp={e => liveSearch(e)} onChange={e => setSearch(e.target.value)} /> 
              </Box>
        </div>
      </div>
     </div>:null
     }
     {
      (data.length!==0)?
         <>
         {
          data.map((el,index)=>{
            return (
              <div key={index}><Product data={el} handleOnEdit={handleOnEdit} /></div>
            )
          })
         }
         <TablePagination
            component="div"
            labelRowsPerPage='Élément par page:'
            count={totalRows}
            rowsPerPageOptions={[50,100,'Tous']}
            page={page-1}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
         </>
      :(errMessage!=='')?<div className='compAllow'><NotAllowedComponent message={errMessage}/></div>:<></>
     }
    </div>
  );
}

