import './App.css';
import { Helmet } from 'react-helmet'
import React, {Component} from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import ErrorPage from './components/error';
import Login from './components/login';
import Pages from './components/pages';
import "react-data-table-component";
import { ThemeProvider } from "@material-ui/core";
import { createAppTheme } from "./css/theme.js"
import EmptyPage from './components/empty';
const Gfunc=require('./Gfunc')
const version=require('./version.json')
var theme = null;

class App extends Component {
  state ={
    menu : {},
    mounted: false,
    loaded: false,
    isLogged:localStorage.getItem('isLoged'),
    param:null
  }
 
  //will be executed once the app is mounted 
  async componentDidMount(){
    var para=await Gfunc.getParams();
    theme = createAppTheme(!(para.app === "2"));
    const link = document.getElementById('dynamic-favicon');

    if (para.app === "2") {
      link.href = "/favicon2.ico";
      import('./css/theme2.css')
        .then(() => {
          import('./css/global.css')
          import('./css/loginLogos2.css')
        })
        .catch(err => {
          console.error('Error loading styles2.css', err);
        });
    } else {
      link.href = "/favicon.ico";
      import('./css/theme1.css')
        .then(() => {
          import('./css/global.css')
          import('./css/loginLogos1.css')
        })
        .catch(err => {
          console.error('Error loading styles1.css', err);
        });

    }
    if(localStorage.getItem('build')&&localStorage.getItem('build') !==version[0].build){
      localStorage.clear();
      window.location=("/");
      localStorage.setItem('build',version[0].build);
    }
    this.setState({
      mounted:true,
      param:para
    })
   if(this.state.isLogged==="true") this.getMenu();
  }  

  //recuperer le fichier json de menu 
  getMenu=async()=> {  
    try{  
        const Gfunc=require('./Gfunc')
        const data=[]
        const response=await Gfunc.axiosGetAction('getMenu.php',"1")
        Gfunc.axiosResponse(response,()=>{
          Object.keys(response.data).map(key=> {
            if(response.data[key]!==null){
              data[key]=response.data[key]
              data.length++
            }
          
            return true
          }) 
        this.setState({loaded:true, menu:data })
        }) 
    }catch(error){
       localStorage.clear();
       window.location=("/");
    }
  }
  render(){
    if (this.state.mounted) {     
      return (
        <ThemeProvider theme={theme}>
            <Helmet>
            <title>{(this.state.isLogged !== 'true') ? ((this.state.param.app === "1") ? this.state.param.webApp : this.state.param.webApp2) + " " + version[0].build : localStorage.getItem('title')}</title>
            </Helmet>
          <BrowserRouter >
            <Switch>
                <Route path="/error"  component={props => <EmptyPage {...props} />}/>
                {this.state.isLogged!=='true'?<Route exact path="/" component={props => <Login {...props} param={this.state.param} />}/>:<Pages menu={this.state.menu} param={this.state.param}/>}
                {(this.state.loaded&&this.state.isLogged==="true")?<Pages menu={this.state.menu} />:<Route component={props => <ErrorPage {...props} />}/>}
          </Switch>
          </BrowserRouter>
        </ThemeProvider>
      );     
    } else {
      return <></>  
    }
  }
}
export default App;