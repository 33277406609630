import React from 'react'
import { PieChart,Pie,Cell,Tooltip,ResponsiveContainer } from 'recharts';

export default function Piechart(props){
    const Gfunc=require('../Gfunc')
    function CustomTooltip({ payload, label, active,...props }) {
        if (active) {   
             return (
                     <div className="custom-tooltip" style={{ border:"2px solid"+Gfunc.rgbToHex(payload[0].payload.fill) }}>
                         <p className="intro"><b>{`${payload[0].name}`}</b>:{(payload.length!==0)?new Intl.NumberFormat().format(`${payload[0].value}`):0 }</p>
                         <p className="intro">{((`${payload[0].value}`*100)/props.percent).toFixed(2)+'%'}</p>
                     </div>
         );}
       return null;}
     /*  function CustomizedLegend({ payload, label, active,...props }) {
        return (
        <div className='legend'>  
         <ul className='legend-content'>
        {
         payload.map((entry, index) => (
            (entry.value==='others')?
             <li id='content' style={{color:entry.payload.fill}} key={`item-${index}`}>Autres</li>
             : (entry.value==='accused')?
             <li  id='content' style={{color:entry.payload.fill}} key={`item-${index}`}>Accusé </li>
             :<></>
          ))
        }
        </ul>
       </div>
    );
              }*/
            
    return (
      <div style={{ width: '100%', height: 300 }}>
          <ResponsiveContainer>
                <PieChart>
                 <Pie data={props.data}  fill="#8884d8" dataKey="value" >
                   {props.data.map((index,i) => (<Cell key={`cell-${index}`} fill={props.color[i]}/>))}        
                 </Pie>  
                <Tooltip content={<CustomTooltip percent={props.percent}/>} /> 
              { /*               <Legend  content={<CustomizedLegend/>}/>*/} 
            </PieChart>
        </ResponsiveContainer>
      </div>      
    );
}