
import {theme} from "./theme.js"

  export const customStyles = {
    header: {
      style: {
        border: "solid 1px black",
        fontSize: '18px',
        textAlign:"center",
        color: "white",
        backgroundColor: "var(--secondary-light)",
        minHeight: '20px',
        height:"25px",
        width:"90%",
        maxWidth:"100%",
        margin:"10px",
        marginLeft: "auto",
        marginRight: "auto",
      },
    },  
    title: {
      style: {
        fontColor: 'red',
        fontWeight: '900',
        marginRight: "15px",
      }
    },
    table: {
      style: {
        width: '96%',
        border: "solid 1px",
        marginLeft: "auto",
        marginRight: "auto"
      }
    },
    rows: {
      style: {
        minHeight: '25px',
        height:'25px',
      },
      highlightOnHoverStyle: {
        color: "var(--primary-dark)",
        backgroundColor: "var(--primary-light)",
        fontWeight:"550",
        transitionDuration: '0.15s',
        transitionProperty: 'background-color',
        borderBottomColor: "black",
        outlineStyle: 'solid',
        outlineWidth: '1px',
        outlineColor: 'black',
      }
    },
    headCells: {
      style: {
        fontSize: '11px',
        justifyContent: 'center',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        color: "var(--primary-dark)",
      },
      activeSortStyle: {
        color: "white",
        '&:hover:not(:focus)': {
          color: "var(--primary-dark)",
        },
      },
      inactiveSortStyle: {
        '&:focus': {
          color: "var(--primary-dark)",
        },
        '&:hover': {
          color: "var(--primary-dark)",
        },
      }
    },
    headRow: {
      style: {
        justifyContent: 'center',
        height: "25px",
        backgroundColor: "var(--secondary-light)",
        minHeight:"25px !important",
        '&:hover': {
          color: "var(--primary-dark)",
       },
      }
    },
    cells: {
      style: {
        fontSize: '11px',
        textAlign: "center",
        //fontWeight:"bold",
        justifyContent: 'center',
        width:'inherit',
      },
    },
    pagination: {
      style: {
        color: "black",
        fontSize: '13px',
        minHeight: '20px',
        minWidth: '20px',
        height:"45px",
        width:"95%",
        marginLeft: "auto",
        marginRight: "auto",
        backgroundColor: "white",
        borderTopStyle: 'solid',
        borderTopWidth: '1px',
        
      },
      pageButtonsStyle: {
        borderRadius: '50%',
        height: '40px',
        width: '40px',
        padding: '8px',
        margin: 'px',
        cursor: 'pointer',
        transition: '0.4s',
        color: "blue",
        fill: "var(--secondary-dark)",
        backgroundColor: 'transparent',
        '&:disabled': {
          cursor: 'unset',
          color: "red",
          fill: "var(--secondary-main)",
        },
        '&:hover:not(:disabled)': {
          backgroundColor: "var(--secondary-main)",
        },
        '&:focus': {
          outline: 'none',
          //backgroundColor: "green",
        },
      },
    },
  };
  export const customStyles1 = {
    header: {
      style: {
        border: "solid 1px black",
        fontSize: '18px',
        textAlign:"center",
        color: "white",
        backgroundColor: "var(--secondary-light)",
        minHeight: '20px',
        height:"25px",
        width:"90%",
        maxWidth:"100%",
        margin:"10px",
        marginLeft: "auto",
        marginRight: "auto",
      },
    },  
    title: {
      style: {
        fontColor: 'red',
        fontWeight: '900',
        marginRight: "15px",
      }
    },
    table: {
      style: {
        width: '99%',
        border: "solid 1px",
        marginLeft: "auto",
        marginRight: "auto"
      }
    },
    rows: {
      style: {
        minHeight: '30px',
        height:'fit-content !important'
      },
      highlightOnHoverStyle: {
        color: "var(--primary-dark)",
        backgroundColor: "var(--primary-light)",
        fontWeight:"550",
        transitionDuration: '0.15s',
        transitionProperty: 'background-color',
        borderBottomColor: "black",
        outlineStyle: 'solid',
        outlineWidth: '1px',
        outlineColor: 'black',
      }
    },
    headCells: {
      style: {
        fontSize: '11px',
        justifyContent: 'center',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        color: "var(--primary-dark)",
      },
      activeSortStyle: {
        color: "white",
        '&:hover:not(:focus)': {
          color: "var(--primary-dark)",
        },
      },
      inactiveSortStyle: {
        '&:focus': {
          color: "var(--primary-dark)",
        },
        '&:hover': {
          color: "var(--primary-dark)",
        },
      }
    },
    headRow: {
      style: {
        justifyContent: 'center',
        height: "25px",
        backgroundColor: "var(--secondary-light)",
        minHeight:"25px !important",
        '&:hover': {
          color: "var(--primary-dark)",
       },
      }
    },
    cells: {
      style: {
        fontSize: '11px',
        textAlign: "center",
        justifyContent: 'center',
        width:'inherit',
      },
    },
    pagination: {
      style: {
        color: "black",
        fontSize: '13px',
        minHeight: '20px',
        minWidth: '20px',
        height:"45px",
        width:"95%",
        marginLeft: "auto",
        marginRight: "auto",
        backgroundColor: "white",
        borderTopStyle: 'solid',
        borderTopWidth: '1px',
        
      },
      pageButtonsStyle: {
        borderRadius: '50%',
        height: '40px',
        width: '40px',
        padding: '8px',
        margin: 'px',
        cursor: 'pointer',
        transition: '0.4s',
        color: "blue",
        fill: "var(--secondary-dark)",
        backgroundColor: 'transparent',
        '&:disabled': {
          cursor: 'unset',
          color: "red",
          fill: "var(--secondary-main)",
        },
        '&:hover:not(:disabled)': {
          backgroundColor: "var(--secondary-main)",
        },
        '&:focus': {
          outline: 'none',
          //backgroundColor: "green",
        },
      },
    },
  };

  export const customStylesTicket = {
    table: {
      style: {
        width: 'auto',
        border: "solid 1px black",
        borderRadius:"0 !important",
        marginLeft: "1px",
        marginRight: "1px",
        marginTop:'0px'
      }
    },
    rows: {
      style: {
        minHeight: '20px',
        maxHeight: '40px',
        color:"black",
        paddingBottom:"2px",
        paddingTop:"2px",
      },
      highlightOnHoverStyle: {
        color: "var(--primary-light)",
        backgroundColor: "var(--secondary-light)",
        transitionDuration: '0.15s',
        transitionProperty: 'background-color',
        borderBottomColor: "black",
        outlineStyle: 'solid',
        outlineWidth: '1px',
        outlineColor: 'black',
      }
    },
    headCells: {
      style: {
        alignItems:'center',
        textAlign:"center",
        fontSize: '12px',
        justifyContent: 'center',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        color: "white",
        minWidth:"0px !important",
        padding:"0px 1px"
      },
      activeSortStyle: {
        color: "white",
        '&:hover:not(:focus)': {
          color: "white",
        },
      },
      inactiveSortStyle: {
        '&:focus': {
          color: "white",
        },
        '&:hover': {
          color: "white",
        },
      }
    },
    headRow: {
      style: {
        textAlign:"center",
        fontSize: '12px',
        justifyContent: 'center',
        alignItems:'center',
        height: "24px",
        backgroundColor:'black',
        width:"100% !important",
        minHeight:"24px !important",
        color:"black !important",
        '&:hover': {
          color: "black !important",
       },
      }
    },
    cells: {
      style: {
        fontSize: '11px',
        fontWeight:'600 !important',
        textAlign: "center",
        justifyContent: 'center',
        minWidth:"0px !important",
        padding:"0px 2px !important",
        whiteSpace:"pre-line !important",
        /*letterSpacing:"-1px"*/
      },
    },
  };

  export const customStylesTicket2 = {
    table: {
      style: {
        width: '98%',
        border: "solid 1px" + "var(--secondary-main)",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop:'0px'
      }
    },
    rows: {
      style: {
        minHeight: '20px',
        maxHeight: '40px',
        color:"black"
      },
      highlightOnHoverStyle: {
        color: "var(--primary-light)",
        backgroundColor: "var(--secondary-light)",
        transitionDuration: '0.15s',
        transitionProperty: 'background-color',
        borderBottomColor: "black",
        outlineStyle: 'solid',
        outlineWidth: '1px',
        outlineColor: 'black',
      }
    },
    headCells: {
      style: {
        alignItems:'center',
        textAlign:"center",
        fontSize: '12px',
        justifyContent: 'center',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        color: "white",
        minWidth:"0px !important",
        padding:"0px 1px"
      },
      activeSortStyle: {
        color: "white",
        '&:hover:not(:focus)': {
          color: "white",
        },
      },
      inactiveSortStyle: {
        '&:focus': {
          color: "white",
        },
        '&:hover': {
          color: "white",
        },
      }
    },
    headRow: {
      style: {
        textAlign:"center",
        fontSize: '12px',
        justifyContent: 'center',
        alignItems:'center',
        height: "24px",
        backgroundColor:'black',
        width:"100% !important",
        minHeight:"24px !important",
        '&:hover': {
          color: "white !important",
       },
      }
    },
    cells: {
      style: {
        fontSize: '9px',
        fontWeight:'600 !important',
        textAlign: "center",
        justifyContent: 'center',
        minWidth:"0px !important",
        padding:"0px 2px !important",
        whiteSpace:"pre-line !important",
      },
    },
  };

  export const numberColumnTicket={
    justifyContent:"end !important",
  }
  //a conditional style used in data table to apply a style in certain condition in rows only
  export const  conditionalRowStyle  =  []; 
  
   //a conditional cell style used in data table to apply a style in certain condition in cell only
   export const iconCell=
    {
          margin:"5px",
          width:"22px !important",
          maxWidth:" 22px !important",
          minWidth:" 22px !important",
    }
  
    export const  conditionalRowStyles  =  [ 
      {
        when: row => row.isbanned===true,
          style: {
                backgroundColor: "lightgray",
                color:"gray"
          }
      },
      {
        when: row => row.activ===0,
          style: {
                backgroundColor: "lightgray",
                color:"gray"
          }
      },
      {
        when: row => row.closed===1,
          style: {
                backgroundColor: "lightgray",
                color:"gray"
          }
      },
      {
        when: row => row.destroyed===1,
          style: {
                backgroundColor: "lightgray",
                color:"gray"
          },
      },
      {
        when: row => row.datusd!=null,
          style: {
            backgroundColor: "var(--bleu-light)",
                textColor:"white"
          },
      },
      {
        when: row => row.alert===true,
          style: {
                color:"red"
          },
      },
      {
        when: row => row.toggleSelected,
        style: {
          backgroundColor: "var(--bleu-light)",
          userSelect: "none",
          "&:hover":{
            backgroundColor: "var(--bleu-light)",
          }
        }
      }
      
    ]; 
    export const numberColumn={
      justifyContent:"end !important",
      paddingRight:'20px !important'
    }  
    