import React,{useState} from 'react';
import {DialogContent,DialogTitle,DialogActions,Paper,Dialog,Button, Tooltip} from '@material-ui/core';
import Draggable from 'react-draggable';
import {paperPropsSmall } from '../../../css/muiStyle';
import { useStyles } from '../../../css/muiStyle';
import Error from "../../../img/placholder.png"
import { useContext } from 'react';
import ParamContext from '../../../Contexte';
import '../../../css/compte.css'



//Add a new role
function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function Cat_add_profil(props) {
    const classes = useStyles();
    const param=useContext(ParamContext);
    const Gfunc=require('../../../Gfunc')
    const [open,setOpen]=useState(false)
    const [nom,setNom]=useState('')
    const service=(props.digit==='digit')?'_dig.php':'_cat.php'
    const image=(props.digit==='digit')?param.DigitImg:param.CatImg
    const ext='.png?'
    //const accept=(props.digit==='digit')?'.png':'.jpg'
    const accept='.png'
    const title=(props.digit==='digit')?'prod_'+props.row.liberoId:props.id.substr(1)
    const handleClickOpen = () => { 
        setOpen(true); 
        setNom('')
    };
    const handleClose = () => { setOpen(false); };

    const UpdateImg=async(file,id)=>{
      var formData = new FormData();
      formData.append("img", file);
      if(props.digit==='digit'){
        formData.append("id",props.row.liberoId);
      }else{
        formData.append("id",props.id);
      }
      const response = await Gfunc.axiosPostAction1(service ,'updimg',formData)
      if(response.data.err&&response.data.err!=='-1'){
        setOpen(false)
        props.handleAlert(true,"error",response.data.err)  
      }else{
        Gfunc.axiosResponse(response,()=>{
          if(response.data.jData===true){
            setOpen(false)
            document.getElementById("img-cat"+props.id).src=image+title+ext+new Date().getTime();
            props.handleAlert(true,"success"," L'image a été modifié avec succès")    
          }
        })
      }
    }

  return (
    <div >
      <Tooltip title="Changer l'image ">
        <div className='img-cat'>
          <img src={image+title+ext+new Date().getTime()} alt='cat ' onClick={handleClickOpen}  id={'img-cat'+props.id} 
              onError={(e) => {
              e.target.onerror = null
              e.target.src = Error }}/>
        </div>
      </Tooltip>
      <Dialog 
            open={open}
            onClose={handleClose}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={paperPropsSmall}
      >
        <DialogTitle className={classes.topDivTitle} onClose={handleClose}> Modifier L'image </DialogTitle>
        <DialogContent> 
          <div style={{display:'flex',gridGap:'5px',fontSize:'13px',fontWeight:'bold',alignItems:'center'}}>         
            <Button variant="contained" component="label"className={classes.fieldBtn}>
              Choisir une image
              <input
                  type="file"
                  hidden
                  accept={accept}
                  onChange={e=>{UpdateImg(e.target.files[0],props.id);setNom(e.target.files[0].name)}}
                />
           </Button>
           {
            (nom!=='')?
           <p>{nom}</p>:
           <p style={{color:'gray'}}>Aucune photo.</p>
           }
          </div>  
        </DialogContent>
        <DialogActions>
          <Button variant="contained"  onClick={handleClose} color="secondary">FERMER</Button>
        </DialogActions>
      </Dialog>
      </div>
  );
}
