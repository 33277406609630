import React,{useState} from 'react';
import {DialogContent,DialogTitle,Paper,Dialog,Button, DialogActions, Tooltip} from '@material-ui/core';
import Draggable from 'react-draggable';
import {paperPropsSmall } from '../../../css/muiStyle';
import { useStyles } from '../../../css/muiStyle';
import Error from "../../../img/placholder.png"
import { useContext } from 'react';
import ParamContext from '../../../Contexte';
import '../../../css/compte.css'


//Add a new role
function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function Ani_new_pub(props) {
   const classes = useStyles();
    const param=useContext(ParamContext);
    const Gfunc=require('../../../Gfunc')
    const [open,setOpen]=useState(false)
    const [nom,setNom]=useState('')
    const service='_new.php'

    const handleClickOpen = () => { 
        setOpen(true); 
        setNom('')
      };
    const handleClose = () => { setOpen(false); };

    const UpdateImg=async(file,id)=>{
      var formData = new FormData();
      formData.append("img", file);
      formData.append("id", props.id);
      const response = await Gfunc.axiosPostAction1(service ,'updimg',formData)
      if(response.data.err&&response.data.err!=='-1'){
       props.handleAlert(true,"error",response.data.err)
      }else{
      Gfunc.axiosResponse(response,()=>{
        if(response.data.jData===true){
          setOpen(false)
          document.getElementById("img-pub"+props.id).src=param.NewImg+props.id.substr(1)+'.jpg?'+new Date().getTime();}
          props.handleAlert(true,"success"," L'image a été modifié avec succès")    
         })
      }
    }
    
  return (
    <div > 
      <div id='div-pub'>
        <Tooltip title="Changer l'image ">
        <img src={param.NewImg+props.id.substr(1)+'.jpg?'+new Date().getTime()} alt='cat ' onClick={handleClickOpen} id={'img-pub'+props.id} 
             className='img-pub' 
             onError={(e) => {
               e.target.onerror = null
               e.target.src = Error }}/>
        </Tooltip>
      </div>
      <Dialog 
            open={open}
            onClose={handleClose}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={paperPropsSmall}
      >
        <DialogTitle className={classes.topDivTitle} onClose={handleClose}>Modifier L'image</DialogTitle>
        <DialogContent>  
          <div style={{display:'flex',gridGap:'5px',fontSize:'13px',fontWeight:'bold',alignItems:'center'}}>         
            <Button variant="contained" component="label" className={classes.fieldBtn}  >
              Choisir une image
              <input
                  type="file"
                  hidden 
                  accept=" .jpg"
                  onChange={e=>{UpdateImg(e.target.files[0],props.id);setNom(e.target.files[0].name)}}
                />
            </Button>
           {
            (nom!=='')?
            <p>{nom}</p>:
            <p style={{color:'gray'}}>Aucune photo.</p>
           }
          </div>
        </DialogContent>
        <DialogActions>
         <Button variant="contained"  onClick={handleClose} color="secondary">FERMER</Button>
        </DialogActions>
      </Dialog>
      </div>
  );
}
